import React, { useState } from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import ResponsiveService from "../../Services/ResponsiveService";
import { Helmet, HelmetProvider } from "react-helmet-async";
import datingSerious from "../../assets/images/dating-serious-1.jpg";
import datingSerious2 from "../../assets/images/dating-serious-2.jpg";
import BigTitle from "../../Components/Uikit/Typography/BigTitle";
import EliteSmallTitle from "../../Components/Uikit/Typography/SmallTitle";
import EliteParagraph from "../../Components/Uikit/Typography/Paragraph";

export function SerieusePage() {
    const [isMobile, setIsMobile] = useState(
        ResponsiveService.isMobile()
    );
    
    const handleResize = (e) => {
        e.preventDefault();
        if (isMobile !== ResponsiveService.isMobile()) {
          setIsMobile({ isMobile: ResponsiveService.isMobile() });
        }
    };
    window.addEventListener("resize", handleResize.bind(this));

    return (
        <HelmetProvider>
        <>
            <Helmet>
                <title>Site de Rencontre Sérieux et 100% Gratuit – Elite Berbère</title>
                <meta name="description" content="Elite berbère est le site de rencontre pour les femmes et les hommes qui 
                rechechent une relation sérieuse. L'inscription est gratuite !"/>
                <meta name="keywords" content="Célibataires exigeants, Trouver l âme soeur, Entrer en contact, Connectés, 
                Faire de nouvelles, Vie de couple, Premier message, Vie de célibataire, Gagner du temps, Créer un profil, 
                Flirt, Compatibles, Nouer, Rencontrer l amour, Trouver un partenaire, Bonne personne, Âme-soeur, Attractive, 
                Relation sérieuse, Célibataire, Sérieuse, Trouver l amour, Faire des rencontres, Affinités, Site de rencontre"/>
            </Helmet>
            <Header />
            <div className="container">
                <div className="row p-4">
                    <div style={{textAlign: "justify"}} className="col-12">
                        <BigTitle
                            marginBottom={"5vh"}
                            titleColor={"#4e4e4e"}
                            textAlign={"left"}
                            text={"Élite Berbère : un site de rencontre sérieux et gratuit"}
                        />
                        <EliteParagraph
                            text={<>
                                Vous recherchez l'<b>âme sœur</b> sur des <b>sites de rencontres</b>, mais ne parvenez pas à trouver une <b>relation sérieuse</b> ?
                                Élite Berbère est votre chance de <b>faire des rencontres</b> et d'enfin <b>trouver l'amour</b>. Laissez votre <b>vie de célibataire </b>
                                aux oubliettes et <b>entrez en contact</b> sur notre site de rencontre sérieux et gratuit avec des <b>célibataires exigeants</b>,
                                qui souhaitent aussi <b>rencontrer l'amour</b> pour de bon. Nous vous faisons <b>gagner du temps</b>, pour que vous puissiez <b>trouver
                                la bonne personne</b> sur notre site de rencontre sur mesure.
                            </>}
                            textAlign={"justify"}
                            fontSize={"1.3em"}/>

                        <EliteSmallTitle
                            textAlign={"left"}
                            text={"Un site de rencontre sérieux et gratuit inédit en France"} />
                        <EliteParagraph
                            text={<>
                                Énormément de sites sont présents sur le web dans le but de vous aider à trouver la femme ou l'homme idéal.
                                Vous ne trouvez pas votre bonheur et vous pensez avoir trop de critères pour enfin trouver la personne <b>compatible </b>
                                avec vous ? Élite Berbère est le premier site de rencontre sérieux et gratuit en France conçu par et pour les Berbères.
                                Ici, vous pouvez <b>nouer</b> des <b>affinités sérieuses</b> avec des personnes qui vous ressemblent. Tous les profils
                                que nous acceptons sur notre site de rencontre sérieux et gratuit sont soigneusement examinés de près,
                                afin de vous offrir une expérience de qualité. La personnalisation de recherche associée à ce tri sur le
                                volet vous permet de vous concentrer uniquement sur votre envie de <b>trouver l'âme sœur</b>.
                            </>}
                            textAlign={"justify"}/>
                        <img className={"img-fluid"} style={{marginBottom: "16px"}} src={ datingSerious } alt={ "serious dating 1" }/>

                        <EliteSmallTitle
                            textAlign={"left"}
                            text={"Exigez la relation que vous méritez sur Élite Berbère"} />
                        <EliteParagraph
                            text={<>
                                Chez <a href={"https://eliteberbere.fr"}>Élite Berbère</a>, notre priorité est de satisfaire tous nos utilisateurs.
                                Même dans une époque où nous sommes constamment <b>connectés</b>, <b>trouver un partenaire</b> peut être fastidieux.
                                Nous sommes là pour vous offrir l'opportunité
                                de <b>faire de nouvelles</b> rencontres de manière respectueuse et sécurisée. Notre offre <b>attractive</b> répond à votre
                                besoin d'exigence en matière de <b>flirt</b>. Il vous suffit de <b>créer un profil</b>, après quoi vous pourrez vous laisser
                                aller à envoyer un <b>premier message</b> à la personne qui saura attirer votre attention. Vous savez que vous méritez
                                quelqu'un à la hauteur de vos attentes et de vos valeurs, alors pourquoi perdriez-vous votre temps sur des sites
                                de rencontres généralistes ? Notre équipe veille à vous offrir la meilleure plateforme d'échanges pour que vous
                                puissiez démarrer une <b>vie de couple</b> saine et sur la même longueur d'onde.
                            </>}
                            textAlign={"justify"}/>

                        <EliteSmallTitle
                            textAlign={"left"}
                            text={"Des profils singuliers sur Élite Berbère"} />
                        <EliteParagraph
                            text={<>
                                Les rencontres se font parfois dans la vie réelle, parfois dans la vie virtuelle. <b>Élite Berbère</b> vous facilite
                                l'opportunité d'échanger avec des personnalités intéressantes et captivantes, au sein d'un espace sécurisé.
                                Vous serez enfin sûre de discuter avec quelqu'un qui vous ressemble, qui a les mêmes envies et priorités,
                                sans avoir à enchaîner les dates ratées. Après vous être découvert des points communs et des qualités
                                indispensables selon vos critères d'exigence, vous pourrez succomber à l'envie de vous voir autour d'un
                                café, sans risquer de perdre votre temps. Nos <b>célibataires</b> ont tous des profils uniques et sérieux.
                                Que vous recherchiez un homme ou une femme, vous pourrez compter sur une description détaillée de chaque personne,
                                afin de pouvoir au mieux vous projeter. Grâce à nos critères élitistes, soyez sûrs de tomber sur un profil honnête
                                et désireux de trouver l'amour de sa vie.
                            </>}
                            textAlign={"justify"}/>
                        <EliteParagraph
                            text={<>
                                La vie amoureuse que vous méritez est à portée de clic, saisissez-la sur Élite Berbère.
                            </>}
                            textAlign={"justify"}/>
                        <img className={"img-fluid"} style={{marginBottom: "16px"}} src={ datingSerious2 } alt={ "serious dating 2" }/>
                    </div>
                </div>
            </div>
            <Footer />
        </>
        </HelmetProvider>
    );
}
