import React, { useEffect } from "react";
import ConnectedHeader from "../../Components/Header/ConnectedHeader/ConnectedHeader";
import ProfilePhoto from "../../Components/ProfilePhoto/ProfilePhoto";
import Tags from "../../Components/Uikit/Tags/Tags";
import TitleOverTitle from "../../Components/Uikit/TitleOverTitle/TitleOverTitle";
import infosReligion from "../../assets/images/infos-religion.svg";
import CustomTabs from "../../Components/Uikit/Tabs/Tabs";
import EliteParagraph from "../../Components/Uikit/Typography/Paragraph";
import AProposDeMoi from "./AProposDeMoi";
import JeRecherche from "./JeRecherche";
import Footer from "../../Components/Footer/Footer";
import iconUserTitle from "../../assets/images/icon-user-title.svg";
import { useCurrentUser } from "../../Hooks/AuthenticatedUser";
import { toast } from "react-toastify";
import { origins } from "../../Services/DataLists";

function handlePercentage({ percentageComplete }) {
  if (percentageComplete < 100) {
    toast.warning("Profil non complété à 100%", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
}

function getImageLabel(value) {
  let imageLabel = "";
  origins.forEach((e) => {
    if (value && (e.value === value || e.value === value[0])) {
      imageLabel = e.imageLabel;
    }
  });
  return imageLabel;
}

let to = null;

export function Profile({ isMobile }) {
  const { user } = useCurrentUser();

  useEffect(() => {
    if(to) {
      clearTimeout(to)
    }
    to = setTimeout(() => {
      handlePercentage(user)
    }, 1000);
  }, [user]);

  const tabsItems = [
    {
      menuItem: (
        <EliteParagraph
          textAlign={"left"}
          uppercase
          fontWeight={500}
          margin={"0"}
          text={"à propos de moi"}
          cursor={"pointer"}
        />
      ),
      menuContent: (
        <>
          <EliteParagraph
            lineHeight={1.5}
            fontWeight={400}
            textAlign={"left"}
            fontSize={"14px"}
            text={user.profile.description}
          />
          <AProposDeMoi user={user} />
        </>
      ),
    },
    {
      menuItem: (
        <EliteParagraph
          uppercase
          textAlign={"left"}
          fontWeight={500}
          margin={"0"}
          text={"Ce que je recherche"}
          cursor={"pointer"}
        />
      ),
      menuContent: (
        <>
          <EliteParagraph
            lineHeight={1.5}
            fontWeight={400}
            textAlign={"left"}
            fontSize={"14px"}
            text={user.searching.description}
          />
          <JeRecherche user={user} />
        </>
      ),
    },
  ];

  let origin  = '-';

  if(user.profile.origin) {
    origin = user.profile.origin;
  }

  return (
    <div>
      <ConnectedHeader />
      <div className={"container"}>
        <div className="row">
          <div className="col-0" />
          <div className="col-12">
            <div className="row">
              <div className="col-12">
                <TitleOverTitle
                  backTitleSize={isMobile && "29px"}
                  frontTitleSize={isMobile && "22px"}
                  title={"Mon profil"}
                  overTitleMarginTop={isMobile && "-25px"}
                  icon={iconUserTitle}
                />
              </div>
              <div className="col-5 d-flex flex-column">
                <ProfilePhoto
                  user={user}
                  noMargin
                  size={"155"}
                  completitionPercentage={parseInt(user.percentageComplete)}
                />
                <div className="d-flex justify-content-evenly mt-4 p-4">
                  <Tags
                    icon={`/images/flags/${getImageLabel(
                      origin
                    )}.png`}
                    smallText={"Origine"}
                    text={origin}
                  />
                  <Tags
                    icon={infosReligion}
                    smallText={"je suis"}
                    text={user.profile.religion ? user.profile.religion : "-"}
                  />
                </div>
              </div>
              <div className="col-7 mb-5">
                <CustomTabs tabs={tabsItems} />
              </div>
            </div>
          </div>
          <div className="col-0" />
        </div>
      </div>
      <Footer isLogged />
    </div>
  );
}
