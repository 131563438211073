import React, {useState} from "react";
import { ReactSVG } from "react-svg";
import backArrow from "../../../assets/images/icon-arrow-back.svg";
import iconCheck from "../../../assets/images/icon-check.svg";
import TxtAsButton from "../../../Components/Uikit/TxtAsButton/TxtAsButton";
import ChooseProfilePhoto from "../../../Components/ChooseProfilePhoto/ChooseProfilePhoto";
import CustomTabs from "../../../Components/Uikit/Tabs/Tabs";
import Footer from "../../../Components/Footer/Footer";
import FooterMenu from "../../../Components/FooterMenu/FooterMenu";
import { EliteRouters } from "../../../Navigation/Routers";
import EliteParagraph from "../../../Components/Uikit/Typography/Paragraph";
import CustomTextArea from "../../../Components/Uikit/TextArea/TextArea";
import ProfileDetailsEdition from "../../../Components/ProfileDetailsEdition/ProfileDetailsEdition";
import CeQueRecherche from "../../../Components/ProfileDetailsEdition/CeQueRecherche";
import { useHistory } from "react-router-dom";
import { useCurrentUser } from "../../../Hooks/AuthenticatedUser";
import { saveProfile } from "../Actions/ProfileActions";
import { Form, Formik } from "formik";
import * as yup from "yup";
import Spinner from "react-bootstrap/Spinner";

export function ProfileEditionMobile() {
  const history = useHistory();

  const goBack = () => history.push(EliteRouters.profile.path);
  const { user, setUser } = useCurrentUser();
  const [loading, setLoading] = useState(false);

  const updateProfile = (attr, value) => {
    setUser({ ...user, profile: { ...user.profile, [attr]: value } })
  }

  const updateSearching = (attr, value) => {
    setUser({ ...user, searching: { ...user.searching, [attr]: value } })
  }

  const setImages = (images) => {
    setUser({ ...user, images })
  }

  const descriptionValidationSchema = yup.object().shape({
    description: yup.string().required('Champ obligatoire').min(50).max(500)
  })

  async function onSaveProfile() {
    const profile = await saveProfile(user)
    if (profile) {
      setUser(profile);
    }
  }
  const tabsItems = [
    {
      menuItem: (
        <EliteParagraph
          textAlign={ "left" }
          uppercase
          fontWeight={ 500 }
          margin={ "0" }
          text={ "à propos de moi" }
          fontSize={ "12px" }
        />
      ),
      menuContent: (
        <>
          <div className={ "container" }>
            <div className="row">
              <div className={ "col-md-12" }>
                <ChooseProfilePhoto
                  isMobile
                  className={ "mb-2" }
                  images={ user.images }
                  onChange={ setImages }
                />
                <EliteParagraph
                  textColor={ "#6c95e7" }
                  uppercase
                  fontWeight={ 500 }
                  textAlign={ "left" }
                  margin={ "32px 0 16px 0" }
                  text={ "Description" }
                />
                <Formik
                  initialValues={ { description: user.profile.description } }
                  validationSchema={ descriptionValidationSchema }
                  validateOnChange
                >
                  { ({ setFieldValue, values, isValid, handleBlur }) => (
                    <Form className={ "needs-validation" }>
                      <div className={ `has-validation ${ isValid ? '' : 'isInvalid' }` }>
                        <CustomTextArea
                          name="description"
                          classes={ isValid ? '' : 'isInvalid' }
                          maxCharacters={ 500 }
                          minCharacters={ 50 }
                          value={ values.description === undefined ? user.profile.description : values.description }
                          onChange={ (value) => {
                            updateProfile('description', value)
                            setFieldValue('description', value, true)
                          } }
                          onBlur={ handleBlur }
                        />
                      </div>
                    </Form>
                  ) }
                </Formik>
                <EliteParagraph
                  textColor={ "#6c95e7" }
                  uppercase
                  fontWeight={ 500 }
                  textAlign={ "left" }
                  text={ "Détails" }
                  margin={ "32px 0 16px 0" }
                />
              </div>
            </div>
          </div>
          <ProfileDetailsEdition isMobile user={ user } onChange={ setUser }/>
        </>
      ),
    },
    {
      menuItem: (
        <EliteParagraph
          uppercase
          textAlign={ "left" }
          fontWeight={ 500 }
          margin={ "0" }
          text={ "Ce que je recherche" }
          fontSize={ "12px" }
        />
      ),
      menuContent: (
        <>
          <div className={ "container" }>
            <div className="row">
              <div className={ "col-md-12" }>
                <EliteParagraph
                  textColor={ "#6c95e7" }
                  uppercase
                  fontWeight={ 500 }
                  textAlign={ "left" }
                  margin={ "32px 0 16px 0" }
                  text={ "Description" }
                />
                <Formik
                  initialValues={ { description: user.searching.description } }
                  validationSchema={ descriptionValidationSchema }
                  validateOnChange
                >
                  { ({ setFieldValue, values, isValid, handleBlur }) => (
                    <Form className={ "needs-validation" }>
                      <div className={ `has-validation ${ isValid ? '' : 'isInvalid' }` }>
                        <CustomTextArea
                          name="description"
                          classes={ isValid ? '' : 'isInvalid' }
                          maxCharacters={ 500 }
                          minCharacters={ 50 }
                          value={ values.description }
                          onChange={ (value) => {
                            updateSearching('description', value)
                            setFieldValue('description', value, true)
                          }}
                          onBlur={ handleBlur }
                        />
                      </div>
                    </Form>
                  ) }
                </Formik>
                <EliteParagraph
                  textColor={ "#6c95e7" }
                  uppercase
                  fontWeight={ 500 }
                  textAlign={ "left" }
                  text={ "Détails" }
                  margin={ "32px 0 16px 0" }
                />
              </div>
            </div>
          </div>
          <CeQueRecherche isMobile user={ user } onChange={ setUser }/>
        </>
      ),
    },
  ];

  return (
    <div>
      <div className={ "container" }>
        <div className="row">
          <div className="col-lg-1 col-md-12"/>
          <div className="col-lg-3 col-md-12">
            <div className={ "back py-3" }>
              <div
                onClick={ goBack }>
                <ReactSVG src={ backArrow }/> Modifier mon profil
              </div>
            </div>
          </div>
        </div>
      </div>
        {loading ?
          <div className="row">
            <div className="loading-searching">
              <Spinner animation="border" />
            </div>
          </div> :
          <CustomTabs isMobile tabs={ tabsItems }/>
        }
      <div className={ "container" }>
        <div className="row">
          <div className="col-lg-4 col-md-12"/>
          <div className="col-lg-3 col-md-12 mt-4 mb-4 justify-content-center d-none d-xs-none d-sm-none d-md-none">
            <TxtAsButton
              width={ "100%" }
              icon={ iconCheck }
              text={ "Valider les modifications" }
              onClick={ onSaveProfile }
            />
          </div>
          <div className="lg-2 col-md-12">
            <div className="row">
              <div className="col-lg-1 col-md-12"/>
              <div className="col-md-12 mt-4  justify-content-center d-block d-lg-none d-xl-none d-xxl-none">
                <TxtAsButton
                  width={ "100%" }
                  icon={ iconCheck }
                  text={ "Valider les modifications" }
                  onClick={ () => {
                    setLoading(true)
                    onSaveProfile().then(() => setLoading(false)).catch(() => setLoading(false))
                  } }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer isLogged/>
      <FooterMenu/>
    </div>
  );
}

export default ProfileEditionMobile;
