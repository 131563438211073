import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NextArrow from "./NextArrow/NextArrow";
import BackArrow from "./BackArrow/BackArrow";
import "./CarouselStyles.css";
import SliderContainer from "../../OurMeets/OurMeetsSliderContainer/OurMeetsSliderContainer";
import ResponsiveService from "../../../Services/ResponsiveService";
import MatchMakingSlider from "../../MatchMakingDuJour/MatchMakingSlider/MatchMakingSlider";
import CardOffer from "../../Offers/CardOffer";
import VisitedProfileSlider from "../../VisitedProfile/VisitedProfileSlider";

class Carousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: ResponsiveService.isMobile(),
    };
    window.addEventListener("resize", this.handleResize.bind(this));
  }

  handleResize() {
    if (this.state.isMobile !== ResponsiveService.isMobile()) {
      this.setState({ isMobile: ResponsiveService.isMobile() });
    }
  }

  render() {
    const { isMobile } = this.state;
    var settings = {
      initialSlide: this.props.initialSlide,
      adaptiveHeight: this.props.adaptiveHeight,
      variableWidth: this.props.variableWidth,
      dots: this.props.hideDots ? false : true,
      dotsClass: this.props.dotsClass || "slick-dots",
      infinite: this.props.infinite,
      centerMode: this.props.centerMode,
      centerPadding: this.props.centerPadding,
      speed: 500,
      slidesToShow: this.props.slidesToShow,
      slidesToScroll: this.props.slidesToScroll,
      nextArrow: <NextArrow />,
      prevArrow: <BackArrow />,
      arrows: !(isMobile || this.props.hideArrows),
      responsive:
        this.props.responsiveBreakpoints && this.props.responsiveBreakpoints,
    };
    return (
      <Slider {...settings}>
        {this.props.data.map((sliderContent, i) => {
          switch (this.props.carouselType) {
            case "ourMeets":
              return <SliderContainer key={i} sliderData={sliderContent} />;
            case "matchMaking":
              return (
                <div>
                <MatchMakingSlider
                  isMobile={isMobile}
                  key={i}
                  sliderData={sliderContent}
                />
                </div>
              );
            case "visitedProfile":
              return (
                <VisitedProfileSlider
                  user={this.props.additionalData}
                  key={i}
                  sliderData={sliderContent}
                  isMobile={isMobile}
                />
              );
            case "offers":
              return (
                <CardOffer
                  key={i}
                  isMobile={this.state.isMobile}
                  sliderData={sliderContent}
                  updateCurrency={this.props.updateCurrency}
                  devise={this.props.devise }
                />
              );

            default:
              break;
          }
          return true;
        })}
      </Slider>
    );
  }
}
Carousel.defaultProps = {
  initialSlide: 0,
  centerMode: false,
  centerPadding: "1px",
  slidesToShow: 5,
  slidesToScroll: 4,
};
export default Carousel;
