import React from "react";
import { ReactSVG } from "react-svg";
import FooterMenu from "../../../Components/FooterMenu/FooterMenu";
import backArrow from "../../../assets/images/icon-arrow-back.svg";
import "./MesLikesMobileStyles.css";
import { useLikers } from "../../../Hooks/Likes";
import { useHistory } from "react-router-dom";
import { isEmpty } from "lodash-es";
import GridMosaic from "../../../Components/Uikit/PhotoMosaic/PhotoMosaic_new";

export function MesLikesMobile() {
  const { likers } = useLikers();
  const history = useHistory();

  return (
    <div>
      <div className="container mesLikesMobile">
        <div className="col-12 col-sm-6">
          <div className={"back py-2"}>
            <div onClick={() => history.push("/home")}>
              <ReactSVG src={backArrow} /> Mes likes
            </div>
          </div>
        </div>
        <div className="col-12 pb-5">
        <div className="row g-0">
        {!isEmpty(likers) ? (
            <GridMosaic
            rows={2}
            padding={'4px'}
              isMobile
              photoType={"miniature"}
              photoSize={"small"}
              data={likers}
            />
          ) : (
            <div className="center">
              <h6 className="pt-5">
                <i>Aucun like pour le moment</i>
              </h6>
            </div>
          )}
        </div>
          
        </div>
      </div>
      <FooterMenu />
    </div>
  );
}

export default MesLikesMobile;
