import React, { useState } from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import ResponsiveService from "../../Services/ResponsiveService";
import { Helmet, HelmetProvider } from "react-helmet-async";
import datingIledeFrance from "../../assets/images/dating-iledefrance-1.jpg";
import datingIledeFrance2 from "../../assets/images/dating-iledefrance-2.jpg";
import BigTitle from "../../Components/Uikit/Typography/BigTitle";
import EliteSmallTitle from "../../Components/Uikit/Typography/SmallTitle";
import EliteParagraph from "../../Components/Uikit/Typography/Paragraph";


export function IleDeFrancePage() {
    const [isMobile, setIsMobile] = useState(
        ResponsiveService.isMobile()
    );
    
    const handleResize = (e) => {
        e.preventDefault();
        if (isMobile !== ResponsiveService.isMobile()) {
          setIsMobile({ isMobile: ResponsiveService.isMobile() });
        }
    };
    window.addEventListener("resize", handleResize.bind(this));

    return (
        <HelmetProvider>
        <>
            <Helmet>
                <title>Site de rencontre Île de France | Elite Berbère</title>
                <meta name="description" content="Faites de belles rencontres 
                dans la région Île-de-France. Trouvez votre partenaire avec le site de rencontre de référence Elite Berbère."/>
                <meta name="keywords" content="Trouver un partenaire, Vie amoureuse, Rencontres amicales, Perle rare, Premier 
                rendez-vous, Célibat, Recherche d une relation, Votre personnalité, Se rencontrer, Rencontrer l amour, 
                Amicales, Relation durable, Faire connaissance, Pour rencontrer, Amoureuses, Faire une rencontre, 
                Rencontrez, Âme-soeur, Trouver l amour, Célibataires, Relation sérieuse, Site de rencontre, Affinités, 
                Célibataire, Faire des rencontres"/>
            </Helmet>
            <Header />
            <div className="container">
                <div className="row p-4">
                    <div style={{textAlign: "justify"}} className="col-12">
                        <BigTitle
                            marginBottom={"5vh"}
                            titleColor={"#4e4e4e"}
                            textAlign={"left"}
                            text={"Elite berbère, numéro un des sites de rencontre berbère en Île-de-France"}
                        />
                        <EliteParagraph
                            text={<>
                                Nous sommes un site spécialisé dans les <a href={"https://eliteberbere.fr"}>rencontres berbères</a> en
                                région parisienne.
                                Nous proposons des services premium à celles et ceux qui désirent sortir du <b>célibat</b> et qui
                                souhaitent <b>faire des rencontres</b> pour trouver un <b>partenaire</b>. Avec nous, vous allez dénicher
                                la <b>perle rare</b> et réussir votre <b>vie amoureuse</b>, en rencontrant des personnes qui correspondent
                                à votre <b>personnalité</b>. Vous ferez également des rencontres amicales, car quand on recherche une
                                <b>relation</b>, cela ne fonctionne pas toujours au <b>premier rendez-vous</b>.
                            </>}
                            textAlign={"justify"}
                            fontSize={"1.3em"}/>

                        <EliteSmallTitle
                            textAlign={"left"}
                            text={"Élite Berbère, pour rencontrer l'amour en Île-de-France"} />
                        <EliteParagraph
                            text={<>
                                La vie moderne est ainsi faite qu'il est difficile de <b>se rencontrer</b>, tant les contraintes privées
                                et professionnelles sont présentes aujourd'hui. <b>Faire connaissance</b> et nouer un <b>lien amical</b> est assez
                                facile, mais tout se complique dès que l'on veut développer une <b>relation durable</b>. C'est pourquoi notre
                                site de rencontre en Ile de France a sélectionné pour sa clientèle les meilleurs profils berbères de
                                Paris et de l'île de France. Notre fichier client est riche de femmes et d'hommes qui souhaitent
                                développer des <b>relations sérieuses</b> au sein de leur communauté. Élite Berbère se charge de mettre
                                en rapport celles et ceux qui veulent vraiment donner un nouvel élan à leur <b>vie amoureuse</b>.
                            </>}
                            textAlign={"justify"}/>
                        <img className={"img-fluid"} style={{marginBottom: "16px"}} src={ datingIledeFrance } alt={ "rencontre ile de France 1" }/>

                        <EliteSmallTitle
                        textAlign={"left"}
                        text={"Pourquoi choisir Élite Berbère, le site de rencontre en Île-de-France ?"} />
                        <EliteParagraph
                            text={<>
                                Nous ne sommes pas un site comme les autres, qui se contente de fournir les coordonnées de gens ayant
                                un même désir de rencontre. Ce que nous faisons est très différent, nous sommes en effet de véritables
                                chasseurs de cœurs” ! Nous mettons toute notre expérience et notre savoir-faire à votre seul service,
                                pour que vous trouviez la <b>personne idéale</b> pour vous, votre <b>âme sœur</b>. C'est pourquoi nous sommes si
                                exigeant au moment de l'inscription sur le site de rencontre en Ile de France Élite Berbère, nous
                                souhaitons présenter les meilleurs profils à notre clientèle. C'est pour cette raison que nous
                                sélectionnons soigneusement les personnes amenées à <b>se rencontrer</b>, pour mettre toutes
                                les chances de votre côté.
                            </>}
                            textAlign={"justify"}/>

                        <EliteSmallTitle
                            textAlign={"left"}
                            text={"Combien de temps pour trouver un partenaire en Île-de-France avec Élite Berbère ?"} />
                        <EliteParagraph
                            text={<>
                                Nous ne pouvons garantir précisément la date à laquelle vous allez <b>rencontrer l'amour</b> sur le site de
                                rencontre en Île-de-France, mais nous savons que vous y arriverez, plus rapidement que vous ne le pensez.
                                En effet, nous avons déjà permis à des milliers de personnes de se rencontrer en région parisienne, dans
                                le respect et la convivialité. Nous avons une approche originale qui permet de lier les relations
                                professionnelles et privées, grâce à des outils qui offrent une véritable personnalisation de la recherche
                                des futurs <b>partenaires</b>. Nous disposons de logiciels particulièrement efficaces qui aident nos conseillers à
                                définir précisément ce qui est le plus pertinent pour vous.
                            </>}
                            textAlign={"justify"}/>
                        <EliteParagraph
                            text={<>
                                Avec Élite Berbère, vous allez découvrir qu'il est possible de <b>faire des rencontres</b> de haut niveau en région
                                parisienne et en Île-de-France. Vous allez donner de la visibilité à votre <b>personnalité</b> et booster votre profil
                                amoureux. Vous allez <b>faire connaissance</b> avec des gens qui vous correspondent vraiment au sein de la communauté
                                berbère d'Île-de-France et <b>trouver l'amour</b>.
                            </>}
                            textAlign={"justify"}/>
                        <img className={"img-fluid"} style={{marginBottom: "16px"}} src={ datingIledeFrance2 } alt={ "rencontre ile de France 2" }/>
                    </div>
                </div>
            </div>
            <Footer />
        </>
        </HelmetProvider>
    );
}
