import React from "react";
import { ErrorMessage, Form, Formik } from "formik";
import "./ModalFieldStyle.css";
import CustomButton from "../Button/CustomButton";
import { map } from "lodash-es";
import EliteParagraph from "../Typography/Paragraph";
import { handleError } from "../../../Services/ErrorHandler";

export function ModalFields(props) {
  async function onSubmit(values, formik) {
    try {
      await (props.onChange && props.onChange(values));
    } catch (e) {
      handleError(e);
      if (e.response && e.response.status === 422) {
        formik.setErrors(e.response.data.errors);
      }
    } finally {
      formik.setSubmitting(false);
    }
  }

  function renderFields({ setFieldValue, values }) {
    return map(props.fields, (field, name) => {
      return (
        <div key={name}>
          <EliteParagraph text={field.label} textAlign="left" />
          {field.render({
            value: values[name],
            onChange: (value) => setFieldValue(name, value),
          })}
          <ErrorMessage
            component="div"
            className={"invalid-feedback d-block"}
            name={name}
          />
        </div>
      );
    });
  }

  return (
    <>
      <Formik
        initialValues={{}}
        validationSchema={props.validationSchema}
        onSubmit={onSubmit}>
        {({ isSubmitting, setFieldValue, values }) => (
          <Form className={"needs-validation px-2"}>
            <div className={`mb-4 has-validation ${props.classes || ""}`}>
              {renderFields({ setFieldValue, values })}
            </div>
            <div
              className={`position-absolute ${props.isMobile ? "px-2" : ""}`}
              style={{ width: props.isMobile ? "95%" : "365px", bottom: 100 }}>
              <CustomButton
                type="submit"
                isDisabled={isSubmitting}
                label={"valider"}
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default ModalFields;
