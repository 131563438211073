import React from "react";
import { ReactSVG } from "react-svg";
import backArrow from "../../../assets/images/icon-arrow-back.svg";
import Carousel from "../../../Components/Uikit/Carousel/Carousel";
import EliteParagraph from "../../../Components/Uikit/Typography/Paragraph";
import AProposDeMoi from "../../Profile/AProposDeMoi";
import JeRecherche from "../../Profile/JeRecherche";
import CustomTabs from "../../../Components/Uikit/Tabs/Tabs";
import "../VisitedProfileStyles.css";
import infosReligion from "../../../assets/images/infos-religion.svg";
import iconDiscuter from "../../../assets/images/icon-chat-bot.svg";
import iconDiscuterDisabled from "../../../assets/images/icon-chat-grey.svg";
import iconLoveEmpty from "../../../assets/images/icon-love-empty-bot.svg";
import iconLoveFull from "../../../assets/images/icon-love-full-bot.svg";
import Tags from "../../../Components/Uikit/Tags/Tags";
import CustomButton from "../../../Components/Uikit/Button/CustomButton";
import { useHistory, useParams } from "react-router-dom";
import { useProfile } from "../../../Hooks/Profile";
import { useLiked } from "../../../Hooks/Likes";
import { origins } from "../../../Services/DataLists";


import "./VisitedProfileMobileStyles.css";
import { handleError } from "../../../Services/ErrorHandler";
import Spinner from "react-bootstrap/Spinner";

export default function VisitedProfileMobile(props) {
  const { id } = useParams()
  const { profile } = useProfile(id);
  const { toggleLike, isLiked } = useLiked();

  const history = useHistory()


  async function onToggleLike(e) {
    e.stopPropagation();
    try {
     await toggleLike(profile);
    } catch (e) {
      handleError(e);
    }
  }

  function openChat() {
    history.push(`/messages/${ profile.room?.id }`)
  }

  function getImageLabel(value) {
    let imageLabel = "";
    origins.forEach((e) => {
      if (value && e.value === value) {
        imageLabel = e.imageLabel;
      }
    });
    return imageLabel;
  }

  if (!profile) {
    return (
      <div style={{alignItems: "center", justifyContent: "center", position: "absolute", top: 0, bottom: 0, left: 0, right: 0, display: "flex"}}>
        <Spinner animation="border" />
      </div>
    )
  }

  const tabsItems = [
    {
      menuItem: (
        <EliteParagraph
          textAlign={ "left" }
          uppercase
          fontWeight={ 500 }
          margin={ "0" }
          text={ "à propos de moi" }
          fontSize={ '12px' }
        />
      ),
      menuContent: (
        <>
          <EliteParagraph
            lineHeight={ 1.5 }
            fontWeight={ 400 }
            textAlign={ "left" }
            fontSize={ "14px" }
            text={ profile.profile.description }
          />
          <AProposDeMoi user={ profile }/>
          <div style={{marginBottom: 80}} />
        </>
      ),
    },
    {
      menuItem: (
        <EliteParagraph
          uppercase
          textAlign={"left"}
          fontWeight={500}
          margin={"0"}
          text={"Ce que je recherche"}
          fontSize={'12px'}
        />
      ),
      menuContent: (
        <>
          <EliteParagraph
            lineHeight={ 1.5 }
            fontWeight={ 400 }
            textAlign={ "left" }
            fontSize={ "14px" }
            text={ profile.searching.description }
          />
          <JeRecherche user={ profile }/>
          <div style={{marginBottom: 80}} />
        </>
      ),
    },
  ];
  const responsiveBreakpoints = [
    {
      breakpoint: 425,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 320,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];

  let origin  = '-';

  if(profile.profile.origin) {
    origin = profile.profile.origin;
  }
  return (
    <>
      <div className={ "back mobile position-absolute pt-3" }>
        <div onClick={ () => {
          if(props.history.location.key) {
            props.history.goBack()
          }else {
            window.location.href = '/home';
          }
        }}>
          <ReactSVG src={ backArrow }/>
        </div>
      </div>

      <div className={ "d-flex flex-column position-relative" }>
        {profile.images.length !== 0 ?
          <Carousel
            hideArrows
            dotsClass={"dotsVisitedProfile"}
            slidesToShow={ 1 }
            slidesToScroll={ 1 }
            carouselType={ "visitedProfile" }
            data={ profile.images }
            additionalData={ profile }
            responsiveBreakpoints={ responsiveBreakpoints }/>
          :
          <div className={'no-image-mobile'}>
            Cet utilisateur n'a pas de photo valide
          </div>
        }
        <div className="d-flex justify-content-between mt-2">
          <Tags
            icon={`/images/flags/${getImageLabel(
              origin,
            )}.png`}
            smallText={ "origine" }
            text={ origin } />
          <Tags
            icon={ infosReligion }
            smallText={ "je suis" }
            text={ profile.profile.religion }/>
        </div>
      </div>
      {
        typeof profile.room?.id === "undefined" &&
        <div className="col-12" style={{fontSize: 12, padding: 10, color: '#bcbcbc', fontWeight: 'bold'}}>
          Les conversations se lancent automatiquement, lorsque 2 utilisateurs se sont respectivement likés.
        </div>
      }
      <div className="container fixed-bottom mb-3">
        <div className={"row"}>
          <div className="col-lg-3 col-6">
            <CustomButton
              icon={typeof profile.room?.id === "undefined" ? iconDiscuterDisabled : iconDiscuter}
              label={ "Discuter" }
              onClick={ openChat }
              isDisabled={typeof profile.room?.id === "undefined"}
            />
          </div>
          <div className="col-lg-3 col-6 m-auto">
            <CustomButton
              icon={
                isLiked(profile) ? iconLoveFull : iconLoveEmpty
              }
              secondary
              textGreen
              label={ isLiked(profile) ? "UNLIKER" : "LIKER" }
              onClick={ onToggleLike }
            />
          </div>
          <div className="clearfix" />
        </div>
      </div>
      <div className={"container"}>
        <div className={"row"}>
          <div className={"col-lg-8 col-md-12 d-flex flex-column"}>
            <div className="mt-4">
              <CustomTabs tabs={ tabsItems }/>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}
