import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { EliteRouters } from "./Routers";

//Services
import ResponsiveService from "../Services/ResponsiveService";
import CookiesBannerMobile from "../Components/CookiesBanner/Mobile/CookiesBannerMobile";
import CookiesBanner from "../Components/CookiesBanner/CookiesBanner";

class CustomRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: ResponsiveService.isMobile(),
      cookiesVisible: false
    };
    window.addEventListener("resize", this.handleResize.bind(this));
  }

  componentDidMount() {
    let cookieState = localStorage.getItem('cookie_popup_state')
    this.setState({
      cookiesVisible: cookieState === null
    })
  }

  handleResize() {
    if (this.state.isMobile !== ResponsiveService.isMobile()) {
      this.setState({ isMobile: ResponsiveService.isMobile() });
    }
  }

  requireAuth() {
    return localStorage.getItem("tokenApi");
  }

  renderPage(isMobile) {
    // eslint-disable-next-line no-unused-vars
    const auth = this.requireAuth();
    let pages = [
      {
        path: EliteRouters.uikit.path,
        component: EliteRouters.uikit.components.desktop,
        exact: true,
      },
      {
        path: EliteRouters.sigup.path,
        component: isMobile
          ? EliteRouters.sigup.components.mobile
          : EliteRouters.nomatch.components.desktop,
        exact: true,
      },
      {
        path: EliteRouters.forgotPassword.path,
        component: isMobile
          ? EliteRouters.forgotPassword.components.mobile
          : EliteRouters.forgotPassword.components.desktop,
        exact: true,
      },
      {
        path: EliteRouters.resetPassword.path,
        component: isMobile
          ? EliteRouters.resetPassword.components.mobile
          : EliteRouters.resetPassword.components.desktop,
        exact: false,
      },
      {
        path: EliteRouters.confirmEmail.path,
        component: isMobile
          ? EliteRouters.confirmEmail.components.mobile
          : EliteRouters.confirmEmail.components.desktop,
        exact: false,
      },
      {
        path: EliteRouters.login.path,
        component: isMobile
          ? EliteRouters.login.components.mobile
          : EliteRouters.login.components.desktop,
        exact: true,
      },
      {
        path: EliteRouters.generalConditions.path,
        component: isMobile
          ? EliteRouters.generalConditions.components.mobile
          : EliteRouters.generalConditions.components.desktop,
        exact: true,
      },
      {
        path: EliteRouters.legalMentions.path,
        component: isMobile
          ? EliteRouters.legalMentions.components.mobile
          : EliteRouters.legalMentions.components.desktop,
        exact: true,
      },
      {
        path: EliteRouters.confidentialPolitics.path,
        component: isMobile
          ? EliteRouters.confidentialPolitics.components.mobile
          : EliteRouters.confidentialPolitics.components.desktop,
        exact: true,
      },
      {
        path: EliteRouters.maghrebHomePage.path,
        component: isMobile
          ? EliteRouters.maghrebHomePage.components.mobile
          : EliteRouters.maghrebHomePage.components.desktop,
        exact: true,
      },
      {
        path: EliteRouters.tunisieHomePage.path,
        component: isMobile
          ? EliteRouters.tunisieHomePage.components.mobile
          : EliteRouters.tunisieHomePage.components.desktop,
        exact: true,
      },
      {
        path: EliteRouters.algerieHomePage.path,
        component: isMobile
          ? EliteRouters.algerieHomePage.components.mobile
          : EliteRouters.algerieHomePage.components.desktop,
        exact: true,
      },
      {
        path: EliteRouters.berbereHomePage.path,
        component: isMobile
          ? EliteRouters.berbereHomePage.components.mobile
          : EliteRouters.berbereHomePage.components.desktop,
        exact: true,
      },
      {
        path: EliteRouters.kabyleHomePage.path,
        component: isMobile
          ? EliteRouters.kabyleHomePage.components.mobile
          : EliteRouters.kabyleHomePage.components.desktop,
        exact: true,
      },
      {
        path: EliteRouters.amazighHomePage.path,
        component: isMobile
          ? EliteRouters.amazighHomePage.components.mobile
          : EliteRouters.amazighHomePage.components.desktop,
        exact: true,
      },
      {
        path: EliteRouters.europeHomePage.path,
        component: isMobile
          ? EliteRouters.europeHomePage.components.mobile
          : EliteRouters.europeHomePage.components.desktop,
        exact: true,
      },
      {
        path: EliteRouters.belgiqueHomePage.path,
        component: isMobile
          ? EliteRouters.belgiqueHomePage.components.mobile
          : EliteRouters.belgiqueHomePage.components.desktop,
        exact: true,
      },
      {
        path: EliteRouters.africaHomePage.path,
        component: isMobile
          ? EliteRouters.africaHomePage.components.mobile
          : EliteRouters.africaHomePage.components.desktop,
        exact: true,
      },
      {
        path: EliteRouters.canadaHomePage.path,
        component: isMobile
          ? EliteRouters.canadaHomePage.components.mobile
          : EliteRouters.canadaHomePage.components.desktop,
        exact: true,
      },
      {
        path: EliteRouters.muslimHomePage.path,
        component: isMobile
          ? EliteRouters.muslimHomePage.components.mobile
          : EliteRouters.muslimHomePage.components.desktop,
        exact: true,
      },
      {
        path: EliteRouters.seriousHomePage.path,
        component: isMobile
          ? EliteRouters.seriousHomePage.components.mobile
          : EliteRouters.seriousHomePage.components.desktop,
        exact: true,
      },
      {
        path: EliteRouters.weddingHomePage.path,
        component: isMobile
          ? EliteRouters.weddingHomePage.components.mobile
          : EliteRouters.weddingHomePage.components.desktop,
        exact: true,
      },
      {
        path: EliteRouters.demandingHomePage.path,
        component: isMobile
          ? EliteRouters.demandingHomePage.components.mobile
          : EliteRouters.demandingHomePage.components.desktop,
        exact: true,
      },
      {
        path: EliteRouters.speeddatHomePage.path,
        component: isMobile
          ? EliteRouters.speeddatHomePage.components.mobile
          : EliteRouters.speeddatHomePage.components.desktop,
        exact: true,
      },
      {
        path: EliteRouters.lovCoachingHomePage.path,
        component: isMobile
          ? EliteRouters.lovCoachingHomePage.components.mobile
          : EliteRouters.lovCoachingHomePage.components.desktop,
        exact: true,
      },
      {
        path: EliteRouters.ileDeFranceHomePage.path,
        component: isMobile
          ? EliteRouters.ileDeFranceHomePage.components.mobile
          : EliteRouters.ileDeFranceHomePage.components.desktop,
        exact: true,
      },
      {
        path: EliteRouters.parisHomePage.path,
        component: isMobile
          ? EliteRouters.parisHomePage.components.mobile
          : EliteRouters.parisHomePage.components.desktop,
        exact: true,
      },
      {
        path: EliteRouters.lyonHomePage.path,
        component: isMobile
          ? EliteRouters.lyonHomePage.components.mobile
          : EliteRouters.lyonHomePage.components.desktop,
        exact: true,
      },
      {
        path: EliteRouters.marseilleHomePage.path,
        component: isMobile
          ? EliteRouters.marseilleHomePage.components.mobile
          : EliteRouters.marseilleHomePage.components.desktop,
        exact: true,
      },
      {
        path: EliteRouters.root.path,
        component: isMobile
          ? EliteRouters.root.components.mobile
          : EliteRouters.root.components.desktop,
        exact: true,
      },
      {
        path: EliteRouters.nomatch.path,
        component: isMobile
          ? EliteRouters.nomatch.components.mobile
          : EliteRouters.nomatch.components.desktop,
        exact: false,
      },
    ];

    if (auth) {
      pages = [
        {
          path: EliteRouters.meslikes.path,
          component: isMobile
            ? EliteRouters.meslikes.components.mobile
            : EliteRouters.meslikes.components.desktop,
          exact: true,
        },
        {
          path: EliteRouters.mesvisites.path,
          component: isMobile
            ? EliteRouters.mesvisites.components.mobile
            : EliteRouters.mesvisites.components.desktop,
          exact: true,
        },
        {
          path: EliteRouters.home.path,
          component: isMobile
            ? EliteRouters.home.components.mobile
            : EliteRouters.home.components.desktop,
          exact: true,
        },
        {
          path: EliteRouters.home.path,
          component: isMobile
            ? EliteRouters.home.components.mobile
            : EliteRouters.home.components.desktop,
          exact: true,
        },
        {
          path: EliteRouters.search.path,
          component: isMobile
            ? EliteRouters.search.components.mobile
            : EliteRouters.search.components.desktop,
          exact: true,
        },
        {
          path: EliteRouters.profile.path,
          component: isMobile
            ? EliteRouters.profile.components.mobile
            : EliteRouters.profile.components.desktop,
          exact: true,
        },
        {
          path: EliteRouters.profile.path,
          component: isMobile
            ? EliteRouters.userProfile.components.mobile
            : EliteRouters.userProfile.components.desktop,
          exact: true,
        },
        {
          path: EliteRouters.parameters.path,
          component: isMobile
            ? EliteRouters.parameters.components.mobile
            : EliteRouters.parameters.components.desktop,
          exact: true,
        },
        {
          path: EliteRouters.profileEdition.path,
          component: isMobile
            ? EliteRouters.profileEdition.components.mobile
            : EliteRouters.profileEdition.components.desktop,
          exact: true,
        },
        {
          path: EliteRouters.management.path,
          component: isMobile
            ? EliteRouters.management.components.mobile
            : EliteRouters.management.components.desktop,
          exact: true,
        },
        {
          path: EliteRouters.checkout.path,
          component: isMobile
          ? EliteRouters.checkout.components.mobile
          : EliteRouters.checkout.components.desktop,
          exact: true,
        },
        {
          path: "/home/:id",
          component: isMobile
            ? EliteRouters.visitedProfile.components.mobile
            : EliteRouters.visitedProfile.components.desktop,
          exact: true,
        },
        {
          path: EliteRouters.matchmaking.path,
          component: isMobile
            ? EliteRouters.matchmaking.components.mobile
            : EliteRouters.matchmaking.components.desktop,
        },
        {
          path: EliteRouters.rooms.path,
          component: isMobile
            ? EliteRouters.rooms.components.mobile
            : EliteRouters.rooms.components.desktop,
          exact: true,
        },
        {
          path: EliteRouters.messages.path,
          component: isMobile
            ? EliteRouters.messages.components.mobile
            : EliteRouters.messages.components.desktop,
          exact: true,
        },
        {
          path: EliteRouters.chatroom.path,
          component: isMobile
            ? EliteRouters.chatroom.components.mobile
            : EliteRouters.chatroom.components.desktop,
          exact: true,
        },
      ].concat(pages);
    }

    return pages.map((e, i) => (
      <Route key={ i } exact={ e.exact } path={ e.path } component={ e.component }/>
    ));
  }

  render() {
    const {isMobile, cookiesVisible} = this.state;
    return (
      <>
        <Router>
          <Switch>{ this.renderPage(isMobile) }</Switch>
        </Router>
        { cookiesVisible &&
        ( isMobile ?
            <CookiesBannerMobile
              trigger={ (state) => {
                localStorage.setItem("cookie_popup_state", state)
                this.setState({ cookiesVisible: false })
              } }
            />
            :
            <CookiesBanner
              trigger={ (state) => {
                localStorage.setItem("cookie_popup_state", state)
                this.setState({ cookiesVisible: false })
              } }
            />
        )
        }
      </>
    );
  }
}

export default CustomRouter;
