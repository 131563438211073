import React, { Component } from "react";
import { ReactSVG } from "react-svg";
import EliteParagraph from "../Typography/Paragraph";
import "./TxtAsButtonStyles.css";

class TxtAsButton extends Component {
  render() {
    return (
      <>
        <span
          onClick={ () => {
            if (!this.props.isDisabled) {
              this.props.onClick()
            }
          }}
          tabIndex={0}
          className={`txtAsButton d-flex justify-content-center ${this.props.isDisabled ? "isDisabled" : ""}`}
          style={{ width: this.props.width, height:this.props.height}}>
          {<ReactSVG src={this.props.icon ? this.props.icon : ''} color="black" />}{" "}
          <EliteParagraph
            cursor={"cursor"}
            text={this.props.text}
            textColor={this.props.isDisabled ? "#bcbcbc" : "#ffffff"}
            margin={this.props.icon ? "0 0 0 8px" : 0}
            fontSize={"14px"}
            fontWeight={"bold"}
            uppercase
          />
        </span>
      </>
    );
  }
}
export default TxtAsButton;
