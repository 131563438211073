import apiClient from "../Services/ApiServices/api.client";
import { useEffect } from "react";
import { useStore } from "react-context-hook";
import { useCurrentUser } from "./AuthenticatedUser";

export function useVisitors() {
  const [visitors, setVisitors] = useStore('visitors', [])
  const { user } = useCurrentUser()

  useEffect(() => {
    ( async function () {
      const { data: { data } } = await apiClient.get('/api/visits')
      setVisitors(data)
    } )()
    // eslint-disable-next-line
  }, [user.visitsCount]);

   function trackVisit({ id }) {
     apiClient.post(`/api/visit/${ id }`)
  }


  return { visitors, trackVisit }
}
