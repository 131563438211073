import React, { useState } from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import ResponsiveService from "../../Services/ResponsiveService";
import { Helmet, HelmetProvider } from "react-helmet-async";
import datingDemanding from "../../assets/images/dating-demanding-1.jpg";
import datingDemanding2 from "../../assets/images/dating-demanding-2.jpg";
import BigTitle from "../../Components/Uikit/Typography/BigTitle";
import EliteSmallTitle from "../../Components/Uikit/Typography/SmallTitle";
import EliteSmallSmallTitle from "../../Components/Uikit/Typography/SmallSmallTitle";
import EliteParagraph from "../../Components/Uikit/Typography/Paragraph";

export function ExigeantPage() {
    const [isMobile, setIsMobile] = useState(
        ResponsiveService.isMobile()
    );
    
    const handleResize = (e) => {
        e.preventDefault();
        if (isMobile !== ResponsiveService.isMobile()) {
          setIsMobile({ isMobile: ResponsiveService.isMobile() });
        }
    };
    window.addEventListener("resize", handleResize.bind(this));

    return (
        <HelmetProvider>
        <>
            <Helmet>
                <title>Site de rencontre exigeant pour célibataires – Elite Berbère</title>
                <meta name="description" content="Vous recherchez l'amour ? Elite Berbère vous 
                multipliera vos chances de trouver un partenaire près de chez vous. Inscrivez-vous dès maintenant !"/>
                <meta name="keywords" content="Premier message, Vie de couple, Essayer de rencontrer, Trouvez l amour, 
                Consulter les profils, Célibataires exigeants, Exigeants, Hommes et femmes, Trouver une personne, 
                Faire une rencontre, Célibat, Rencontrez, Trouver un partenaire, Faire connaissance, Faire de belles 
                rencontres, Relation durable, Âme-soeur, Rencontre amoureuse, Attractive, Célibataire, Relation sérieuse, 
                Sérieuse, Trouver l amour, Faire des rencontres, Site de rencontre"/>
            </Helmet>
            <Header />
            <div className="container">
                <div className="row p-4">
                    <div style={{textAlign: "justify"}} className="col-12">
                        <BigTitle
                            marginBottom={"5vh"}
                            titleColor={"#4e4e4e"}
                            textAlign={"left"}
                            text={"Elite berbère, le site de rencontre exigeant"}
                        />
                        <EliteParagraph
                            text={<>
                                Élite Berbère vous propose ses services pour vous aider dans la rencontre de l'âme sœur.
                                Les bouleversements sociétaux rendent difficiles les rencontres de qualité. Nous avons
                                développé des prestations haut de gamme pour vous permettre de lier connaissance avec des
                                personnes de la communauté berbère internationale.
                            </>}
                            textAlign={"justify"}
                            fontSize={"1.3em"}/>

                        <EliteSmallTitle
                            textAlign={"left"}
                            text={"Des rencontres de qualité pour des personnes exigeantes"} />
                        <EliteParagraph
                            text={<>
                                Pour favoriser des rencontres de qualité entre des personnes exigeantes, <a href={"https://eliteberbere.fr"}>
                                Élite Berbère</a> s'appuie sur des services raffinés et exclusifs.
                            </>}
                            textAlign={"justify"}/>
                        <EliteSmallSmallTitle
                            textAlign={"left"}
                            text={"De belles rencontres sur différents continents"} />
                        <EliteParagraph
                            text={<>
                                La communauté berbère est étendue sur les différents continents. Notre réseau mondial nous permet de
                                mettre en relation les personnes qui désirent des rencontres au niveau international. Cela élargit
                                la possibilité d'effectuer de belles rencontres et de se forger de bons souvenirs.
                            </>}
                            textAlign={"justify"}/>
                        <img className={"img-fluid"} style={{marginBottom: "16px"}} src={ datingDemanding } alt={ "demanding dating 1" }/>

                        <EliteSmallSmallTitle
                            textAlign={"left"}
                            text={"Des profils de qualité"} />
                        <EliteParagraph
                            text={<>
                                Pour réussir une relation durable, Élite Berbère sélectionne sérieusement les hommes et les femmes qui
                                sollicitent nos services. Une personnalisation de recherche est disponible pour les abonnés afin qu'ils
                                puissent affiner le profil désiré.
                            </>}
                            textAlign={"justify"}/>

                        <EliteSmallSmallTitle
                            textAlign={"left"}
                            text={"Un accompagnement personnalisé et des événements"} />
                        <EliteParagraph
                            text={<>
                                Les célibataires abonnés à Élite Berbère peuvent bénéficier d'un accompagnement personnalisé.
                                Nous ne sommes pas un simple site de rencontres ! Nous organisons également des événements
                                pour donner encore davantage de chance aux affiliés pour trouver l'amour.
                            </>}
                            textAlign={"justify"}/>

                        <EliteSmallTitle
                            textAlign={"left"}
                            text={"Des rencontres sérieuses sur des valeurs fortes"} />
                        <EliteParagraph
                            text={<>
                                Des rencontres sérieuses ne peuvent se concrétiser que sur des valeurs fortes. Pour vous aider à rompre le célibat,
                                Élite Berbère partage les mêmes morales que vous.
                            </>}
                            textAlign={"justify"}/>
                        <EliteParagraph
                            text={<>
                                Le respect entre l'homme et la femme qui se rencontrent garantit le futur de leur relation. Nous sommes aussi très
                                vigilants sur cette valeur sur les échanges effectués via notre site de rencontre exigeant. Nous demandons une
                                certaine éthique à nos abonnés.
                            </>}
                            textAlign={"justify"}/>
                        <EliteParagraph
                            text={<>
                                La sincérité des célibataires, dès le premier message sur notre site de rencontre exigeant, est gage d'une relation
                                sérieuse à venir. Partir sur le mensonge ne peut qu'entraîner d'amères désillusions.
                            </>}
                            textAlign={"justify"}/>
                        <EliteParagraph
                            text={<>
                                Élite Berbère répond à cette valeur fondamentale. La discrétion de nos services est l'assurance de notre renommée.
                                Vous pouvez vous fier à nous en toute tranquillité ! Nous demandons, en retour, du savoir-vivre aux célibataires
                                qui s'inscrivent sur notre site.
                            </>}
                            textAlign={"justify"}/>
                        <img className={"img-fluid"} style={{marginBottom: "16px"}} src={ datingDemanding2 } alt={ "demanding dating 2" }/>

                        <EliteSmallTitle
                            textAlign={"left"}
                            text={"Des rencontres durables sur le site de rencontre exigeant Élite Berbère"} />
                        <EliteParagraph
                            text={<>
                                Nous mettons en relation des hommes et des femmes dans l'espoir que leur relation soit durable.
                                C'est pour cela que nous prenons toutes nos précautions pour vérifier les profils enregistrés
                                sur notre site de rencontre exigeant.
                            </>}
                            textAlign={"justify"}/>
                        <EliteParagraph
                            text={<>
                                Nos célibataires exigeants cherchent la rencontre pour créer un couple pour la vie. Comme eux,
                                nous sommes attachés à l'image de la famille, base d'une relation saine.
                            </>}
                            textAlign={"justify"}/>
                        <EliteParagraph
                            text={<>
                                Élite Berbère met en contact des adultes qui veulent davantage qu'une simple histoire d'amour.
                                En consultant les profils, ils désirent trouver la perle rare qui saura partager ses passions,
                                ses idées, ses valeurs. Ce socle commun favorise une bonne entente sur le long terme.
                            </>}
                            textAlign={"justify"}/>
                        <EliteParagraph
                            text={<>
                                La culture berbère rassemble tous nos abonnés. Elle est la force de chacun d'entre nous.
                                Rencontrer un partenaire qui adhère à ses valeurs et à son histoire donne une promesse
                                supplémentaire à la réussite des rencontres sur Élite Berbère !
                            </>}
                            textAlign={"justify"}/>
                    </div>
                </div>
            </div>
            <Footer />
        </>
        </HelmetProvider>
    );
}
