import React, { Component } from "react";
import { Range } from "react-range";
import CustomButton from "../Button/CustomButton";

export class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = { list: [this.props.starterPoint, this.props.endPoint] };
    this.getTrackBackground.bind(this);
  }


  onChange() {
    return (values) => {
      this.setState({ list: values })
      if (this.props.onChange) {
        this.props.onChange(values)
      }
    }
  }

  getTrackBackground({
                       values,
                       colors,
                       min,
                       max,
                       direction = "to right",
                       rtl = false,
                     }) {
    // sort values ascending

    const progress = values
      .slice(0)
      .sort((a, b) => a - b)
      .map((value) => ( ( value - min ) / ( max - min ) ) * 100);

    const middle = progress.reduce(
      (acc, point, index) =>
        `${ acc }, ${ colors[index] } ${ point }%, ${ colors[index + 2] } ${ point }%`,
      "",
    );

    return `linear-gradient(${direction}, ${colors[0]} 0%${middle}, ${
      colors[colors.length - 1]
    } 100%)`;

    /* background-image: linear-gradient(95deg, var(--green-34-d-10-e) 11%, var(--blue-6-c-95-e-7) 89%); */
  }

  render() {
    const STEP = this.props.step;
    const MIN = this.props.min;
    const MAX = this.props.max;
    const colorsHandlers = ["#34d10e", "#6c95e7"];

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
        }}>
        <Range
          values={ this.state.list }
          step={ STEP }
          min={ MIN }
          max={ MAX }
          onChange={ this.onChange() }
          renderTrack={ ({ props, children }) => (
            <div
              onMouseDown={ props.onMouseDown }
              onTouchStart={ props.onTouchStart }
              style={ {
                ...props.style,
                height: "36px",
                display: "flex",
                width: "90%",
              } }>
              <div
                ref={ props.ref }
                style={ {
                  height: "6px",
                  width: "100%",
                  borderRadius: "4px",
                  backgroundImage: this.getTrackBackground({
                    values: this.state.list,
                    colors: ["#e0e0e0", "#6c95e7", "#34d10e", "#e0e0e0"],
                    min: MIN,
                    max: MAX,
                  }),
                  alignSelf: "center",
                }}>
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, isDragged, index }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: "16px",
                width: "16px",
                borderRadius: "50%",
                backgroundColor: colorsHandlers[index],
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0px 2px 6px #AAA",
              }}>
              <div
                style={ {
                  position: "absolute",
                  top: "20px",
                  color: "#4e4e4e",
                  fontWeight: "bold",
                  fontSize: "14px",
                  padding: "4px",
                }}>
                {this.state.list[index].toFixed(this.props.decimal) + this.props.suffix}
              </div>
            </div>
          ) }
        />
        { this.props.update &&
        <div
          className={ `mt-4 w-100 ${
            this.props.showValidationButton ? "d-block" : "d-none"
          }` }>
          <CustomButton
            onClick={() =>{
              this.props.update &&
              this.props.update({ choices: this.state.list, container: this.props.specificType ? this.props.specificType : "slider" });
              this.props.closeModal.current.handleCloseModal()
            }
            }
            label={ "valider" }
          />
        </div>
        }
      </div>
    );
  }
}

Slider.defaultProps = {
  starterPoint: 25,
  endPoint: 75,
  max: 100,
  min: 0,
  step: 0.1,
  decimal: 1,
  showValidationButton: false,
  suffix:'m'
};
export default Slider;
