import React from "react";
import "./ConnectedHeaderStyles.css";
import { generatePath, NavLink, useHistory } from "react-router-dom";
import { ReactSVG } from "react-svg";
import iconHome from "../../../assets/images/icon-home.svg";
import iconHomeWhite from "../../../assets/images/icon-home-white.svg";
import iconSearch from "../../../assets/images/icon-search.svg";
import iconSearchWhite from "../../../assets/images/icon-search-white.svg";
import iconUserWhite from "../../../assets/images/icon-user-white.svg";
import iconUserYellow from "../../../assets/images/icon-user-yellow.svg";
import iconMatchYellow from "../../../assets/images/icon-match-yellow.svg";
import iconMatchWhite from "../../../assets/images/icon-match-menu.svg";
import iconChatWhite from "../../../assets/images/icon-chat-white.svg";
import iconChatYellow from "../../../assets/images/icon-chat-yellow.svg";
import { EliteRouters } from "../../../Navigation/Routers";
import EliteParagraph from "../../Uikit/Typography/Paragraph";
import { useNotifications } from "../../../Hooks/Notifications";
import iconLogout from "../../../assets/images/icon-logout.svg";
import {useCurrentUser} from "../../../Hooks/AuthenticatedUser";

export default function MenuConnected() {
  const history = useHistory();
  const { notifications } = useNotifications();
    const { onLogout } = useCurrentUser();

  const checkURLpath = (pathToSearch) => {
    return history.location.pathname.indexOf(pathToSearch) > -1;
  };

  return (
    <div className={"headerMenuNav"}>
      <div className="container-fluid">
        <ul className="navbar-nav">
          <li className="nav-item">
            <NavLink to={EliteRouters.home.path} activeClassName={"activeLink"}>
              <ReactSVG
                src={
                  history.location.pathname === EliteRouters.home.path ||
                  history.location.pathname === EliteRouters.mesvisites.path ||
                  history.location.pathname === EliteRouters.meslikes.path ||
                  history.location.pathname === EliteRouters.visitedProfile.path
                    ? iconHome
                    : iconHomeWhite
                }
              />
              <span
                className={`${
                  history.location.pathname === EliteRouters.home.path ||
                  history.location.pathname === EliteRouters.mesvisites.path ||
                  history.location.pathname === EliteRouters.meslikes.path ||
                  history.location.pathname === EliteRouters.visitedProfile.path
                    ? "d-block"
                    : "d-none"
                }`}>
                {EliteRouters.home.name}
              </span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              exact
              to={EliteRouters.search.path}
              activeClassName={"activeLink"}>
              <ReactSVG
                src={
                  history.location.pathname === EliteRouters.search.path
                    ? iconSearch
                    : iconSearchWhite
                }
              />
              <span
                className={`${
                  history.location.pathname !== EliteRouters.search.path &&
                  "d-none"
                }`}>
                {EliteRouters.search.name}
              </span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              exact
              to={generatePath(EliteRouters.messages.path, { roomId: " " })}
              isActive={()=> checkURLpath("messages")}
              activeClassName={"activeLink"}>
              <ReactSVG
                src={
                  history.location.pathname === EliteRouters.messages.path ||
                  checkURLpath("messages")
                    ? iconChatYellow
                    : iconChatWhite
                }
              />
              <span
                className={`${
                  history.location.pathname === EliteRouters.messages.path ||
                  checkURLpath("messages")
                    ? "d-block"
                    : "d-none"
                }`}>
                {EliteRouters.messages.name}
              </span>
              {notifications.unreadMessages ? (
                <div className="messagesUnread">
                  <EliteParagraph
                    text={notifications.unreadMessages}
                    fontSize={"12px"}
                    fontWeight={"bold"}
                    textColor={"#34d10e"}
                    margin={0}
                  />
                </div>
              ) : (
                ""
              )}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              exact
              to={EliteRouters.matchmaking.path}
              activeClassName={"activeLink"}>
              <ReactSVG
                src={
                  history.location.pathname === EliteRouters.matchmaking.path
                    ? iconMatchYellow
                    : iconMatchWhite
                }
              />
              <span
                className={`${
                  history.location.pathname !== EliteRouters.matchmaking.path &&
                  "d-none"
                }`}>
                {EliteRouters.matchmaking.name}
              </span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to={EliteRouters.profile.path}
              activeClassName={"activeLink"}>
              <ReactSVG
                src={
                  history.location.pathname === EliteRouters.profile.path ||
                  history.location.pathname === EliteRouters.parameters.path ||
                  history.location.pathname === EliteRouters.profileEdition.path
                    ? iconUserYellow
                    : iconUserWhite
                }
              />
              <span
                className={`${
                  history.location.pathname === EliteRouters.profile.path ||
                  history.location.pathname === EliteRouters.parameters.path ||
                  history.location.pathname === EliteRouters.profileEdition.path
                    ? "d-block"
                    : "d-none"
                }`}>
                {EliteRouters.profile.name}
              </span>
            </NavLink>
          </li>
            <div className=" logoutContainer ml-6 btn" title={"Se déconnecter"}>
                <ReactSVG src={iconLogout} onClick={onLogout} />
            </div>
        </ul>

      </div>
    </div>
  );
}
