import React, { Component } from "react";
import BigTitle from "../Uikit/Typography/BigTitle";
import EliteParagraph from "../Uikit/Typography/Paragraph";
import "./WhyChooseUsStyles.css";
import ResponsiveService from "../../Services/ResponsiveService";

class WhyChooseUs extends Component {
  render() {
    return (
      <>
        <div className={"container-fluid w-100 p-0 mt-2"}>
          <div className={"whyChooseUsContainer"}>
            <BigTitle showUnderline text={"Pourquoi nous choisir ?"} />
            <div className={"d-flex flex-sm-row flex-column justify-content-center p-4"}>
              <div className={(ResponsiveService.isMobile() ? "" : "whyChooseUsParagraph ") + "col-sm-5 col-12 justify-content-end"}>
                <EliteParagraph
                  textColor={"#ffffff"}
                  whychoose
                  textAlign={"justify"}
                  text={
                    "De nos jours il est devenu difficile de trouver son âme-sœur au détour d’une rue quant bien même ses exigences et valeurs sont importantes."
                   } />
                  <EliteParagraph
                      whychoose
                      textAlign={"justify"}
                      textColor={"#ffffff"}
                      text={
                          "EliteBerbère.fr c’est l’exigence de savoir ce que l’on veut et de le trouver ! Mais aussi vous faire découvrir les cultures berbères."
                        }
                      />
                  <EliteParagraph
                      textColor={"#ffffff"}
                      textAlign={"justify"}
                      text={
                          "Vous faire découvrir le monde, la langue, la culture berbère, grâce à nos profils en majorité berbérophones qui sont des femmes ou hommes berbères célibataires : Rifains, Amazigh, Chaouis, Chenouis, Infusen, Zayanes, Chleuhs, Zenagas, Touareg, Berbères des Oasis…"
                      } />
              </div>
                <div className={"greenDot"} />
              <div className={(ResponsiveService.isMobile() ? "" : "whyChooseUsParagraph ") + "col-sm-5 col-12 justify-content-start"}>
                  <EliteParagraph
                      textColor={"#ffffff"}
                      textAlign={"justify"}
                      margin={ResponsiveService.isMobile() ? "16px 0 0 0" : "0"}
                      text={
                          "Notre site de rencontre Elite Berbère propose un service de qualité misant sur le respect éthique, moral mais aussi culturel. Vous échangerez avec des personnes de différents continents, horizons, dynasties, tribus, en passant par l’Algérie, le Maroc, la Tunisie, la Libye, l’Afrique subsaharienne et bien d’autres  ayant comme éléments fédérateurs : la situation, les racines, la langue, les traditions, la culture, les coutumes, la cuisine… "
                      } />

                  <EliteParagraph
                      textColor={"#ffffff"}
                      textAlign={"justify"}
                      text={
                          "Sur le site de rencontre Élite Berbère vous allez faire des rencontres  avec des personnes célibataires, avoir des échanges sérieux, intellectuels et engagés pour la cause berbère mais surtout faire la découverte de cette union sacrée afin de fonder un avenir sur des bases communes et solides avec la personne tant attendue."
                      }
                  > </EliteParagraph>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default WhyChooseUs;
