import React, { useEffect } from "react";
import Chat from "../../../Components/Chat/Chat";
import FooterMenu from "../../../Components/FooterMenu/FooterMenu";
import { useParams } from "react-router-dom";
import { useMessages } from "../../../Hooks/MessagesHooks";
import Spinner from "react-bootstrap/Spinner";

export default function ChatRoomMobile() {
  const { selectedRoom, setRoomId, messages, onSendMessage, getRooms, setRoomLastReadAt } = useMessages()
  const { roomId } = useParams();

  useEffect(() => {
    if (roomId && ( !selectedRoom || roomId !== selectedRoom.id.toString() )) {
      setRoomId(roomId);
    }
  }, [roomId, selectedRoom, setRoomId]);

  useEffect(() => {
    if (roomId) { setRoomLastReadAt(roomId) }
    // eslint-disable-next-line
  }, [roomId, messages]);

  if(!selectedRoom){
    return (
      <div style={{alignItems: "center", justifyContent: "center", position: "absolute", top: 0, bottom: 0, left: 0, right: 0, display: "flex"}}>
        <Spinner animation="border" />
      </div>
    )
  }

  return (
    <>
      <div className={ "container"}>
        <div className="col-12">
          <Chat isMobile room={ selectedRoom } messages={ messages } onSendMessage={ onSendMessage } getRooms={getRooms}/>
        </div>
      </div>
      <FooterMenu/>
    </>
  );
}
