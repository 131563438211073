import React from "react";
import { ReactSVG } from "react-svg";
import ConnectedHeader from "../../Components/Header/ConnectedHeader/ConnectedHeader";
import backArrow from "../../assets/images/icon-arrow-back.svg";
import Footer from "../../Components/Footer/Footer";
import "./MesLikesStyles.css";
import { useLikers } from "../../Hooks/Likes";
import { useHistory } from "react-router-dom";
import { isEmpty } from "lodash-es";
import GridMosaic from "../../Components/Uikit/PhotoMosaic/PhotoMosaic_new";

export function MesLikes() {
  const { likers } = useLikers();
  const history = useHistory();

  return (
    <>
      <ConnectedHeader />
      <div className={"container"}>
        <div className="row">
          <div className="col-2"></div>
          <div className="col-8">
            <div className="col-4">
              <div className={"back py-5"}>
                <div onClick={() => history.push("/home")}>
                  <ReactSVG src={backArrow} /> Mes likes
                </div>
              </div>
            </div>
            <div className="col-12 mesLikesContainer">
              <div className="row g-0">
                {!isEmpty(likers) ? (
                  <GridMosaic
                  padding={"8px"}
                    isMobile={false}
                    photoType={"miniature"}
                    photoSize={"small"}
                    data={likers}
                  />
                ) : (
                  <div className="center">
                    <h6 className="pt-5">
                      <i>Aucun like pour le moment</i>
                    </h6>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-2"></div>
        </div>
      </div>
      <Footer isLogged />
    </>
  );
}
