import React from 'react'
import EliteParagraph from '../Typography/Paragraph'
import './TextAreaStyles.css'

export function CustomTextArea({ name, classes, value, minCharacters, maxCharacters, rows, onChange, onBlur, onKeyDown,placeholder, toModerate }) {
  let helpText

  if (minCharacters && ( !value || value.length < minCharacters )) {
    helpText = minCharacters + ' caractères minimum';
  } else if (maxCharacters) {
    helpText = maxCharacters + ' caractères maximum';
  }

  return (
    <div>
      {toModerate && <EliteParagraph fontSize={ '12px' } textAlign={ 'left' } text="En attente de validation par notre modérateur"/>}
      <textarea className={ classes } placeholder={placeholder} name={ name } value={ value || '' } rows={ rows || 4 } onBlur={ onBlur }
        onChange={ (e) => onChange(e.target.value) } onKeyDown={ onKeyDown }/>
      <EliteParagraph fontSize={ '12px' } textAlign={ 'right' } text={ helpText }/>
    </div>
  )
}

export default CustomTextArea
