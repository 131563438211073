import React, {useState} from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import TitleOverTitle from "../../Components/Uikit/TitleOverTitle/TitleOverTitle";
import ResponsiveService from "../../Services/ResponsiveService";
import {EliteRouters} from "../../Navigation/Routers";
import {NavLink} from "react-router-dom";
import {ReactSVG} from "react-svg";
import backArrow from "../../assets/images/icon-arrow-back.svg";
import { useHistory } from "react-router-dom";


export function ConditionGenerales() {
    const [isMobile, setIsMobile] = useState({
        isMobile: ResponsiveService.isMobile(),
    });
    const history = useHistory();

    const handleResize = (e) => {
        e.preventDefault();
        if (isMobile !== ResponsiveService.isMobile()) {
            setIsMobile({isMobile: ResponsiveService.isMobile()});
        }
    };
    window.addEventListener("resize", handleResize.bind(this));

    return (
        <>
            <Header/>
            <div className="container">
                <div className="row p-4">
                    <div className="col-12">
                        <div className="col-4">
                            <div className={"back"}>
                                <div onClick={() => history.goBack()}>
                                    <ReactSVG src={backArrow} /> Retour
                                </div>
                            </div>
                        </div>
                        <TitleOverTitle
                            overTitleMarginTop={isMobile.isMobile ? "-30px" : "-25"}
                            backTitleSize={isMobile.isMobile ? "30px" : "60px"}
                            frontTitleSize={isMobile.isMobile ? "24px" : "32px"}
                            title={"Conditions générales"}
                        />
                        <div className={"pt-4"}>
                            <h2>
                                <strong>
                                    1. Définitions
                                    <br/>
                                </strong>
                            </h2>
                            <div style={{marginLeft: "20px"}}>
                                <p>
                                    <strong>« Abonnement » : </strong>
                                    contrat à durée déterminée à titre onéreux auquel le Membre
                                    souscrit afin de bénéficier du Service durant la période
                                    spécifiée par l’offre d’abonnement concernée
                                    <br/>
                                </p>
                                <p>
                                    <strong>« Abonné » </strong>
                                    : tout Membre souscrivant au Service via un Abonnement
                                    <br/>
                                </p>
                                <p>
                                    <strong>« Compte » </strong>
                                    : espace personnel des Membres leur permettant de
                                    s’identifier, de gérer leur Profil et, en cas d’Abonnement, de
                                    bénéficier du Service
                                    <br/>
                                </p>
                                <p>
                                    <strong>« Données » </strong>
                                    : ensemble des informations concernant les Utilisateurs
                                    <br/>
                                </p>
                                <p>
                                    <strong>« Données personnelles » </strong>
                                    : toute information susceptible d’identifier, directement ou
                                    indirectement les Utilisateurs personne physique.
                                    <br/>
                                </p>
                                <p>
                                    <strong>« Membre » </strong>
                                    : tout Utilisateur personne physique majeure s’étant inscrit
                                    sur le Site
                                    <br/>
                                </p>
                                <p>
                                    <strong>« Politique » </strong>
                                    : la présente politique de confidentialité
                                    <br/>
                                </p>
                                <p>
                                    <strong>« Profil » </strong>
                                    : interface personnelle créée par le Membre et visible par les
                                    autres Membres sur le Site, comprenant des informations le
                                    concernant qu’il aura fourni via son Compte
                                    <br/>
                                </p>
                                <p>
                                    <strong>« Responsable de traitement » </strong>: la société
                                    ELITE BERBERE, société par actions simplifiée situé 40 B rue
                                    de Dantzig 75015 Paris, immatriculée au RCS de Paris sous le
                                    numéro 879238194
                                    <span>
                  <br/>
                </span>
                                </p>
                                <p>
                                    <strong>« Utilisateur » </strong>
                                    : tout internaute se connectant au Site, accédant à une ou
                                    des pages du Site
                                    <br/>
                                </p>
                                <p>
                                    <strong>« Service » </strong>
                                    <span>
                  : service de mise en relation entre deux personnes à des fins
                  privées via le Site permettant la création d’un Profil
                  visible pour les autres Membres, la proposition d’autres
                  Profils, la possibilité de « like » et de discuter via une
                  messagerie privée en cas de « match »
                  <br/>
                </span>
                                </p>
                                <p>
                                    <strong>« Site » </strong>
                                    : Le présent site internet situé à l’adresse url «
                                    www.eliteberbere.fr » et l’application Elite Berbère dédiée
                                    <br/>
                                </p>
                            </div>
                            <h2 style={{marginTop:"20px"}}>
                                <strong>
                                    2. Objet
                                    <br/>
                                </strong>
                            </h2>
                            <div style={{marginLeft: "20px"}}>
                                <p>
                                    Les Conditions Générales ont pour objet de déterminer les conditions et modalités dans lesquelles l’Utilisateur pourra utiliser le Site ainsi que celles dans lesquelles le Membre pourra bénéficier du Service.
                  <br/>
                                </p>
                            </div>
                            <h2 style={{marginTop:"20px"}}>
                                <strong>
                                    3. Dispositions générales
                                    <br/>
                                </strong>
                            </h2>
                            <div style={{marginLeft: "20px"}}>
                                <h3 style={{marginTop:"10px"}}>
                                    3.1 Champ d’application
                                    <br/>
                                </h3>

                                <p>
                                    Les Conditions Générales sont applicables à toute utilisation du Site par un
                                    Utilisateur et au Service fournis par le Prestataire aux Membres.
                                    <br/>
                                </p>
                            </div>

                            <div style={{marginLeft: "20px"}}>
                                <h3 style={{marginTop:"10px"}}>
                                    3.2 Acceptation
                                    <br/>
                                </h3>
                                <p>
                                    Tout Utilisateur ou Membre déclare avoir pris connaissance et accepter expressément
                                    et sans réserve l’intégralité (i) des Conditions Générales, (ii) de la politique de
                                    confidentialité et (iii) de la Charte éthique du Site. <br/>
                                </p>
                            </div>
                            <h2 style={{marginTop:"20px"}}>
                                <strong>
                                    4. Création d’un Compte
                                    <br/>
                                </strong>
                            </h2>
                            <div style={{marginLeft: "20px"}}>
                                <h3 style={{marginTop:"10px"}}>
                                    4.1 Inscription
                                    <br/>
                                </h3>

                                <p>
                                    L’Utilisateur a la possibilité de s’inscrire gratuitement sur le Site, sous réserve
                                    des frais pour l’accès au Site qui restent intégralement à sa charge (connexion
                                    Internet, matériel informatique, etc.).
                                    <br/>
                                </p>
                                <p>
                                    Cependant, une telle inscription ne lui permet pas d’accéder aux Services: les
                                    photos des autres Membres seront floutées et il ne pourra ni donner ou recevoir de
                                    Like, ni avoir de Match, ni communiquer via la messagerie privée.
                                </p>
                                <p>
                                    Afin de pouvoir s’inscrire, l’Utilisateur devra remplir les conditions cumulatives
                                    suivantes:
                                    <br/>
                                </p>
                                <ul type={"circle"} style={{marginLeft:"20px"}}>
                                    <li>
                                        Être majeur et disposer de sa pleine capacité juridique
                                        <br/>
                                    </li>
                                    <li>
                                        Choisir un identifiant et un mot de passe strictement personnel et conformes aux
                                        recommandations du Site
                                        <br/>
                                    </li>
                                    <li>
                                        Fournir l’ensemble des informations qui lui seront demandées dans la rubrique
                                        dédiée sur le Site <br/>
                                    </li>
                                    <li>
                                        Accepter les Conditions Générales. <br/>
                                    </li>
                                </ul>
                                <p>
                                    A ce titre, l’Utilisateur déclare et garantit que l’ensemble des informations
                                    transmises sont personnelles, sincères, précises, exactes et actuelles. <br/>
                                    <br/>
                                </p>
                                <p>
                                    Tout Utilisateur s’étant inscrit devient Membre. Il appartient à ce dernier de
                                    régulièrement mettre à jour ses informations afin d’en maintenir leur
                                    exactitude. <br/>
                                </p>
                                <p>
                                    En cas de perte ou d’utilisation non-autorisée ou frauduleuse de ses identifiants et
                                    mot de passe, le Membre devra impérativement en aviser le Prestataire par tous
                                    moyens et les modifier dans les meilleurs délais sur le Site. <br/>
                                </p>
                                <p>
                                    Dans le cas où l’Utilisateur ne respecterait pas les présentes modalités
                                    d’inscription et particulièrement s'il fournit des informations, fausses, inexactes,
                                    incomplètes ou non à jour, le Prestataire se réserve le droit de refuser son
                                    inscription, de suspendre ou supprimer son Compte dans les conditions de l’Article
                                    10 des Conditions Générales. <br/>
                                </p>
                                <p>
                                    En aucun cas la responsabilité du Prestataire ne saurait être retenue vis-à-vis des
                                    Utilisateurs, des Membres ou de tiers pour toute erreur, omission ou imprécision
                                    quant aux informations communiquées lors de l’inscription.
                                    <br/>
                                </p>
                                <h3 style={{marginTop:"10px"}}>
                                    4.2 Création du Profil
                                    <br/>
                                </h3>
                                <p>
                                    Chaque Membre s’étant inscrit est invité à créer un Profil qui sera visible par les
                                    autres Membres sur le Site, en renseignant les informations demandées sur le Site.
                                    <br/>
                                    Le Membre est personnellement responsable des informations transmises et garantit
                                    qu’elles respectent les termes des Conditions Générales et la Charte éthique.
                                </p>
                            </div>
                            <h2 style={{marginTop:"20px"}}>
                                <strong>
                                    5. Souscription à un Abonnement
                                    <br/>
                                </strong>
                            </h2>

                            <div style={{marginLeft: "20px"}}>
                                <h3 style={{marginTop:"10px"}}>
                                    5.1 Création du Profil
                                    <br/>
                                </h3>
                                <p>
                                    Afin de pouvoir bénéficier des Services, tout Membre à la possibilité de s’abonner
                                    au travers de l’une des offres d’Abonnement proposée sur le Site.
                                    <br/>
                                    Le Prestataire propose des offres d’Abonnement dont le contenu est précisé sur le
                                    Site.
                                    <br/>
                                    Chaque Abonnement donne accès à un nombre forfaitaire de Like par jour par Membre
                                    mais ce dernier peut en acquérir en supplément en souscrivant directement aux offres
                                    qui lui seront proposées sur le Site.
                                    <br/>
                                    Le Prestataire se réserve le droit, à sa seule discrétion et à tout moment, de
                                    modifier les offres d’Abonnement. Toute modification s’appliquera sans délai aux
                                    Abonnements n’ayant pas fait l’objet d’une validation par le Membre lors de sa
                                    souscription.
                                    <br/>
                                </p>

                                <h3 style={{marginTop:"10px"}}>
                                    5.2 Conditions financières
                                    <br/>
                                </h3>

                                <p>
                                    Les prix des Abonnements affichés sur le Site sont indiqués en euros toutes taxes
                                    comprises (TVA et autres taxes applicables).
                                    <br/>
                                    Les Prix sont susceptibles d’être modifiés unilatéralement par le Prestataire, à
                                    tout moment et s’appliqueront sans délai à toute souscription à un Abonnement ou
                                    tout achat de Like supplémentaire n’ayant pas fait l’objet d’une validation.
                                    <br/>
                                    Le paiement s’effectue par carte bancaire et bénéficie du système de sécurisation 3d
                                    Secure.
                                    <br/>
                                    En cas de reconduction tacite, l’Abonnement sera facturé à l’Abonné sur la base
                                    tarifaire de l’Abonnement initial (sauf offre promotionnelle) et le paiement
                                    s’effectuera mensuellement par prélèvement automatique, conformément aux
                                    spécifications de l’Abonnement concerné.
                                    <br/>
                                    En cas de défaut de paiement de l’Abonné, le Prestataire se réserve le droit de
                                    suspendre ou supprimer son accès à son Compte et au Service sans préjudice des
                                    éventuels dommages et intérêts pouvant être réclamés de ce fait.
                                    <br/>
                                </p>
                            </div>
                            <h2 style={{marginTop:"20px"}}>
                                <strong>
                                    6. Durée de l’Abonnement
                                    <br/>
                                </strong>
                            </h2>

                            <div style={{marginLeft: "20px"}}>
                                <h3 style={{marginTop:"10px"}}>
                                    6.1 Durée initiale
                                    <br/>
                                </h3>

                                <p>
                                    La durée initiale est celle correspondant au type d’abonnement choisi par le Membre
                                    lors de sa souscription, dans les conditions de l’article 5 des Conditions
                                    Générales.
                                    <br/>
                                    Cette durée est ferme et ne saurait faire l’objet d’une réduction ou d’une
                                    prorogation.
                                </p>

                                <h3 style={{marginTop:"10px"}}>
                                    6.2 Reconduction tacite
                                    <br/>
                                </h3>

                                <p>
                                    Lors de l’arrivée de son terme, l’Abonnement est reconduit tacitement pour la même
                                    durée que celle prévue initialement.
                                    <br/>
                                    <strong>
                                        Cependant, l’Abonné peut s’opposer à cette reconduction en adressant sa demande
                                        au plus tard vingt-quatre (24) heures avant la date d’échéance de l’Abonnement
                                        par courrier ou en demandant la suspension de son Abonnement via la rubrique
                                        dédiée de son Compte.
                                    </strong>
                                    <br/>
                                    Le Prestataire prendra acte de cette demande qui sera confirmée à l’Abonné par
                                    courrier électronique. L’Abonnement prendra alors fin à sa date d’échéance initiale.
                                    <br/>
                                    En outre, et conformément à l’article L215-1 du Code de la consommation, le
                                    Prestataire informera l’Abonné, par courrier électronique envoyé au plus tôt trois
                                    mois et au plus tard un mois avant la fin de l’Abonnement initial, du renouvellement
                                    de son Abonnement et de la possibilité qui lui est offerte de refuser cette
                                    reconduction. Étant entendu que, concernant les Abonnements dont la durée est
                                    inférieure ou égale à un (1) mois, cette information est réputée donnée à l’Abonné
                                    par le présent article.
                                    <br/>
                                </p>
                            </div>
                            <h2 style={{marginTop:"20px"}}>
                                <strong>
                                    7. Description du Service
                                    <br/>
                                </strong>
                            </h2>

                            <div style={{marginLeft: "20px"}}>
                                <p>
                                    Pour utiliser le Service l’Abonné doit disposer des équipements et des logiciels
                                    requis, et notamment bénéficier d’une connexion internet.
                                    <br/>
                                    La souscription a un Abonnement offre la possibilité aux Abonnés d’accéder au
                                    Service pendant toute la durée de l’Abonnement choisi, sous réserve du paiement
                                    effectif du prix par l’Abonné.
                                    <br/>
                                    L’Abonné est invité à choisir les critères concernant les Profils qu’il souhaite
                                    rencontrer sur le Site. Il lui est ensuite proposé l’ensemble des Profils
                                    correspondant aux critères qu’il aura préalablement choisi.
                                    <br/>
                                    L’Abonné a la possibilité de (i) consulter chaque Profil proposé, (ii) donner ou non
                                    un Like au Profil, (iii) en cas de Match, contacter le Membre concerné au travers la
                                    messagerie privée.
                                    <br/>
                                    Chaque Abonné a la possibilité, discrétionnairement, de bloquer un ou plusieurs
                                    autre(s) Abonné(s). Dans ce cas, son Profil n’apparaîtra plus dans les Profils
                                    proposés et l’Abonné bloqué n’aura plus la possibilité de le contacter.
                                    <br/>
                                    Le Prestataire se réserve le droit, à sa seule discrétion et à tout moment, de
                                    mettre à jour ou modifier le Service. Dans le cas où une modification réduirait
                                    significativement les fonctionnalités disponibles aux Abonnés dans le cadre de leur
                                    Abonnement, ces derniers ont la possibilité de le résilier dans un délai de dix (10)
                                    jours suivant la date des modifications et recevoir un remboursement au prorata de
                                    tous les montants prépayés, mais inutilisés.
                                    <br/>
                                </p>
                            </div>

                            <h2 style={{marginTop:"20px"}}>
                                <strong>
                                    8. Obligations du Membre
                                    <br/>
                                </strong>
                            </h2>

                            <div style={{marginLeft: "20px"}}>
                                    <p>
                                        Tout Membre ou Abonné déclare que toute violation à l’une de ses obligations
                                        constitue une faute contractuelle grave susceptible d’entraîner les sanctions
                                        prévues par l’article 10 des Conditions Générales, sans préjudice de tous les
                                        dommages et intérêts possiblement réclamés par le Prestataire.
                                        <br/>
                                    </p>

                                <h3 style={{marginTop:"10px"}}>
                                    8.1 Quant à l’Utilisation du Site et du Service
                                    <br/>
                                </h3>

                                    <p>
                                        Le Site et le Service sont exclusivement réservés à un strict usage privé.
                                        <br/>
                                        Par conséquent, tout usage à des fins commerciales est prohibé. Ainsi, le Membre
                                        s’engage à ne faire aucune offre non sollicitée, publicité et proposition, à
                                        n’envoyer aucun courrier indésirable à d’autres Membres ou à créer un
                                        jeu-concours via le Site. Cette stipulation n’a cependant pas pour vocation
                                        d’empêcher la création d’un réseau professionnel des Membres.
                                        <br/>
                                        Chaque Compte étant strictement unique et personnel, il est interdit (i)
                                        d’accéder ou de tenter d’accéder au Profil ou au Compte d’un autre Membre, (ii)
                                        de permettre à un tiers de se connecter sur son Compte, (iii) de créer un Profil
                                        avec de fausses informations ou les informations concernant un Membre ou un
                                        tiers, (iv) de créer plusieurs Comptes.
                                        <br/>
                                        Le Membre garantit utiliser le Service de manière loyale et s’interdit d’engager
                                        ou encourager toute activité illégale et plus particulièrement d’harceler,
                                        intimider, manipuler, escroquer ou violenter de quelque manière que ce soit les
                                        autres Membres.
                                        <br/>
                                        L’Utilisateur ou le Membre s’interdit de perturber le fonctionnement du Site ou
                                        l’utilisation du Service par un autre Membre, par quelque moyen que ce soit, y
                                        compris en téléchargeant ou en diffusant des virus, spams ou d’autres codes
                                        malveillants.
                                        <br/>
                                        Il est interdit au Membre de recourir à un quelconque procédé automatisé dans le
                                        cadre de l’utilisation du Service.
                                        <br/>
                                        Enfin, tout Membre dont le Compte a déjà fait l’objet d’une résiliation à
                                        l’initiative du Prestataire ne peut en créer un nouveau sans l’accord préalable
                                        du Prestataire.
                                        <br/>
                                    </p>

                                <h3 style={{marginTop:"10px"}}>
                                    8.2 Quant aux Contenus
                                    <br/>
                                </h3>

                                    <p>
                                        Chaque Membre est personnellement responsable de l’ensemble des Contenus qu’il
                                        communiquera sur le Site, au travers son Profil, ses messages privées ou par un
                                        quelconque autre moyen.
                                        <br/>
                                        Ainsi, il s’engage à ne pas divulguer de Contenus incitant à la haine ou à la
                                        violence, à caractère pornographique, pédophile ou contenant de la nudité et de
                                        manière générale, tout Contenu contraire à la Charte éthique, à l’ordre public,
                                        aux lois et règlements en vigueur et notamment ceux pouvant faire l’objet d’une
                                        sanction pénale.
                                        <br/>
                                        Il s’engage à ne publier aucun Contenu (i) qui viole ou enfreint les droits
                                        d’autrui, notamment ceux relatifs aux droits de la personnalité (notamment la
                                        vie privée), aux droits d’auteur ou des marques ou tout autre droit de propriété
                                        intellectuelle ou contractuel, (ii) divulguant des informations personnelles
                                        permettant d’identifier autrui ou sa localisation.
                                        <br/>
                                        Il doit s’abstenir de tout comportement ou propos choquants, contraire aux
                                        bonnes mœurs ou susceptible de porter atteinte aux lois et règlements en
                                        vigueur, aux droits des tiers ou à l’image du Site, du Service ou du
                                        Prestataire.
                                        <br/>
                                    </p>
                                </div>

                            <h2 style={{marginTop:"20px"}}>
                                <strong>
                                    9. Modération des Contenus par le Prestataire
                                    <br/>
                                </strong>
                            </h2>

                            <div style={{marginLeft: "20px"}}>
                                <p>
                                    Le Prestataire est l’hébergeur des Contenus communiqués par le Membre sur le Site et
                                    via le Service et ne saurait voir sa responsabilité engagée du fait de ces Contenus.
                                    Il s’engage, au titre d’une obligation de moyens, à réaliser la modération des
                                    Contenus.
                                    <br/>
                                    Ainsi, le Prestataire a la possibilité, lorsqu’il estime, à sa seule discrétion, que
                                    ces Contenus contreviennent aux stipulations des Conditions Générales, de les
                                    retirer et de suspendre ou de supprimer le Compte du Membre concerné dans les
                                    conditions de l’article 10 des Conditions Générales.
                                    <br/>
                                    L’ensemble des Contenus, ou comportement d’un Membre ou d’un Abonné peuvent faire
                                    l’objet d’un signalement par tout autre Membre lorsque ces Contenus lui paraissent
                                    porter atteinte aux Conditions Générales, à la Charte éthique ou aux droits d’un
                                    tiers. Les signalements peuvent être opérés directement sur le Profil de l’Abonné
                                    concerné via la rubrique « signaler un abus », par le formulaire de contact du Site
                                    ou par courrier électronique à l’adresse <a href={"mailto:aide.contact@eliteberbere.fr"}>aide.contact@eliteberbere.fr</a>
                                    <br/>
                                    Les Membres reconnaissent et acceptent pouvoir faire l’objet de signalements et donc
                                    d’une modération par le Prestataire.
                                    <br/>
                                    Aucun remboursement du prix de l’Abonnement ou de l’achat de Like ne sera accordé à
                                    l’Abonné du fait de la modération opérée par le Prestataire.
                                    <br/>
                                </p>
                                <h3 style={{marginTop:"10px"}}>
                                    9.1 Modération des Profils
                                    <br/>
                                </h3>

                                <p>
                                    Toute création ou modification de Profil par les Membres est soumise à la modération
                                    préalable du Prestataire.
                                    <br/>
                                    Dès lors, le Prestataire se réserve le droit de refuser toute création ou
                                    modification des Profils ne se conformant pas aux Conditions Générales.
                                    <br/>
                                    Tout Compte présentant un Profil non-conforme fera l’objet d’une suspension et d’une
                                    suppression dans le cas où aucune mise en conformité ne serait réalisée par le
                                    Membre après dans un délai de sept (7) jours à compter de la notification du
                                    Prestataire.
                                    <br/>
                                </p>

                                <h3 style={{marginTop:"10px"}}>
                                    9.2 Modérations des Contenus
                                    <br/>
                                </h3>

                                <p>
                                    En aucun cas la responsabilité du Prestataire ne peut être engagé du fait de
                                    Contenus non-conforme aux Conditions Générales n’ayant pas fait l’objet de
                                    signalement.
                                    <br/>
                                    Dans le cas où le Contenu signalé ne se conforme pas aux Conditions Générales ou à
                                    la Charte éthique, le Prestataire se réserve le droit (i) de les supprimer, (ii) de
                                    suspendre ou (iii) supprimer le Compte de du Membre, (iv) le cas échéant, résilier
                                    l’Abonnement de l’Abonné.
                                    <br/>
                                </p>
                            </div>

                            <h2 style={{marginTop:"20px"}}>
                                <strong>10. Suspension et résiliation du Compte ou de l’Abonnement </strong>
                            </h2>
                            <div style={{marginLeft: "20px"}}>
                                <p>
                                    La suspension du Compte entraîne pour le Membre la révocation de son accès au
                                    Service. Cette suspension est temporaire et n’interrompt pas l’Abonnement.
                                    <br/>
                                    La résiliation du Compte et de l’Abonnement sont définitives et entraîne la
                                    suppression de l’intégralité du Compte, du Profil et des Contenus ainsi que la
                                    révocation de l’accès au Service.
                                    <br/>
                                    En tout état de cause, toute somme réglée par le Membre est définitivement acquise
                                    et ne saurait faire l’objet d’un remboursement de la part du Prestataire, sans
                                    préjudice des éventuels dommages et intérêts qui pourraient être réclamés par le
                                    Prestataire.
                                    <br/>
                                </p>
                                <h3 style={{marginTop:"10px"}}>
                                    10.1 Par le Membre <br/>
                                </h3>
                                <p>
                                    Chaque Membre peut à tout moment demander la suspension de son Abonnement, via la
                                    rubrique dédiée à cet effet sur son Compte.
                                    <br/>
                                    Tout Membre ayant créé un Compte est libre de le supprimer à tout moment, sans motif
                                    et sans frais autres que ceux liés à la transmission de sa demande, en adressant sa
                                    demande directement au Prestataire via la rubrique de contact dédiée.
                                    <br/>
                                    Cette demande sera réputée effectuée le jour ouvré suivant la réception par le
                                    Prestataire de la demande de clôture du Compte concerné.
                                    <br/>
                                    Dans le cas où la demande est effectuée par un Abonné, cette résiliation entraîne
                                    celle de l’Abonnement et le Prestataire lui adressera un courrier électronique
                                    prenant acte de la résiliation et indiquant sa date effective.
                                    <br/>
                                </p>
                                <h3 style={{marginTop:"10px"}}>
                                    10.2 Par le Prestataire <br/>
                                </h3>
                                <p>
                                    En cas de non-respect par le Membre de l’une de ses obligations ou plus généralement
                                    à l’une des stipulations des Conditions Générales ou à une disposition légale ou
                                    règlementaire, le Prestataire se réserve la possibilité de suspendre ou supprimer
                                    définitivement son Compte et, par conséquent, son Abonnement, sans préavis ni mise
                                    en demeure.
                                    <br/>
                                    La décision de suspension, de suppression ou de résiliation est notifié au Membre
                                    concerné par courrier électronique et prendra effet sans délai.
                                    <br/>
                                </p>
                            </div>


                            <h2 style={{marginTop:"20px"}}>
                                <strong>11. Droit de rétractation </strong>
                            </h2>
                            <div style={{marginLeft: "20px"}}>
                                <h3 style={{marginTop:"10px"}}>
                                    11.1 Exercice du droit de rétractation <br/>
                                </h3>
                                <p>
                                    Conformément aux articles L221-18 du Code de la consommation, l’Abonné dispose d’un délai de 14 jours à compter du jour de la souscription à l’Abonnement pour exercer son droit de rétractation, sans avoir à motiver sa décision.
                                    <br/>
                                    Si l’Abonné souhaite exercer son droit de rétraction, il doit en informer le Prestataire avant l’expiration du délai par l’envoi, par courrier ou par email, du formulaire reproduit ci-après (article 11.2 Formulaire de rétractation) ou par tout autre déclaration dénuée d’ambiguïté sous peine d’irrecevabilité de la demande.
                                    <br/>
                                    Lorsque le droit de rétractation est exercé, le Prestataire rembourse le Client du prix payé par ce dernier lors de la transaction initiale.
                                    <br/>
                                    Toutefois, conformément à l’article L221-25 du Code de la consommation, si l’Abonné a pu bénéficier du Service avant la fin du délai de rétractation, il devra payé un montant proportionnel à cette utilisation, au prorata du prix de l’Abonnement auquel il a souscrit.
                                    <br/>
                                    Toute première connexion sur le Site par l’Abonné à compter de la souscription d’un Abonnement constitue une demande expresse d’avoir immédiatement accès au Service.
                                    <br/>
                                    Le Prestataire remboursera alors le prix de la transaction initiale déduction faite du montant correspondant aux jours durant lesquels l’Abonné a pu utiliser le Service, étant entendu que tout jour débuté est du.
                                    <br/>
                                    Le remboursement interviendra dans un délai de quatorze (14) jours à compter de la date à laquelle le Prestataire a été informée de la décision de l’Abonné de se rétracter, par virement bancaire.
                                    <br/>
                                </p>
                                <h3 style={{marginTop:"10px"}}>
                                    10.2 Par le Prestataire <br/>
                                </h3>
                                <p>
                                    En cas de non-respect par le Membre de l’une de ses obligations ou plus généralement
                                    à l’une des stipulations des Conditions Générales ou à une disposition légale ou
                                    règlementaire, le Prestataire se réserve la possibilité de suspendre ou supprimer
                                    définitivement son Compte et, par conséquent, son Abonnement, sans préavis ni mise
                                    en demeure.
                                    <br/>
                                    La décision de suspension, de suppression ou de résiliation est notifié au Membre
                                    concerné par courrier électronique et prendra effet sans délai.
                                    <br/>
                                </p>
                            </div>


                            <h2 style={{marginTop:"20px"}}>
                                <strong>12. Responsabilités </strong>
                            </h2>
                            <div style={{marginLeft: "20px"}}>
                                <h3 style={{marginTop:"10px"}}>
                                    12.1 De l’Utilisateur et du Membre
                                    <br/>
                                </h3>
                                <p>
                                    L’Utilisateur s’engage expressément à accéder au Site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière génération mis-à-jour.
                                    <br/>
                                    Il demeure entièrement responsable de son utilisation du Site qu’il garantit utiliser de bonne foi, dans le respect des Conditions Générales et des lois et règlements en vigueur.
                                    <br/>
                                    Le Membre (ou, le cas échéant, l’Abonné) est seul responsable de l’utilisation qu’il fait du Service et des Contenus qu’il communique sur le Site au travers son Profil et ses messages privés. Il est susceptible d’engager sa responsabilité du fait d’un usage non-conforme de ces derniers ou en cas de non-respect des Conditions Générales.
                                    <br/>
                                    En outre, le Site n’étant pas destiné au stockage de données, le Membre demeure responsable de leurs sauvegardes, par ses propres moyens.
                                    <br/>
                                </p>
                                <h3 style={{marginTop:"10px"}}>
                                    12.2 Du Prestataire
                                    <br/>
                                </h3>
                                <p>
                                    Le Prestataire ne pourra voir sa responsabilité engagée que dans l’hypothèse où il aurait commis une faute certaine ayant été la cause exclusive d’un dommage direct causé à un Utilisateur, un Membre ou un Abonné.
                                    <br/>
                                    Le Prestataire ne pourra en aucun cas être tenue responsable des conséquences (i) d’une utilisation non-conforme du Site et du Service, (ii) du fait d’un Utilisateur, Membre ou d’un tiers, (iii) de la force majeure.
                                    <br/>
                                    Le Prestataire exclue expressément toute responsabilité à l’égard des évènements de toute nature qui pourraient survenir lors de rencontres réelle entre Membres à la suite de l’utilisation du Service.
                                    <br/>
                                    Le Prestataire met en œuvre toute mesure raisonnable pour permettre le fonctionnement du Site 24 heures sur 24 et 7 jours sur 7 mais ne saurait le garantir.
                                    <br/>
                                    La responsabilité du Prestataire ne saurait être engagée en cas de dommages causés à l’Utilisateur, au Membre ou aux tiers, et/ou au matériel informatique de ces derniers résultant, directement ou indirectement, notamment :
                                    <br/>
                                    <ul type={"circle"} style={{marginLeft:"20px"}}>
                                        <li>
                                            du fonctionnement, du défaut ou de l’absence de fonctionnement, de l’accès et/ou de l’utilisation du Site ;
                                            <br/>
                                        </li>
                                        <li>
                                            d’une opération de maintenance sur le Site ;
                                            <br/>
                                        </li>
                                        <li>
                                            de l’indisponibilité due à la faiblesse ou à l’absence de couverture réseaux par le fournisseur d’accès du Prestataire et/ou de l’Utilisateur ;
                                            <br/>
                                        </li>
                                        <li>
                                            du dysfonctionnement ou absence d’acheminement des emails adressés par le biais du Site ;
                                            <br/>
                                        </li>
                                        <li>
                                            de la violation par un tiers des dispositions des articles 323-1 à 323-7 du Code Pénal français réprimant les atteintes aux systèmes de traitement automatisé de données, soit des pratiques de piratage (en anglais, de « hacking ») du Site ou des Contenus ;
                                            <br/>
                                        </li>
                                        <li>
                                            d’un cas de force majeure empêchant le Prestataire de fournir l’accès au Site.
                                            <br/>
                                        </li>
                                    </ul>
                                    Le Site peut inclure des liens hypertextes renvoyant vers d’autres sites internet. Le Prestataire ne détient aucun contrôle sur les informations accessibles via l’un de ces liens hypertextes et sa responsabilité ne pourra pas être engagée de ce fait.
                                    <br/>
                                </p>
                                <h3 style={{marginTop:"10px"}}>
                                    12.3 Force majeure
                                    <br/>
                                </h3>
                                <p>
                                    Aucune Partie ne sera responsable de la non-exécution totale ou partielle de ses obligations provoquées par un événement constitutif de force majeure au sens du droit français.
                                    <br/>
                                    La Partie invoquant un événement constitutif de force majeure devra en aviser l’autre Partie dans les trois (3) jours calendaires suivant la survenance de cet événement. Elle fera tous ses efforts pour en limiter la portée.
                                    <br/>
                                    L’exécution du Contrat reprendra son cours normal dès que l’événement constitutif de force majeure aura cessé. Si la suspension du Contrat devait excéder trente (30) jours calendaires, chaque Partie pourra résilier le Contrat après notification à l’autre Partie par courrier recommandé avec accusé de réception.
                                    <br/>
                                </p>
                            </div>


                            <h2 style={{marginTop:"20px"}}>
                                <strong>13. Propriété intellectuelle </strong>
                            </h2>
                            <div style={{marginLeft: "20px"}}>
                                <p>
                                    L'ensemble des marques, figuratives ou non, et plus généralement toutes les autres illustrations, images et logotypes figurant sur le Site, qu'ils soient déposés ou non, sont et demeureront la propriété exclusive du Prestataire.
                                    <br/>
                                    Toute reproduction totale ou partielle, modification ou utilisation de ces marques, illustrations, images et logotypes, pour quelque motif et sur quelque support que ce soit, sans accord exprès et préalable du Prestataire, est strictement interdite.
                                    <br/>
                                    Le Site (notamment, structure générale, logiciel, codes, textes, images animées ou non, vidéos, sons, et, d’une manière générale, tout élément constitutif du Site) est la propriété exclusive du Prestataire et est protégé par le droit d’auteur (articles L. 111-1 et suivants du Code de la propriété intellectuelle) et la propriété intellectuelle (droits sur les bases de données, marques, dessins et modèles etc.). Il est interdit, à titre privé ou public, de reproduire, représenter, copier, imiter, valoriser, louer, vendre, revendre ou exploiter, que ce soit dans un but commercial ou purement gratuit, tout ou partie du Site ou de ses éléments, sauf accord exprès, préalable et écrit du Prestataire.
                                    <br/>
                                    Tout contrevenant pourra être poursuivi civilement et/ou pénalement pour les infractions qui seraient commises (contrefaçon, parasitisme etc.).
                                    <br/>
                                    Le Membre concède au Prestataire une licence d’utilisation des droits de propriété intellectuelle attachés aux Contenus qu’il aura fourni, pour le monde entier et pendant toute la période de validité du Compte comprenant le droit de reproduire, représenter, adapter, traduire, numériser, sous licencier sur l’ensemble du Site et, plus généralement sur tous supports de communication électronique (mail, réseaux sociaux, application mobile...). Le Membre autorise également le Prestataire à modifier ses Contenus afin d’assurer leur compatibilité (technique et esthétique) avec le Site et le Service.
                                    <br/>
                                </p>
                            </div>


                            <h2 style={{marginTop:"20px"}}>
                                <strong>14. Données personnelles</strong>
                            </h2>
                            <div style={{marginLeft: "20px"}}>
                                <p>
                                    Dans le cadre de son activité, le Prestataire est amené à traiter des informations concernant les Utilisateurs, Membres et Abonnés dont certaines sont de nature à les identifier (« données personnelles »).
                                    <br/>
                                    Le traitement de ces données est effectué conformément aux dispositions du Règlement (UE) du Parlement européen et du Conseil du 27 avril 2016 (RGPD) et à la loi française n° 78-17 du 6 janvier 1978 (loi informatique et libertés).
                                    <br/>
                                    Les informations concernant le traitement de ces données par le Prestataire sont contenues dans la
                                    <NavLink to={EliteRouters.confidentialPolitics.path}> Politique de confidentialité</NavLink>.
                                    <br/>
                                </p>
                            </div>


                            <h2 style={{marginTop:"20px"}}>
                                <strong>15. Dispositions diverses </strong>
                            </h2>
                            <div style={{marginLeft: "20px"}}>
                                <p>
                                    Non-renonciation: Le fait pour une des Parties de ne pas se prévaloir d'un manquement par l'autre partie à l'une quelconque des obligations visées dans les présentes ne saurait être interprété pour l'avenir comme une renonciation à l'obligation en cause.
                                    <br/>
                                    Nullité d’une clause : Dans le cas où l’une des clauses du Contrat serait déclarée nulle, inefficace ou sans objet par décision de justice définitive, cette clause serait réputée non écrite et ne saurait entraîner la nullité du Contrat dans son ensemble (sous réserve d’une stipulation expresse contraire).
                                    <br/>
                                    Modification : Le Prestataire se réserve le droit de modifier à tout moment les Conditions Générales. Les Conditions Générales applicables aux Abonnements sont celles en vigueur lors de la validation de la souscription.
                                    <br/>
                                </p>
                            </div>


                            <h2 style={{marginTop:"20px"}}>
                                <strong>16. Règlement des litiges </strong>
                            </h2>
                            <div style={{marginLeft: "20px"}}>
                                <p>
                                    Loi applicable : les Conditions Générales sont exclusivement soumises à la loi française, indifféremment de la localisation ou de la nationalité de l’Utilisateur ou du Membre, sous réserve des règles impératives de la loi de son pays de résidence.
                                    <br/>
                                    Résolution amiable : En cas de litige susceptible de survenir en lien avec les Conditions Générales, l’Utilisateur ou le Membre s’engage à prendre contact par tous moyens avec le Prestataire afin d’en trouver une issue amiable.
                                    <br/>
                                    Médiation : Conformément aux dispositions de l’article L612-1 du Code de la consommation, le Membre ayant recours au Service a la possibilité de recourir à un médiateur afin de trouver une solution amiable aux éventuels litiges l’opposant au Prestataire.
                                    <br/>
                                    Le Prestataire adhère au service de médiation de l’Association Nationale des Médiateurs (ANM Conso) 62, rue Tiquetonne 75002 représentée par Didier MORFOISSE, son Président D’une part Ci-après dénommée « ANM CONSO ». Le membre à la possibilité de contacter après avoir recherché une résolution amiable directement auprès le Prestataire.
                                    <br/>
                                    Compétence juridictionnelle : En cas d’échec de résolution amiable, les juridictions françaises seront exclusivement compétentes pour trancher les contestations, réclamations ou litiges liés au Site, au Service ou au Conditions Générales.
                                    <br/>
                                </p>
                            </div>

                            <div style={{marginLeft:"20px",marginTop:"20px"}}>
                                L’Association Nationale des Médiateurs (ANM Conso) 62, rue Tiquetonne 75002 PARIS ELITE BERBERE 40 B RUE DANTZIG 75015 PARIS Représentée par Didier MORFOISSE, son Président D’une part Ci-après dénommée «ANM CONSO»
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <Footer/>
            </>
            );
            }
