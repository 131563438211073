import React, { useEffect, useState } from "react";
import TitleOverTitle from "../../../Components/Uikit/TitleOverTitle/TitleOverTitle";
import iconMatchTitle from "../../../assets/images/icon-match-title.svg";
import EliteParagraph from "../../../Components/Uikit/Typography/Paragraph";

import FooterMenu from "../../../Components/FooterMenu/FooterMenu";
import UserService from "../../../Services/ApiServices/UserService";
import {hasValidSubscription} from "../../../Services/ApiServices/api.client";
import MatchMakingOffer from "../../../Components/MatchmakingCard/MatchMakingOffer";
import Alert from "react-bootstrap/Alert";
import {useCurrentUser} from "../../../Hooks/AuthenticatedUser";

export default function MatchMakingMobile(props) {

  const [matches, setMatches] = useState([]);
  const {user} = useCurrentUser();


  const handleMatchChoice = (choice, profileId) => {
    UserService.updateSuggestionFlag(profileId, choice).then((res) => {
      setMatches(res.data.list);
    });
  };

  useEffect(() => {
    async function fetchMatches() {
      if (hasValidSubscription()) {
        await UserService.fetchSuggestions().then((res) => {
          setMatches(res.data.list);
        });
      }
    }
    fetchMatches();
  }, []);

  return (
    <>
      <div className={"container"}>
        <div className="row">
          <div className="col-12">
            <TitleOverTitle
              backTitleSize={"36px"}
              frontTitleSize={"24px"}
              title={"Matching du jour"}
              overTitleMarginTop={"-25px"}
              icon={iconMatchTitle}
            />
          </div>
          { (!user.loading && (!user.searching.city || user.searching.city.length <= 0)) &&
          <div>
              <Alert className="col-12" variant="secondary">
                <p className={"m-auto text-center"}>
                  ✔ Pour avoir des matchs, mettez à jour votre profil : <a href={"/profile"}>"Ce que je recherche"</a>
                </p>
              </Alert>
            </div>
          }
          <div
            className={`col-md-12 col-lg-4 d-flex flex-column overflow-auto h-100vh ${
              !hasValidSubscription() ? "px-5" : ""
            }`}>
            <div className="row">
            <MatchMakingOffer
              matchData={matches}
              callback={handleMatchChoice}
              isMobile
            />
            </div>

            <div className={`col-12`}>
              <EliteParagraph hide={matches.length === 0 || hasValidSubscription()}>
                <a href={"/#"}>Changer d’offre</a>
              </EliteParagraph>
            </div>
          </div>
        </div>
      </div>

      <FooterMenu></FooterMenu>
    </>
  );
}
