import React, {Component} from "react";
import moment from "moment";
import EliteParagraph from "../../../Components/Uikit/Typography/Paragraph";
import NavigationFrontBack from "../../Uikit/Wizard/NavigationWizard/NavigationFB";
import EliteErrorText from "../../Uikit/Typography/ErrorText";
import AutoComplete from "../../Uikit/AutoComplete/AutoComplete";
import DateSelector from "../../Uikit/DateSelector/DateSelector";

class SignUpStep2 extends Component {
  updateProfile(prop, value) {
    this.props.onChange({[prop]: value});
  }

  renderError(key) {
    if (!this.props.showErrors) return;

    if (key === "birth_date") {
      if (!this.props.profile.birth_date) {
        return <EliteErrorText text="Champ obligatoire"/>;
      } else if (
        moment().diff(moment(this.props.profile.birth_date), "year") < 18
      ) {
        return <EliteErrorText text="L’utilisateur doit être majeur"/>;
      }
    } else if (key === "city" && !this.props.profile.city) {
      return <EliteErrorText text="Champ obligatoire"/>;
    }
  }

  render() {
    const minDate = moment().subtract(18, "years").toDate();
    return (
      <>
        <div className={`bornContainer`}>
          <div style={{height: "1vh"}}/>
          <EliteParagraph
            margin={"32px 0 8px 0"}
            uppercase
            textAlign={"left"}
            textColor={"#fff"}
            text={"Je suis né(e) le"}
          />
          <DateSelector
            fontSize={"14px"}
            maxDate={minDate}
            onChange={(value) => this.updateProfile("birth_date", value?.toISOString().slice(0, 10))}
            showContainer
            isMobile={this.props.isMobile}
            value={this.props.profile.birth_date}
            isInvalid={!this.props.profile.birth_date || moment().diff(moment(this.props.profile.birth_date), "year") < 18}
            errorText={this.renderError("birth_date")}
            isSubscriptions
          />
        </div>
        <EliteParagraph
          uppercase
          textAlign={"left"}
          textColor={"#fff"}
          text={"J’habite à"}
        />
        <AutoComplete
          id="city"
          classes={"pb-2"}
          isMobile={this.props.isMobile}
          placeholder={"Ville de résidence"}
          value={[this.props.profile.city]}
          url={`${process.env.REACT_APP_API_URL}/api/cities?q=`}
          onChange={(value) =>
            this.updateProfile("city", value ? value.id : null)
          }
          color={"#fff"}
          isInvalid={this.props.showErrors && !this.props.profile.city}
          errorText={this.renderError("city")}
          isSubscription
          fontSize={"14px"}
        />
        {/*<CustomTextField
          isMobile
          placeholder={"Code postal"}
          value={this.props.profile.zip}
          onChange={(value) => this.updateProfile("zip", value)}
          isInvalid={this.props.showErrors && !this.props.profile.zip}
          errorText={this.renderError("zip")}
          isSubscription
          autoComplete={"chrome-off"}
          fontSize={"14px"}
        />*/}
        {/* mandadory in every step */}
        <NavigationFrontBack
          profile={this.props.profile}
          onShowErrors={this.props.onShowErrors}
          isMobile={this.props.isMobile}
          wizardProps={this.props}
          isStepOne={false}
        />
      </>
    );
  }
}

export default SignUpStep2;
