import React, {Component} from 'react';
import './SignUpStyle.css';
import SignUpMobile from "./Mobile/SignUpMobile";

class SignUp extends Component {
  render() {
    return (
      <div>
        SignUp
      </div>
    );
  }
}

export {SignUp, SignUpMobile};
