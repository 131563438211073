import React, { useRef } from "react";
import "./OfferCardStyles.css";
import stars from "../../assets/images/illustration-stars.svg";
import iconRightOffer from "../../assets/images/icon-arrow-right-offer.svg";
import { ReactSVG } from "react-svg";
import BigTitle from "../Uikit/Typography/BigTitle";
import EliteParagraph from "../Uikit/Typography/Paragraph";
import ContextualMenu from "../Uikit/ContextualMenu/ContextualMenu";
import CustomModal from "../Uikit/Modal/Modal";
import Offers from "../Offers/Offers";
import { hasValidSubscription } from "../../Services/ApiServices/api.client";
import { useHistory } from "react-router-dom";
import { EliteRouters } from "../../Navigation/Routers";

export function OfferCard(props) {
  const child = useRef();
  const history = useHistory();

    const contextualMenuAllItems = [
        {
          label: "Gérer ma souscription",
          clickEvent: () => {
            history.push({
              pathname: EliteRouters.management.path,
              state: {
                currentSubscription: props.currentSubscription
              }
            })
          },
        },
    ];

    /*function to add zero to date/month < 10*/
    // const zeroPad = (nr, base) => {
    //   var len = (String(base).length - String(nr).length) + 1;
    //   return len > 0? new Array(len).join('0') + nr : nr;
    // }

    // const getUnavailableDate = (dateStr) => {
    //   var dattmp = dateStr.split('/').reverse().join('/');
    //   var nwdate =  new Date(dattmp);
    //   nwdate.setDate(nwdate.getDate() + 1);
    //   return [ zeroPad(nwdate.getDate(), 10)
    //       ,zeroPad(nwdate.getMonth()+1, 10)
    //       ,nwdate.getFullYear() ].join('/');
    // }

  return (
    <>
      <div className={"offerCard d-flex position-relative"}>
        <div
          className={"contextualMenuOffer position-absolute"}
          style={{
            display: props.currentSubscription !== null ? "block" : "none",
          }}>
            {
              hasValidSubscription() ?
          <ContextualMenu
            menuItems={contextualMenuAllItems}
            isActive={props.currentSubscription !== null}
          />:''}
        </div>
        <div className={"stars d-flex me-4 flex-column"}>
          <ReactSVG src={stars} />
        </div>
        <div className={"offerText mt-0 flex-column"}>
          <BigTitle
            titleColor={"#f9fa00"}
            fontWeight={"bold"}
            uppercase
            fontFamily={"Roboto"}
            fontSize={"24px"}
            text={
              props.currentSubscription !== null
                ? "ELITE " + props.currentSubscription?.name
                : "Offre"
            }
            textAlign={"left"}
          />
          <EliteParagraph
            textAlign={"left"}
            textColor={"#fff"}
            fontSize={"18px"}
            fontWeight={"500"}
            text={
              props.currentSubscription?.endDate
                ? "Votre abonnement est valable jusqu’au"
                : "Vous n’avez pas d’offre   en cours pour le moment."
            }
          />
          {props.currentSubscription !== null ? (
            <div className="d-flex justify-content-start align-items-center">
              <EliteParagraph
                uppercase
                textAlign={"left"}
                textColor={"#ffffff"}
                fontSize={"20px"}
                fontWeight={"bold"}
                text={props.currentSubscription?.endDate}
                margin={"0"}
              />
            </div>
          ) : (
            <div
              onClick={() => child.current.handleOpenModal()}
              className="d-flex justify-content-end align-items-center cursor-pointer">
              <EliteParagraph
                uppercase
                textAlign={"right"}
                textColor={"#6c95e7"}
                fontSize={"18px"}
                fontWeight={"bold"}
                text={"Découvrir nos offres"}
                margin={"0"}
              />
              <ReactSVG src={iconRightOffer} />
            </div>
          )}
          {/* {props.currentSubscription?.status === 'canceled' && 
            <EliteParagraph
              textAlign={"left"}
              textColor={"#fff"}
              fontSize={"18px"}
              fontWeight={"500"}
              fontStyle={"italic"}
              text={"A partir du " + getUnavailableDate(props.currentSubscription?.endDate) + " vous n'avez pas d'offre en cours"}
            />
          } */}
        </div>
      </div>
      <CustomModal
        overlayClassName="Overlay"
        modalContent={<Offers isMobile={props.isMobile} closeModal={child} />}
        modalClass={
          props.isMobile
            ? props.currentSubscription === null
              ? "offersModalMobile"
              : "offersModalMobileBoost"
            : props.currentSubscription === null
            ? "offersModal"
            : "offersModalBoost"
        }
        ref={child}
      />
    </>
  );
}

export default OfferCard;
