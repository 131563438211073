import React, { useState } from "react";
import "./LoginMobileStyles.css";
import CustomTextField from "../../Components/Uikit/TextFields/TextFields";

import iconEmail from "../../assets/images/icon-mail.svg";
import iconLock from "../../assets/images/icon-lock.svg";
import CustomButton from "../../Components/Uikit/Button/CustomButton";
import EliteParagraph from "../../Components/Uikit/Typography/Paragraph";
import { useHistory } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

function CustomLoginMobile({ onLogin }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  return (
    <div className={ "signUpFormContainer" }>
      <CustomTextField
        isMobile
        isSubscription
        borderColor={"#FFFFFF"}
        value={ email }
        onChange={ setEmail }
        placeholder={ "Email" }
        addOn={iconEmail }/>
      <CustomTextField
        valeu={ password }
        borderColor={"#FFFFFF"}
        isMobile
        isSubscription
        type="password"
        placeholder={ "Mot de passe" }
        onChange={ setPassword }
        addOn={ iconLock }/>

      <span className={ 'forgotPasswordSpan' } onClick={ () => history.push("/forgot-password") }>
        Mot de passe oublié ?
      </span>

      {loading ?
        <div className="loading-searching">
            <Spinner animation="border"/>
        </div> :
        <>
            <CustomButton onClick={() => {
                setLoading(true)
                onLogin(email, password).then(() => setLoading(false)).catch(() => setLoading(false))
            }} label={"Connexion"}/>
          <div style={{height:"35px"}}/>
        </>
      }
      <EliteParagraph
        margin={ '16px 0 0 0' }
        textColor={ "#fff" }
        fontSize={ "14px" }
        text={ "Pas encore de compte ?  " }>
        <span onClick={ () => history.push("/sign-up") }>
          Inscrivez-vous
        </span>
      </EliteParagraph>
    </div>
  );
}

export default CustomLoginMobile;
