import React from "react";
import EliteParagraph from "../Typography/Paragraph";
import "./ItemSelectorModalStyles.css";
import { ReactSVG } from "react-svg";
import iconRightColor from "../../../assets/images/icon-arrow-right-color.svg";
import iconAlert from "../../../assets/images/icon-alerte.svg";

export function ItemSelectorModal(props) {
  return (
    <div
      className={
        "itemSelector d-flex align-items-center justify-content-between overflow-hidden"
      }>
      <div className={ "d-flex align-items-center" } style={ { width: "45%" } }>
        <EliteParagraph
          margin={ "0 16px 0 0" }
          text={ props.label }
          fontSize={ "12px" }
          textColor={ "#2c2c2c" }
          uppercase
          textAlign={ "left" }
        />
        <ReactSVG src={ (!props.value || props.value==='' || props.value==='null') && props.label === 'MOT DE PASSE' ?  iconAlert : '' }/>
      </div>
      <div className={ "d-flex align-self-baseline" } style={ { width: "50%" } }>
        <EliteParagraph
          textAlign={ "left" }
          margin={ "0" }
          text={ props.value==='null' ? '' : props.value }
          fontSize={ "12px" }
          fontWeight={'normal'}
        />
      </div>
      <div
        className={ "d-flex" }
        style={ { width: "5%", justifyContent: "flex-end" } }>
        <ReactSVG src={ iconRightColor }/>
      </div>
    </div>
  );
}

export default ItemSelectorModal;
