import React, { Component } from "react";
import { ReactSVG } from "react-svg";
import citationIlus from "../../../assets/images/citation.svg";
import CustomAvatar from "../../Uikit/Avatar/Avatar";
import "./OurMeetsSliderContainerStyles.css";
import EliteParagraph from "../../Uikit/Typography/Paragraph";
import ResponsiveService from "../../../Services/ResponsiveService";

class SliderContainer extends Component {
  render() {
    const namesAndAges =
      this.props.sliderData.womanName +
      " - " +
      this.props.sliderData.womanAge +
      " ans & " +
      this.props.sliderData.manName +
      " - " +
      this.props.sliderData.manAge +
      " ans ";
    return (
      <div className={"row col-12 sliderContainer " + (ResponsiveService.isMobile() ? " sliderContainerMobile " : " ") + " d-flex flex-column align-items-center"}>
        <div className={"sliderContent" + (ResponsiveService.isMobile() ? " sliderMobile " : " ") + "d-flex col-12 flex-column align-items-center"}>
          <div className={"d-flex flex-row"}>
            <CustomAvatar
                noLink
                noMargin
                size={"100"}
                borderColor={'#f9fa00'}
                photoUrl={this.props.sliderData.womanPhotoUrl}/>
          </div>
          <EliteParagraph
            marginTop={"15px"}
            fontSize={"20px"}
            textColor={"#6c95e7"}
            fontFamily={"kurale"}
            text={this.props.sliderData.introText} />
          <EliteParagraph
              marginBottom={"15px"}
            textColor={"#bcbcbc"}
            fontSize={"14px"}
            text={namesAndAges}/>
          <div className={"citationMark"}>
            <ReactSVG src={citationIlus}/>
          </div>
        </div>
      </div>
    );
  }
}
export default SliderContainer;
