import React, {Component} from "react";
import CustomWizard from "../Uikit/Wizard/Wizard";
import "./CustomSignUpStyles.css";
import "../../Containers/Login/Mobile/LoginMobileStyle.css";
import fullLogoColor from "../../assets/images/logo-full-colo.png";
import BigTitle from "../../Components/Uikit/Typography/BigTitle";
import {ReactSVG} from "react-svg";
import closeButton from "../../assets/images/btn-close.svg"
import ResponsiveService from "../../Services/ResponsiveService";
import {withRouter} from "react-router";

class CustomSignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: ResponsiveService.isMobile(),
        };
        window.addEventListener("resize", this.handleResize.bind(this));
    }

    handleResize() {
        if (this.state.isMobile !== ResponsiveService.isMobile()) {
            this.setState({isMobile: ResponsiveService.isMobile()});
        }
    }

    render() {
        return (
            <>
                <div
                    className={`SignUpContainer w-100vh container-fluid d-flex flex-column align-items-center ${
                        this.state.isMobile && "SignUpContainerMobile"
                    }`}>
                    <div className={'scrollContent w-100'}>

                        <div
                            className={"SignUpImageContainer"}
                            onClick={() => this.props.history.push("/")}>
                            <img src={fullLogoColor} alt={"logo full color"}/>
                        </div>
                        <div className={"SignUpTitlesContainer"}>
                            <BigTitle
                                fontSize={"34px"}
                                titleOpacity={0.1}
                                text={"Inscription"}/>
                            <div className={"SignUpOverTitle mb-2"}>
                                <BigTitle
                                    textAlign={'center'}
                                    fontFamily={"Roboto"}
                                    fontSize={"24px"}
                                    fontWeight={"bold"}
                                    text={"Inscription"}/>
                            </div>
                        </div>
                        <CustomWizard
                            profile={this.props.profile}
                            isMobile={this.state.isMobile}
                            journey={"signUpDesktop"}/>
                    </div>
                    <div
                        style={{marginTop:'50px'}}
                        className={`${
                            this.state.isMobile
                                ? "d-none"
                                : "closeButtonLevitate d-flex flex-column align-items-center"
                        }`}>
                        <ReactSVG
                            style={{ cursor: "pointer" }}
                            src={closeButton}
                            onClick={() =>
                                this.props.closeModal.current.handleCloseModal()
                            }/>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(CustomSignUp);
