import React from "react";
import EliteParagraph from "../Uikit/Typography/Paragraph";
import { EliteRouters } from "../../Navigation/Routers";
import { ReactSVG } from "react-svg";
import ribbon from "../../assets/images/ribbon.svg";
import Select from "../Uikit/SelectCurrency/Select";
import { useHistory, generatePath } from "react-router-dom";
import CustomButton from "../Uikit/Button/CustomButton";
import { Checkout } from "../../Containers/Checkout/Checkout";

export default function CardOffer(props) {
  const currencies = {
    EUR: "Euro",
    CAD: "Dollar canadien",
    USD: "Dollar",
    //DZD: "Dinar algérien",
    //MAD: "Dirham marocain",
  };
  const history = useHistory();

  const options = Object.keys(currencies).map((value) => ({
    value,
    label: currencies[value],
  }));

  const handleRedirection = ({ name, id }, boost) => {
    <Checkout subId={name} offer={boost} />;
    history.push({
      pathname: generatePath(EliteRouters.checkout.path, {selectedSub:name}),
      search: boost ? "boost=true" : null,
    
    });
  };

  const CardAction = () => {
    if (props.hideAction) {
      return <></>;
    } else {
      return (
        <div className={"position-absolute"} style={{ bottom: "30px" }}>
          <CustomButton
            width={"246px"}
            label={"Je choisis cette offre"}
            onClick={() =>
              handleRedirection(
                {
                  name: props.sliderData.name.toLowerCase(),
                  id: props.sliderData.id,
                },
                props.type === "boost"
              )
            }
          />
        </div>
      );
    }
  };

  return (
    <>
      <div className={"cardOfferWrapper position-relative"}>
        <div className="d-flex subTitle">
          <EliteParagraph
            text={props.sliderData.subTitle}
            uppercase
            fontSize={"16px"}
            fontWeight={500}
            textColor={"#f9fa00"}
            textAlign={"center"}
          />
        </div>
        {props.sliderData.promo && (
          <div
            className={`exclusiveHeader position-absolute ${
              props.isMobile && "mobile"
            }`}
          >
            <EliteParagraph
              text={props.sliderData.promo}
              textColor={"#fff"}
              textAlign={"center"}
              uppercase
              fontSize={"26px"}
              fontWeight={"bold"}
              margin={"3px 0"}
            />
          </div>
        )}
        <div
          key={props.sliderData?.name?.split("-")?.join()}
          className={`cardOffer d-flex flex-column align-items-center ${
            props.type === "boost" && "boost"
          }`}
          style={{
            height: props.customOffer
              ? (props.sliderData.promo || props.idx === 1) && !props.isMobile
                ? props.cardHeight ?? "380px"
                : props.cardHeight ?? "324px"
              : props.type === "boost" && !props.isMobile
              ? props.cardHeight ?? "440px"
              : props.cardHeight ?? "374px",
            marginTop:
              (props.sliderData.promo || props.idx === 1) && !props.isMobile
                ? "20px"
                : "50px",
          }}
        >
          <div className={`${props.sliderData.promo ? "d-none" : "d-block"}`}>
            <div
              className={`position-absolute ribbonWrapper ${
                props.idx === 1 && "promo"
              }`}
            >
              <ReactSVG src={ribbon} />
            </div>
            <div
              className={`position-absolute ribbonWrapperText ${
                props.idx === 1 && "promo"
              }`}
            >
              <EliteParagraph
                text={props.sliderData.duration + " mois"}
                textColor={"#fff"}
                fontSize={"20px"}
                fontWeight={"bold"}
                margin={0}
                textAlign={"center"}
              />
            </div>
          </div>
          <div className="d-flex flex-column mb-sm-8">
            {props.sliderData.title && (
              <EliteParagraph
                text={props.sliderData.title}
                textColor={"#6c95e7"}
                fontSize={props.type === "boost" ? "14px" : "20px"}
                fontWeight={"bold"}
                margin={"0 8px 15px"}
                textAlign={"center"}
                width={"90%"}
              />
            )}

            <EliteParagraph
              text={"Elite "}
              fontWeight={"bold"}
              uppercase
              fontSize={"14px"}
              textColor={"#4e4e4e"}
              margin={
                props.sliderData.promo || props.idx === 1 || props.isMobile
                  ? props.sliderData.title
                    ? "5px 0 22px 0"
                    : "75px 0 22px 0"
                  : "50px 0 22px 0"
              }
            >
              <span className={"nameBigger"}>{props.sliderData.name}</span>
            </EliteParagraph>

            <div className="d-flex justify-content-center">
              <EliteParagraph
                text={
                  props.type === "boost"
                    ? props.sliderData.pricingByWeek.toLocaleString()
                    : props.sliderData.pricingByWeek.toLocaleString()
                }
                background={"linear-gradient(114deg, #34d10e, #6c95e7)"}
                fontSize={"46px"}
                fontWeight={"bold"}
                bgClip={"text"}
                textFill={"transparent"}
                margin={5}
              >
                <span className={"Line"}>{props.sliderData.currency}</span>
              </EliteParagraph>
              {!props.disableCurrencyChange && (
                <Select
                  seletableItems={options}
                  placeholder={""}
                  menuPlacement={"auto"}
                  onChange={props.updateCurrency}
                  value={props.devise}
                />
              )}
            </div>

            <EliteParagraph
              text={props.sliderData.frequency}
              uppercase
              textColor={"#bcbcbc"}
              fontSize={"12px"}
              fontWeight={"bold"}
            />
            {props.sliderData.basicOffer && (
              <EliteParagraph
                text={"Au lieu de "}
                fontFamily={"robotoBold"}
                fontSize={"12px"}
                textColor={"#bcbcbc"}
              >
                <span className={"offerPrice cross"}>
                  {props.sliderData.basicOffer}
                  {props.sliderData.currency}/
                  {props.sliderData.daily ? "jour" : "mois"}{" "}
                </span>
              </EliteParagraph>
            )}

            {props.type === "boost" && (
              <div>
                <EliteParagraph
                  text={"Soit "}
                  fontFamily={"robotoBold"}
                  fontSize={"12px"}
                  textColor={"#bcbcbc"}
                >
                  <span className={"offerPrice"}>
                    {props.sliderData.pricingByDay.toLocaleString()}
                    {props.sliderData.currency}/
                    {props.sliderData.reduceUnitDaily ? "jour" : "mois"}{" "}
                  </span>
                </EliteParagraph>
                <br />
                <EliteParagraph
                  text={
                    "✓ Augmentez votre visibilité auprès des autres profils "
                  }
                  fontFamily={"robotoBold"}
                  fontSize={"11px"}
                  textColor={"#bcbcbc"}
                />

                <EliteParagraph
                  text={"✓ Augmentez les nouveaux matching (10 matching/jour)"}
                  fontFamily={"robotoBold"}
                  fontSize={"11px"}
                  textColor={"#bcbcbc"}
                />
              </div>
            )}

            {props.sliderData.pricingByMonth && (
              <EliteParagraph
                text={"Soit "}
                fontFamily={"robotoBold"}
                fontSize={"12px"}
                textColor={"#bcbcbc"}
              >
                <span className={"offerPrice"}>
                  {props.sliderData.pricingByMonth}
                  {props.sliderData.currency}/
                  {props.sliderData.daily ? "jour" : "mois"}{" "}
                </span>
              </EliteParagraph>
            )}
          </div>
          <CardAction />
          {/* <EliteParagraph
              text={"Maintenance/Mise à jour en cours sur les moyens de paiements, revenez dans quelques jours afin de pouvoir choisir cette offre."}
              fontFamily={"robotoBold"}
              fontSize={"12px"}
              fontStyle={"italic"}
              margin={"0 8px 0 8px"}
              textColor={"#4e4e4e"} />*/}
        </div>
      </div>
    </>
  );
}
