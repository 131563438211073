import React, {useEffect, useState} from "react";
import {ReactSVG} from "react-svg";
import Footer from "../../Components/Footer/Footer";
import ConnectedHeader from "../../Components/Header/ConnectedHeader/ConnectedHeader";
import OfferCard from "../../Components/OfferCard/OfferCard";
import backArrow from "../../assets/images/icon-arrow-back.svg";
import {EliteRouters} from "../../Navigation/Routers";
import ParametersEdition from "../../Components/ParametersEdition/ParametersEdition";
import {useCurrentUser} from "../../Hooks/AuthenticatedUser";
import UserService from "../../Services/ApiServices/UserService";
import Alert from 'react-bootstrap/Alert'
import {getCurrentSubscription, getMatchingSubscription} from "../../Services/ApiServices/api.client";

export function Parameters(props) {
  const {user, setUser} = useCurrentUser()
  const [displayCheckoutMsg, setDisplayCheckoutMsg] = useState(0);
  const [currentSubscription, setCurrentSubscription] = useState(extractSubscriptions());
  const [isBoost, setIsBoost] = useState(0);

  function extractSubscriptions() {
    return getMatchingSubscription() ? [getCurrentSubscription(), getMatchingSubscription()] : [getCurrentSubscription()];
  }

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(props.location.search);

    if (query.get("success") === 'true') {
      UserService.createUserSubscription(query.get("sub_id"), query.get("session_id"), query.get("is_boost"))
        .then(() => {
          setCurrentSubscription(extractSubscriptions());
          setDisplayCheckoutMsg(1);
          if (query.get("is_boost") !== "0") {
            setIsBoost(1)
          } else {
            setIsBoost(0)
          }
        })
    }

    if (query.get("success") === 'false') {
      setCurrentSubscription(null);
      setDisplayCheckoutMsg(2);
    }
  }, [props.location.search]);

  return (
    <>
      <ConnectedHeader/>
      <div className={"container mesLikesContainer"}>
        <div className="row">
          <div className="col-lg-1 col-md-12"/>
          <div className="col-lg-4 col-md-12">
            <div className={"back py-5"}>
              <div
                onClick={() =>
                  props.history.push(EliteRouters.profile.path)
                }>
                <ReactSVG src={backArrow}/> Paramètres
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12"/>
          <div className="col-lg-1 col-md-12"/>

          {displayCheckoutMsg !== 0 &&
          <>
            <div className="col-lg-1 col-md-12"/>
            <Alert className="col-lg-10 col-md-10" variant={displayCheckoutMsg === 1 ? "success" : "danger"}
                   onClose={() => setDisplayCheckoutMsg(0)}>
              <Alert.Heading
                className="text-center">{displayCheckoutMsg === 1 ? "FÉLICITATION votre souscription est complétée !" : "Echec Souscription"}</Alert.Heading>
              <p className="text-center">{displayCheckoutMsg === 1 ?
                (
                  isBoost === 1 ?
                    'Votre paiement a bien été validé et son renouvellement est désormais actif. Vous pouvez dès à présent profiter de votre booster afin de mettre encore plus de chance de votre côté afin de trouver LA bonne personne qu\'il vous faut !'
                    :
                    'Votre paiement a bien été validé et votre souscription renouvelable est désormais active .Vous pouvez dès à présent profiter de toutes les fonctionnalités d\'Elite Berbère !'
                ) :
                'Paiement annulé / rejeté, vous êtes invité(e) à recommencer le processus de souscription.'}</p>
            </Alert>
            <div className="col-lg-1 col-md-12"/>
          </>
          }
          <div className="col-lg-1 col-md-12"/>
          <div className="col-lg-4 col-md-12">
            {currentSubscription && currentSubscription.length > 0 &&
            currentSubscription.map((e, i) => (
              <div className="col-lg-12 col-md-12 mb-4" key={i}>
                <OfferCard user={user} currentSubscription={e}/>
              </div>
            ))
            }
          </div>
          <div className="col-lg-6 col-md-12">
            <ParametersEdition user={user} onChange={setUser}/>
            <div className="col-lg-1 col-md-12"/>
          </div>
        </div>
      </div>
      <Footer isLogged/>
    </>
  );
}

export default Parameters;
