import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"; // eslint-disable-line
import "./TabsStyles.css";

export class CustomTabs extends Component {
  constructor(props) {
    super(props);
    this.buildTabsMenu = this.buildTabsMenu.bind(this);
    this.buildTabsContent = this.buildTabsContent.bind(this);
  }

  buildTabsMenu() {
    return (
      <>
        <div className={`container ${!this.props.isMobile && "g-0"}`}>
          <div className={"row"}>
            <div className={"col-md-12"}>
              {this.props.tabs.map((tab, i) => (
                <Tab
                  className={`react-tabs__tab ${
                    this.props.isMobile && "isMobile"
                  }`}
                  key={i}>
                  {tab.menuItem}
                </Tab>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
  buildTabsContent() {
    return this.props.tabs.map((tab, i) => (
      <TabPanel key={i}>{tab.menuContent}</TabPanel>
    ));
  }

  render() {
    return (
      <Tabs>
        <TabList>{this.buildTabsMenu()}</TabList>
        {this.buildTabsContent()}
      </Tabs>
    );
  }
}

export default CustomTabs;
