import React from "react";
import "./FooterMenuStyles.css";
import {ReactSVG} from "react-svg";
import iconHomeGreen from "../../assets/images/icon-home-green.svg";
import iconHomeGrey from "../../assets/images/icon-home-grey.svg";
import iconSearchGreen from "../../assets/images/icon-search-green.svg";
import iconSearchGrey from "../../assets/images/icon-search-grey.svg";
import iconUserActive from "../../assets/images/icon-user-profile-active.svg";
import iconUserInactive from "../../assets/images/icon-user-profile-inactive.svg";
import iconChatWhite from "../../assets/images/icon-chat-menu-grey.svg";
import iconChatYellow from "../../assets/images/icon-chat-menu-green.svg";
import iconMatchGreen from "../../assets/images/icon-match-footer-green.svg";
import iconMatchGrey from "../../assets/images/icon-match-footer-grey.svg";

import {NavLink, useHistory} from "react-router-dom";
import {EliteRouters} from "../../Navigation/Routers";
import EliteParagraph from "../Uikit/Typography/Paragraph";
import {useNotifications} from "../../Hooks/Notifications";

export default function FooterMenu() {
  const {location} = useHistory();
  const {notifications} = useNotifications();
  const checkURLpath = (pathToSearch) => {
    return location.pathname.indexOf(pathToSearch) > -1;
  };
  return (
    <div className={'footerGradient fixed-bottom'}>
      <div className={"footerMenu w-100 d-flex fixed-bottom justify-content-around align-items-center"}>
        <NavLink to={EliteRouters.home.path} activeClassName={"activeLink"}>
          <div className="menuItem">

            <ReactSVG
              src={
                location.pathname === EliteRouters.home.path ||
                location.pathname === EliteRouters.mesvisites.path ||
                location.pathname === EliteRouters.meslikes.path
                  ? iconHomeGreen
                  : iconHomeGrey
              }
            />
          </div>
        </NavLink>
        <NavLink to={EliteRouters.search.path} activeClassName={"activeLink"}>
          <div className="menuItem">
            <ReactSVG
              src={
                location.pathname === EliteRouters.search.path
                  ? iconSearchGreen
                  : iconSearchGrey
              }
            />
          </div>
        </NavLink>
        <NavLink
          exact
          to={EliteRouters.rooms.path}
          isActive={() => checkURLpath("messages")}
          activeClassName={"activeLink"}>
          <div className="menuItem">
            <ReactSVG
              src={
                location.pathname === EliteRouters.rooms.path ||
                checkURLpath("messages")
                  ? iconChatYellow
                  : iconChatWhite
              }
            />
            {notifications.unreadMessages > 0 &&
            <div className="row">
              <div className="messagesUnreadMobile">
                <EliteParagraph
                  text={notifications.unreadMessages}
                  fontSize={"12px"}
                  fontWeight={"bold"}
                  textColor={"#fff"}
                  margin={0}
                  textDecoration={"none"}
                />
              </div>
            </div>
            }
          </div>
        </NavLink>
        <NavLink
          exact
          to={EliteRouters.matchmaking.path}
          activeClassName={"activeLink"}>
          <div className="menuItem">

            <ReactSVG
              src={
                location.pathname === EliteRouters.matchmaking.path
                  ? iconMatchGreen
                  : iconMatchGrey
              }
            />
          </div>
        </NavLink>
        <NavLink
          to={EliteRouters.profile.path}
          activeClassName={"activeLink"}>
          <div className="menuItem">
            <ReactSVG
              src={
                location.pathname === EliteRouters.profile.path ||
                location.pathname ===
                EliteRouters.profileEdition.path ||
                location.pathname === EliteRouters.parameters.path
                  ? iconUserActive
                  : iconUserInactive
              }
            />
          </div>
        </NavLink>
      </div>
    </div>
  );
}
