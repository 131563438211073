import React, { Component } from "react";

export class CircleProgressBar extends Component {
  render() {
    var c = Math.PI * (112 * 2);
    const calculate = ((100 - this.props.percentage) / 100) * c;

    return (
      <>
        <svg
          width="224px"
          height="185px"
          viewBox="0 0 224 185"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg">
          <title>Artboard</title>
          <defs>
            <linearGradient
              x1="50%"
              y1="0%"
              x2="50%"
              y2="115.75628%"
              id="linearGradient-1">
              <stop stopColor="#F9FA00" offset="0%"></stop>
              <stop stopColor="#34D10E" offset="100%"></stop>
            </linearGradient>
            <circle id="path-2" cx="112" cy="112" r="112"></circle>
            <mask
              id="mask-3"
              maskContentUnits="userSpaceOnUse"
              maskUnits="objectBoundingBox"
              x="0"
              y="0"
              width="224"
              height="224"
              fill="white">
              <use href="#path-2"></use>
            </mask>
          </defs>
          <g
            id="Artboard"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd">
            <circle
              id="Oval"
              stroke="#F5F5F5"
              strokeWidth="6"
              cx="112"
              cy="112"
              r="109"></circle>
            <use
              id="Oval"
              stroke="url(#linearGradient-1)"
              mask="url(#mask-3)"
              strokeWidth="12"
              strokeDasharray={"720"}
              strokeDashoffset={isNaN(calculate) ? 0 : calculate}
              transform="rotate(90 112 112)"
              href="#path-2"></use>
          </g>
        </svg>
      </>
    );
  }
}

export default CircleProgressBar;
