import React from "react";
import "./SelectStyles.css";

export default function Select(props) {
  return (
    <>
      <select
        className={"selectStyles"}
        name={props.name}
        value={ props.value }
        onChange={ (e) => props.onChange(e.target.value) }
        onBlur={ props.onBlur }
        style={{ display: "block" }}>
        <option value="" label={ props.placeholder }/>
        {props.seletableItems.map((item) => (
          <option
            key={ item.value }
            value={ item.value }
            label={ item.label || item.value }/>
        ))}
      </select>
    </>
  );
}
