import EliteParagraph from "../Uikit/Typography/Paragraph";
import { ReactSVG } from "react-svg";
import backArrow from "../../assets/images/icon-arrow-back.svg";
import closeButton from "../../assets/images/btn-close.svg";
import React from "react";
import CustomModal from "../Uikit/Modal/Modal";
import CustomButton from "../Uikit/Button/CustomButton";

export default class ConfirmBlockModal extends React.Component {
  constructor(props) {
    super(props);
    this.modal = React.createRef();
    this.state = { modelContent: "" };
  }

  open(onConfirm) {
    const modalContent = (
      <>
        <div className={this.props.isMobile ? "d-none" : "d-block p-4"}>
          <EliteParagraph
            text="Désirez-vous vraiment bloquer ce profil ?"
            fontSize={"20px"}
            textColor={"#6c95e7"}
            textAlign={"left"}
            fontWeight={500}
          />
            <div className="" style={{
                fontSize : "14px" }}>
                <p className={"mb-0"}>Si vous rencontrez un problème particulier,</p>
                <p>n'hésitez pas à nous contacter dans <a href={"/profile/parameters"}>votre espace.</a></p>
            </div>
        </div>
        <div
          className={
            !this.props.isMobile
              ? "d-none"
              : "d-block col-lg-4 col-md-12 mb-4 headerModalMobile"
          }>
          <div className={"back"} style={{ marginTop: "64px" }}>
            <div onClick={() => this.modal.current.handleCloseModal()}>
              <ReactSVG src={backArrow} style={{ fontSize: "14px" }}/> Désirez-vous vraiment bloquer ce profil ?
            </div>
          </div>
            <div className="mt-3 mx-5" style={{
                fontSize : "14px" }}>
                <p className={"mb-0"}>Si vous rencontrez un problème particulier,</p>
                <p>n'hésitez pas à nous contacter dans <a href={"/profile/parameters"}>votre espace.</a></p>
            </div>
        </div>
        <div
          style={{ height: this.props.isMobile ? 'calc(100vh - 250px)' : "120px" }}
          className={`d-flex flex-column w-100 px-5 position-relative ${
            this.props.isMobile ? "mobile" : ""
          }`}>
          <div
            className={`position-absolute ${this.props.isMobile ? "px-2" : ""}`}
            style={{
              width: this.props.isMobile ? "calc(100% - 100px)" : "360px",
              bottom: 0,
            }}>
            <div className="mb-3">
              <CustomButton label={"Bloquer"} onClick={onConfirm} />
            </div>

            <CustomButton
              secondary
              label={"Annuler"}
              onClick={() => this.modal.current.handleCloseModal()}
            />
          </div>
        </div>
        <div
          className={
            "closeButtonLevitate d-flex flex-column align-items-center"
          }>
          <ReactSVG
            style={{ cursor: "pointer" }}
            src={closeButton}
            onClick={() => this.modal.current.handleCloseModal()}
          />
        </div>
      </>
    );
    this.setState({ modalContent });
    this.modal.current.handleOpenModal();
  }

  render() {
    return (
      <CustomModal
        modalHeight={this.props.isMobile ? "100vh" : "300px"}
        modalContent={this.state.modalContent}
        modalClass={
          this.props.isMobile
            ? "defaultProfileModalMobile"
            : "defaultProfileModal"
        }
        ref={this.modal}
      />
    );
  }
}
