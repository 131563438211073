import React, { useState } from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import ResponsiveService from "../../Services/ResponsiveService";
import { Helmet, HelmetProvider } from "react-helmet-async";
import datingSpeed from "../../assets/images/dating-speed-1.jpg";
import datingSpeed2 from "../../assets/images/dating-speed-2.jpg";
import BigTitle from "../../Components/Uikit/Typography/BigTitle";
import EliteSmallTitle from "../../Components/Uikit/Typography/SmallTitle";
import EliteParagraph from "../../Components/Uikit/Typography/Paragraph";


export function SpeedDatPage() {
    const [isMobile, setIsMobile] = useState(
        ResponsiveService.isMobile()
    );
    
    const handleResize = (e) => {
        e.preventDefault();
        if (isMobile !== ResponsiveService.isMobile()) {
          setIsMobile({ isMobile: ResponsiveService.isMobile() });
        }
    };
    window.addEventListener("resize", handleResize.bind(this));

    return (
        <HelmetProvider>
        <>
            <Helmet>
                <title>Rencontre Speed Dating entre Célibataires – EliteBerbere.fr</title>
                <meta name="description" content="Participez à un speed dating près de chez vous avec 
                le site de rencontre Elite Berbère, faites des rencontres enrichissantes !"/>
                <meta name="keywords" content="Faire de belles rencontres, Amicales, Attractive, Rencontrer l amour, 
                Faire connaissance, Bonne personne, Grand amour, Célibat, Rencontre par affinité, Rencontrer facilement, 
                Séduction, Vie amoureuse, Nouer, Rejoignez, Draguer, Vie de célibataire, Points communs, Faire de nouvelles, 
                Rencontre amoureuse, Âme-soeur, Amoureuses, Dating, Trouver l amour, Célibataire, Faire des rencontres"/>
            </Helmet>
            <Header />
            <div className="container">
                <div className="row p-4">
                    <div style={{textAlign: "justify"}} className="col-12">
                        <BigTitle
                            marginBottom={"5vh"}
                            titleColor={"#4e4e4e"}
                            textAlign={"left"}
                            text={"Les rencontres speed dating de qualité avec Élite Berbère"}
                        />
                        <EliteParagraph
                            text={<>
                                Élite Berbère représente le meilleur choix pour rencontrer son âme sœur. Notre <a href={"https://eliteberbere.fr"}>
                                site de rencontres berbères</a> vous garantit le sérieux dans les prestations offertes.
                                En tant qu'abonné, vous aurez accès à des profils vérifiés par nos soins. Pour affiner votre choix entre
                                plusieurs personnes, Élite Berbère vous propose des rencontres speed dating.
                            </>}
                            textAlign={"justify"}
                            fontSize={"1.3em"}/>

                        <EliteSmallTitle
                            textAlign={"left"}
                            text={"En quoi consiste un speed dating ?"} />
                        <EliteParagraph
                            text={<>
                                Le speed dating existe depuis un certain nombre d'années. Il n'est pas réservé aux sites de rencontres puisqu'il
                                est utilisé dans le monde des affaires.
                            </>}
                            textAlign={"justify"}/>
                        <EliteParagraph
                            text={<>
                                Ces rencontres speed dating vous permettent d'avoir des entretiens avec plusieurs personnes.
                                Élite Berbère met à votre disposition cet outil supplémentaire pour augmenter vos chances de
                                faire une rencontre amoureuse. Sur le site, vous avez l'occasion de regarder les profils et d'en
                                choisir plusieurs pour effectuer un entretien avec chacune des personnes remarquées.
                            </>}
                            textAlign={"justify"}/>
                        <EliteParagraph
                            text={<>
                                Le speed dating donne l'occasion de faire de belles rencontres, rapidement. Ces dernières ne
                                durent que quelques minutes, entre 6 et 10 minutes. Elles permettent une première approche rapide.
                                Ce bref tour d'horizon ne vous engage à rien. Vous donnerez suite seulement si vous avez des
                                affinités avec les personnes.
                            </>}
                            textAlign={"justify"}/>
                        <EliteParagraph
                            text={<>
                                Le speed dating représente une bonne opportunité pour briser sa vie de célibataire. Ces rencontres
                                brèves forment une bonne répétition pour les futurs rendez-vous plus conséquents. Vous allez prendre
                                de l'assurance grâce à cette forme de répétition.
                            </>}
                            textAlign={"justify"}/>
                        <img className={"img-fluid"} style={{marginBottom: "16px"}} src={ datingSpeed } alt={ "speed dating 1" }/>

                        <EliteSmallTitle
                            textAlign={"left"}
                            text={"Pourquoi recourir au speed dating ?"} />
                        <EliteParagraph
                            text={<>
                                Le speed dating reflète une bonne approche pour nouer des relations,
                                qu'elles soient amoureuses ou amicales, sans perdre de temps.
                            </>}
                            textAlign={"justify"}/>
                        <EliteParagraph
                            text={<>
                                Après le speed dating, vous allez pouvoir composer une première sélection. Ensuite, il sera plus
                                facile de rencontrer de potentiels partenaires de vie. En quelques minutes, vous allez découvrir
                                si vous avez des points communs avec la personne face à vous.
                            </>}
                            textAlign={"justify"}/>
                        <EliteParagraph
                            text={<>
                                Élite Berbère pense que les rencontres speed dating permettent d'établir un premier contact entre célibataires.
                                Cela donne l'occasion de faire plus simplement connaissance. Le premier regard, la première impression sont
                                révélateurs pour beaucoup de personnes !
                            </>}
                            textAlign={"justify"}/>
                        <EliteParagraph
                            text={<>
                                Les abonnés de notre site de rencontres recherchent la bonne personne pour les accompagner dans leur vie.
                                Ils n'ont pas envie de perdre du temps en relations stériles ou manquant de profondeur. Le speed dating
                                favorise un gain de temps dans les étapes d'une relation amoureuse.
                            </>}
                            textAlign={"justify"}/>
                        <img className={"img-fluid"} style={{marginBottom: "16px"}} src={ datingSpeed2 } alt={ "speed dating 2" }/>

                        <EliteSmallTitle
                            textAlign={"left"}
                            text={"Que peut m'apporter une rencontre speed dating ?"} />
                        <EliteParagraph
                            text={<>
                                Le speed dating ne peut que vous apporter du mieux dans votre vie amoureuse !
                            </>}
                            textAlign={"justify"}/>
                        <EliteParagraph
                            text={<>
                                Vous pourrez passer un agréable moment. Pendant ces quelques minutes, vous discuterez tranquillement
                                en découvrant votre potentiel grand amour. Vous passerez un bon moment, en bonne compagnie.
                                Élite Berbère vous garantit un examen très rigoureux des profils des abonnés.
                            </>}
                            textAlign={"justify"}/>
                        <EliteParagraph
                            text={<>
                                Le speed dating représente une bonne alternative aux rendez-vous ratés, arrangés par une connaissance.
                                Pour une femme seule, cela évite les mauvaises rencontres ! Vous n'avez pas envie de vous faire draguer :
                                vous cherchez à nouer une relation solide avec une personne partageant les valeurs berbères.
                            </>}
                            textAlign={"justify"}/>
                        <EliteParagraph
                            text={<>
                                Élite Berbère vous accompagne dans votre quête de la personne prête à partager votre vie, votre culture.
                                Le speed dating est le premier pas vers la découverte de votre grand amour !
                            </>}
                            textAlign={"justify"}/>
                    </div>
                </div>
            </div>
            <Footer />
        </>
        </HelmetProvider>
    );
}
