import React, { Component } from "react";

import TitleOverTitle from "../Uikit/TitleOverTitle/TitleOverTitle";
import "./SearchContainerStyles.css";
import iconSearch from "../../assets/images/icon-search-small-black.svg";
import SearchComponentsContainer from "./SearchComponentsContainer";
import EliteParagraph from "../Uikit/Typography/Paragraph";
import CustomButton from "../Uikit/Button/CustomButton";
import iconFilter from "../../assets/images/icon-filter.svg";
import iconDrop from "../../assets/images/icon-dropup.svg";
import { Actions } from "./Actions";
import Spinner from "react-bootstrap/esm/Spinner";
import { isEmpty } from "lodash-es";
import GridMosaic from "../Uikit/PhotoMosaic/PhotoMosaic_new";
import {ReactSVG} from "react-svg";
import checkMark from "../../assets/images/icon-check-circle.svg";
import Alert from "react-bootstrap/Alert";
import { connect } from "react-redux";
import { affectSearchFields } from "../../Redux/searchFilters/action"

export class SearchContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtersIsOpen: true,
      profiles: [],
      selectedOptions: props.selectedOptions,
      loading: true,
      pagescount: 0,
    };
    this.handleFiltersContainer.bind(this);
    this.handleFiltersOptionsChange.bind(this);
    this.fetchProfiles.bind(this);
  }

  handleFiltersContainer() {
    this.setState({
      filtersIsOpen: !this.state.filtersIsOpen,
    });
  }

  handleFiltersOptionsChange(key, value) {
    let obj = {};
    obj[key] = value;
    this.setState({
      selectedOptions: { ...this.state.selectedOptions, ...obj },
    });
  }

  fetchProfiles = () => {

    this.setState(
      {
        loading: true,
      },
      () => {
        Actions.fetchProfiles(this.state.selectedOptions).then((res) => {
          this.setState({
            profiles: res.list,
            pagesCount: res.pages,
            filtersIsOpen: false,
            loading: false,
          });
        });
      },
    );
  };

  componentDidMount() {
    this.fetchProfiles();
  }

  render() {
    return (
      <>
        <div className="container min-vh-100 ">
          <div className="row">
            <div className="col-2"></div>
            <div className="col-8">
              <div className="row d-flex align-items-center">
              <div className={"col-12 "}>
              <TitleOverTitle
                icon={iconSearch}
                title={"Recherche"}></TitleOverTitle>
            </div>
              <div className={"col-8 d-flex align-items-center"} >
                {!!this.state.selectedOptions.cities.length && (
                  <>
                    <div className={"col-4 d-flex"}>
                    <EliteParagraph
                      textAlign={"left"}
                      text={"Résultats à"}
                      margin={"0 0 0 8px"}
                      fontSize={"16px"}></EliteParagraph>
                    {!!this.state.selectedOptions.distanceMax && (
                      <EliteParagraph
                        textAlign={"left"}
                        text={this.state.selectedOptions.distanceMax + "km de"}
                        margin={"0 0 0 8px"}
                        fontSize={"16px"}></EliteParagraph>
                    )}
                    <EliteParagraph
                      textAlign={"left"}
                      text={":"}
                      margin={"0 0 0 8px"}
                      fontSize={"16px"}></EliteParagraph>
                    </div>
                    <EliteParagraph
                      textAlign={"left"}
                      margin={"0 0 0 8px"}
                      fontSize={"24px"}
                      textColor={"#34d10e"}>
                      <span
                        title={this.state.selectedOptions.cities
                          .map((city) => {
                            return city.label + "\n";
                          })
                          .join("")}>
                        {this.state.selectedOptions.cities[0].label}
                        {this.state.selectedOptions.cities.length > 1 && (
                          <sup>
                            +{this.state.selectedOptions.cities.length - 1}
                          </sup>
                        )}
                      </span>
                    </EliteParagraph>
                  </>
                )}
              </div>
                <div className={`col-4 d-flex justify-content-end`} >
                  <div className={"filterButton"}>
                    <CustomButton
                        textTransform={"capitalize"}
                        textColor={"#4e4e4e"}
                        fontWeight={"normal"}
                        isOpen={this.state.filtersIsOpen}
                        onClick={() => this.handleFiltersContainer()}
                        tertiary
                        label={"Filtres"}
                        icon={iconFilter}
                        endIcon={iconDrop} />
                    <div className="searchCheckMarkDesk">
                      <ReactSVG className={`text-md- ${ !this.state.selectedOptions.withOption && "d-none"}` } src={checkMark}/>
                    </div>
                  </div>
                </div>


                <div className="searchFlex container">
              <div className="row">
                <div className={this.state.filtersIsOpen ? "col-8" : "col-12"}>

                    {this.state.profiles.length >= 1 ||
                    this.state.selectedOptions.offset > 0 ? (
                      <GridMosaic
                        hasInfiniteScroll
                        padding={'8px'}
                        rows={this.state.filtersIsOpen ? 3 : 4}
                        photoType={"miniature"}
                        photoSize={"small"}
                        data={this.state.profiles}
                        loading={this.state.loading}
                        pagesCount={this.state.pagesCount}
                        offset={this.state.selectedOptions.offset}
                        next={() => {
                          let nextOffset =
                            this.state.selectedOptions.offset + 1;
                          console.log('NEXT: '+nextOffset)
                          console.log('STATE: '+this.state.pagesCount)
                          if (nextOffset < this.state.pagesCount) {
                            this.setState({
                              selectedOptions: {
                                ...this.state.selectedOptions,
                                ...{ offset: nextOffset },
                              },
                            });
                            this.fetchProfiles();
                          }
                        }}
                        prev={() => {
                          let prevOffset =
                            this.state.selectedOptions.offset - 1;
                          console.log('PREV: '+prevOffset)
                          console.log('STATE: '+this.state.pagesCount)
                          if (prevOffset >= 0) {
                            this.setState({
                              selectedOptions: {
                                ...this.state.selectedOptions,
                                ...{ offset: prevOffset },
                              },
                            });
                            this.fetchProfiles();
                          }
                        }}
                      />
                    ) : isEmpty(this.state.profiles) && !this.state.loading ? (
                      <div className="mosaic">
                        <div className="center">
                            <Alert className="col-lg-12 col-md-12 mt-2" variant="primary">
                              <p className={"m-auto"}>
                                Aucun célibataire connecté ne correspond à vos critères pour le moment...
                              </p>
                            </Alert>
                        </div>
                      </div>
                    ) : (
                      <div className="loading-searching">
                        <Spinner animation="border" />
                      </div>
                    )}

                </div>
                <div
                  className={this.state.filtersIsOpen ? "col-4 p-0" : "d-none"}>
                  <div className="searchComponents">
                    <SearchComponentsContainer
                      handleSearchFilter={this.handleFiltersContainer}
                      fetchProfiles={() => {
                        this.setState({
                          selectedOptions: {
                            ...this.state.selectedOptions,
                            ...{ offset: 0 },
                            ...{withOption: true},
                          },
                        });
                        this.props.affectSearchFields(this.state.selectedOptions)
                        this.fetchProfiles();
                      }}
                      selectedOptions={this.state.selectedOptions}
                      handleFiltersOptionsChange={(key, value) =>
                        this.handleFiltersOptionsChange(key, value)
                      }
                      filterIsOpen={this.state.filtersIsOpen}
                    />
                  </div>
                </div>
              </div>
            </div>
              </div>
            </div>
            <div className="col-2"></div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedOptions: state.filterReducer.searchFields
  }
}


export default connect(mapStateToProps, { affectSearchFields })(SearchContainer);
