import React, { Component } from "react";
import TitleOverTitle from "../../Uikit/TitleOverTitle/TitleOverTitle";
import iconSearch from "../../../assets/images/icon-search-small-black.svg";
import iconFilter from "../../../assets/images/icon-filter.svg";
import checkMark from "../../../assets/images/icon-check-circle.svg";
import EliteParagraph from "../../Uikit/Typography/Paragraph";
import { ReactSVG } from "react-svg";
import "./SearchContainerMobileStyles.css";
import {Actions} from "../Actions"
import backArrow from "../../../assets/images/icon-arrow-back.svg";
import SearchComponentsContainer from "../SearchComponentsContainer";
import GridMosaic from "../../Uikit/PhotoMosaic/PhotoMosaic_new";
import {isEmpty} from "lodash-es";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import { connect } from "react-redux";
import { affectSearchFields } from "../../../Redux/searchFilters/action"

export class SearchContainerMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayFilteredCity: false,
      filtersIsOpen: false,
      profiles: [],
      selectedOptions: props.selectedOptions,
      loading: true,
      pagescount: 0,
    };
    this.handleFiltersContainer.bind(this);
    this.handleFiltersOptionsChange.bind(this);
    this.fetchProfiles.bind(this);
    console.log(props.selectedOptions);
  }

  handleFiltersContainer() {
    this.setState({
      filtersIsOpen: !this.state.filtersIsOpen,
    });
  }

  handleFiltersOptionsChange(key, value) {
    let obj = {}
    obj[key] = value
    this.setState({"selectedOptions" : {...this.state.selectedOptions, ...obj}})
  }

  fetchProfiles = () => {
    this.setState({
      loading: true
    }, () => {
      Actions.fetchProfiles(this.state.selectedOptions)
        .then(res => {
          this.setState({
            profiles: res.list,
            pagesCount: res.pages,
            filtersIsOpen: false,
            loading: false
          })
        })
    })
  }

  componentDidMount() {
    this.fetchProfiles()
  }

  render() {
    return (
        <>
          {!this.state.filtersIsOpen &&
              <div className={"pt-1 row"}>
                <div className={"col-12 align-items-center justify-content-between d-flex"}>
                  <div className={"col-10 d-flex justify-content-start"}>
                    <TitleOverTitle
                        backTitleSize={"36px"}
                        frontTitleSize={"24px"}
                        overTitleMarginTop={"-40px"}
                        icon={iconSearch}
                        title={"Recherche"} />
                  </div>
                </div>
                <div className="col-12 d-flex align-items-center justify-content-between">
                  <div className={"d-flex align-items-start flex-column"}>

                    <div className={"col-12 d-flex"}>
                      <EliteParagraph
                        textAlign={"left"}
                        text={"Résultats à"}
                        margin={"0 0 0 8px"}
                        fontSize={"14px"}></EliteParagraph>
                      {!!this.state.selectedOptions.distanceMax && (
                        <EliteParagraph
                          textAlign={"left"}
                          text={this.state.selectedOptions.distanceMax + "km de"}
                          margin={"0 0 0 8px"}
                          fontSize={"14px"}></EliteParagraph>
                      )}
                      <EliteParagraph
                        textAlign={"left"}
                        text={":"}
                        margin={"0 0 0 8px"}
                        fontSize={"14px"}></EliteParagraph>
                    </div>
                    <EliteParagraph
                        textAlign={"left"}
                        margin={"0 0 0 8px"}
                        fontSize={"20px"}
                        textColor={"#34d10e"}
                    >
                      <span
                          onClick={() => this.setState({displayFilteredCity: !this.state.displayFilteredCity})}
                          title={
                            this.state.selectedOptions.cities.map((city) => {
                              return city.label + "\n"
                            }).join('')}>
                        {this.state.selectedOptions.cities[0]?.label}
                        {(this.state.selectedOptions.cities.length > 1) &&
                        <sup>+{(this.state.selectedOptions.cities.length - 1)}</sup>
                        }
                      </span>
                    </EliteParagraph>
                  </div>
                  <div
                      onClick={() => this.setState({'filtersIsOpen': !this.state.filtersIsOpen})}
                      className="d-flex col-2  mt-0 justify-content-end me-2 filterButtonMobile">
                    <ReactSVG src={iconFilter}/>
                    <div className="searchCheckMark">
                      <ReactSVG className={`text-md- ${ !this.state.selectedOptions.withOption && "d-none"}` } src={checkMark}/>
                    </div>
                  </div>
                </div>
                <div className={`col-12 card d-flex align-items-center justify-content-start my-2 ${!this.state.displayFilteredCity ? 'd-none': ''}`}>
                  <div className="row p-2 w-100">
                    {this.state.selectedOptions.cities
                        .map((city, idx) =>
                            <div className="col-sm-4" key={idx}>- {city.label}</div>
                        )
                    }
                    <div className="clearfix" />
                  </div>
                </div>
                <div className="col-12">
                  <div className="searchMosaic min-vh-100">

                    {this.state.profiles.length >= 1 ||
                    this.state.selectedOptions.offset > 0 ? (
                        <GridMosaic
                            hasInfiniteScroll
                            padding={'8px'}
                            rows={2}
                            photoType={"miniature"}
                            photoSize={"small"}
                            data={this.state.profiles}
                            loading={this.state.loading}
                            pagesCount={this.state.pagesCount}
                            offset={this.state.selectedOptions.offset}
                            next={() => {
                              let nextOffset =
                                  this.state.selectedOptions.offset + 1;
                              if (nextOffset < this.state.pagesCount) {
                                this.setState({
                                  selectedOptions: {
                                    ...this.state.selectedOptions,
                                    ...{ offset: nextOffset },
                                  },
                                });
                                this.fetchProfiles();
                              }
                            }}
                            prev={() => {
                              let prevOffset =
                                this.state.selectedOptions.offset - 1;
                              if (prevOffset >= 0) {
                                this.setState({
                                  selectedOptions: {
                                    ...this.state.selectedOptions,
                                    ...{ offset: prevOffset },
                                  },
                                });
                                this.fetchProfiles();
                              }
                            }}
                        />
                    ) : isEmpty(this.state.profiles) && !this.state.loading ? (
                        <div className="mosaic">
                          <div className="center">
                            <Alert className="col-lg-12 col-md-12 mt-2" variant="primary">
                              <p className={"m-auto"}>
                                Aucun célibataire connecté ne correspond à vos critères pour le moment...
                              </p>
                            </Alert>
                          </div>
                        </div>
                    ) : (
                        <div className="loading-searching">
                          <Spinner animation="border" />
                        </div>
                    )}

                  </div>
                </div>
              </div>
          }
          {this.state.filtersIsOpen &&
              <div className="container min-vh-100 mesLikesMobile">
                <div className="col-6">
                  <div className={"back"}>
                    <div
                        onClick={() => this.setState({'filtersIsOpen': !this.state.filtersIsOpen})}>
                      <ReactSVG src={backArrow}></ReactSVG> Recherche
                    </div>
                  </div>
                </div>
                <div className="col-12 filtersMobile">
                  <SearchComponentsContainer
                      handleSearchFilter={() => this.handleFiltersContainer()}
                      fetchProfiles={() => {
                        this.setState({"selectedOptions": {...this.state.selectedOptions, ...{"offset": 0}, ...{withOption: true}}})
                        this.props.affectSearchFields(this.state.selectedOptions)
                        this.fetchProfiles()
                      }}
                      selectedOptions={this.state.selectedOptions}
                      handleFiltersOptionsChange={(key, value) => this.handleFiltersOptionsChange(key, value)}
                      filterIsOpen={this.state.filtersIsOpen}
                      isMobile={true}
                  />
                </div>
              </div>
          }
        </>
    );
  }
};

const mapStateToProps = state => {
  return {
    selectedOptions: state.filterReducer.searchFields
  }
}
export default connect(mapStateToProps, { affectSearchFields })(SearchContainerMobile);

