import React, { useState } from "react";
import VisitedProfileSlider from "../../VisitedProfile/VisitedProfileSlider";
import "./PhotoGalleryStyles.css";

export default function PhotoGallery(props) {
  const [selectedPhoto, setSelectedPhoto] = useState({
    url: props.data[0]?.url,
  });

  return (
    <>
      <div className={"photoGalleryContainer"}>
        <VisitedProfileSlider
          photoGallery
          sliderData={selectedPhoto}
          user={props.additionalData}
        />
        <div className={" d-flex photoGalleryThumbs mt-2"}>
          {props.data.length !== 0 ?
            props.data.map((photo, i) => (
              <div
                key={i}
                className="thumb"
                style={ { backgroundImage: `url(${ photo.url })`, backgroundSize: 'cover' } }
                onClick={ () => setSelectedPhoto({ url: props.data[i].url }) }
              />
            )) :
            <div className="no-valid-pic">
              Cet utilisateur n'a pas encore de photo valide.
            </div>
          }
        </div>
      </div>
    </>
  );
}
