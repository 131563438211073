import React, { useState } from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import ResponsiveService from "../../Services/ResponsiveService";
import { Helmet, HelmetProvider } from "react-helmet-async";
import datingEurope from "../../assets/images/dating-europe-1.jpg";
import datingEurope2 from "../../assets/images/dating-europe-2.jpg";
import BigTitle from "../../Components/Uikit/Typography/BigTitle";
import EliteSmallTitle from "../../Components/Uikit/Typography/SmallTitle";
import EliteParagraph from "../../Components/Uikit/Typography/Paragraph";

export function EuropePage() {
    const [isMobile, setIsMobile] = useState(
        ResponsiveService.isMobile()
    );
    //const history = useHistory();
    
    const handleResize = (e) => {
        e.preventDefault();
        if (isMobile !== ResponsiveService.isMobile()) {
          setIsMobile({ isMobile: ResponsiveService.isMobile() });
        }
    };
    window.addEventListener("resize", handleResize.bind(this));

    return (
        <HelmetProvider>
            <>
                <Helmet>
                    <title>Site de Rencontre Europe 100% Gratuit – Elite Berbère</title>
                    <meta name="description" content="Envie de faire des rencontres en Europe ? 
                    Le site de rencontre Elite Berbère est fait pour vous. Insrciption rapide et gratuite !"/>
                    <meta name="keywords" content="Site de rencontre sérieux, Dating, Affinités, Célibataire, 
                    Trouver l amour, Sérieuse, Faire des rencontres, Site de rencontre, Célibataires, Âme-soeur, 
                    Pour rencontrer, Relation durable, Comparatif, Recherche d une relation, Faire connaissance, 
                    Célibat, Exigeants, Compatibles, Rencontre facile, Construire une relation, Rencontre entre 
                    célibataires, Vie de couple, Recherches sérieuses, Rencontre une personne, Rencontre rapide"/>
                </Helmet>
                <Header />
                <div className="container">
                    <div className="row p-4">
                        <div style={{textAlign: "justify"}} className="col-12">
                            <BigTitle
                                marginBottom={"5vh"}
                                titleColor={"#4e4e4e"}
                                textAlign={"left"}
                                text={"Trouver l'âme sœur avec Elite berbère, le site de rencontre en Europe"}
                            />
                            <EliteParagraph
                                text={<>
                                    Vous avez des valeurs et considérez que vos traditions sont incontournables pour vivre au mieux
                                    votre quotidien ? Mais lorsqu'il faut trouver un partenaire qui partage vos idées, ce n'est pas
                                    toujours évident. <b>Trouver l'amour</b> est alors, en effet, encore plus difficile. Simplifiez-vous la
                                    recherche en faisant appel à un <b>site de rencontre en Europe sérieux</b> qui, tout comme vous, ne veut
                                    pas tergiverser.
                                </>}
                                textAlign={"justify"}
                                fontSize={"1.3em"}/>

                            <EliteSmallTitle
                                textAlign={"left"}
                                text={"Construire une relation avec la bonne personne"} />
                            <EliteParagraph
                                text={<>
                                    Vous mettez en avant vos traditions berbères et ne supportez pas que l'on vous juge sur cela ? Vous
                                    avez raison, nous sommes comme vous ! Pour <b>faire des rencontres</b> de qualité, vous avez le choix entre
                                    laisser la place au hasard ou au contraire vous adresser à un expert du <b>dating</b> qui ne veut pas que
                                    vous perdiez du temps inutilement.
                                </>}
                                textAlign={"justify"}/>
                            <EliteParagraph
                                text={<>
                                    Pour cela, <a href={"https://eliteberbere.fr"}>Élite Berbère</a> s'adresse uniquement aux personnes
                                    qui partagent une culture commune et des
                                    <b>affinités</b> profondes. Pour commencer une <b>relation durable</b>, c'est un atout non négligeable. Vous ne
                                    serez pas face à une personne qui ne vous comprend pas. Que vous soyez Rifain, Chleuh ou Chaoui,
                                    vous pourrez commencer une discussion de qualité et pourquoi pas poursuivre celle-ci avec une rencontre.
                                    Qui sait ?
                                </>}
                                textAlign={"justify"}/>
                            <EliteParagraph
                                text={<>
                                    Parce que l'affinité culturelle est capitale, vous gagnez ainsi beaucoup de temps et êtes sûr(e) de
                                    partager avec une personne qui a les mêmes centres d'intérêt que vous.
                                </>}
                                textAlign={"justify"}/>
                            <img className={"img-fluid"} style={{marginBottom: "16px"}} src={ datingEurope } alt={ "dating Europe 1" }/>

                            <EliteSmallTitle
                                textAlign={"left"}
                                text={"Nous sommes aux côtés des célibataires berbères en quête d'amour"} />
                            <EliteParagraph
                                text={<>
                                    C'est décidé, vous en avez assez de vivre seul et voulez <b>construire une relation</b> stable et qui va durer
                                    longtemps ? Très bien. Oubliez les rencontres faciles et utilisez notre outil <b>pour rencontrer l'âme sœur</b>.
                                </>}
                                textAlign={"justify"}/>
                            <EliteParagraph
                                text={<>
                                    Vous renseignez le type de personne que vous recherchez, votre lieu d'habitation et votre date de naissance,
                                    ajoutez une photo et un pseudo et voilà. C'est simple et rapide. Vous avez alors accès à notre <b>site de rencontre
                                    en Europe</b>. À vous d'en dire plus sur vous et de sélectionner les personnes qui vous attirent le plus et qui vous
                                    semblent totalement compatibles. <b>Faire connaissance</b> est l'étape suivante. C'est ensuite à vous de commencer une
                                    vie de couple <b>sérieuse</b>.
                                </>}
                                textAlign={"justify"}/>

                            <EliteSmallTitle
                                textAlign={"left"}
                                text={"Rencontrer une personne sans se tromper sur un site de rencontre en Europe"} />
                            <EliteParagraph
                                text={<>
                                    S'adresser à Élite Berbère, c'est avant tout vouloir faire des <b>recherches sérieuses</b> pour oublier le <b>célibat</b>.
                                    Nous vous proposons un système sur mesure pour réaliser une <b>rencontre facile</b> avec un partenaire qui répond à
                                    votre besoin de compatibilité culturelle.
                                </>}
                                textAlign={"justify"}/>
                            <EliteParagraph
                                text={<>
                                    Nous proposons des contenus de grande qualité, mais aussi des évènements exclusifs (dîners, conférences…).
                                    Exigeants, nous avons sélectionné des membres en <b>recherche d'une relation</b> durable. Amazighophone ou arabophone,
                                    vous avez la garantie de rencontrer le célibataire qui va faire battre votre cœur.
                                </>}
                                textAlign={"justify"}/>
                            <EliteParagraph
                                text={<>
                                    Tout comme vous, nous ne voulons pas de relations d'un jour. C'est pourquoi nous sommes sélectifs. Votre recherche
                                    sera totalement personnalisée. Nous mettons en avant le respect éthique, mais aussi culturel et moral. Notre
                                    plateforme peut vous permettre de trouver un partenaire en Europe partageant ce qui compte pour vous : coutume,
                                    tradition, langue, cuisine, histoire…
                                </>}
                                textAlign={"justify"}/>
                            <EliteParagraph
                                text={<>
                                    Que vous viviez en France, en Allemagne ou encore en Italie, vous avez la possibilité d'avoir des échanges de qualité.
                                    La personne que vous attendez fait certainement déjà partie des membres de notre site de rencontre en Europe.
                                    Alors pour réussir votre <b>rencontre entre célibataires</b>, confiez-nous vos attentes. Nous vous proposerons les partenaires
                                    les plus en adéquation avec vos valeurs. Simplement pour fonder une vie de famille durable et solide.
                                </>}
                                textAlign={"justify"}/>
                            <img className={"img-fluid"} style={{marginBottom: "16px"}} src={ datingEurope2 } alt={ "dating EUrope 2" }/>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        </HelmetProvider>
    );
}
