import "./BannerStylesMobile.css";
import bigLogo from "../../assets/images/logo-web-elite-berbere.png";
import bannerFooter from "../../assets/images/arrondi-mask@3x.png";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { useEffect } from "react";


function CustomBannerMobile(props) {

  useEffect(() => {
    if (window.location.search === '?register=1') {
      toast.success("Demande d'inscription bien reçu. Vérifiez votre boîte mail pour confirmer votre adresse mail.");
    }
    // eslint-disable-next-line
  }, [window.location.search]);

  return (
    <>
      <div className={ "container-fluid w-100 p-0" }>
        <div
          className={
            "bigHeroBannerMobile d-flex flex-column justify-content-end align-items-center"
          }>
          <div className={ "subscriptionContainer d-flex  flex-column align-items-center" }>
            <img className={ "bigLogo" } src={ bigLogo } alt={ "Logo Elite Berbère" }/>
            <div className={ 'connectIt' } onClick={ () => props.history.push('/login') }>connectez-vous</div>
            <button className={ 'button mobileButton mt-1' }
                    onClick={ () => props.history.push('/sign-up') }>Inscrivez-vous
            </button>
          </div>
          <div className={ "bigHeroBannerFooterMobile" }>
            <img src={ bannerFooter } alt={ "banner footer" }/>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(CustomBannerMobile);
