import { toast } from "react-toastify";

export function handleErrorByStatus(status, e = null) {
  let message = "";
  switch (status) {
    case 401:
      message = 'Email ou mot de passe incorrect'
      break;
    case 400:
      message = 'Entrez un email et un mot de passe'
      break;
    default:
      message = (e && e.response.data.error) ? e.response.data.error :'Une erreur est survenue'
      break;
  }
  toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

export function handleError(e) {
  if (e.response) {
    return handleErrorByStatus(e.response.status, e)
  }
  console.error(e);
}
