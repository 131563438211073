import React, { useEffect, useState } from "react";
import Carousel from "../Uikit/Carousel/Carousel";
import "./MatchMakingDuJourStyles.css";
import { ReactSVG } from "react-svg";
import rightArrow from "../../assets/images/icon-arrow-right.svg";
import iconMatch from "../../assets/images/icon-match.svg";
import TitleOverTitle from "../Uikit/TitleOverTitle/TitleOverTitle";
import { NavLink } from "react-router-dom";
import { EliteRouters } from "../../Navigation/Routers";
import UserService from "../../Services/ApiServices/UserService";
import {hasValidSubscription} from "../../Services/ApiServices/api.client";

export default function MatchMakingDuJour(props) {
  const [matches, setMatches] = useState([]);
  useEffect(() => {
    async function fetchMatches() {
        if (hasValidSubscription()) {
            await UserService.fetchSuggestions().then((res) => {
                setMatches(res.data.list);
            });
        }
    }
    fetchMatches();
  }, []);

  return (
    <div className={`${!matches.length ? "d-none" : ""}`}>
      <div className={"col-12"}>
        <TitleOverTitle
          backTitleSize={props.isMobile && "29px"}
          frontTitleSize={props.isMobile && "22px"}
          icon={iconMatch}
          title={"Matching du jour"}
          overTitleMarginTop={props.isMobile && "-25px"}
        />
      </div>
      <div className={"col-12 matchContainer"}>
        <Carousel
          hideDots
          hideArrows
          carouselType={"matchMaking"}
          data={matches}
          slidesToShow={props.isMobile ? 2 : matches.length <= 3 ? matches.length : 3}
          slidesToScroll={1}
        />
        <div className={`fadeOutOver ${props.isMobile ? "d-none" : ""}`}>
          <NavLink to={EliteRouters.matchmaking.path}>
            Voir plus <ReactSVG src={rightArrow} />
          </NavLink>
        </div>
      </div>
      <div className={`fadeOutOverMobile ${!props.isMobile ? "d-none" : ""}`}>
        <NavLink to={EliteRouters.matchmaking.path}>
          Voir plus <ReactSVG src={rightArrow} />
        </NavLink>
      </div>
    </div>
  );
}
