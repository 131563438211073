import React, { Component } from "react";

class EliteParagraph extends Component {
  render() {
    let customStyle = this.props.customStyle ? this.props.customStyle : {};
    return (
      <>
        <p
          style={{
            lineHeight: this.props.lineHeight,
            fontStyle: this.props.fontStyle,
            color: this.props.textColor,
            fontFamily: this.props.fontFamily,
            fontSize: this.props.fontSize,
            fontWeight: this.props.fontWeight,
            margin: this.props.margin,
            textAlign: this.props.textAlign,
            textTransform: `${this.props.uppercase && "uppercase"}`,
            background: this.props.background,
            textFillColor: this.props.textFill,
            backgroundClip: this.props.bgClip,
            cursor: this.props?.cursor || "text",
            width: this.props.width,
            display: this.props.display ? this.props.display : 'block',
            ...customStyle,
          }}
          className={`eliteParagraph${(this.props.whychoose)?" whychoose": ""} text-md- ${this.props.hide ? " d-none" : ""}${
            this.props.background ? " gradientText" : ""
          }`}>
          {this.props.text}
          {this.props.children}
        </p>
      </>
    );
  }
}

export default EliteParagraph;
