import ReactGA from 'react-ga';

class gaService {
  constructor() {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKER);
  }

  track({type, category, action, page}) {
    let cookieState = localStorage.getItem('cookie_popup_state');
    if(cookieState === '1' || cookieState === '2')
      return;
    switch (type) {
      case "event": {
        ReactGA.event({
          category,
          action
        })
        break;
      }
      case "page": {
        ReactGA.pageview(page)
        break;
      }
      default:
        break;
    }
  }
}

export default new gaService();
