import React, { useMemo, useRef } from "react";
import { ReactSVG } from "react-svg";
import Spinner from "react-bootstrap/Spinner";
import EliteParagraph from "../Typography/Paragraph";
import "./PhotoStyles.css";
import loveEmptyGreen from "../../../assets/images/icon-love-empty-green.svg";
import loveFull from "../../../assets/images/icon-love-full.svg";
import { handleError } from "../../../Services/ErrorHandler";
import { generatePath, useHistory } from "react-router-dom";
import { EliteRouters } from "../../../Navigation/Routers";
import { useNotifications } from "../../../Hooks/Notifications";
import { hasValidSubscription } from "../../../Services/ApiServices/api.client";
import CustomModal from "../Modal/Modal";
import Offers from "../../Offers/Offers";
import { useLiked } from "../../../Hooks/Likes";
import ResponsiveService from "../../../Services/ResponsiveService";

export default function Photo(props) {
  const { profile } = props;
  const { notifications } = useNotifications()
  const history = useHistory()
  const { isLiked,toggleLike } = useLiked()
  const modalOffers = useRef();
  const isOnline = useMemo(
    () => notifications.onlineUsers.includes(profile.id),
    [profile.id, notifications.onlineUsers],
  );

  async function onToggleLike(e) {
    e.stopPropagation();
    try {
      await toggleLike(profile);
    } catch (e) {
      handleError(e);
    }
  }

  function goToProfile() {

    if (modalOffers.current.state.showModal) return;
    if (hasValidSubscription()) {
      history.push(generatePath(EliteRouters.visitedProfile.path, {id: profile.id}))
    } else {
      modalOffers.current.handleOpenModal();
    }
  }

  if (profile.avatar === "loader") {
    return (
      <div
        className={`photoContainer ${props.type} ${props.size} ${
          props.isMobile && "mobile"
        }`}
        style={{
          textAlign: "center",
          paddingTop: "60px",
          backgroundColor: "#dfdfdf",
        }}>
        <Spinner animation="border" />
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundImage: `url(${
          profile.avatar ||
          `${process.env.REACT_APP_INITIALS_GENERATOR + profile.username}`
        })`,
        cursor: "pointer",
      }}
      className={`photoContainer ${props.type} ${props.size} ${
        props.isMobile && "mobile"
      }`}
      onClick={() => goToProfile()}>
      <div className={`isOnline ${isOnline ? "d-block" : "d-none"}`} />
      <div className="dateName">
        <EliteParagraph
          textAlign={"left"}
          fontSize={props.size === "small" ? "14px" : "16px"}
          textColor={"#fff"}
          fontWeight={"bold"}
          margin={0}
          text={profile.username}
        />
      </div>
      <span className="btn btn-default liked" onClick={ onToggleLike }>
        <ReactSVG className={ 'iconLove' } src={ isLiked(profile) ? loveFull : loveEmptyGreen }/>
      </span>
      <div className="gradientFilter" />

      <CustomModal
        overlayClassName="Overlay"
        modalContent={
          <Offers
            isMobile={ResponsiveService.isMobile()}
            closeModal={modalOffers}
          />
        }
        modalClass={"offersModalMobile"}
        ref={modalOffers}
      />
    </div>
  );
}
