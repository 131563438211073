import React, { useState } from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import ResponsiveService from "../../Services/ResponsiveService";
import { Helmet, HelmetProvider } from "react-helmet-async";
import datingCanada from "../../assets/images/dating-canada-1.jpg";
import datingCanada2 from "../../assets/images/dating-canada-2.jpg";
import BigTitle from "../../Components/Uikit/Typography/BigTitle";
import EliteSmallTitle from "../../Components/Uikit/Typography/SmallTitle";
import EliteParagraph from "../../Components/Uikit/Typography/Paragraph";

export function CanadaPage() {
    const [isMobile, setIsMobile] = useState(
        ResponsiveService.isMobile()
    );
    
    const handleResize = (e) => {
        e.preventDefault();
        if (isMobile !== ResponsiveService.isMobile()) {
          setIsMobile({ isMobile: ResponsiveService.isMobile() });
        }
    };
    window.addEventListener("resize", handleResize.bind(this));

    return (
        <HelmetProvider>
            <>
                <Helmet>
                    <title>Meilleur site de rencontre au Canada – EliteBerbere.fr</title>
                    <meta name="description" content="Elite Berbère vous offre la possibilité 
                    de rencontrer des célibataires dans tout le Canada. Profitez d'une inscription 
                    gratuite et rapide !"/>
                    <meta name="keywords" content="Premier message, Sincère, Nouer, Femme célibataire, 
                    Durables, Compatibles, Rencontres faciles, Points communs, Rencontrer l amour, 
                    Votre personnalité, Faire connaissance, Faire une rencontre, Âme-soeur, Relation 
                    sérieuse, Affinités, Trouver l amour, Faire des rencontres, Sérieuse, Rencontre amoureuse, 
                    Site de rencontres, Recherche d une relation, Pour rencontrer, Amicales, Premier rendez-vous, Affinité"/>
                </Helmet>
                <Header />
                <div className="container">
                    <div className="row p-4">
                        <div style={{textAlign: "justify"}} className="col-12">
                            <BigTitle
                                marginBottom={"5vh"}
                                titleColor={"#4e4e4e"}
                                textAlign={"left"}
                                text={"Elite berbère, numéro un des sites de rencontre berbère au Canada"}
                            />
                            <EliteParagraph
                                text={<>
                                    <b>Faire une rencontre</b> avec quelqu'un de <b>compatible</b> n'est pas toujours évident.
                                    En effet, dès qu'il s'agit de <b>relations durables</b>, il faut que vous partagiez
                                    de nombreuses <b>affinités</b> avec votre partenaire. <a href={"https://eliteberbere.fr"}>Élite Berbère</a> est le site de
                                    rencontre au Canada qui va vous permettre de faire de <b>nouvelles rencontres</b> en
                                    rapport avec <b>votre personnalité</b>, grâce à notre base de données, riche en profils
                                    de qualité.
                                </>}
                                textAlign={"justify"}
                                fontSize={"1.3em"}/>

                            <EliteSmallTitle
                                textAlign={"left"}
                                text={"Pourquoi faire appel à Élite Berbère pour des rencontres berbères de qualité au Canada ?"} />
                            <EliteParagraph
                                text={<>
                                    Les célibataires sont de plus en plus exigeants, et de moins en moins disponibles pour <b>faire des rencontres</b>.
                                    La vie moderne est extrêmement chronophage, ce qui laisse peu de temps pour les rencontres amoureuses surtout
                                    lorsqu'on cherche une <b>relation sérieuse</b>. C'est tout l'intérêt de notre site de rencontre au Canada, qui centralise
                                    et sélectionne pour vous des profils adaptés à vos exigences. C'est pour vous très appréciable, car vous gagnez
                                    beaucoup de temps lors de votre recherche <b>amoureuse</b>. Élite Berbère est international. Au-delà des <b>rencontres amoureuses
                                    en Afrique</b>, ce <b>site de rencontre au Canada</b> vous permet de faire des <b>rencontres entre célibataires</b> ayant
                                    la même culture et la même exigence.
                                </>}
                                textAlign={"justify"}/>

                            <EliteSmallTitle
                                textAlign={"left"}
                                text={"Réussir son premier rendez-vous avec Élite Berbère"} />
                            <EliteParagraph
                                text={<>
                                    La première impression que vous allez donner est déterminante dans le cadre d'une rencontre entre célibataires.
                                    Que ce soit pour se <b>faire des amis</b> ou envisager une future <b>vie de couple</b>, la présentation est essentielle.
                                    Élite Berbère est l'agence spécialiste des rencontres haut de gamme, au service de célibataires exigeants.
                                    Nous serons à vos côtés pour réussir ce <b>premier rendez-vous</b> déterminant pour la suite de votre vie de couple.
                                </>}
                                textAlign={"justify"}/>
                            <img className={"img-fluid"} style={{marginBottom: "16px"}} src={ datingCanada } alt={ "dating Canada 1" }/>

                            <EliteSmallTitle
                                textAlign={"left"}
                                text={"Élite Berbère, spécialiste de la rencontre africaine au Canada"} />
                            <EliteParagraph
                                text={<>
                                    Trouver l'amour au Canada n'est pas si simple. Si vous souhaitez <b>entrer en contact</b> avec une <b>femme noire</b> ou une
                                    <b> femme africaine</b> coutumière de la culture amazighe ou berbère, Élite Berbère va vous faire <b>rencontrer une personne </b>
                                    qui partage un grand nombre de vos <b>affinités</b>, afin que vous puissiez avancer dans votre projet de vie, grâce à des
                                    rencontres africaines de haut niveau. Vos exigences vont vraiment être prises en compte pour obtenir les résultats
                                    que vous attendez, dans le domaine des rencontres africaines au Canada.
                                </>}
                                textAlign={"justify"}/>

                            <EliteSmallTitle
                                textAlign={"left"}
                                text={"Comment sélectionnons-nous les candidats chez Élite Berbère ?"} />
                            <EliteParagraph
                                text={<>
                                    Pour faire partie de notre fichier premium, nous sélectionnons des célibataires sérieux, motivés, qui répondent à
                                    des critères de sélection élevés. Toutes les informations que nous mettons à votre disposition sont vérifiées par
                                    nos équipes qui en assurent aussi la confidentialité. Ainsi, vos données et celles de vos futurs partenaires sont
                                    complètement protégées. C'est ce qui nous permet d'avoir un taux de réussite très élevé dans la rencontre entre
                                    <b> célibataires exigeants</b> au Canada.
                                </>}
                                textAlign={"justify"}/>

                            <EliteSmallTitle
                                textAlign={"left"}
                                text={"Comment s'inscrire sur Élite Berbère, leader des sites de rencontre berbère au Canada ?"} />
                            <EliteParagraph
                                text={<>
                                    En quelques clics, vous pouvez nous rejoindre et faire partie de l'aventure Élite Berbère. Il vous suffit de rentrer
                                    vos informations, de répondre à notre questionnaire et de préciser vos demandes. Vous aurez ensuite un contact direct
                                    avec une de nos collaboratrices ou un de nos coachs, qui mettront en valeur votre parcours de vie, afin de maximiser
                                    vos chances de rencontrer le ou la partenaire idéal(e). C'est simple, efficace, rapide et sans engagement.
                                    <b> Rejoignez</b>-nous, vous ne le regretterez pas. Avec Élite Berbère, votre <b>vie amoureuse</b> va être à la hauteur de vos exigences.
                                    Vous allez rencontrer l'âme sœur et <b>trouver l'amour</b> !
                                </>}
                                textAlign={"justify"}/>
                            <img className={"img-fluid"} style={{marginBottom: "16px"}} src={ datingCanada2 } alt={ "dating Canada 2" }/>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        </HelmetProvider>
    );
}
