import React, { useEffect, useState } from "react";

import ConnectedHeader from "../../Components/Header/ConnectedHeader/ConnectedHeader";
import iconMatchTitle from "../../assets/images/icon-match-title.svg";
import Footer from "../../Components/Footer/Footer";
import TitleOverTitle from "../../Components/Uikit/TitleOverTitle/TitleOverTitle";
import UserService from "../../Services/ApiServices/UserService";
import { hasValidSubscription } from "../../Services/ApiServices/api.client";
import MatchMakingOffer from "../../Components/MatchmakingCard/MatchMakingOffer";
import Alert from "react-bootstrap/Alert";
import {useCurrentUser} from "../../Hooks/AuthenticatedUser";

export default function Matchmaking(props) {
  const [matches, setMatches] = useState([]);
  const {user} = useCurrentUser();

  const handleMatchChoice = (choice, profileId) => {
    UserService.updateSuggestionFlag(profileId, choice).then((res) => {
      setMatches(res.data.list);
    });
  };

  useEffect(() => {
    async function fetchMatches() {
      if (hasValidSubscription()) {
        await UserService.fetchSuggestions().then((res) => {
          setMatches(res.data.list);
        });
      }
    }
    fetchMatches();
  }, []);

  return (
    <>
      <div style={{ height:"100vh", display: "flex", flexDirection: "column" }}>
        <ConnectedHeader />
        <div className={"container mesLikesContainer mb-5"}>
          <div className="row">
            <div className="col-1"></div>
            <div className="col-10">
              <div className="col-12">
                <TitleOverTitle
                  backTitleSize={props.isMobile && "36px"}
                  frontTitleSize={props.isMobile && "24px"}
                  title={"Matching du jour"}
                  overTitleMarginTop={props.isMobile && "-25px"}
                  icon={iconMatchTitle}
                />
              </div>
              { (!user.loading && (!user.searching.city || user.searching.city.length <= 0)) &&
                <Alert className="col-lg-12 col-md-12" variant="secondary">
                  <p className={"m-auto"}>
                    ✔ Pour avoir des matchs, mettez à jour votre profil : <a href={"/profile"}>"Ce que je recherche"</a>
                  </p>
                </Alert>
              }
              <div className="col-lg-1 col-md-12" />
              <div className={"row g-3"}>
                <MatchMakingOffer
                  matchData={matches}
                  callback={handleMatchChoice}
                />
              </div>
            </div>
            <div className="col-1"></div>
          </div>
        </div>
        <Footer isLogged></Footer>
      </div>
    </>
  );
}
