import React, {Component} from "react";
import './CookiesBannerStyle.css';

class CookiesBanner extends Component {
  render() {
    return (
      <div className={"cookies-banner-container"+(this.props.mobile ? '-mobile' : '')}>
        <div>Ce site utilises des cookies pour vous assurer la meilleure expérience possible sur notre site. <a href={"/privacy-policy#cookie"}>En savoir plus</a></div>
        <div>
          <div>
            <button onClick={() => this.props.trigger(0) }>
              Je refuse
            </button>
          </div>
          <div>
            <button onClick={ () => this.props.trigger(1)}>
              J'ai compris!
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default CookiesBanner;
