import React, { useState } from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import ResponsiveService from "../../Services/ResponsiveService";
import { Helmet, HelmetProvider } from "react-helmet-async";
import datingKabyl from "../../assets/images/dating-kabyle-1.jpg";
import datingKabyl2 from "../../assets/images/dating-kabyle-2.jpg";
import BigTitle from "../../Components/Uikit/Typography/BigTitle";
import EliteSmallTitle from "../../Components/Uikit/Typography/SmallTitle";
import EliteParagraph from "../../Components/Uikit/Typography/Paragraph";

export function KabylePage() {
    const [isMobile, setIsMobile] = useState(
        ResponsiveService.isMobile()
    );
    
    const handleResize = (e) => {
        e.preventDefault();
        if (isMobile !== ResponsiveService.isMobile()) {
          setIsMobile({ isMobile: ResponsiveService.isMobile() });
        }
    };
    window.addEventListener("resize", handleResize.bind(this));

    return (
        <HelmetProvider>
            <>
                <Helmet>
                    <title>Site de Rencontre Kabyle par excellence – Elite Berbère</title>
                    <meta name="description" content="Créez votre profil gratuitement sur 
                    le site de rencontre Elite Berbère et faites connaissance avec le ou la kabyle fait-e pour vous."/>
                    <meta name="keywords" content="Rencontre par affinités, Hommes et femmes, Rencontrez, Dating, 
                    Affinités, Tchat, Trouver l amour, Âme-soeur, Célibataire, Sérieuse, Relation sérieuse, 
                    Site de rencontre, Rencontre amoureuse, Sérieuses, Amoureuses, Pour rencontrer, Votre personnalité, 
                    Sincère, Relations sérieuses, Séduction, Draguer, Construire une relation, Site pour rencontrer, 
                    Concernant la rencontre, Faire des rencontres intéressantes"/>
                </Helmet>
                <Header />
                <div className="container">
                    <div className="row p-4">
                        <div style={{textAlign: "justify"}} className="col-12">
                            <BigTitle
                                marginBottom={"5vh"}
                                titleColor={"#4e4e4e"}
                                textAlign={"left"}
                                text={"Élite Berbère : un site de rencontre kabyle exigeant pour rencontrer l’âme sœur !"}
                            />
                            <EliteParagraph
                                text={<>
                                    Vous entrez dans un café et votre regard est attiré par une personne séduisante assise au fond de la
                                    salle. Vous vous approchez, vous hésitez… Comment l'aborder ? Votre vie amoureuse ressemble à un désert
                                    sentimental. Vous avez tout essayé : <b>dating</b>, <b>tchat</b>, <b>sites de rencontres</b>, etc. À chaque fois, vous avez
                                    eu l'impression de <b>draguer</b> et vos tentatives se sont soldées par des échecs. Soit vous n'aviez rien à
                                    partager, soit vos attentes étaient différentes. Ce serait tellement plus simple pour briser la glace de
                                    rencontrer quelqu'un d'intéressant qui partage les mêmes affinités et la même culture que vous ! Soudain,
                                    la personne lève son regard vers vous et vous sourit : « J'ai l'impression que nous avons pas mal de
                                    choses en commun. Asseyez-vous pour qu'on bavarde un peu ! » Et si ce n'était pas un rêve ? <a href={"https://eliteberbere.fr"}>
                                    Inscrivez-vous sur Élite Berbère</a> et découvrez un <b>site de rencontre kabyle exigeant</b> pour rencontrer l'<b>âme sœur</b> !
                                </>}
                                textAlign={"justify"}
                                fontSize={"1.3em"}/>

                            <EliteSmallTitle
                                textAlign={"left"}
                                text={"Construire une relation sérieuse grâce à des rencontres intéressantes"} />
                            <EliteParagraph
                                text={<>
                                    Nous vous proposons de faire des <b>rencontres par affinités et par culture</b> et de tout mettre en œuvre pour
                                    vous permettre de <b>trouver l'amour</b>. Les personnes inscrites sur notre <b>site de rencontre kabyle</b> sont des
                                    <b>célibataires exigeants</b> qui ont été présélectionnés. Il s'agit de profils qualifiés qui appartiennent
                                    principalement à la communauté berbère et kabyle et qui sont, comme vous, à la recherche de <b>relations sérieuses</b>.
                                    En fonction de <b>votre personnalité</b>, <b>rencontrez des hommes et des femmes</b> qui souhaitent construire une relation
                                    durable dans le respect de l'autre, sous le signe d'une culture commune. Attention, en vous inscrivant sur
                                    notre <b>site de rencontre kabyle</b>, votre vie risque de changer !
                                </>}
                                textAlign={"justify"}/>
                            <img className={"img-fluid"} style={{marginBottom: "16px"}} src={ datingKabyl } alt={ "dating Kabyl 1" }/>

                            <EliteSmallTitle
                                textAlign={"left"}
                                text={"Bénéficier d'un site de rencontre kabyle fiable, simple et gratuit"} />
                            <EliteParagraph
                                text={<>
                                    Faire partie du cercle d'Elite Berbère, c'est accéder en quelques minutes à des profils exigeants sélectionnés
                                    par nos équipes en fonction de critères d'entrée appliqués à tous les nouveaux venu​​s. Notre plateforme vous
                                    permet de prendre votre temps pour rencontrer des personnes <b>sérieuses</b> et <b>sincères</b>. Nos équipes procèdent aux
                                    vérifications nécessaires et traquent les faux profils afin de vous assurer des échanges en toute confiance.
                                    Vous multipliez ainsi vos chances de débuter rapidement une <b>relation sérieuse</b>. Notre plateforme est entièrement
                                    gratuite et l'inscription se réalise en trois étapes simples et efficaces.
                                </>}
                                textAlign={"justify"}/>

                            <EliteSmallTitle
                                textAlign={"left"}
                                text={"Profiter de nombreux événements où faire des rencontres intéressantes"} />
                            <EliteParagraph
                                text={<>
                                    Se retrouver en tête-à-tête avec un ou une inconnu(e) n'est pas toujours simple, mais une rencontre amoureuse
                                    ne peut se réduire à communiquer derrière un écran. C'est pourquoi Élite Berbère favorise les rencontres en
                                    situation réelle. Nous favorisons les événements qui vous permettront de multiplier les prises de contacts directes
                                    et de faire des rencontres intéressantes. Inscrivez-vous et profitez dès maintenant d'événements près de
                                    chez vous : dîners, conférences ou manifestations culturelles en lien avec la communauté berbère. Et pour joindre
                                    l'utile à l'agréable, nous organisons également des événements professionnels en vous faisant bénéficier de notre
                                    accompagnement de qualité. <b>Concernant la rencontre</b>, nous privilégions la mise en relation dans des contextes qui
                                    ne sont pas uniquement dédiés à la <b>séduction</b>. Notre objectif est de vous aider à entretenir des <b>relations
                                    sérieuses</b> en fonction de vos affinités et vos origines !
                                </>}
                                textAlign={"justify"}/>
                            <img className={"img-fluid"} style={{marginBottom: "16px"}} src={ datingKabyl2 } alt={ "dating Kabyl 2" }/>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        </HelmetProvider>
    );
}
