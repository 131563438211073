import React from 'react';
import ForgotPasswordModal from '../../Components/ForgotPasswordModal/ForgotPasswordModal';
import './ForgotPasswordStyle.css';
import ForgotPasswordMobile from "./Mobile/ForgotPasswordMobile";

function ForgotPassword(props) {
  return (
    <ForgotPasswordModal closeModalContent={ props.closeModal }/>
  );
}

export {ForgotPassword, ForgotPasswordMobile};
