import apiClient from "../Services/ApiServices/api.client";
import { useEffect } from "react";
import { handleError } from "../Services/ErrorHandler";
import { useStore } from "react-context-hook";
import { useCurrentUser } from "./AuthenticatedUser";

function getUniqueListBy(arr, key) {
  return [...new Map(arr.map(item => [item[key], item])).values()]
}

export function useLikers() {
  const [likers, setLikers] = useStore('likers', [])
  const { user } = useCurrentUser()


  useEffect(() => {
    if (!likers || likers.length !== user.likersCount) {
      ( async () => {
        const { data: { data } } = await apiClient.get('/api/likers')
        setLikers(getUniqueListBy(data, 'id'))
      } )()
    }
    // eslint-disable-next-line
  }, [likers.length, user.likersCount])

  return { likers }
}

export function useLiked() {
  const { liked, setLiked } = useCurrentUser()

  function isLiked({ id }) {
    return liked.includes(id.toString());
  }

  async function toggleLike(profile) {
    try {
      const { data } = await ( isLiked(profile) ? unlikeProfile(profile) : likeProfile(profile) );
      setLiked(data);
    } catch (e) {
      handleError(e)
      throw e;
    }
  }

  function likeProfile({ id }) {
    return apiClient.post(`/api/like/${ id }`)
  }

  function unlikeProfile({ id }) {
    return apiClient.delete(`/api/unlike/${ id }`)
  }

  return { isLiked, toggleLike }
}





