import React, { useState } from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import ResponsiveService from "../../Services/ResponsiveService";
import { Helmet, HelmetProvider } from "react-helmet-async";
import datingAlgeria from "../../assets/images/dating-algeria-1.jpg";
import datingAlgeria2 from "../../assets/images/dating-algeria-2.jpg";
import BigTitle from "../../Components/Uikit/Typography/BigTitle";
import EliteSmallTitle from "../../Components/Uikit/Typography/SmallTitle";
import EliteParagraph from "../../Components/Uikit/Typography/Paragraph";

export function AlgeriePage() {
    const [isMobile, setIsMobile] = useState(
        ResponsiveService.isMobile()
    );
    
    const handleResize = (e) => {
        e.preventDefault();
        if (isMobile !== ResponsiveService.isMobile()) {
          setIsMobile({ isMobile: ResponsiveService.isMobile() });
        }
    };
    window.addEventListener("resize", handleResize.bind(this));

    return (
        <HelmetProvider>
            <>
                <Helmet>
                    <title>Site de rencontre Algérie - Trouver l'amour sur EliteBerbere.fr</title>
                    <meta name="description" content="Trouvez l'âme soeur qui partage les mêmes 
                    valeurs que vous sur le site de rencontre Elite Berbère. Inscription rapide et gratuite."/>
                    <meta name="keywords" content="Faire des rencontres, Sites de rencontre, Tchat, Âme-soeur, 
                    Attractive, Trouver une personne, Pour rencontrer, Votre personnalité, Célibat, Points communs, 
                    Vie de célibataire, Rencontre entre célibataires, Pour les hommes, Pour les femmes, 
                    Rencontre romantique, Rencontres amicales, Premier message, Faire de nouvelles, 
                    Rencontres d un soir, Connaissances sérieuses, Sérieuse, Relation sérieuse, Affinités, 
                    Célibataire, Trouver l amour"/>
                </Helmet>
                <Header />
                <div className="container">
                    <div className="row p-4">
                        <div style={{textAlign: "justify"}} className="col-12">
                            <BigTitle
                                marginBottom={"5vh"}
                                titleColor={"#4e4e4e"}
                                textAlign={"left"}
                                text={"Trouver le grand amour grâce à un site de rencontre en Algérie avec Elite Berbère"}
                            />
                            <EliteParagraph
                                text={<>
                                    Entamer une <b>relation sérieuse</b> peut être très difficile. Il faut, en effet, <b>trouver une
                                    personne</b> qui partage les mêmes <b>affinités</b> et qui voit la vie de la même façon que vous.
                                    Mais cela n'est pas toujours simple de <b>faire des rencontres</b> de qualité. Et cela ne concerne
                                    pas uniquement les relations amoureuses. Il peut alors s'avérer très utile de se faire aider.
                                    Et <a href={"https://eliteberbere.fr"}>Élite Berbère</a> est là pour ça !
                                </>}
                                textAlign={"justify"}
                                fontSize={"1.3em"}/>

                            <EliteSmallTitle
                                textAlign={"left"}
                                text={"Un site de rencontre en Algérie qui a tout compris"} />
                            <EliteParagraph
                                text={<>
                                    Vous souhaitez trouver l'<b>âme sœur</b>, mais cela n'est pas simple, d'autant que pour vous il est important
                                    que l'autre partage les mêmes valeurs et traditions que vous. Si vous mettez en avant la culture berbère,
                                    vous devez donc rencontrer des personnes qui ont les mêmes <b>points communs</b> que vous pour oublier votre <b>vie
                                    de célibataire</b>.
                                </>}
                                textAlign={"justify"}/>
                            <EliteParagraph
                                text={<>
                                    Pour passer des moments inoubliables avec l'autre, il est indispensable de pouvoir communiquer et partager
                                    les mêmes choses.
                                </>}
                                textAlign={"justify"}/>
                            <EliteParagraph
                                text={<>
                                    Mais pour faire une rencontre entre célibataires de qualité, mieux vaut ne pas laisser la place au hasard.
                                    En effet, vous pouvez rencontrer une personne <b>attractive</b>, mais qui ne partagera rien avec vous. Pour être
                                    sûr de faire des <b>connaissances sérieuses</b> et gagner du temps, mieux vaut alors s'adresser à une plateforme
                                    de rencontres qui met votre satisfaction au premier plan.
                                </>}
                                textAlign={"justify"}/>
                            <img className={"img-fluid"} style={{marginBottom: "16px"}} src={ datingAlgeria } alt={ "dating Algeria 1" }/>

                            <EliteSmallTitle
                                textAlign={"left"}
                                text={"Pourquoi faire confiance à Élite Berbère ?"} />
                            <EliteParagraph
                                text={<>
                                    Si vous êtes à la recherche d'un partenaire en Algérie, il est important de vous adresser à un site de
                                    rencontre en Algérie qui a les mêmes valeurs que vous. Vous l'aurez compris, Élite Berbère s'adresse
                                    avant tout aux Imazighen. Nous avons bien compris que vous avez besoin de rencontrer des personnes qui
                                    pensent comme vous.
                                </>}
                                textAlign={"justify"}/>
                            <EliteParagraph
                                text={<>
                                    Notre plateforme se veut élitiste, car nous ne voulons pas que vous rencontriez n'importe qui. Nous voulons
                                    répondre à vos exigences en vous permettant de rencontrer de nouvelles personnes de qualité et respectueuses.
                                </>}
                                textAlign={"justify"}/>
                            <EliteParagraph
                                text={<>
                                    Nous avons mis en place différents événements pour vous aider à <b>trouver l'amour</b> ou simplement faire des
                                    <b>rencontres d'un soir</b>. Notre site de rencontre en Algérie peut aussi être à vos côtés pour réaliser des
                                    <b>rencontres amicales</b>. Notre but est avant tout de vous faire <b>faire de nouvelles</b> rencontres. À vous ensuite
                                    de créer une relation amoureuse (et quitter votre <b>célibat</b>) ou amicale.
                                </>}
                                textAlign={"justify"}/>
                            <img className={"img-fluid"} style={{marginBottom: "16px"}} src={ datingAlgeria2 } alt={ "dating Algeria 2" }/>

                            <EliteSmallTitle
                                textAlign={"left"}
                                text={"Comment faire pour le/la rencontrer ?"} />
                            <EliteParagraph
                                text={<>
                                    Notre plateforme est idéale <b>pour les hommes</b> et <b>pour les femmes</b> ne voulant plus être <b>célibataires</b>.
                                </>}
                                textAlign={"justify"}/>
                            <EliteParagraph
                                text={<>
                                    Nous avons pensé qu'un site de rencontre en Algérie facile d'utilisation était une excellente
                                    solution pour vous permettre de faire des rencontres facilement. Il vous suffit de renseigner
                                    quelques informations générales pour créer votre compte. À vous ensuite de sélectionner les
                                    partenaires qui vous font craquer. Envoyez-leur un <b>premier message</b>, puis commencez un <b>tchat </b>
                                    qui vous mènera à une <b>rencontre romantique</b> ou simplement à un rendez-vous entre amis.
                                    Dans tous les cas, vous aurez une entrevue sérieuse et rencontrerez des personnes en
                                    adéquation avec votre <b>personnalité</b> et pas loin de chez vous.
                                </>}
                                textAlign={"justify"}/>
                            <EliteParagraph
                                text={<>
                                    En plus de vous aider à faire des <b>rencontres entre célibataires</b>, Élite Berbère
                                    vous propose différents événements comme des conférences, dîners, accompagnements
                                    personnalisés, coachings... Vous aurez ainsi toutes les clefs <b>pour rencontrer</b> de
                                    nouvelles personnes qui répondent à vos exigences.
                                </>}
                                textAlign={"justify"}/>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        </HelmetProvider>
    );
}
