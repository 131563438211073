import React, { Component } from "react";
import { ReactSVG } from "react-svg";
import backArrow from "../../../../assets/images/left-arrow.svg";

class BackArrow extends Component {
  render() {
    return (
      <div className={this.props.className} onClick={this.props.onClick}>
        <ReactSVG src={backArrow}></ReactSVG>
      </div>
    );
  }
}
export default BackArrow;
