import { useStore } from "react-context-hook";
import apiClient from "../Services/ApiServices/api.client";

export function useNotifications() {
  const [notifications, setNotifications] = useStore('notifications', { onlineUsers: [] })
  const [pinging, setPinging] = useStore('pinging', false);

  async function pingServer() {
    if(localStorage.getItem('tokenApi')) {
      try {
        const { data } = await apiClient.get('/api/ping');
        setNotifications(data);
      } catch (e) {
        //console.error(e)
      }
    }
  }

  async function keepOnline() {
    if (!pinging) {
      setPinging(true);
      await pingServer();
      const interval = setInterval(() => pingServer(), 5 * 1000);
      return () => {
        setPinging(false);
        clearInterval(interval)
      };
    }
  }

  return { notifications, pinging, keepOnline }
}
