import React, { Component } from "react";
import "./TypographyStyles.css";
class BigTitle extends Component {
  render() {
    return (
      <>
        <h1
          style={{
            color: this.props.titleColor,
            fontFamily: this.props.fontFamily,
            fontSize: this.props.fontSize,
            fontWeight: this.props.fontWeight,
            opacity: this.props.titleOpacity,
            textAlign: this.props.textAlign,
            padding: this.props.padding,
            marginBottom: this.props.marginBottom || "1.7vh",
            textTransform: this.props.uppercase && "uppercase",
          }}
          className={"eliteBigTitle"}>
          {this.props.text}
        </h1>
        {this.props.showUnderline && <div className={"smallUnderline"}></div>}
        {this.props.showUnderlineWhite && <div className={"smallUnderlineWhite"}></div>}
      </>
    );
  }
}
export default BigTitle;
