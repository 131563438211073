import React, { useState } from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import TitleOverTitle from "../../Components/Uikit/TitleOverTitle/TitleOverTitle";
import ResponsiveService from "../../Services/ResponsiveService";
import {ReactSVG} from "react-svg";
import backArrow from "../../assets/images/icon-arrow-back.svg";
import { useHistory } from "react-router-dom";

export function PolitiqueConfidentialites() {
  const [isMobile, setIsMobile] = useState({
    isMobile: ResponsiveService.isMobile(),
  });
  const history = useHistory();

  const handleResize = (e) => {
    e.preventDefault();
    if (isMobile !== ResponsiveService.isMobile()) {
      setIsMobile({ isMobile: ResponsiveService.isMobile() });
    }
  };
  window.addEventListener("resize", handleResize.bind(this));
  return (
    <>
      <Header />
      <div className="container">
        <div className="row p-4">
          <div className="col-12">
            <div className="col-4">
              <div className={"back"}>
                <div onClick={() => history.goBack()}>
                  <ReactSVG src={backArrow} /> Retour
                </div>
              </div>
            </div>
            <TitleOverTitle
              overTitleMarginTop={isMobile.isMobile ? "-30px" : "-25"}
              backTitleSize={isMobile.isMobile ? "30px" : "60px"}
              frontTitleSize={isMobile.isMobile ? "24px" : "32px"}
              title={"Politique de confidentialités"}
            />
            <div className={"pt-4"}>
              <h2>
                <strong>
                  1. Définitions
                  <br />
                </strong>
              </h2>
              <div style={{ marginLeft: "20px" }}>
                <p>
                  <strong>« Abonnement » : </strong>
                  contrat à durée déterminée à titre onéreux auquel le Membre
                  souscrit afin de bénéficier du Service durant la période
                  spécifiée par l’offre d’abonnement concernée
                  <br />
                </p>
                <p>
                  <strong>« Abonné » </strong>
                  : tout Membre souscrivant au Service via un Abonnement
                  <br />
                </p>
                <p>
                  <strong>« Compte » </strong>
                  : espace personnel des Membres leur permettant de
                  s’identifier, de gérer leur Profil et, en cas d’Abonnement, de
                  bénéficier du Service
                  <br />
                </p>
                <p>
                  <strong>« Données »</strong>
                  : ensemble des informations concernant les Utilisateurs
                  <br />
                </p>
                <p>
                  <strong>« Données personnelles » </strong>
                  : toute information susceptible d’identifier, directement ou
                  indirectement les Utilisateurs personne physique.
                  <br />
                </p>
                <p>
                  <strong>« Membre » </strong>
                  » : tout Utilisateur personne physique majeure s’étant inscrit
                  sur le Site
                  <br />
                </p>
                <p>
                  <strong>« Politique » </strong>
                  : la présente politique de confidentialité
                  <br />
                </p>
                <p>
                  <strong>« Profil » </strong>
                  : interface personnelle créée par le Membre et visible par les
                  autres Membres sur le Site, comprenant des informations le
                  concernant qu’il aura fourni via son Compte
                  <br />
                </p>
                <p>
                  <strong>« Responsable de traitement » </strong>: la société
                  ELITE BERBERE, société par actions simplifiée situé 40 B rue
                  de Dantzig 75015 Paris, immatriculée au RCS de Paris sous le
                  numéro
                  <span>
                    879238194
                    <br />
                  </span>
                </p>
                <p>
                  <strong>« Utilisateur » </strong>
                  : tout internaute se connectant au Site, accédant à une ou
                  des pages du Site
                  <br />
                </p>
                <p>
                  <strong>« Service » </strong>
                  <span>
                    : service de mise en relation entre deux personnes à des fins
                    privées via le Site permettant la création d’un Profil
                    visible pour les autres Membres, la proposition d’autres
                    Profils, la possibilité de « like » et de discuter via une
                    messagerie privée en cas de « match »
                    <br />
                  </span>
                </p>
                <p>
                  <strong>« Site » </strong>
                  : Le présent site internet situé à l’adresse url «
                  www.eliteberbere.fr » et l’application Elite Berbère dédiée
                  <br />
                </p>
              </div>
              <h2 style={{marginTop:"20px"}}>
                <strong>
                  2. Objet
                  <br />
                </strong>
              </h2>
              <div style={{ marginLeft: "20px" }}>
                <p>
                  Le Responsable de traitement
                  est susceptible de collecter des Données au travers le Site.
                  La Politique a pour objet d’exposer les conditions dans
                  lesquelles les Données collectées sont traitées et
                  d’informer les Utilisateurs sur leurs droits en matière de
                  Données personnelles.
                  <br />
                </p>
              </div>
              <h2>
                <strong>
                  3. Confidentialité des Données et consentement
                  <br />
                </strong>
              </h2>
              <div style={{ marginLeft: "20px" }}>
                <p>
                  La société ELITE BERBERE, éditeur du site www.eliteberbere.fr,
                  s’engage à conserver le secret le plus strict et à ne pas
                  divulguer à un tiers, directement ou indirectement, ni
                  l’existence, ni les caractéristiques des informations
                  apportées par les Utilisateurs du Site, à l’exception des
                  traitements envisagés par la Politique
                  <br />
                </p>
                <p>
                  <span>
                    A ce titre, le Responsable de traitement met en œuvre toutes
                    mesures techniques et organisationnelles afin de protéger
                    les Données mais ne saurait garantir de manière certaine
                    leur sécurité.
                    <br />
                  </span>
                </p>
                <p>
                  Leur confidentialité sera partiellement ou totalement levée
                  dans les cas suivants :
                  <br />
                </p>
                <div style={{marginLeft:"20px"}}>
                  <ul>
                    <li>
                      elles sont entrées dans le domaine public préalablement à
                      leur divulgation par l’Utilisateur, ou après celle-ci du
                      fait de l’Utilisateur ou d’un tiers ;
                      <br />
                    </li>
                  </ul>
                  <ul>
                    <li>
                      sur autorisation expresse, écrite et préalable de
                      l’Utilisateur ;
                      <br />
                    </li>
                  </ul>
                  <ul>
                    <li>
                      sur injonction d’une disposition légale ou réglementaire ou
                      dans le cadre d’une procédure judiciaire, administrative ou
                      arbitrale.
                      <br />
                    </li>
                  </ul>
                </div>
                <p>
                  <strong>
                    <span>
                      En utilisant le Site, l’Utilisateur consent et accepte
                      l&#39;utilisation de ses Données personnelles par le
                      Responsable de traitement, conformément à la Politique.
                      <br />
                    </span>
                  </strong>
                </p>
              </div>
              <h2>
                <strong>
                  4. Les Données personnelles collectées
                  <br />
                </strong>
              </h2>
              <div style={{ marginLeft: "20px" }}>
                <p>
                  Le Responsable de traitement est amené à traiter des
                  informations concernant les Utilisateurs, dont certaines sont
                  de nature à les identifier (ci-après dénommées «
                  <strong>Données personnelles</strong>
                  »).
                  <br />
                </p>
                <p>
                  Le Site et le Service proposé par le Responsable de traitement
                  induit la collecte des Données des Membres afin que ce dernier
                  puisse pleinement exécuter sa prestation.
                  <br />
                </p>
                <p>
                  Le traitement de ces Données est effectué conformément aux
                  dispositions du Règlement (UE) du Parlement européen et du
                  Conseil du 27 avril 2016 (ci-après dénommé « RGPD ») et à la
                  loi française n° 78-17 du 6 janvier 1978 (« loi informatique
                  et libertés »).
                  <br />
                </p>
                <p>
                  Dans le cadre du Site et du Service, différents types de
                  Données sont susceptibles d’être collectées.
                  <br />
                </p>
                <h3 style={{marginTop:"10px"}}>
                  4.1 Les Données d’inscriptions et d’Abonnement
                  <br />

                </h3>
                <p>
                  Les Données fournies par l’Utilisateur au Responsable de
                  traitement dans le cadre de son inscription sur le Site et de
                  la souscription à un Abonnement sont les suivantes :
                  <br />
                </p>
                <ul type={"circle"}>
                  <li>
                    nom, prénoms, civilité, date de naissance, adresse postale,
                    adresse électronique et numéro de téléphone de
                    l’Utilisateur,
                    <br />
                  </li>
                </ul>
                <ul>
                  <li>
                    identifiant et mot de passe crypté permettant
                    l’identification sur le Site si l’Utilisateur choisit de
                    créer un compte,
                    <br />
                  </li>
                </ul>
                <p>
                  Les Données de paiement de l’Abonnement sont directement
                  traitées par l’intermédiaire de paiement du Responsable de
                  traitement.
                  <br />
                </p>
                <h3 style={{marginTop:"10px"}}>
                  4.2 Les Données du Profil
                  <br />
                </h3>
                <p>
                  Les Données recueillies dans le cadre de la création d’un
                  Profil par un Membre sont celles volontairement fournies par
                  le Membre.
                  <br />
                </p>
                <p>
                  En indiquant ces Données, le Membre accepte et consent à ce
                  qu’elles soient visibles sur le Site par les autres Membres.
                  <br />
                </p>
                <p>
                  Ces Données sont les suivantes :
                  <br />
                </p>
                <div style={{marginLeft:"20px"}}>
                  <ul type={"circle"}>
                    <li>
                      Une à plusieurs photos de son choix ;
                      <br />
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Son pseudonyme ;
                      <br />
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Son âge ;
                      <br />
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Son sexe ;
                      <br />
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Sa taille et son poids ;
                      <br />
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Son orientation sexuelle ;
                      <br />
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Son origine ;
                      <br />
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Sa pratique religieuse ;
                      <br />
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Ses caractéristiques physiques (silhouette, couleur de
                      cheveux, couleur des yeux) ;
                      <br />
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Son signe astrologique ;
                      <br />
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Sa parentalité et son désir d’enfant ;
                      <br />
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Sa situation Maritale ;
                      <br />
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Sa ou ses langue(s) parlée(s) ;
                      <br />
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Sa pratique d’un sport ;
                      <br />
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Son niveau d’études ;
                      <br />
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Son secteur d’activité ;
                      <br />
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Son caractère ;
                      <br />
                    </li>
                  </ul>
                  <ul>
                    <li>
                      S’il est fumeur ou non ;
                      <br />
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Sa localisation géographique ;
                      <br />
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Un texte à la rédaction libre.
                      <br />
                    </li>
                  </ul>
                </div>

                <p>
                  <span>
                    Le Responsable de traitement est susceptible de collecter,
                    lors de la création ou la modification du Profil, des
                  </span>
                  <em>
                    <span> Données sensibles </span>
                  </em>
                  <span>
                    du Membre notamment relative à son origine ethnique, sa
                    pratique religieuse ou son orientation sexuelle.
                    <br />
                  </span>
                </p>
                <p>
                  <span>
                    Conformément à l’article 9 du RGPD, les Données sont
                    fournies volontairement par le Membre qui accepte
                    spécifiquement, par un acte positif de volonté lors de la
                    création ou la modification de son Profil
                    qu’elles soient traitées conformément à l’article 5 des
                    présentes, à l’exclusion de tout traitement effectué dans un
                    but de prospection commerciale ou publicitaire.
                    <br />
                  </span>
                </p>
                <h3 style={{marginTop:"10px"}}>
                  4.3 Les Données d’utilisation du Site
                  <br />
                </h3>

                <p>
                  <span>
                    Le Responsable de traitement recueille automatiquement des
                    informations relatives à l’utilisation faite du Site par les
                    Utilisateurs et du Service par les Membres. Elles concernent
                    notamment le système d’exploitation utilisé, le logiciel de
                    navigation, les Données de connexion (date et heure) et de
                    navigation sur le Site, d’interactions avec les autres
                    Membres (date, heure, nombre de message) et d’interaction
                    avec les publicités présentes sur le Site.
                    <br />
                  </span>
                </p>
                <p>
                  <span>
                    Enfin, le Responsable de traitement peut collecter des
                    informations concernant l’Utilisateur auprès d’autres
                    Membres l’ayant contacté, notamment au travers leurs
                    signalements.
                    <br />
                  </span>
                </p>
                <p> </p>
                <p>
                  En dehors de celles volontairement fournies par l’Utilisateur
                  dans son Profil, le Responsable de traitement ne traite aucune
                  donnée sensible. Toute autre information ou donnée sensible
                  communiquée par un Utilisateur sur le Site (notamment via la
                  messagerie privée ou le texte à rédaction libre) relève de sa
                  seule responsabilité.
                  <br />
                </p>
              </div>
              <h2 style={{marginTop:"20px"}}>
                <strong>
                  5. Les finalités de traitement des Données
                  <br />
                </strong>
              </h2>
              <div style={{ marginLeft: "20px" }}>
                <p>
                  Le traitement des Données personnelles de l’Utilisateur et du
                  Membre par le Responsable de traitement permet d’opérer, de
                  fournir et d’améliorer les Services proposés à l’Utilisateur
                  ou au Membre.
                  <br />
                </p>
                <p> </p>
                <p>
                  L’Utilisateur ou le Membre consent expressément au traitement
                  de ses Données pour l’ensemble des finalités suivantes :
                  <br />
                </p>
                <div style={{marginLeft:"20px"}}>
                  <ul>
                    <li>
                      la création et la gestion des Comptes, des Profils, la
                      souscription aux Abonnements et l’authentification des
                      Utilisateurs ;
                      <br />
                    </li>
                  </ul>
                  <ul>
                    <li>
                      la prise de contact entre le Responsable de traitement et
                      l’Utilisateur ;
                      <br />
                    </li>
                  </ul>
                  <ul>
                    <li>
                      la modération et le traitement des signalements ;
                      <br />
                    </li>
                  </ul>
                  <ul>
                    <li>
                      l’affichage et la mise en relation des Profils entre Membres
                      ;
                      <br />
                    </li>
                  </ul>
                  <ul>
                    <li>
                      la liaison des Comptes, Profils et du Service entre le site
                      internet et l’application mobile du Responsable de
                      traitement ;
                      <br />
                    </li>
                  </ul>
                  <ul>
                    <li>
                      la prospection commerciale et la proposition de publicité
                      pertinente sur le Site ou d’autres sites partenaires
                      (notamment via le reciblage ou « retargeting » publicitaire
                      sur les réseaux sociaux) ;
                      <br />
                    </li>
                  </ul>
                  <ul>
                    <li>
                      les statistiques et mesures d’audience du Site;
                      <br />
                    </li>
                  </ul>
                  <ul>
                    <li>
                      la prévention de la fraude.
                      <br />
                    </li>
                  </ul>
                </div>
              </div>

              <h2 style={{marginTop:"20px"}}>
                <strong>
                  6. La base légale du traitement
                  <br />
                </strong>
              </h2>
              <div style={{ marginLeft: "20px" }}>
                <p>
                  Le Traitement des Données de l’Utilisateur ou du Membre est
                  fondée, conformément à l’article 6 du RGPD sur les bases
                  légales suivantes :
                  <br />
                </p>
                <p>
                  <u>Le contrat</u> : la plupart des Données de l’Utilisateur sont
                  collectées et traitées par le Responsable de traitement afin
                  de lui permettre sa bonne exécution du Service : la création
                  et la gestion du Compte et du Profil, les prises de contact,
                  la souscription à un Abonnement, la mise en ligne du Profil,
                  l’utilisation de la messagerie, la modération ou encore le
                  traitement des signalements.
                  <br />
                </p>
                <p>
                  <u>L’intérêt légitime</u> : les Données collectées permettent aux
                  Responsables de traitement d’améliorer la qualité du Site et du
                  Service, de détection la fraude ou d’une autre finalité
                  juridique et de prévenir toute atteinte à la sécurité du Site.
                  <br />
                </p>
                <p>
                  <u> Le consentement de l’Utilisateur </u>: l’Utilisateur consent, en
                  acceptant les présentes, au traitement de ses Données
                  conformément à la Politique, notamment dans un but
                  publicitaire.
                  <br />
                </p>
              </div>
              <h2 style={{marginTop:"20px"}}>
                <strong>
                  7. Les destinataires des Données
                  <br />
                </strong>
              </h2>
              <div style={{ marginLeft: "20px" }}>
                <p>
                  Les Données collectées sont principalement destinées au
                  traitement par le Responsable de traitement ainsi qu’aux
                  destinataires suivants.
                  <br />
                </p>
                <h3 style={{marginTop:"10px"}}>
                  7.1 Les autres Membres
                  <br />
                </h3>
                <p>
                  Du fait de la spécificité du Service, l’ensemble des Membres
                  ont accès aux Données volontairement divulguées via le Profil
                  des autres Membres.
                  <br />
                </p>
                <div style={{marginTop:"10px"}}>
                  7.2 Le prestataire d’hébergement
                  <br />
                </div>
                <p>
                  Les Données collectées font l’objet d’un hébergement sur les
                  serveurs de la société OVH, agissant en qualité de
                  sous-traitant du Responsable de traitement.
                  <br />
                </p>
                <p>
                  Le détail du traitement de ces Données par l’hébergeur est
                  disponible au lien suivant :
                  <br />
                </p>
                <ul style={{marginLeft:"20px"}}>
                  <li>
                    <a href={"https://www.ovh.com/fr/protection-donnees-personnelles/"}>https://www.ovh.com/fr/protection-donnees-personnelles/</a>
                    <br />
                  </li>
                </ul>
                <p> </p>
                <h3 style={{marginTop:"10px"}}>
                  7.3 Les prestataires publicitaires et d’analyse
                  <br />
                </h3>

                <p>
                  Les Données collectées automatiquement sont accessibles à
                  Google Analytics à des fins d’analyse et de statistiques.
                  <br />
                </p>
                <p>
                  <span>
                    Le Responsable de traitement est également susceptible de
                    partager les Données de l’Utilisateur avec des prestataires
                    publicitaires comme Facebook et Google afin de présenter aux
                    Utilisateurs des publicités ciblées sur le Site ainsi que
                    sur des sites tiers.
                    <br />
                  </span>
                </p>
                <p>
                  Le détail du traitement de ces Données par les prestataires
                  est disponible aux liens suivants :
                  <br />
                </p>
                <div style={{marginLeft:"20px"}}>
                  <ul>
                    <li>
                      <a href="' https://fr-fr.facebook.com/policy.php'">
                        {" "}
                        https://fr-fr.facebook.com/policy.php
                      </a>
                      <br />
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <a href="'https://policies.google.com/privacy'">
                        {" "}
                        https://policies.google.com/privacy
                      </a>
                      <br />
                    </li>
                  </ul>
                </div>
                <p>
                  L’Utilisateur a la possibilité de gérer les Données
                  communiquées via les
                  <em> Cookies </em>à ces prestataires dans les conditions du
                  titre
                  <em> 10. Cookies </em>
                  de la présente Politique.
                  <br />
                </p>
                <h3 style={{marginTop:"10px"}}>
                  7.4 Le prestataire de paiement
                  <br />
                </h3>
                <p>
                  Les Données de paiement de l’Abonnement sont directement
                  traitées par l’intermédiaire de paiement Stripe, dont le
                  détail du traitement est disponible au lien suivant :
                  <br />
                </p>
                <ul>
                  <li>
                    <a href="'https://stripe.com/fr/privacy#Securite-et-conservation-des-donnees'">
                      {" "}
                      https://stripe.com/fr/privacy#Securite-et-conservation-des-donnees
                    </a>
                    <br />
                  </li>
                </ul>
                <p> </p>
                <h3 tyle={{marginTop:"10px"}}>
                  7.5 Les autres destinataires
                  <br />
                </h3>
                <p>
                  Le cas échéant, le Responsable de traitement pourra
                  communiquer les Données collectées pour se conformer à des
                  obligations légales, réglementaires, judiciaires ou
                  administratives ou en cas de fusion,
                  vente, acquisition, cession, restructuration, dissolution,
                  changements de propriété ou de contrôle du Responsable de
                  traitement.
                  <br />
                </p>
                <p>
                  <span>
                    Le Responsable de traitement est également susceptible de
                    partager des Données pour faire valoir ses droits notamment
                    en cas d’éventuelles procédures judiciaires ou
                    administratives auxquelles il est parti.
                    <br />
                  </span>
                </p>
              </div>
              <h2>
                <strong>
                  8. La durée de conservation des Données
                  <br />
                </strong>
              </h2>
              <div style={{ marginLeft: "20px" }}>
                <p>
                  Les Données sont conservées dans la base active du Responsable
                  de traitement le temps nécessaire pour répondre aux finalités
                  pour lesquelles elles ont été collectées, soit toute la durée
                  de validité du Compte, du Profil ou de l’Abonnement
                  (c’est-à-dire avant toute résiliation) sans que la durée de
                  conservation ne puisse excéder trois (3) ans à compter de la
                  dernière interaction de l’Utilisateur avec le Site.
                  <br />
                </p>
                <p>
                  Cependant, le Responsable de traitement ne peut pas garantir
                  que l’ensemble des Données seront supprimées dans ce délai du
                  fait de limitations techniques.
                  <br />
                </p>
                <p>
                  Toutefois, le Responsable de traitement pourra archiver
                  certaines Données plus longtemps si nécessaire afin de faire
                  valoir ses droits devant toute juridiction et de se conformer
                  à des obligations légales, réglementaires, judiciaires ou
                  administratives.
                  <br />
                </p>
              </div>
              <h2>
                <strong>
                  9. Les droits des Utilisateurs
                  <br />
                </strong>
              </h2>
              <div style={{ marginLeft: "20px" }}>
                <p>
                  Conformément à la loi informatique et liberté et au RGPD,
                  l’Utilisateur bénéficie d’un droit :
                  <br />
                </p>
                <div style={{marginLeft:"20px"}}>
                  <ul>
                    <li>
                      d’accès, de rectification, de mise à jour et d’effacement
                      des informations qui le concernent ;
                      <br />
                    </li>
                  </ul>
                  <ul>
                    <li>
                      d’opposition au traitement des Données à caractère personnel
                      le concernant pour des motifs légitimes, sans motifs et sans
                      frais, à ce que ces Données soient utilisées à des fins de
                      prospection commerciale ;
                      <br />
                    </li>
                  </ul>
                  <ul>
                    <li>
                      d’interrogation du responsable de traitement des Données le
                      concernant ;
                      <br />
                    </li>
                  </ul>
                  <ul>
                    <li>
                      de définition des directives relatives au sort de ses
                      Données personnelles après sa mort ;
                      <br />
                    </li>
                  </ul>
                  <ul>
                    <li>
                      de portabilité des Données d’un système automatisé à un
                      autre, sans que le Responsable de traitement ne puisse y
                      faire obstacle ;
                      <br />
                    </li>
                  </ul>
                  <ul>
                    <li>
                      d’information par notification en cas de faille de sécurité
                      et d’accès illicite à ses informations ;
                      <br />
                    </li>
                  </ul>
                  <ul>
                    <li>
                      de retrait de son consentement à tout moment au traitement
                      de Données ;
                      <br />
                    </li>
                  </ul>
                  <ul>
                    <li>
                      d’introduire une réclamation auprès d’une autorité de
                      contrôle en cas d’une violation par Responsable de
                      traitement de ces droits.
                      <br />
                    </li>
                  </ul>
                </div>
                <p>
                  L’utilisateur peut exercer ces droits ci-décrits en
                  s’adressant au Responsable de traitement :
                  <br />
                </p>
                <div style={{marginLeft:"20px"}}>
                  <ul>
                    <li>
                      par email à l’adresse électronique :
                      aide.contact@eliteberbere.fr ; ou
                      <br />
                    </li>
                  </ul>
                  <ul>
                    <li>
                      par courrier à l’adresse postale : ELITE BERBERE, 40 B rue
                      de Dantzig, 75015, Paris (en joignant impérativement à la
                      demande une copie d’une pièce d’identité).
                      <br />
                    </li>
                  </ul>
                </div>
                <p>
                  Le Responsable de traitement fait héberger le Site auprès du
                  prestataire spécialisé OVH dont les informations sont
                  contenues dans les Mentions Légales.
                  <br />
                </p>
              </div>
              <h2>
                <strong id={'cookie'}>10. Cookies </strong>
              </h2>
              <div style={{ marginLeft: "20px" }}>
                <h3 style={{marginTop:"10px"}}>
                  10.1 Définition
                  <br/>
                </h3>
                <p>
                  Un
                  <em> cookie </em>
                  (ou « traceur ») est un petit fichier informatique, déposé et
                  lu lors de la consultation d’un site internet. Le terme
                  <em> cookie </em>
                  recouvre par exemple les
                  <em> cookies </em>
                  http, les
                  <em> cookies </em>
                  flash, le résultat du calcul d’empreinte, les pixels
                  invisibles ou tout autre identifiant généré par un logiciel ou
                  un système d’exploitation, par exemple.
                  <br />
                </p>
                <h3 style={{marginTop:"10px"}}>
                  10.2 Cookies déposés sur le Site
                  <br/>
                </h3>
                <p>
                  <span>
                    Les cookies nécessaires à l’utilisation du Site permettent
                    de vous offrir le Service à travers le Site et pour utiliser
                    certaines de ses fonctionnalités, telles que l’accès au
                    Compte.
                    <br />
                  </span>
                </p>
                <p>
                  <span>Les prestataires publicitaires et d’analyse </span>
                  <span>
                    utilisent des cookies sur le Site afin de permettre l’analyse
                    de l’utilisation faite du Site par les Utilisateurs, de
                    compiler des rapports d’activité du Site, de fournir
                    d’autres services relatifs à l’activité du Site et de
                    présenter aux utilisateurs des annonces commerciales
                    ciblées.
                    <br />
                  </span>
                </p>
                <p>
                  <span>
                    Les cookies « réseaux sociaux » permette le partage de
                    contenu du Site sur des réseaux sociaux et également être
                    utilisés à des fins publicitaires.
                    <br />
                  </span>
                </p>
                <p> </p>
                <h3 style={{marginTop:"10px"}}>
                  10.3 Bloquer ou supprimer les cookies
                  <br/>
                </h3>
                <p>
                  <span>
                    L’utilisateur peut décider, à tout moment d’accepter ou de
                    refuser tout ou partie des
                  </span>
                  <em>
                    <span> cookies.</span>
                  </em>
                </p>
                <p>
                  <span>Cependant, le refus de tout ou partie des </span>
                  <em>
                    <span> cookies </span>
                  </em>
                  <span>
                    pourrait empêcher l’Utilisateur d’accéder à certaines
                    fonctionnalités du Site.
                    <br />
                  </span>
                </p>
                <p>
                  <span>Le cas échéant, le </span>
                  Responsable de traitement
                  ne pourra pas être tenu pour responsable pour les
                  conséquences liées au fonctionnement dégradé du Site
                  résultant de l’impossibilité pour le
                  Responsable de traitement
                  <span> d’enregistrer ou de consulter les </span>
                  <em>
                    <span> cookies </span>
                  </em>
                  <span>
                    nécessaires à son fonctionnement.
                    <br />
                  </span>
                </p>
                <p>
                  <span>
                    L’Utilisateur peut :
                    <br />
                  </span>
                </p>
                <div style={{marginLeft:"20px"}}>
                  <ul>
                    <li>
                      accepter les cookies : tous les cookies sont acceptés (par
                      défaut) ;
                      <br />
                    </li>
                  </ul>
                  <ul>
                    <li>
                      accepter seulement les cookies du site visité : les cookies
                      de tierces parties, d’un domaine extérieur à celui visité,
                      sont refusés ;
                      <br />
                    </li>
                  </ul>
                  <ul>
                    <li>
                      ne jamais accepter les cookies : tous les cookies sont
                      refusés.
                      <br />
                    </li>
                  </ul>
                </div>
                <p>
                  Pour gérer ses cookies, l’Utilisateur doit se reporter aux
                  rubriques concernées de son navigateur Internet, notamment :
                  <br />
                </p>
                <div style={{marginLeft:"20px"}}>
                  <ul>
                    <li>
                    <span>
                      Microsoft Internet Explorer
                      <br />
                      <a href="'http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies'">
                        {" "}
                        http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies
                      </a>
                      <br />
                    </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                    <span>
                      Microsoft Edge
                      <br />
                      <a href="'https://privacy.microsoft.com/fr-fr/windows-10-microsoft-edge-and-privacy'">
                        {" "}
                        https://privacy.microsoft.com/fr-fr/windows-10-microsoft-edge-and-privacy
                      </a>
                      <br />
                    </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                    <span>
                      Apple Safari
                      <br />
                      <a href="' http://docs.info.apple.com/article.html?path=Safari/3.0/fr/9277.html'">
                        {" "}
                        http://docs.info.apple.com/article.html?path=Safari/3.0/fr/9277.html
                      </a>
                      <br />
                    </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                    <span>
                      Google Chrome
                      <br />
                      <a href="'http://support.google.com/chrome/bin/answer.py?hl=fr&amp;hlrm=en&amp;answer=95647'">
                        {" "}
                        http://support.google.com/chrome/bin/answer.py?hl=fr&amp;hlrm=en&amp;answer=95647
                      </a>
                      <br />
                    </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                    <span>
                      Mozilla Firefox
                      <br />
                      <a href="'https://support.mozilla.org/fr/kb/activer-desactiver-cookies-preferences'">
                        {" "}
                        https://support.mozilla.org/fr/kb/activer-desactiver-cookies-preferences
                      </a>
                      <br />
                    </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                    <span>
                      Opera
                      <br />
                      <a href="'http://help.opera.com/Windows/10.20/fr/cookies.html'">
                        {" "}
                        http://help.opera.com/Windows/10.20/fr/cookies.html
                      </a>
                      <br />
                    </span>
                    </li>
                  </ul>
                </div>

                <strong>
                  <span>« Do not track »</span>
                </strong>
                <p>
                  <span>
                    L’Utilisateur peut paramétrer son navigateur afin que
                    celui-ci envoie un code indiquant aux sites Web qu’il ne
                    souhaite pas être suivi (option « Do not track »), notamment
                    :
                    <br />
                  </span>
                </p>
                <div style={{marginLeft:"20px"}}>
                  <ul>
                    <li>
                    <span>
                      Microsoft Internet Explorer
                      <br />
                      <a href="'http://windows.microsoft.com/fr-fr/internet-explorer/use-tracking-protection#ie'">
                        {" "}
                        http://windows.microsoft.com/fr-fr/internet-explorer/use-tracking-protection#ie
                      </a>
                      = ie-11
                      <br />
                    </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                    <span>
                      Microsoft Edge
                      <br />
                      <a href="'https://privacy.microsoft.com/fr-fr/windows-10-microsoft-edge-and-privacy'">
                        {" "}
                        https://privacy.microsoft.com/fr-fr/windows-10-microsoft-edge-and-privacy
                      </a>
                      <br />
                    </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                    <span>
                      Apple Safari
                      <br />
                      <a href="'http://support.apple.com/kb/PH11952'">
                        {" "}
                        http://support.apple.com/kb/PH11952
                      </a>
                      <br />
                    </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                    <span>
                      Google Chrome
                      <br />
                      <a href="'https://support.google.com/chrome/answer/114836'">
                        {" "}
                        https://support.google.com/chrome/answer/114836
                      </a>
                      <br />
                    </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                    <span>
                      Mozilla Firefox
                      <br />
                      <a href="'https://support.mozilla.org/fr/kb/comment-activer-option-ne-pas-pister'">
                        {" "}
                        https://support.mozilla.org/fr/kb/comment-activer-option-ne-pas-pister
                      </a>
                      <br />
                    </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                    <span>
                      Opera
                      <br />
                      <a href="' http://help.opera.com/Windows/12.10/fr/notrack.html'">
                        {" "}
                        http://help.opera.com/Windows/12.10/fr/notrack.html
                      </a>
                      <br />
                    </span>
                    </li>
                  </ul>
                </div>

                <strong>
                  <span>
                    Cookies de mesure d’audience et cookies analytiques
                  </span>
                </strong>
                <p>
                  <span>
                    Les cookies analytiques, comme ceux déposés sur le Site,
                    permettent d’obtenir des statistiques anonymes de
                    fréquentation afin d’optimiser le Site et de détecter
                    d’éventuels dysfonctionnements.
                    <br />
                  </span>
                </p>
                <p>
                  <span>
                    Si l’Utilisateur ne souhaite pas que le Site enregistre des
                    cookies dans son navigateur à des fins de mesures
                    d’audience, il peut cliquer sur les liens suivants afin de
                    trouver des informations supplémentaires :
                    <br />
                  </span>
                </p>
                <div style={{marginLeft:"20px"}}>

                  <ul>
                    <li>
                    <span>
                      Cookies Google Analytics
                      <br />
                      https ://tools.google.com/dlpage/gaoptout
                      <br />
                    </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                    <span>
                      Cookies ATInternet
                      <br />
                      <a href="'https://www.atinternet.com/societe/protection-des-donnees/mettre-a-jour-mes-preferences/'">
                        {" "}
                        https://www.atinternet.com/societe/protection-des-donnees/mettre-a-jour-mes-preferences/
                      </a>
                      <br />
                    </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                    <span>
                      Cookies Amplitude
                      <br />
                      <a href="'https://amplitude.com/privacy'">
                        {" "}
                        https://amplitude.com/privacy
                      </a>
                      <br />
                    </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                    <span>
                      Cookies Charbeat
                      <br />
                      <a href="'https://chartbeat.com/privacy/'">
                        {" "}
                        https://chartbeat.com/privacy/
                      </a>
                      <br />
                    </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                    <span>
                      Cookie Weborama
                      <br />
                      <a href="'https://weborama.com/fr/respect-de-la-vie-privee/'">
                        {" "}
                        https://weborama.com/fr/respect-de-la-vie-privee/
                      </a>
                      <br />
                    </span>
                    </li>
                  </ul>
                </div>
                <p> </p>
                <strong>
                  <span>Cookies « Réseaux sociaux »</span>
                </strong>
                <p>
                  Le Site est susceptible d’inclure des fonctionnalités de
                  réseaux sociaux. Ces fonctionnalités peuvent générer des
                  <em> cookies </em>
                  pour en permettre le fonctionnement et de recueillir l’adresse
                  IP et la navigation de l’utilisateur. Ces fonctionnalités sont
                  régies par la politique de confidentialité de la société les
                  fournissant, notamment :
                  <br />
                </p>
                <div style={{marginLeft:"20px"}}>
                  <ul>
                    <li>
                    <span>
                      Pour gérer les Données sur Facebook et Instagram
                      <br />
                      <a href="'https://www.facebook.com/help/cookies/'">
                        {" "}
                        https://www.facebook.com/help/cookies/
                      </a>
                      <br />
                    </span>
                    </li>
                  </ul>
                  <p> </p>
                  <ul>
                    <li>
                    <span>
                      Pour gérer les Données sur Google
                      <br />
                      <a href="'https://policies.google.com/technologies/cookies?hl=en'">
                        {" "}
                        https://policies.google.com/technologies/cookies?hl=en
                      </a>
                      <br />
                    </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                    <span>
                      Pour gérer les Données sur Twitter
                      <br />
                      <a href="'https://help.twitter.com/fr/rules-and-policies/twitter-cookies'">
                        {" "}
                        https://help.twitter.com/fr/rules-and-policies/twitter-cookies
                      </a>
                      <br />
                    </span>
                    </li>
                  </ul>
                </div>
                <p>
                  <span>
                    Désactiver ces cookies empêche toute interaction avec les
                    réseaux sociaux.
                    <br />
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
