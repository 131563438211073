import React, { Component } from "react";
import { debounce } from "lodash-es";
import EliteParagraph from "../../../Components/Uikit/Typography/Paragraph";
import CustomTextField from "../../Uikit/TextFields/TextFields";
import NavigationFrontBack from "../../Uikit/Wizard/NavigationWizard/NavigationFB";
import iconLock from "../../../assets/images/icon-lock.svg";
import iconUser from "../../../assets/images/icon-user.svg";
import CustomCheckbox from "../../Uikit/Checkbox/Checkbox";
import EliteErrorText from "../../Uikit/Typography/ErrorText";
import UserService from "../../../Services/ApiServices/UserService";

class SignUpStep4 extends Component {
  updateProfile(prop, value) {
    this.props.onChange({ [prop]: value });
  }

  checkEmail = debounce(async () => {
    let email = this.props.profile.email;

    const exists = await UserService.checkIfEmailExists(email);
    this.updateProfile("email_exists", exists);
  }, 500);

  renderError(key) {
    if (!this.props.showErrors) return;

    if (key === "accepted_terms" && !this.props.profile.accepted_terms) {
      return <EliteErrorText text="Champ obligatoire" />;
    } else if (
      key === "accepted_responsibility" &&
      !this.props.profile.accepted_responsibility
    ) {
      return <EliteErrorText text="Champ obligatoire" />;
    } else if (key === "email") {
      if (!this.props.profile.email) {
        return <EliteErrorText text="Champ obligatoire" />;
      } else if (this.props.profile.email_exists) {
        return <EliteErrorText text="Email déjà utilisé." />;
      }
    } else if (key === "password") {
      if (!this.props.profile.password) {
        return <EliteErrorText text="Champ obligatoire" />;
      } else if (
        !this.props.profile.password.match(
          /(?=.{8,})(?=.*?[^\w\s])(?=.*?[0-9])(?=.*?[A-Z]).*?[a-z].*/g,
        )
      ) {
        return (
          <EliteErrorText text=" Votre mot de passe doit contenir 8 caractères incluant  lettre capitale, une minuscule, un caractère spécial ainsi qu’un nombre" />
        );
      }
    } else if (key === "password_confirmation") {
      if (!this.props.profile.password_confirmation) {
        return (
          <EliteErrorText text="Veuillez saisir  à nouveau le mot de passe" />
        );
      } else if (
        this.props.profile.password_confirmation !== this.props.profile.password
      ) {
        return (
          <EliteErrorText text="Veuillez  renseigner 2 mots de passe identiques" />
        );
      }
    }
  }

  render() {
    return (
      <>
          <div style={{height:"1vh"}}/>
          <EliteParagraph
          margin={"32px 0 8px 0"}
          uppercase
          text={"Je créé(e) mes identifiants"}
          textColor={"#fff"}
          textAlign={"left"}
        />

        <CustomTextField
          type="email"
          isInvalid={
            this.props.showErrors &&
            (!this.props.profile.email || this.props.profile.email_exist)
          }
          borderColor={"#FFFFFF"}
          classes={`pb-1`}
          addOn={iconUser}
          isMobile
          border
          placeholder={"Adresse mail"}
          value={this.props.profile.email}
          onChange={(value) => {
            this.updateProfile("email", value);
            this.checkEmail(value);
          }}
          errorText={this.renderError("email")}
          isSubscription
          autoComplete={"chrome-off"}
        />

        <CustomTextField
          type="password"
          isInvalid={
            this.props.showErrors &&
            (!this.props.profile.password ||
              !this.props.profile.password.match(
                /(?=.{8,})(?=.*?[^\w\s])(?=.*?[0-9])(?=.*?[A-Z]).*?[a-z].*/g,
              ))
          }
          classes={"py-1"}
          addOn={iconLock}
          isMobile
          borderColor={"#FFFFFF"}
          placeholder={"Mot de passe"}
          value={this.props.profile.password}
          onChange={(value) => this.updateProfile("password", value)}
          errorText={this.renderError("password")}
          isSubscription
          autoComplete={"chrome-off"}
        />
        <CustomTextField
          type="password"
          isInvalid={
            this.props.showErrors &&
            (!this.props.profile.password_confirmation ||
              this.props.profile.password_confirmation !==
                this.props.profile.password)
          }
          classes={"py-1"}
          addOn={iconLock}
          isMobile
          placeholder={"Confirmation mot de passe"}
          value={this.props.profile.password_confirmation}
          onChange={(value) =>
            this.updateProfile("password_confirmation", value)
          }
          errorText={this.renderError("password_confirmation")}
          isSubscription
          autoComplete={"chrome-off"}
        />
        <div style={{height:"1chx"}} />
        <CustomCheckbox
            fontSize={"12px"}
            label={
            "Je certifie être majeur(e) et accepter les Conditions générales"
          }
          value={ this.props.profile.accepted_terms}
          onChange={ (value) => this.updateProfile('accepted_terms', value) }
        />
        {this.renderError("accepted_terms")}
          <div style={{height:"2vh"}} />
        <CustomCheckbox
            fontSize={"12px"}
          label={
            "J’accepte que mes données sensibles soient traitées par Élite Berbère"
          }
          value={this.props.profile.accepted_responsibility}
          onChange={(value) =>
            this.updateProfile("accepted_responsibility", value)
          }
        />
        {this.renderError("accepted_responsibility")}

          <div style={{height:"2vh"}} />

        {/* mandadory in every step */}
        <NavigationFrontBack
          profile={this.props.profile}
          isMobile={this.props.isMobile}
          wizardProps={this.props}
          onShowErrors={this.props.onShowErrors}
          isStepOne={false}
        />
      </>
    );
  }
}

export default SignUpStep4;
