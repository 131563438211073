import React, { Component } from "react";
import EliteParagraph from "../Typography/Paragraph";
import "./TagsStyles.css";

export class Tags extends Component {
  render() {
    return (
      <div className={"tags"}>
        <div className="tagsContainer d-flex align-items-center">
          <div
            className={"iconTag"}
            style={{ backgroundImage: `url(${this.props.icon})` }} />
          <div className="d-flex flex-column textTagContainer" style={{width:'70px'}}>
            <div className="smallTextTag">
              <EliteParagraph
              fontWeight={'normal'}
                textAlign={"left"}
                fontSize={"8px"}
                textColor={"#6c95e7"}
                text={this.props.smallText}
                uppercase
              />
            </div>
            <div className="textTag">
              <EliteParagraph
                margin={"-8px 0 0"}
                textAlign={"left"}
                fontSize={"12px"}
                text={this.props.text}
                fontWeight={'500'}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Tags;
