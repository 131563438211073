import React, { useState } from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import TitleOverTitle from "../../Components/Uikit/TitleOverTitle/TitleOverTitle";
import ResponsiveService from "../../Services/ResponsiveService";
import {ReactSVG} from "react-svg";
import backArrow from "../../assets/images/icon-arrow-back.svg";
import { useHistory } from "react-router-dom";

export function MentionsLegales() {
  const [isMobile, setIsMobile] = useState({
    isMobile: ResponsiveService.isMobile(),
  });
    const history = useHistory();


    const handleResize = (e) => {
    e.preventDefault();
    if (isMobile !== ResponsiveService.isMobile()) {
      setIsMobile({ isMobile: ResponsiveService.isMobile() });
    }
  };
  window.addEventListener("resize", handleResize.bind(this));
  return (
      <>
          <Header/>
          <div className="container">
              <div className="row mesLikesContainer">
                  <div className="col-12">
                      <div className="col-4">
                          <div className={"back"}>
                              <div onClick={() => history.goBack()}>
                                  <ReactSVG src={backArrow} /> Retour
                              </div>
                          </div>
                      </div>
                      <TitleOverTitle
                          overTitleMarginTop={isMobile.isMobile ? "-30px" : "-25"}
                          backTitleSize={isMobile.isMobile ? "36px" : "60px"}
                          frontTitleSize={isMobile.isMobile ? "24px" : "32px"}
                          title={"Mentions légales"}
                      />
                      <div className={"pt-3"}>
                          <h2>ÉDITEUR</h2>
                          <div style={{marginLeft:"20px"}}>
                              <p>
                                  Le site internet www.eliteberbere.fr est édité par la société
                                  ELITE BERBERE, société par actions simplifiée au capital social
                                  de 1000 euros, située au 40 B rue de Dantzig, 75015, Paris.
                              </p>
                              <p>Numéro d’immatriculation au RCS de Paris : 879238194</p>
                              <p>Numéro de TVA intracommunautaire : FR93879238194</p>
                              <p>Courrier électronique : aide.contact@eliteberbere.fr </p>
                              <br/>
                          </div>

                          <h2>HÉBERGEUR</h2>
                          <div style={{marginLeft:"20px"}}>
                              <p>
                                  Le site Internet www.eliteberbere.fr est hébergé par le
                                  prestataire OVH, société par actions simplifiée au capital de 10
                                  069 020 €, ayant son siège social au 2 rue Kellermann, 59100
                                  Roubaix, France.
                              </p>
                              <p>
                                  Numéro d’immatriculation au RCS de Lille : 424 761 419 00045
                              </p>
                              <p>Numéro de TVA intracommunautaire : FR 22 424 761 419</p>
                          </div>

                      </div>
                  </div>

              </div>
          </div>
          <Footer/>

      </>
  );
}
