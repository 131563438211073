import React from "react";
import DerniereConnexion from "../../../Components/DerniereConnexions/DerniereConnexion";
import Footer from "../../../Components/Footer/Footer";
import FooterMenu from "../../../Components/FooterMenu/FooterMenu";
import ConnectedHeaderMobile from "../../../Components/Header/ConnectedHeader/ConnectedHeaderMobile/ConnectedHeaderMobile";
import MatchMakingDuJour from "../../../Components/MatchMakingDuJour/MatchMakingDuJour";
import EliteParagraph from "../../../Components/Uikit/Typography/Paragraph";
import "./ConnectedHomeMobileStyle.css";

export function ConnectedHomeMobile() {
  return (
    <>
      <ConnectedHeaderMobile />
      <div className="promoPhotoContainer d-flex align-items-center">
        <div className="promoText">
          <EliteParagraph
            text={"Découvrez des profils exigeants"}
            textColor={"#4e4e4e"}
            fontSize={"14px"}
            fontWeight={500}
            textAlign={"left"}
            margin={0}
          />
        </div>
      </div>
      <div className="container py-1 containerConnectedHome">
      <div className="row">
        <MatchMakingDuJour isMobile />
        <DerniereConnexion isMobile />
        </div>
      </div>
      <Footer isLogged />
      <FooterMenu />
    </>
  );
}

export default ConnectedHomeMobile;
