import React, { useState } from "react";
import StepWizard from "react-step-wizard";
import SubscriptionContainer from "../../Banner/SubscriptionChooser/SubscriptionContainer";
import SignUpStep2 from "../../SignUp/SignUpSteps/Step2";
import SignUpStep3 from "../../SignUp/SignUpSteps/Step3";
import SignUpStep4 from "../../SignUp/SignUpSteps/Step4";
import WizardNav from "./NavigationWizard/NavigationWizard";

function CustomWizard(props) {
  const [showErrors, setShowErrors] = useState(false);
  const [profile, setProfile] = useState({ ...props.profile })


  function updateProfile(update) {
    setProfile({ ...profile, ...update })
  }


  function journeyRender(journey) {
    let initial = profile.looking_for && profile.gender ? 2 : 1;

    switch (journey) {
      case "signUpDesktop":

        return (
          <div
            className={ `wizardContainer ${
              props.isMobile ? "wizardContainerMobile" : ""
            }` }>
            <StepWizard initialStep={ initial } nav={ <WizardNav/> }>

              <SubscriptionContainer profile={ profile }
                                     isMobile={ props.isMobile }
                                     showErrors={ showErrors }
                                     onChange={ updateProfile }
                                     onShowErrors={ setShowErrors }/>

              <SignUpStep2 profile={ profile }
                           isMobile={ props.isMobile }
                           showErrors={ showErrors }
                           onChange={ updateProfile }
                           onShowErrors={ setShowErrors }/>

              <SignUpStep3 profile={ profile }
                           isMobile={ props.isMobile }
                           showErrors={ showErrors }
                           onChange={ updateProfile }
                           onShowErrors={ setShowErrors }/>

              <SignUpStep4 profile={ profile }
                           isMobile={ props.isMobile }
                           showErrors={ showErrors }
                           onChange={ updateProfile }
                           onShowErrors={ setShowErrors }
              />

            </StepWizard>
          </div>
        );

      default:
        break;
    }
  }

  return <>{ journeyRender(props.journey) }</>;
}
export default CustomWizard;
