import React, { Fragment, useEffect, useRef, useState } from "react";
import { get, map } from "lodash-es";
import { useCurrentUser } from "../../Hooks/AuthenticatedUser";
import moment from "moment";
import CustomTextArea from "../Uikit/TextArea/TextArea";
import EliteParagraph from "../Uikit/Typography/Paragraph";
import "./ChatStyles.css";
import { ReactSVG } from "react-svg";
import iconCamChat from "../../assets/images/icon-camera-chat.svg";
import iconSendChat from "../../assets/images/icon-send.svg";
import { toast } from "react-toastify";
import ResponsiveService from "../../Services/ResponsiveService";

export function ChatBody({ isMobile, messages, onSendMessage }) {
  const chatBottom = useRef();
  const [currentMessage, setCurrentMessage] = useState();
  const { user } = useCurrentUser();
  const sendImage = useRef(null);
  const defaultFirstMessageContent = "Nouveau match"

  function isCurrentUser(sender) {
    return sender.id === user.id;
  }

  function scrollToBottom() {
    chatBottom.current?.scrollIntoView({ behavior: "smooth" });
  }

  const todayMessages = get(messages, "Aujourd'hui", []).length;
  useEffect(scrollToBottom, [todayMessages]);

  async function onSend(e) {
    e.stopPropagation();
    if (typeof currentMessage !== "undefined" && currentMessage.toString().trim() !== "" ) {
      await onSendMessage(currentMessage.toString().trim() || "", []);
      setCurrentMessage("");
      scrollToBottom();
    }
  }

  async function submitIfEnter(e) {
    if (!e.shiftKey && e.code === "Enter") {
      e.preventDefault();
      onSend(e);
    }
  }

  function renderFile(file) {
    return (
      <div
        key={file.id}
        className="messageFile"
      >
        <img src={file.url} alt={'chat pic'}/>
      </div>
    );
  }

  function renderMessage() {
    return (message) => {
      let timeAgo = moment
        .duration(moment(message.createdAt).subtract(1, 'seconds').diff())
        .humanize(true);
      return message.content !== defaultFirstMessageContent ? (
        <li key={message.id}>
          <div className={` d-flex flex-column`}>
            <div
              className={`chatMessageItem ${
                isCurrentUser(message.sender) ? "myMessage" : "receivedMessage"
              }`}>
              <EliteParagraph
                text={message.content}
                margin={0}
                textAlign={"left"}
                fontSize={"18px"}
                lineHeight={"1.25"}
                textColor={"#4e4e4e"}
                fontWeight={400}
              />
              {map(message.files, renderFile)}
            </div>
            <div className="receivedDate">
              <EliteParagraph
                margin={"0 8px 0 8px"}
                textAlign={isCurrentUser(message.sender) ? "right" : "left"}
                text={timeAgo}
                fontSize={ResponsiveService.isMobile() ? "8px" : "9px"}
                fontStyle={"italic"}
                textColor={"#4e4e4e"}
              />
            </div>
          </div>
        </li>
      ) : '';
    };
  }

  function renderMessages(messages, title) {
    if (messages.length) {
      return (
        <Fragment key={title}>
          <li>
            <h4 className={"labelDay"}>{title}</h4>
          </li>
          {map(messages, renderMessage())}
        </Fragment>
      );
    }
  }

  function showFile(event) {
    map(event.target.files, (file) => {
      if(['image/jpeg', 'image/png', 'image/jpg'].indexOf(file.type) === -1) {
        toast.error(`${ file.name } n'est pas une image`);
        return;
      }

      if (file.size > 5 * 1024 * 1024) {
        toast.error(`${ file.name } est une image trop grosse. La taille max est de 5 mB`);
        return;
      }

      onSendMessage("", [file]);
    });
    event.target.value = '';
  }

  return (
    <>
      <div className={`chatBodyContainer ${isMobile ? "mobileChat" : ""}`}>
        <ul>{map(messages, renderMessages)}</ul>
        <div className={"chatBottomRef"} ref={chatBottom} />
      </div>
      <div
        className={`d-flex w-100 mb-8 justify-content-between align-items-center chatSendMessageContainer ${
          isMobile && "fixedToBottomChat"
        }`}>
        <div className="chatCam p-8">
          <ReactSVG src={iconCamChat} style={{marginTop:"-6px"}} onClick={() => sendImage.current.click()} />
          <input
            ref={sendImage}
            type="file"
            multiple
            accept="image/x-png,image/jpeg"
            onChange={(e) => showFile(e)}
          />
        </div>
        <div className="chatInput">
          <CustomTextArea
            maxCharacters={""}
            value={currentMessage}
            rows={1}
            placeholder={"écrivez votre message"}
            onKeyDown={submitIfEnter}
            onChange={setCurrentMessage}
          />
        </div>
        <div className="chatSendButton" onClick={onSend}>
          <ReactSVG src={iconSendChat} />
        </div>
      </div>
      <div style={{height: "30p"}} />
    </>
  );
}

export default ChatBody;
