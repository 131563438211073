import React, { useEffect } from "react";
import "./LoginMobileStyle.css";
import CustomLoginMobile from "../../../Components/LoginMobile/LoginMobile";
import fullLogoColor from "../../../assets/images/logo-full-colo.png";
import BigTitle from "../../../Components/Uikit/Typography/BigTitle";
import { Actions } from "../Actions";
import { handleError, handleErrorByStatus } from "../../../Services/ErrorHandler";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import UserService from "../../../Services/ApiServices/UserService";

async function confirmEmail(token) {
  try {
    await UserService.confirmEmail(token)
    toast.success('Inscription validée. Vous pouvez vous connecter à votre compte.')
    setTimeout(() => window.location.href = '/login', 500);
  } catch (e) {
    handleError(e);
  }
}

function LoginMobile() {
  const history = useHistory();
  const { token } = useParams()

  useEffect(() => {
    if (history.location.pathname.match(/^\/confirm-email/)) {
      confirmEmail(token);
    }
  }, [history.location.pathname, token])

  async function onLogin(email, password) {
    try {
      await Actions.logIn(email, password)
      window.location.href = '/home'
    } catch (err) {
      if (err.response) {
        handleErrorByStatus(err.response.status, err)
      } else {
        console.error(err);
      }
    }
  }

  return (
    <div
      className={
        "LoginContainer container-fluid d-flex flex-column align-items-center"
      }>
      <div
        className={ "LoginImageContainer" }
        onClick={ () => history.push("/") }>
        <img src={ fullLogoColor } alt={ "logo full color" }/>
      </div>
      <div className={ "LoginTitlesContainer" }>
        <BigTitle
          fontSize={ "34px" }
          titleOpacity={ 0.1 }
          text={ "Connexion" }/>
        <div className={ "LoginOverTitle mb-2" }>
          <BigTitle
            fontFamily={ "Roboto" }
            fontSize={ "24px" }
            fontWeight={ "bold" }
            text={ "Connexion" }/>
        </div>
        <div style={{height:"15px"}}/>
        <CustomLoginMobile onLogin={ onLogin }/>
      </div>
    </div>
  );
}

export default LoginMobile;
