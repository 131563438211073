import React, { Component } from "react";
import EliteParagraph from "../../Uikit/Typography/Paragraph";
import NavigationFrontBack from "../../Uikit/Wizard/NavigationWizard/NavigationFB";
import ContainerSubsciptionChooser from "./SubscriptionChooser";
import EliteErrorText from "../../Uikit/Typography/ErrorText";

class SubscriptionContainer extends Component {
  constructor(props) {
    super(props);
    this.updateProfile = this.updateProfile.bind(this);
  }

  updateProfile(prop, value) {
    this.props.onChange({ [prop]: value });
  }

  renderError(key) {
    if (!this.props.showErrors) return;

    if (key === 'looking_for' && !this.props.profile.looking_for) {
      return <EliteErrorText text='Champ obligatoire'/>
    } else if (key === 'gender' && !this.props.profile.gender) {
      return <EliteErrorText text='Champ obligatoire'/>
    }
  }


  render() {
    return (
      <>
          <div style={{height : "30px"}} className={`text-md- ${ !this.props.isMobile && "d-none"}`}/>
          <EliteParagraph
            fontSize={"14px"}
          hide={ !this.props.isMobile }
          uppercase
          textAlign={ "left" }
          textColor={ "#fff" }
          text={ "Je suis …" }
        />
        <ContainerSubsciptionChooser
          title={ !this.props.isMobile && "VOUS ÊTES" }
          name={ "etes" }
          selected={ this.props.profile.gender }
          onChange={ (value) => this.updateProfile('gender', value) }
        />
        { this.renderError('gender') }

          <div style={{height : "18px"}} className={`text-md- ${ !this.props.isMobile && "d-none"}`}/>
          <EliteParagraph
            fontSize={"14px"}
          hide={ !this.props.isMobile }
          uppercase
          textAlign={ "left" }
          textColor={ "#fff" }
          text={ "Je recherche …" }/>
        <ContainerSubsciptionChooser
          title={ !this.props.isMobile && "VOUS CHERCHEZ" }
          name={ "cherchez" }
          selected={ this.props.profile.looking_for }
          onChange={ (value) => this.updateProfile('looking_for', value) }
        />
        { this.renderError('looking_for') }
          <div style={{height : "30px"}}/>
        {/* mandadory in every step */ }
        { !this.props.onBanner && (
          <NavigationFrontBack
              isStepOne={true}
            profile={ this.props.profile }
            onShowErrors={ this.props.onShowErrors }
            isMobile={ this.props.isMobile }
            wizardProps={ this.props }/>
        ) }
      </>
    );
  }
}
export default SubscriptionContainer;
