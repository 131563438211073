import React, { Component } from "react";
import './TypographyStyles.css';
//title
class EliteSmallSmallTitle extends Component {
  render() {
    return (
      <>
        <h3
            style={{
              textAlign: this.props.textAlign,
            }}
            className={"eliteSmallSmallTitle mt-4"}>
            {this.props.text}
        </h3>
      </>
    );
  }
}
export default EliteSmallSmallTitle;


