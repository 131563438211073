import React, { Component } from 'react'
import CustomButton from '../Button/CustomButton';
import iconDrop from "../../../assets/images/icon-dropdown.svg";

export class MiniDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = { isOpen: false, label: this.props.label };
        this.handleDropdown.bind(this);
        this.handleClick.bind(this);
      }

      handleDropdown() {
        this.setState({ isOpen: !this.state.isOpen });
      }

      handleClick(e) {
        this.setState({ ...this.state, label: e.target.outerText, isOpen: false });
      }

      render() {
        return (
          <div className={"mini position-relative"}>
            <CustomButton
              isOpen={this.state.isOpen}
              onClick={() => this.handleDropdown()}
              dropdown
              label={this.state.label}
              endIcon={iconDrop}
              height={"32px"}
              relative
              top0
              fontWeight={400}/>
            <ul
              className={`dropdown-menu ${
                this.state.isOpen ? "d-block" : "d-none "
              }`} style={{right: this.props.mobile ? 0 : 'auto', width: '200px'}}>
              {this.props.dropdownItems.map((item,i)=>(<li key={i} onClick={(e) => {
                item.action();
                this.handleClick(e)
              }}>
                <span className={"dropdown-item"}>{item.label}</span>
              </li>))}
            </ul>
          </div>
        );
      }
}

export default MiniDropdown
