import React, { useEffect, useState } from "react";
import iconCheck from "../../../assets/images/icon-check-color.svg";
import { ReactSVG } from "react-svg";
import "./MultipleChoiceModalStyles.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
export function MultipleChoiseModal({ onChange, ...props }) {
  const [selected, setSelected] = useState(props.value || []);

  useEffect(() => {
    if (onChange) {
      onChange(selected);
    }
    // eslint-disable-next-line
  }, [selected]);

  function isSelected(value) {
    return selected.includes(value);
  }

  function onSelect(choice) {
    if (isSelected(choice.value)) {
      setSelected(selected.filter((val) => val !== choice.value));
    } else {
      setSelected([...selected, choice.value]);
    }
  }

  function renderList() {
    return props.choices.map((choice) => (
      <div
        className={`d-flex justify-content-between itemChoice ${
          isSelected(choice.value) ? "isSelected" : ""
        }`}
        key={choice.value}
        onClick={() => onSelect(choice)}>
        {choice.label}{" "}
        {<ReactSVG src={isSelected(choice.value) ? iconCheck : ""} />}
      </div>
    ));
  } 

  return (
    <PerfectScrollbar>
      <div
        className={`choicesContainer ${
          props.isMobile ? "mobile" : ""
        }`}>
        {renderList()}
      </div>
    </PerfectScrollbar>
  );
}

export default MultipleChoiseModal;
