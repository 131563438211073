import React, { useEffect, useRef } from "react";
import Footer from "../../Components/Footer/Footer";
import ConnectedHeader from "../../Components/Header/ConnectedHeader/ConnectedHeader";
import TitleOverTitle from "../../Components/Uikit/TitleOverTitle/TitleOverTitle";
import iconChatTtile from "../../assets/images/icon-chat-title.svg";
import ChatCardUser from "../../Components/Chat/ChatCardUser";
import "./MessagesStyle.css";
import ChooseConversation from "../../Components/Chat/ChooseConversation";
import Chat from "../../Components/Chat/Chat";
import EliteParagraph from "../../Components/Uikit/Typography/Paragraph";
import MiniDropdown from "../../Components/Uikit/Dropdown/MiniDropdown";
import { useMessages } from "../../Hooks/MessagesHooks";
import { map, isEmpty } from "lodash-es";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { EliteRouters } from "../../Navigation/Routers";
import { subscribe } from "../../Services/Mercure";
import { useCurrentUser } from "../../Hooks/AuthenticatedUser";
import { hasValidSubscription } from "../../Services/ApiServices/api.client";
import CustomModal from "../../Components/Uikit/Modal/Modal";
import Offers from "../../Components/Offers/Offers";

export function Messages({ isMobile }) {
  const history = useHistory();
  const { user } = useCurrentUser();
  const {
    sortedRooms,
    selectedRoom,
    setRoomId,
    updateRoom,
    getRooms,
    messages,
    onSendMessage,
    filterActions,
    setRoomLastReadAt
  } = useMessages();
  const { roomId } = useParams();
  const modalOffers = useRef();

  useEffect(() => {
    if (
      sortedRooms.length &&
      roomId &&
      (!selectedRoom || roomId !== selectedRoom.id.toString())
    ) {
      setRoomId(roomId);
    }
  }, [roomId, selectedRoom, setRoomId, sortedRooms.length]);

  useEffect(() => {
    if (user.id) {
      subscribe(`user-${user.id}-rooms`, updateRoom);
    }
    // eslint-disable-next-line
  }, [user.id]);

  useEffect(() => {
    if (roomId) { setRoomLastReadAt(roomId) }
    // eslint-disable-next-line
  }, [roomId, messages]);

  function goToProfile(id) {
    if (hasValidSubscription()) {
      history.push(
        generatePath(EliteRouters.visitedProfile.path, { id }),
      );
    } else {
      modalOffers.current.handleOpenModal();
    }
  }

  function showRooms() {
    return (
      <>
        {map(sortedRooms, (room) => (
          <div
            className="mt-1 mb-1 chatUserCardContainer"
            key={room.id}
            onClick={() => {
                  if (hasValidSubscription()) {
                    history.push(
                      generatePath(EliteRouters.messages.path, { roomId: room.id }),
                    );
                  }  else {
                    modalOffers.current.handleOpenModal();
                  }
            }}>
            <ChatCardUser
              room={room}
              isSelected={
                hasValidSubscription() &&
                selectedRoom &&
                room.id === selectedRoom.id
              }
              goToProfile={() => goToProfile(room.otherParticipant.id)}
            />
          </div>
        ))}
      </>
    );
  }

  return (
    <>
    <div style={{ height:"100vh", display: "flex", flexDirection: "column" }}>
      <ConnectedHeader />
      <div className={"container chatMaxContainer"}>
        <div className="row">
          <div className="col-1" />
          <div className="col-10">
            <div className="row">
              <div className="col-12">
                <TitleOverTitle
                  backTitleSize={isMobile && "29px"}
                  frontTitleSize={isMobile && "22px"}
                  title={"Messages"}
                  overTitleMarginTop={isMobile && "-25px"}
                  icon={iconChatTtile}
                />
              </div>
              <div className={`d-flex flex-row ${!isEmpty(sortedRooms) ? "col-5" : "d-none"}`}>
                <div className="row">
                  <div className="col-12 d-flex justify-content-end">
                    <div className="d-flex position-relative">
                      <EliteParagraph
                        customStyle={{
                          display: 'flex',
                          alignItems: 'center'
                        }}
                        margin={"0 8px 0 0"}
                        text={"Trier par : "}
                      />
                      <MiniDropdown
                        label={"date"}
                        dropdownItems={filterActions}
                      />
                    </div>
                  </div>
                  <div className="col-12 d-flex flex-column userChatColumn">
                    {showRooms()}
                  </div>
                </div>
                <div className={"chatLine"}/>
              </div>
              <div
                className={`${
                  !isEmpty(sortedRooms) ? "col-7 chatChatColumn" : "col-12"
                }`}>
                {selectedRoom ? (
                  <Chat
                    room={selectedRoom}
                    messages={messages}
                    onSendMessage={onSendMessage}
                    getRooms={getRooms}
                  />
                ) : (
                  <div className="d-flex justify-content-center h-100">
                    <ChooseConversation noRooms={isEmpty(sortedRooms)} />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-1">
          </div>
        </div>

        <CustomModal
          overlayClassName="Overlay"
          modalContent={<Offers isMobile={isMobile} closeModal={modalOffers} />}
          modalClass={isMobile ? "offersModalMobile" : "offersModal"}
          ref={modalOffers}
        />
      </div>
      <Footer isLogged />
    </div>
    </>
  );
}

export default Messages;
