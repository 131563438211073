import UserService from "../../../Services/ApiServices/UserService";

export const Actions = {
  logIn: async (email, password) => {
    try {
      await UserService.logIn(email, password)
      return true;
    } catch (e) {
      throw e
    }
  },
};
