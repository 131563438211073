import React, { useEffect, useState } from "react";
import "./ManagementStyles.css";
import { EliteRouters } from "../../Navigation/Routers";
import backArrow from "../../assets/images/icon-arrow-back.svg";
import paypalLogo from "../../assets/images/paypal.svg";
import stripeLogo from "../../assets/images/stripe.svg";
import UserService from "../../Services/ApiServices/UserService";
import { useLocation } from "react-router-dom";
import { Checkout } from "../Checkout/Checkout";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/esm/Spinner";
import ConnectedHeader from "../../Components/Header/ConnectedHeader/ConnectedHeader";
import DIAMANT from "../../assets/images/Diamant.jpeg";
import RUBIS from "../../assets/images/Rubis.jpeg";
import SAPHIR from "../../assets/images/Saphir.jpeg";
import STARTER from "../../assets/images/Starter.png";
import BOOSTER from "../../assets/images/Booster.png";
import CustomButton from "../../Components/Uikit/Button/CustomButton";
import { ReactSVG } from "react-svg";
import closeButton from "../../assets/images/btn-close.svg";

import jcb from "../../assets/images/payments/jcb.png";
import visa from "../../assets/images/payments/visa.png";
import amex from "../../assets/images/payments/amex.png";
import mastercard from "../../assets/images/payments/mastercard.png";

export const Management = (props) => {
  const location = useLocation();
  const [currentSub, setCurrentSub] = useState();
  const [cardList, setCardList] = useState();
  const [cardPopup, setCardPopup] = useState(false);
  const [subscriptionPopup, setSubscriptionPopup] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [cardSelected, setCardSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [popUpConfirm, setPopUpConfirm] = useState(false);
  const [isSpin, setSpin] = useState(false);
  //const [popUpPaypal, setPopUpPaypal] = useState(false);
  const [dots, setDots] = useState(false);
  const [popUpModifyPlan, setPopUpModifyPlan] = useState(false);

  let plans = null;
  let links = null;
  let result = null;

  useEffect(() => {
    setCurrentSub(location.state.currentSubscription);
    /*if (!cardList) {
        const cardL = await UserService.getUserCards();
        setCardList(cardL);
      }*/
    setLoading(true);
  }, [cardList, location]);

  const Plan = () => {
    if (popUpModifyPlan.name === "DIAMANT") {
      return <img src={DIAMANT} alt="diamant" />;
    } else if (popUpModifyPlan.name === "RUBIS") {
      return <img src={RUBIS} alt="rubis" />;
    } else if (popUpModifyPlan.name === "SAPHIR") {
      return <img src={SAPHIR} alt="saphir" />;
    } else if (popUpModifyPlan.name === "STARTER") {
      return <img src={STARTER} alt="starter" />;
    } else {
      return <img src={BOOSTER} alt="booster" />;
    }
  };

  const CancelPlan = () => {
    if (currentSub.name === "DIAMANT") {
      return <img src={DIAMANT} alt="diamant" />;
    } else if (currentSub.name === "RUBIS") {
      return <img src={RUBIS} alt="rubis" />;
    } else if (currentSub.name === "SAPHIR") {
      return <img src={SAPHIR} alt="saphir" />;
    } else if (currentSub.name === "STARTER") {
      return <img src={STARTER} alt="starter" />;
    } else {
      return <img src={BOOSTER} alt="booster" />;
    }
  };

  const modifyPlan = async (sub) => {
    setPopUpModifyPlan(false);
    setSpin(true);

    if (currentSub.module_subscription_type === "paypal") {
      await UserService.getPlansFromPayPal().then((res) => {
        //setPlans(res.data.plans);
        plans = res.data.plans;
      });

      if (sub.name && sub.pricingByMonth) {
        const plan = plans.filter(
          (pl) =>
            pl.name.includes(sub.devise) &&
            pl.name.includes(sub.name) &&
            pl.status === "ACTIVE"
        );
        await UserService.reviseSubscription(
          currentSub.paymentSubscriptionId,
          currentSub.module_subscription_type,
          {
            plan_id: plan[0].id,
          }
        ).then((response) => {
          links = response.data.links;
          UserService.updateUserSubscription(sub.id, currentSub.sub_id);
        });
        const link = links.filter(
          (lnk) => lnk.href.includes("webapps") && lnk.href.includes("ba_token")
        );
        if (link[0].href) {
          window
            .open(
              link[0].href,
              "ConfirmRevision",
              "height=500,width=600,left=300,top=200"
            )
            .focus();

          const currentS = await UserService.getCurrentSubscription();
          setCurrentSub(currentS.data.currentSubscription);
          setSubscriptionPopup(false);
          //setPopUpPaypal(true);
          setSpin(false);
          toast.success(
            "Votre demande a bien été prise en compte. Vous devez confirmer le changement de plan depuis la fenetre qui a ete ouverte"
          );
        } else {
          toast.error("Une erreur est survenue");
        }
      } else {
        toast.error("Une erreur est survenue");
      }
    } else if (currentSub.module_subscription_type === "stripe") {
      console.log(sub);
      await UserService.getStripePlans(currentSub.paymentSubscriptionId).then(
        (res) => {
          let subscriptionItem = res.data.subscriptionItems[0];
          let plans = res.data.plans.map((p) => {
            return {
              ...p,
              product: res.data.products.find((pr) => p.product === pr.id),
            };
          });
          let plan = plans.filter(
            (pl) =>
              pl.product.name === `Élite ${sub.name}` &&
              pl.currency === sub.devise.toLowerCase() &&
              pl.interval_count === sub.duration &&
              pl.amount === sub.pricingByMonth * 100 &&
              pl.active
          )[0];

          if (plan) {
            UserService.reviseSubscription(
              currentSub.paymentSubscriptionId,
              currentSub.module_subscription_type,
              {
                subscriptionItemId: subscriptionItem.id,
                plan: plan.id,
              }
            ).then(async (response) => {
              UserService.updateUserSubscription(sub.id, currentSub.sub_id);
              const currentS = await UserService.getCurrentSubscription();
              setCurrentSub(currentS.data.currentSubscription);
              setSubscriptionPopup(false);
              //setPopUpPaypal(true);
              setSpin(false);
              // toast.success("");
            });
          } else {
            toast.error("Une erreur est survenue");
          }
        }
      );
    }
  };

  const cancelPlan = async (popup = false) => {
    if (popup) {
      setPopUpConfirm(true);
    } else {
      setSpin(true);
      setPopUpConfirm(false);
      await UserService.cancelSubscription(
        currentSub.paymentSubscriptionId,
        currentSub.module_subscription_type
      );

      await UserService.updateSubscriptionStatus({
        status: "canceled",
        paymentSubscriptionId: currentSub.paymentSubscriptionId,
      }).then((res) => {
        result = res.data;
      });

      if (result.status === "success") {
        await UserService.getCurrentSubscription();
        /*if (currentSub.boosted === 1) {
          setMatchingSubscription(null);
        }else{
          setCurrentSubscription(null);
        }*/
        setSpin(false);
        toast.success("Votre abonnement a bien été annulé");
      } else {
        setSpin(false);
        toast.error(
          "Une erreur est survenue. Vous n'avez pas pu etre desabonne"
        );
      }

      props.history.push(EliteRouters.parameters.path);
    }
  };

  const modifyCard = async (card = null, displayPopup = false) => {
    if (displayPopup) {
      setCardSelected(card);
      setCardPopup(true);
    } else {
      const data = await UserService.getCurrentSubscription();
      try {
        await UserService.updateCustomer({
          customerId: data.data.currentSubscription.securionCustomerId,
          card: {
            ...card,
            expiryDate: card.expiry,
            cardholderName: card.name,
            cardNumber: card.number,
          },
        });
      } catch (e) {
        toast.error(e.response.data.error.message);
        return;
      }
      if (cardSelected) {
        await UserService.deleteCard(cardSelected);
      }
      const cardL = await UserService.getUserCards();
      setCardList(cardL);
      setCardSelected(null);
      setCardPopup(false);
    }
  };

  const deleteCard = async (card) => {
    //are you sure about that ?
    await UserService.deleteCard(card);
    const cardL = await UserService.getUserCards();
    setCardList(cardL);
  };

  const StripeCardPayments = ()=>{
    let arr = [visa, mastercard, jcb, amex];

    return(
      arr.map((img,i)=>{
        return (
          <img height="50px" className="mx-2" src={img} alt="" key={i} />
        )
      })
    )
  }

  const PaymentMode = () => {
    let type = currentSub.module_subscription_type;

    if (!type || (type !== "stripe" && type !== "paypal")) {
      return <></>;
    }

    return (
      <div className="card-info">
        <div
          className="border"
          style={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 5,
            backgroundColor: "aliceblue",
            padding: "5px 10px",
          }}
        >
          {type === "paypal" && (
            <div>
              <div>
                <img height="30px" alt={type} src={paypalLogo} />
              </div>
              <hr />
              <p className="text-center mb-0" style={{ fontWeight: "bold" }}>
                Paiement avec un compte Paypal
              </p>
            </div>
          )}
          {type === "stripe" && (
            <div>
              <div>
                <img height="30px" alt={type} src={stripeLogo} />
              </div>
              <hr />
              <div className="d-flex">
                <StripeCardPayments />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const SubInfo = ()=>{
    if(currentSub.status === 'canceled'){
       if (currentSub.module_subscription_type === "paypal") {
         return (
           <span>
             Votre abonnement a bien été annulé, il s'arrêtera le{" "}
             {currentSub.endDate}
           </span>
         );
       } else {
         return (
           <span>Votre abonnement s'arrêtera le {currentSub.endDate}</span>
         );
       }

    } else {
      if(currentSub.currentModifiedSubscription){
        return (
          `Votre abonnement a été mis à jour le ${currentSub.verified_at} et la modification de votre compte sera effective à la date anniversaire du renouvellement de celui-ci, soit le ${currentSub.startDate}.`
        )
      } else {
        return (
          "Votre abonnement sera renouvelé automatiquement le " +
          currentSub.endDate +
          " avec le moyen de paiement par défault utilisé lors de la première souscription."
        );
  
      }
    }
  }

  // const getFormatedDate = () => {
  //   var today = new Date();
  //   var dd = today.getDate();
  //   var mm = today.getMonth() + 1;
  //   if (dd < 10) {
  //     dd = '0' + dd;
  //   }
  //   if (mm < 10) {
  //     mm = '0' + mm;
  //   }
  //   return dd + '/' + mm + '/' + today.getFullYear();
  // }

  return (
    <>
      <ConnectedHeader />
      {loading ? (
        <div className="container">
          <div className="row">
            <div className="col-12 offset-1 col-md-10">
              <div className="row">
                <div className={"back py-5 pb-0"}>
                  <div
                    onClick={() =>
                      props.history.push(EliteRouters.parameters.path)
                    }
                  >
                    <ReactSVG src={backArrow} /> Paramètres /
                    {typeof currentSub.name === "undefined"
                      ? "No Subscription"
                      : " Elite " +
                        currentSub.name.toLowerCase().charAt(0).toUpperCase() +
                        currentSub.name.toLowerCase().slice(1)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {currentSub ? (
              <div
                className="col-12 offset-1 col-md-10 section_2 min-vh-100"
                style={{ paddingTop: "2%" }}
              >
                <pre className="plan-actuel">
                  <strong>Abonnement actuel</strong>
                </pre>
                <div className="row">
                  <div className="col-12 col-md-8">
                    <p className="moyens">
                      <strong>
                        {typeof currentSub.name === "undefined"
                          ? "No Subscription"
                          : "ELITE " + currentSub.name}
                      </strong>
                    </p>
                    <p>
                      {typeof currentSub.price === "undefined"
                        ? "No Subscription"
                        : currentSub.price}{" "}
                      {typeof currentSub.currency === "undefined"
                        ? "No Subscription"
                        : currentSub.currency}{" "}
                      par mois
                    </p>

                    <p>
                       <SubInfo/>
                    </p>

                    
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="row buttons">
                      {currentSub.name !== "BOOSTER" &&
                        currentSub.status === "active" && (
                          <div className="col-12">
                            <CustomButton
                              onClick={() => {
                                if (subscriptionPopup) {
                                  setSubscriptionPopup(false);
                                } else {
                                  if (
                                    subscriptions &&
                                    subscriptions.length !== 0
                                  ) {
                                    setSubscriptionPopup(true);
                                    return;
                                  }
                                  UserService.getSubscriptions(
                                    currentSub.module_subscription_type
                                  ).then((res) => {
                                    setSubscriptionPopup(true);
                                    setSubscriptions(res.data.list);
                                  });
                                }
                              }}
                              label={
                                !subscriptionPopup
                                  ? "Afficher les abonnements"
                                  : "cacher les abonnements"
                              }
                            />
                          </div>
                        )}
                      {currentSub.status === "active" && (
                        <div className="col-12">
                          <button
                            style={{
                              paddingTop: "10px",
                              paddingBottom: "10px",
                            }}
                            className="btn btn-radius moyens"
                            onClick={() => cancelPlan(true)}
                          >
                            <strong>ANNULER L' ABONNEMENT</strong>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  {subscriptionPopup && (
                    <div className="row" style={{ marginTop: "30px" }}>
                      <p className="moyens">
                        <strong>ABONNEMENTS DISPONIBLES</strong>
                      </p>
                      {subscriptions.map((sub, i) => {
                        return (
                          currentSub.id !== sub.id && (
                            <div style={{ paddingBottom: 20 }} key={i}>
                              Abonnement <strong>{sub.name} </strong>, paiement
                              de{" "}
                              <strong>
                                {sub.duration === 1 || sub.name === "STARTER"
                                  ? sub.pricingByMonth
                                  : sub.pricingByMonth * sub.duration}
                                {sub.currency}{" "}
                              </strong>
                              tous les{" "}
                              {sub.duration === 1 || sub.name === "STARTER"
                                ? ""
                                : sub.duration}{" "}
                              mois{"   "}
                              <button
                                style={{
                                  color: "#34d10e",
                                  textDecorationLine: "underline",
                                  border: "none",
                                  backgroundColor: "white",
                                }}
                                onClick={() => {
                                  setPopUpModifyPlan(sub);
                                }}
                              >
                                Je choisis cette offre
                              </button>
                            </div>
                          )
                        );
                      })}
                    </div>
                  )}
                  <div className="col-12" style={{ marginTop: "30px" }}>
                    <p className="moyens">
                      <strong>MOYENS DE PAIEMENT</strong>
                    </p>
                    {cardList ? (
                      cardList.map((card, index) => (
                        <div className="d-flex card-info" key={index}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <span className="visa">{card.brand}</span>&nbsp;
                            <strong>...</strong>&nbsp;{card.last4}&nbsp;
                            {index === 0 ? (
                              <small
                                className="par-defaut"
                                style={{ marginRight: 5 }}
                              >
                                Par Defaut
                              </small>
                            ) : (
                              ""
                            )}
                          </div>
                          <div>
                            Expire le {card.expMonth}/{card.expYear}
                          </div>

                          <div className="dots-container">
                            <button
                              className="dots"
                              onClick={() => {
                                if (dots) {
                                  setDots(false);
                                } else {
                                  setDots(index);
                                }
                              }}
                            >
                              ...
                            </button>
                            {dots === index && (
                              <div className="dropdown">
                                <ul>
                                  <li
                                    onClick={() => {
                                      modifyCard(card, true);
                                      setDots(false);
                                    }}
                                  >
                                    Modifier
                                  </li>
                                  <li
                                    onClick={() => {
                                      deleteCard(card);
                                      setDots(false);
                                    }}
                                  >
                                    Supprimer
                                  </li>
                                </ul>
                              </div>
                            )}
                          </div>
                          {/*<button className="btn" onClick={() => modifyCard(card, true)}>Modifier</button>
                          <button className="btn-danger" onClick={() => deleteCard(card)}>Supprimer</button> */}
                        </div>
                      ))
                    ) : (
                      <PaymentMode />
                    )}
                  </div>
                  {/* <div className="col-12" style={{marginTop: "30px"}}>
                      <pre className="moyens"><strong>INFORMATIONS DE FACTURATION</strong></pre>
                      {cardList ? ( cardList.map((card, index) => {
                        if (index !== 0) {
                          return ''
                        }
                        return (
                          <div className="d-flex card-info" key={index}>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                              <span className="afficher-detaile"><pre>Email</pre></span>
                            </div>
                            <div>
                              <pre>{currentSub.userEmail}</pre>
                            </div>

                          </div>
                        )
                      })) : (
                        <div className="d-flex card-info">
                          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <span className="afficher-detaile"><pre>Email:</pre></span>
                          </div>
                          <div>
                            <pre>{currentSub.userEmail}</pre>
                          </div>

                        </div>
                      )}
                    </div> */}
                  {/*
                    <div className="col-12" style={{marginTop: "30px"}}>
                      <pre className="moyens"><strong>HISTORIQUE DE FACTURATION</strong></pre>
                      {cardList.map((card, index) => {
                        if(index !== 0) {
                          return ''
                        }
                        return (
                          <div className="d-flex card-info" key={index}>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                              <pre>{card.expMonth}/{card.expYear}</pre>
                            </div>
                            <div><pre>{currentSub.data.currentSubscription.price}{currentSub.data.currentSubscription.currency}</pre></div>
                            <div><pre>Elite {currentSub.data.currentSubscription.name}</pre></div>
                          </div>
                        )
                      })}

                    </div>
                      */}
                </div>
                <small className="checkout-footer">
                  Copyright - 2021- Elite Berbère
                </small>
              </div>
            ) : (
              <div className="col-12 col-md-8 section_2">
                Pas d'abonnement en cours
              </div>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      {cardPopup && (
        <div
          style={{
            height: window.innerHeight,
            width: window.innerWidth,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          <div
            style={{
              zIndex: 2,
              backgroundColor: "black",
              opacity: 0.3,
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }}
          />
          <div style={{ backgroundColor: "white", zIndex: 3, padding: 20 }}>
            <Checkout
              card={cardSelected}
              modifyCard={(card) => modifyCard(card)}
              closePopup={() => setCardPopup(false)}
            />
          </div>
        </div>
      )}

      {popUpModifyPlan && (
        <div
          style={{
            height: window.innerHeight,
            width: window.innerWidth,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          <div
            style={{
              zIndex: 2,
              backgroundColor: "black",
              opacity: 0.3,
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }}
          />
          <div
            style={{
              maxWidth: "500px",
              backgroundColor: "white",
              zIndex: 3,
              padding: 60,
              paddingTop: "25px",
              paddingBottom: "25px",
              borderRadius: 20,
            }}
          >
            <div
              style={{
                fontSize: 16,
                color: "#6c95e7",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              MISE À JOUR DE VOTRE ABONNEMENT
            </div>
            <div style={{ paddingBottom: "30px", marginTop: "20px" }}>
              <strong style={{ fontSize: "30px" }}>
                {popUpModifyPlan.pricingByMonth
                  ? popUpModifyPlan.pricingByMonth * popUpModifyPlan.duration
                  : popUpModifyPlan.pricingByWeek}
                {popUpModifyPlan.currency}
              </strong>
              <small style={{ color: "#6d6d6d" }}>
                {" "}
                {popUpModifyPlan.duration === 1
                  ? "par mois"
                  : "tous les " + popUpModifyPlan.duration + " mois"}
              </small>
              <p style={{ fontSize: "15px" }}>
                A partir du {currentSub.endDate}
              </p>
            </div>

            <div className="detail-block">
              <div className="row" style={{ paddingBottom: "20px" }}>
                <div className="col-2 plan-image">
                  <Plan />
                </div>
                <div className="col-6 tous-les-mois">
                  <p className="m-0">Elite {popUpModifyPlan.name}</p>
                  <p style={{ color: "#6d6d6d" }}>
                    Facturé tous les{" "}
                    {popUpModifyPlan.duration === 1
                      ? ""
                      : popUpModifyPlan.duration}{" "}
                    mois
                  </p>
                </div>
                <div className="col-4">
                  <strong>
                    {popUpModifyPlan.pricingByMonth
                      ? popUpModifyPlan.pricingByMonth *
                        popUpModifyPlan.duration
                      : popUpModifyPlan.pricingByWeek}
                    {popUpModifyPlan.currency}
                  </strong>
                </div>
              </div>
              <div className="row" style={{ paddingBottom: "20px" }}>
                <div className="col-2"></div>
                <div className="col-6">
                  <p>Sous-Total</p>
                </div>
                <div className="col-4">
                  <strong>
                    {popUpModifyPlan.pricingByMonth
                      ? popUpModifyPlan.pricingByMonth *
                        popUpModifyPlan.duration
                      : popUpModifyPlan.pricingByWeek}
                    {popUpModifyPlan.currency}
                  </strong>
                </div>
                <div className="col-8 offset-2">
                  <hr />
                </div>
              </div>
            </div>
            {/* <div style={{width: '100%', height: 1, backgroundColor: "#CCCCCC", marginTop: 5, marginBottom: 15}}/>
          <div/> */}

            <CustomButton
              onClick={() => modifyPlan(popUpModifyPlan)}
              type="submit"
              label="valider"
            />
          </div>
          <div
            style={{ backgroundColor: "none", zIndex: 3, marginTop: "64px" }}
          >
            <div className="col-12">
              <ReactSVG
                style={{ cursor: "pointer" }}
                src={closeButton}
                onClick={() => {
                  setPopUpModifyPlan(false);
                }}
              />
            </div>
          </div>
        </div>
      )}

      {/* {popUpPaypal && 
        <div style={{
          height: window.innerHeight,
          width: window.innerWidth,
          display: "flex",
          alignItems: 'center',
          justifyContent: 'center',
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0
        }}>
          <div
            style={{zIndex: 2, backgroundColor: "black", opacity: 0.3, position: "absolute", top: 0, bottom: 0, left: 0, right: 0}}/>
          <div style={{backgroundColor: "white", zIndex: 4, padding: 20, borderRadius: 5}}>
              <h3>Close the window before continue</h3>
          </div>
        </div>
      } */}

      {popUpConfirm && (
        <div
          style={{
            height: window.innerHeight,
            width: window.innerWidth,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          <div
            style={{
              zIndex: 2,
              backgroundColor: "black",
              opacity: 0.4,
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }}
          />
          <div
            style={{
              backgroundColor: "white",
              zIndex: 3,
              padding: 60,
              paddingTop: "25px",
              paddingBottom: "25px",
              borderRadius: 20,
              maxWidth: "500px",
            }}
          >
            <div
              style={{
                fontSize: 16,
                color: "#6c95e7",
                fontWeight: "bold",
                textAlign: "center",
                marginBottom: 30,
              }}
            >
              ANNULATION DE VOTRE ABONNEMENT
            </div>
            <div style={{ paddingBottom: "30px", marginTop: "20px" }}>
              <strong style={{ fontSize: "30px" }}>
                {currentSub.price} {currentSub.currency}
              </strong>
              <small style={{ color: "#6d6d6d" }}>
                {" "}
                {currentSub.duration === 1
                  ? "par mois"
                  : "tous les " + currentSub.duration + " mois"}
              </small>
              <p style={{ fontSize: "15px" }}>
                Votre abonnement sera annulé à partir du {currentSub.endDate}
              </p>
            </div>

            <div className="detail-block">
              <div className="row" style={{ paddingBottom: "20px" }}>
                <div className="col-2 plan-image">
                  <CancelPlan />
                </div>
                <div className="col-6 tous-les-mois">
                  <p className="m-0">Elite {currentSub.name}</p>
                  <p style={{ color: "#6d6d6d" }}>
                    Facturé tous les{" "}
                    {currentSub.duration === 1 ? "" : currentSub.duration} mois
                  </p>
                </div>
                <div className="col-4">
                  <strong>
                    {!isNaN(currentSub.duration) ? currentSub.price * currentSub.duration : currentSub.price}
                    {currentSub.currency}
                  </strong>
                </div>
              </div>
              <div className="row" style={{ paddingBottom: "20px" }}>
                <div className="col-2"></div>
                <div className="col-6">
                  <p>Sous-Total</p>
                </div>
                <div className="col-4">
                  <strong>
                    {!isNaN(currentSub.duration) ? currentSub.price * currentSub.duration : currentSub.price}
                    {currentSub.currency}
                  </strong>
                </div>
                <div className="col-8 offset-2">
                  <hr />
                </div>
              </div>
            </div>
            <CustomButton
              onClick={() => cancelPlan()}
              type="submit"
              label="valider"
            />
          </div>
          <div
            style={{ backgroundColor: "none", zIndex: 3, marginTop: "64px" }}
          >
            <div className="col-12">
              <ReactSVG
                style={{ cursor: "pointer" }}
                src={closeButton}
                onClick={() => {
                  setPopUpConfirm(false);
                }}
              />
            </div>
          </div>
        </div>
      )}

      {isSpin && (
        <div
          style={{
            zIndex: 2,
            backgroundColor: "grey",
            opacity: 0.3,
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          <div
            className="row page-row"
            style={{ zIndex: 3, display: "flex", flexDirection: "column" }}
          >
            <div className="loading-searching" style={{ height: "100vh" }}>
              <Spinner animation="border" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
