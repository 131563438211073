import React from "react";
import { ErrorMessage, Form, Formik } from "formik";
import "./ModalFieldStyle.css";
import CustomButton from "../Button/CustomButton";
import ResponsiveService from "../../../Services/ResponsiveService";

export function ModalField(props) {
  function onSubmit(values, formik) {
    try {
      props.onChange && props.onChange(values[props.name]);
    } finally {
      formik.setSubmitting(false);
    }
  }

  return (
    <>
      <Formik
        initialValues={{ [props.name]: props.value }}
        validationSchema={props.validationSchema}
        onSubmit={onSubmit}>
        {({ isSubmitting, setFieldValue, values }) => (
          <Form className={"needs-validation mt-6" + ResponsiveService.isMobile() ? ' mobile' : ''}>
            <div className={`mb-4 has-validation px-2 mb-8 ${props.classes || ""}`}>
              {props.renderField({
                id: props.id,
                name: props.name,
                value: values[props.name],
                onChange: (value) => setFieldValue(props.name, value),
              })}
              <ErrorMessage
                component="div"
                className={"invalid-feedback d-block"}
                name={props.name}
              />
            </div>
            <div
              className={`position-absolute ${props.isMobile ? "px-2" : ""}`}
              style={{ width: props.isMobile ? "100%" : "365px", bottom: 0 }}>
              <CustomButton
                type="submit"
                isDisabled={isSubmitting}
                label={"valider"}
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default ModalField;
