import React, { Component } from "react";
import EliteParagraph from "../../../Components/Uikit/Typography/Paragraph";

import NavigationFrontBack from "../../Uikit/Wizard/NavigationWizard/NavigationFB";
import iconCamera from "../../../assets/images/icon-camera-2.svg";
import iconEdit from "../../../assets/images/icon-edit-white.svg";
import { ReactSVG } from "react-svg";
import EliteErrorText from "../../Uikit/Typography/ErrorText";
import CustomTextField from "../../Uikit/TextFields/TextFields";

class SignUpStep3 extends Component {
  constructor(props) {
    super(props);
    this.state = { file: "" };
  }

  updateProfile(prop, value) {
    this.props.onChange({ [prop]: value });
  }

  renderError(key) {
    if (!this.props.showErrors) return;

    if (key === "username" && !this.props.profile.username) {
      return <EliteErrorText text="Champ obligatoire" />;
    }
    if (key === "photo") {
      if (!this.props.profile.photo) {
        return <EliteErrorText text="Champ obligatoire" />;
      }
      if (this.props.profile.photo.size > 5 * 1024 * 1024) {
        return <EliteErrorText text="Le fichier doit faire moins de 5Mo" />;
      }
      if (
        !["image/jpg", "image/png", "image/jpeg"].includes(
          this.props.profile.photo.type,
        )
      ) {
        return <EliteErrorText text="Formats acceptés : .png, jpeg, jpg" />;
      }
    }
  }

  onChange(event) {
    this.loadDataImage(event.target.files[0]);
    this.props.onChange({ photo: event.target.files[0] });
  }

  loadDataImage(file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      this.setState({ file: e.target.result });
    };
    reader.readAsDataURL(file);
  }

  render() {
    if (!this.state.file && this.props.profile.photo) {
      this.loadDataImage(this.props.profile.photo);
    }
    return (
        <>
          <div className={"profileContainer w-100vh"}>

            <div style={{height:"1vh"}}/>
            <EliteParagraph
                margin={"22px 0 0 0"}
                uppercase
                text={"J’ajoute une photo de moi"}
                textColor={"#fff"}
            />
            <div className={"hidddenInput d-flex flex-column align-items-center"}>
              <input
                  type="file"
                  accept="image/x-png,image/jpeg"
                  onChange={(e) => this.onChange(e)}
              />

              <div
                  style={{
                    backgroundImage: `url(${this.state.file})`,
                  }}
                  className={"cameraContainer"}>
                <ReactSVG
                    className={`${this.state.file !== "" && "d-none"}`}
                    src={iconCamera}></ReactSVG>
              </div>
              <div className={"mt-2"}>
                <ReactSVG fill={"#FFFFFF"} color={"#FFFFFF"} src={iconEdit}/>
              </div>
              {this.renderError("photo")}
              <EliteParagraph
                  text={"Photo de préférence au format carré (type Instagram) et d'une taille inférieure ou égale à 1080 pixels."}
                  textColor={"#fff"}
                  fontSize={"0.7em"}
              />
            </div>

            <CustomTextField
                isMobile
                inputLength="10"
                placeholder={"Pseudo"}
                value={this.props.profile.username}
                onChange={(value) => this.updateProfile("username", value)}
                isInvalid={this.props.showErrors && !this.props.profile.username}
                errorText={this.renderError("username")}
                isSubscription
                noEmoji
                autoComplete={"chrome-off"}
            />


            {/* mandadory in every step */}
            <NavigationFrontBack
                profile={this.props.profile}
                onShowErrors={this.props.onShowErrors}
                isMobile={this.props.isMobile}
                wizardProps={this.props}
                isStepOne={false}

            />

          </div>
      </>
    );
  }
}

export default SignUpStep3;
