import React, { Component } from "react";
import { ReactSVG } from "react-svg";
import "./ChipsStyles.css";
import iconIgnore from "../../../assets/images/icon-ignore-small-yellow.svg";

export class Chips extends Component {
  render() {
    return (
      <div className={"chipsContainer"}>
        <div className={"label label-default chips"}>
          {this.props.text}
          <span
            className={`pointer ${this.props.showClose ? "d-block" : "d-none"}`}
            onClick={(i) => this.props.close(i)}>
            <ReactSVG src={iconIgnore} />
          </span>
        </div>
      </div>
    );
  }
}

export default Chips;
