import React from "react";
import { ReactSVG } from "react-svg";
import FooterMenu from "../../../Components/FooterMenu/FooterMenu";
import backArrow from "../../../assets/images/icon-arrow-back.svg";
import "./MesVisitesMobile.css";
import { useHistory } from "react-router-dom";
import { useVisitors } from "../../../Hooks/Visits";
import { isEmpty } from "lodash-es";
import GridMosaic from "../../../Components/Uikit/PhotoMosaic/PhotoMosaic_new";

export function MesVisitesMobile() {
  const history = useHistory();

  const { visitors } = useVisitors();

  return (
    <div>
      <div className="container mesVisitesMobile">
        <div className="col-12">
          <div className={"back py-2"}>
            <div onClick={() => history.push("/home")}>
              <ReactSVG src={backArrow} /> Mes visites
            </div>
          </div>
        </div>
        <div className="col-12 pb-5">
          <div className="row g-0">
            {!isEmpty(visitors) ? (
              <GridMosaic
              rows={2}
              padding={'4px'}
                isMobile
                photoType={"miniature"}
                photoSize={"small"}
                data={visitors}
              />
            ) : (
              <div className="text-center">
                <h6 className="pt-5">
                  <i>Aucune visite pour le moment</i>
                </h6>
              </div>
            )}
          </div>
        </div>
      </div>
      <FooterMenu />
    </div>
  );
}
