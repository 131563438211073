import React, { useMemo } from "react";
import CustomAvatar from "../Uikit/Avatar/Avatar";
import EliteParagraph from "../Uikit/Typography/Paragraph";
import moment from "moment";
import 'moment/locale/fr';
import { getState } from "./Helpers";
import { useNotifications } from "../../Hooks/Notifications";
import {hasValidSubscription} from "../../Services/ApiServices/api.client";
import { EliteRouters } from "../../Navigation/Routers";
import { generatePath, useHistory } from "react-router-dom";

export function ChatCardUser({ room, isSelected, goToProfile }) {
  const { notifications } = useNotifications()
  const history = useHistory();
  const participant = room.otherParticipant;
  const lastMessageTime = room.lastMessageAt ? moment.duration(moment(room.lastMessageAt).diff()).humanize(true) : 'New'
  const isOnline = useMemo(() => notifications.onlineUsers.includes(participant.id), [participant.id, notifications.onlineUsers]);

  const openChatRoom = () => {
    if (hasValidSubscription()) {
      history.push(
        generatePath(EliteRouters.messages.path, { roomId: room.id }),
      )
    }
  }

  return (
    <>
      <div
        className={ `cardContainer d-flex align-items-center btn ${
          isSelected ? "selected" : ""
        }` }>
        <div className="chatAvatarUser"
             onClick={ (e) => {
               e.stopPropagation();
               goToProfile()
             } }>
          <CustomAvatar
            size={ "48" }
            state={ getState(isOnline, parseInt(room.unreadCount)) }
            photoUrl={ participant.avatar }
            link={hasValidSubscription ?? "/home/"+ participant.id}
          />
        </div>
        <div className="d-flex flex-column nameSubtitleChatUser" onClick={ openChatRoom }>
          <div className="chatUser">
            <EliteParagraph
              text={ participant.username }
              textColor={ "#4e4e4e" }
              fontSize={ "14px" }
              margin={ "0 0 5px 0" }
              textAlign={ "left" }
              fontWeight={ parseInt(room.unreadCount) ? "bold" : "500" }
              cursor={ "pointer" }
            />
          </div>
          <div style={{display: "flex", justifyContent: "space-between", flexDirection:"row",alignItems: "flex-start", width:"100%"}}>
            <div className="chatUser">
              <EliteParagraph
                  text={ hasValidSubscription() ? ((room.lastMessage?.content !== "" || room.lastMessage?.content !== null) ? room.lastMessage?.content : "") : ""}
                  textColor={ "#4e4e4e" }
                  fontSize={ "10px" }
                  margin={ 0 }
                  textAlign={ "left" }
                  lineHeight={ 1.2 }
                  fontWeight={ parseInt(room.unreadCount) ? "bold" : "400" }
                  cursor={ "pointer" }
              />
            </div>
            <div className="isOnlineChatUser d-flex justify-content-end" onClick={ openChatRoom }>
              <EliteParagraph
                  text={ lastMessageTime }
                  textColor={ isSelected ? "#b9ccf4" : "#6c95e7" }
                  fontSize={ "10px" }
                  margin={ 0 }
                  textAlign={ "right" }
                  lineHeight={ 1.2 }
                  fontWeight={ 400 }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChatCardUser;
