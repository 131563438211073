import UserService from "./ApiServices/UserService";
import { EventSourcePolyfill } from "event-source-polyfill";
import { handleError } from "./ErrorHandler";

export async function subscribe(topic, onMessage) {

  const { data: { token } } = await UserService.getSubscriptionToken()

  if (token) {
    const url = new URL(process.env.REACT_APP_MERCURE_SUBCRIBE_URL);
    const headers = { 'Authorization': `Bearer ${ token }` };
    url.searchParams.append('topic', topic);
    const eventSource = new EventSourcePolyfill(url, { headers });
    eventSource.onmessage = (event) => {
      onMessage(JSON.parse(event.data))
    }
    eventSource.onerror = (e) => {
      handleError(e)
    }

    return () => {
      eventSource.close()
    }
  }
}
