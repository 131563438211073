import React from "react";
import {ReactSVG} from "react-svg";
import UniqueChoiceModal from "../../Uikit/UniqueChoiseModal/UniqueChoiceModal";
import closeButton from "../../../assets/images/btn-close.svg";
import EliteParagraph from "../../Uikit/Typography/Paragraph";
import backArrow from "../../../assets/images/icon-arrow-back.svg";

import ModalField from "../../Uikit/ModalField/ModalField";
import MultipleChoiseModal from "../../Uikit/MultipleChoiseModal/MultipleChoiseModal";

export function Sportive(props) {
  const choices = [
    {label: "Pratique régulièrement", value: "Pratique régulièrement"},
    {label: "Occasionnellement", value: "Occasionnellement"},
    {label: "Non", value: "Non"},
  ];

  function handleClose() {
    props.closeModal();
  }

  function onChange(value) {
    props.onChange(value);
    handleClose()
  }

  function renderField(attrs) {
    if (props.multiple) {
      return (
        <MultipleChoiseModal
          choices={choices}
          {...attrs}
        />)
    }
    return (
      <UniqueChoiceModal
        isMobile={props.isMobile}
        choices={choices}
        {...attrs}
      />
    )
  }

  return (
    <>
      <div className={props.isMobile ? "d-none" : "d-block px-5 pt-4 pb-2"}>
        <EliteParagraph
          text={props.title}
          fontSize={"24px"}
          textColor={"#6c95e7"}
          textAlign={"left"}
          fontWeight={500}
        />
      </div>
      <div
        className={
          !props.isMobile ? "d-none" : "d-block col-lg-4 col-md-12 mb-4 headerModalMobile"
        }>
        <div className={"back"} style={{marginTop: "64px"}}>
          <div
            onClick={handleClose}>
            <ReactSVG src={backArrow}/> {props.title}
          </div>
        </div>
      </div>
      <div
        className={`d-flex flex-column w-100 contentModalMinHeight position-relative ${props.isMobile ? 'mobile' : 'px-5'}`}>
        <ModalField
          isMobile={props.isMobile}
          id="sportive"
          name="sportive"
          value={props.value}
          onChange={onChange}
          closeModal={handleClose}
          placeholder={props.title}
          renderField={renderField}
          classes={props.isMobile ? 'headerModalMobile' : ''}
        />
      </div>
      <div
        className={
          "closeButtonLevitate d-flex flex-column align-items-center"
        }>
        <ReactSVG
          style={{cursor: "pointer"}}
          src={closeButton}
          onClick={handleClose}/>
      </div>
    </>
  );
}

export default Sportive;
