import React from "react";
import EliteParagraph from "../../Components/Uikit/Typography/Paragraph";
import "./ProfileStyles.css";


function buildLabels(labels) {
  return labels.map((label, i) => (
    <div
      key={ i }
      className={ `labelsContainerForm d-flex ${
        i === 1 || i === 7 || i === 11 || i >= labels.length - 1
          ? "pt-4"
          : ""
      }` }>
      <div className="titleContainerProfile">
        <EliteParagraph
          fontSize={ "12px" }
          fontWeight={ 500 }
          textColor={ "#6c95e7" }
          uppercase
          textAlign={ "left" }
          text={ label.title }
        />
      </div>
      <div className="titleContainerProfile">
        <EliteParagraph
          fontWeight={ 400 }
          fontSize={ "14px" }
          textAlign={ "left" }
          text={ label.content }
        />
      </div>
    </div>
  ));
}

export default function AProposDeMoi({ user }) {

  const genders = {
    H: 'Homme',
    F: 'Femme',
  };

  const labels = [
    { title: "Ici pour rencontre :", content: user.profile.hereFor ? ( user.profile.hereFor || [] ).join(', ') : '-' },
    { title: "sexe :", content: genders[user.profile.gender] },
    { title: "taille :", content: user.profile.height ? `${user.profile.height} cm` : '-' },
    { title: "Silhouette :", content: user.profile.shape ? user.profile.shape : '-' },
    { title: "Couleur de cheveux :", content: user.profile.hairColor ? user.profile.hairColor : '-' },
    { title: "Couleur des yeux :", content: user.profile.eyeColor ? user.profile.eyeColor : '-' },
    { title: "Signe astrologique :", content: user.profile.astroSign ? user.profile.astroSign : '-' },
    { title: "à des enfants :", content: user.profile.hasChildren ? 'Oui' : 'Non' },
    { title: "Veux des enfants :", content: user.profile.wantChildren ? 'Oui' : 'Non' },
    { title: "Situation :", content: user.profile.maritalStatus ? user.profile.maritalStatus : '-' },
    { title: "Langue :", content: user.profile.languages ? ( user.profile.languages || [] ).join(', ') : '-'},
    { title: "Tabac :", content: user.profile.tabac ? user.profile.tabac : '-' },
    { title: "Activité sportive :", content: user.profile.sport ? user.profile.sport : '-' },
    { title: "Niveau d’études :", content: user.profile.studyLevel ? user.profile.studyLevel : '-' },
    { title: "Secteur d’activité :", content: user.profile.activityArea ? user.profile.activityArea : '-' },
    { title: "Caractère :", content: user.profile.traits ? (user.profile.traits || []).join(', ') : '-' },
  ];
  return <div className={ "pt-4" }>{ buildLabels(labels) }</div>;
}
