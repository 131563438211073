import React, { Component } from "react";

import EliteParagraph from "../Uikit/Typography/Paragraph";
import CustomCheckbox from "../Uikit/Checkbox/Checkbox";
import InputRange from "react-input-range";
import Slider from 'react-input-slider';
import "react-input-range/lib/css/index.css";
// import { Multiselect } from "multiselect-react-dropdown";
import {
  ageMax,
  ageMin,
  heightMax,
  heightMin,
  purposes
} from "../../Services/DataLists";
import UserService from "../../Services/ApiServices/UserService";
import TxtAsButton from "../Uikit/TxtAsButton/TxtAsButton";
import Select, { components } from "react-select";
import AsyncSelect from 'react-select/async';
import iconDrop from "../../assets/images/icon-dropup.svg";
import { ReactSVG } from "react-svg";

export class SearchComponentsContainer extends Component {
  constructor(props) {
    super(props);
    let selectedPurpose = props.selectedOptions.purpose;

    if (selectedPurpose) {
      selectedPurpose = {
        label: props.selectedOptions.purpose,
        value: props.selectedOptions.purpose
      }
    }

    this.state = {
      cities: [],
      profiles: [],
      userId: 1,
      selectedCities: props.selectedOptions.cities,
      selectedPurpose: selectedPurpose
    };
  }

  isChecked = (checked) => {
    this.setState({ selectedOption: checked.target.value });
  };

  citiesOptions = (inputValue: string) =>
    new Promise((resolve) => {
      setTimeout(() => {
        this.fetchCities(inputValue).then(() => {
          resolve(this.state.cities);
        });
      }, 2000);
    });

  fetchCities = (q) => {
    return new Promise((resolve) => {
      UserService.fetchCities(q).then((res) => {
        this.setState({
          cities: res.data.map((city) => {
            return { value: city.id, label: city.name };
          }),
        });

        resolve(true);
      });
    })
  };

  handleGenderCheck = (gender, isChecked) => {
    let genders = this.props.selectedOptions.genders;
    let idx = genders.indexOf(gender);
    if (idx > -1) {
      genders.splice(idx, 1);
    }
    if (isChecked) {
      genders.push(gender);
    }

    this.props.handleFiltersOptionsChange("genders", genders);
  };

  componentDidMount() {
    this.fetchCities("");
  }

  handleChangeCities = (cities) => {
    this.props.handleFiltersOptionsChange("cities", cities);
    this.setState({ 'selectedCities': cities });
  }

  render() {
    const DropdownIndicator = (props) => {
      return (
        <components.DropdownIndicator {...props}>
          <ReactSVG className={"pointDown"} src={iconDrop} />
        </components.DropdownIndicator>
      );
    };
    const colourStyles = {
      multiValueLabel: (styles, { data }) => ({
        ...styles,
        backgroundImage:
          "linear-gradient(106deg,#34d10e 11%, #6c95e7 90%) !important",
        color:'#fff',
        borderRadius:'8px 0 0 8px'
      }),
      multiValueRemove:(styles, { data }) =>({
        ...styles,
        backgroundColor:'#6c95e7',
        color:'#fff',
        borderRadius:'0 8px 8px 0'

      })
    };
    return (
      <>
        <div
          className={`contentFilters ${
            this.props.filterIsOpen ? "" : "d-none"
          } ${this.props.isMobile ? "contentFiltersMobile" : ""}`}>
          <EliteParagraph
            margin={"16px 0 0 0"}
            uppercase
            text={"Vous recherchez :"}
            textColor={"#6c95e7"}
            fontWeight={500}
            fontSize={"16px"}
            textAlign={"left"} />
          <Select
            components={{ DropdownIndicator , IndicatorSeparator: () => null}}
            placeholder={"Sélectionner..."}
            options={purposes}
            onChange={(evt) => {
              this.props.handleFiltersOptionsChange("purpose", evt.value);
              this.setState({ 'selectedPurpose': evt });
            }}
            menuPlacement={'auto'}
            value={this.state.selectedPurpose}
          />
          <EliteParagraph
            margin={"32px 0 0 0"}
            uppercase
            text={"avec :"}
            textColor={"#6c95e7"}
            fontWeight={500}
            fontSize={"16px"}
            textAlign={"left"} />
          <div className="d-flex flex-row justify-content-around">
            <CustomCheckbox
              isColor
              label={"Un homme"}
              key="male-gender"
              checked={this.props.selectedOptions.genders.indexOf("H") > -1}
              onChange={(value) =>
                this.handleGenderCheck("H", value)
              } />
            <CustomCheckbox
              isColor
              label={"Une femme"}
              key="female-gender"
              checked={this.props.selectedOptions.genders.indexOf("F") > -1}
              onChange={(value) =>
                this.handleGenderCheck("F", value)
              } />
          </div>
          <div className="d-flex flex-row mt-4 justify-content-around">
            <CustomCheckbox
              isColor
              label={"Avec photo"}
              key="with-avatar"
              checked={this.props.selectedOptions.withAvatar}
              onChange={(value) =>
                this.props.handleFiltersOptionsChange("withAvatar", value)
              } />
          </div>
          <EliteParagraph
            margin={"32px 0 0 0"}
            uppercase
            text={"IL/ELLE A :"}
            textColor={"#6c95e7"}
            fontWeight={500}
            fontSize={"16px"}
            textAlign={"left"} />
          <div className="m-4">
            <InputRange
              draggableTrack
              maxValue={ageMax}
              minValue={ageMin}
              activeTrack="active-track"
              onChange={(value) => {
                if (value.min >= ageMin && value.max <= ageMax) {
                  this.props.handleFiltersOptionsChange("ageMin", value.min);
                  this.props.handleFiltersOptionsChange("ageMax", value.max);
                }
              }}
              formatLabel={(value) => `${value} ans`}
              value={{
                min: this.props.selectedOptions.ageMin,
                max: this.props.selectedOptions.ageMax,
              }}
            />
            <div style={{height: "32px"}} />
          </div>
          <EliteParagraph
            margin={"0 0 0 0"}
            uppercase
            text={"mesure entre :"}
            textColor={"#6c95e7"}
            fontWeight={500}
            fontSize={"16px"}
            textAlign={"left"} />
          <div className="m-4">
            <InputRange
              draggableTrack
              maxValue={heightMax}
              minValue={heightMin}
              activeTrack="active-track"
              onChange={(value) => {
                if (value.min >= heightMin && value.max <= heightMax) {
                  this.props.handleFiltersOptionsChange("heightMin", value.min);
                  this.props.handleFiltersOptionsChange("heightMax", value.max);
                }
              }}
              formatLabel={(value) => `${value} cm`}
              value={{
                min: this.props.selectedOptions.heightMin,
                max: this.props.selectedOptions.heightMax,
              }}
            />
          </div>
          {/*<div style={{height: "32px"}} />
          <EliteParagraph
            key="shape"
            margin={"0 0 0 0"}
            uppercase
            text={"Avoir la silhouette : "}
            textColor={"#6c95e7"}
            fontWeight={500}
            fontSize={"16px"}
            textAlign={"left"} />
          <Select
           components={{ DropdownIndicator, IndicatorSeparator: () => null }}
            placeholder={"Sélectionner..."}
            options={shapes}
            onChange={(evt) =>
              this.props.handleFiltersOptionsChange("shape", evt.value)
            }
            menuPlacement={'auto'}
          />*/}
          <EliteParagraph
            margin={"32px 0 0 0"}
            uppercase
            text={"IL/ELLE DOIT habiter à :"}
            textColor={"#6c95e7"}
            fontWeight={500}
            fontSize={"16px"}
            textAlign={"left"} />
          {/* <Multiselect
            className={"form-control dropdown"}
            options={this.state.cities}
            selectedValues={
              typeof this.props.selectedOptions.cities === "string" &&
              !!this.props.selectedOptions.cities.length
                ? this.props.selectedOptions.cities
                    .split(",")
                    .map((k) =>
                      !!this.state.cities.filter(
                        (org_object) => k === org_object.value,
                      ).length
                        ? this.state.cities.filter(
                            (org_object) => k === org_object.value,
                          )[0]
                        : "",
                    )
                : this.props.selectedOptions.cities
            }
            onSearch={(value) => this.fetchCities(value)}
            onSelect={(value) =>
              this.props.handleFiltersOptionsChange("cities", value)
            }
            onRemove={(value) =>
              this.props.handleFiltersOptionsChange("cities", value)
            }
            displayValue="label"
            showCheckbox={true}
            placeholder="Sélectionner"
            emptyRecordMsg="Aucun enregistrement"
          /> */}
          <AsyncSelect
            isMulti
            cacheOptions
            defaultOptions
            placeholder={"Sélectionner..."}
            loadOptions={this.citiesOptions}
            components={{ DropdownIndicator, IndicatorSeparator: () => null }}
            styles={colourStyles}
            noOptionsMessage={() => '"Aucun enregistrement"'}
            value={this.state.selectedCities}
            onChange={(cities) => this.handleChangeCities(cities)}
            menuPlacement={'auto'}
          />
          <EliteParagraph
            margin={"16px 0 -16px 0"}
            uppercase
            text={"Distance : " + this.props.selectedOptions.distanceMax + " km"}
            textColor={"#6c95e7"}
            fontWeight={500}
            fontSize={"16px"}
            textAlign={"left"} />
          <div className="m-4">
            <div aria-disabled="false" class="input-range slider">
              <span class="input-range__label input-range__label--min">
                <span class="input-range__label-container">0 km</span>
              </span>
              <div>
                <Slider
                  axis="x"
                  x={this.props.selectedOptions.distanceMax}
                  onChange={({ x }) => {
                    this.props.handleFiltersOptionsChange("distanceMax", x);
                  }}
                  xmax={500}
                  xstep={5}
                  className="slider-distance"
                  styles={{
                    track: {
                    },
                    active: {
                      backgroundImage: "linear-gradient(to right, rgb(224, 224, 224) 0%, rgb(224, 224, 224) 0%, rgb(52, 209, 14) 0%, rgb(108, 149, 231) 100%, rgb(224, 224, 224) 100%, rgb(224, 224, 224) 100%)"
                    },
                    thumb: {
                      background: "rgb(108, 149, 231)"
                    },
                    disabled: {
                      opacity: 0.5
                    }
                  }}
                />
                {/*<InputRange
                  draggableTrack={false}
                  maxValue={500}
                  minValue={0}
                  activeTrack="active-track"
                  step={5}
                  formatLabel={(value) => `${value} km`}
                  value={{
                    min: 0,
                    max: this.props.selectedOptions.distanceMax,
                  }}
                  onChange={(value) => {
                    if (value.max <= distanceMax) {
                      this.props.handleFiltersOptionsChange("distanceMax", value.max);
                    }
                  }}
                />*/}
              </div>
              <span class="input-range__label input-range__label--max">
                <span class="input-range__label-container">500 km</span>
              </span>
            </div>
          </div>

          <div className="mt-4">
            <TxtAsButton
              width={"100%"}
              text={"RECHERCHE"}
              onClick={() => this.props.fetchProfiles()} />
          </div>
        </div>
      </>
    );
  }
}

export default SearchComponentsContainer;
