const update = (state = {}, action) => {
  switch (action.type) {
    case 'DELETE':
      state.user = null;
      break;
    case 'UPDATE':
      state.user = action.user
      break;
    default:
      //
      break;
  }
  return state;
}


export default update
