import React, {useEffect, useState} from "react";
import { ReactSVG } from "react-svg";
import closeButton from "../../assets/images/btn-close.svg";
import CardOffer from "./CardOffer";
import UserService from "../../Services/ApiServices/UserService";
import {handleError} from "../../Services/ErrorHandler";
import {useCurrentUser} from "../../Hooks/AuthenticatedUser";

export default function BoostOffer(props) {
    const [subscriptions, setSubscriptions] = useState()
    const [reloadSubscription, setReloadSubscription] = useState(0);
    const {user} = useCurrentUser();
    const [currentUserCurrency, setCurrentUserCurrency] = useState(user.devise);

    useEffect(() => {
        async function fetchDescriptions() {
            await UserService.getBoostSubscriptions()
                .then(res => {
                    setSubscriptions(res.data.list[0])
                })
        }
        if(typeof reloadSubscription !== 'undefined') {
            fetchDescriptions();
            }
        }, [reloadSubscription])

    async function updateCurrency(newCurrency) {
        try {
            await UserService.updateDevise(newCurrency).then(()=> {
                setReloadSubscription(reloadSubscription + 1);
            })
            setCurrentUserCurrency(newCurrency)
        } catch (e) {
            handleError(e);
        }
    }

  return (
    <div className={'d-flex flex-column align-items-center'}>
     {subscriptions && <CardOffer type={'boost'} sliderData={{...subscriptions, daily: false,basicOffer:null, economic:true, reduceUnitDaily:true}} updateCurrency={ updateCurrency } devise={currentUserCurrency}/>}
      <div
        className={"closeButtonLevitate d-flex flex-column align-items-center"}
        style={{ marginTop: props.isMobile && "16px" }}>
        <ReactSVG
          style={{ cursor: "pointer" }}
          src={closeButton}
          onClick={() =>
            props.closeModal.current.handleCloseModal()
          }></ReactSVG>
      </div>
    </div>
  );
}
