import React, { useState } from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import ResponsiveService from "../../Services/ResponsiveService";
import { Helmet, HelmetProvider } from "react-helmet-async";
import datingLoveCoaching from "../../assets/images/dating-lovcoach-1.jpg";
import datingLoveCoaching2 from "../../assets/images/dating-lovcoach-2.jpg";
import BigTitle from "../../Components/Uikit/Typography/BigTitle";
import EliteSmallTitle from "../../Components/Uikit/Typography/SmallTitle";
import EliteParagraph from "../../Components/Uikit/Typography/Paragraph";


export function CoachingAmoureuxPage() {
    const [isMobile, setIsMobile] = useState(
        ResponsiveService.isMobile()
    );
    
    const handleResize = (e) => {
        e.preventDefault();
        if (isMobile !== ResponsiveService.isMobile()) {
          setIsMobile({ isMobile: ResponsiveService.isMobile() });
        }
    };
    window.addEventListener("resize", handleResize.bind(this));

    return (
        <HelmetProvider>
        <>
            <Helmet>
                <title>Coaching amoureux Personnalisé avec Elite Berbère</title>
                <meta name="description" content="Vous rencontrez des difficultés niveau amour ? 
                Le coaching amoureux vous permet de trouver l'amour et rendre durable votre relation amoureuse."/>
                <meta name="keywords" content="Affirmation de soi, Rencontrer des personnes, Vaincre, Draguer, 
                Timide, Mieux gérer, Test de personnalité, Trouver l âme soeur, Premier rendez-vous, Coup de foudre, 
                Vie de couple, Histoire d amour, Comment trouver l amour,  Trouver l amour, Relation amoureuse, 
                Rencontrer l amour, Rencontre amoureuse, Grand amour, Personnel et professionnel, Métier de coach, 
                Confiance en soi, Développement personnel, Un coach, Coachs, Coaching"/>
            </Helmet>
            <Header />
            <div className="container">
                <div className="row p-4">
                    <div style={{textAlign: "justify"}} className="col-12">
                        <BigTitle
                            marginBottom={"5vh"}
                            titleColor={"#4e4e4e"}
                            textAlign={"left"}
                            text={"Un coaching amoureux de qualité avec Élite Berbère"}
                        />
                        <EliteParagraph
                            text={<>
                                Vous cherchez à rencontrer des personnes pour <b>trouver l'amour</b> avec un grand A ?
                                Vous devez pour cela vaincre votre caractère timide et votre réserve.
                                Chez Élite Berbère, notre agence de rencontres familiales, nous sommes tout à
                                fait conscients de ce handicap. Afin de le surmonter, nous mettons un <b>coaching
                                amoureux</b> à votre disposition.
                            </>}
                            textAlign={"justify"}
                            fontSize={"1.3em"}/>

                        <EliteSmallTitle
                            textAlign={"left"}
                            text={"Un coaching à votre mesure !"} />
                        <EliteParagraph
                            text={<>
                                Le métier de coach est en pleine effervescence. Nous en mettons à votre disposition,
                                afin de vous faire bénéficier d'un coaching amoureux. Ce partenaire de séduction va
                                non seulement vous apporter un œil nouveau sur vous-même grâce à un test de personnalité
                                réalisé avec les meilleurs outils de mesure, mais il va <b>développer la confiance en soi</b>,
                                indispensable pour s'engager dans une histoire d'amour réussie. Que ce soit pour poser les
                                bases d'un premier rendez-vous ou pour vous projeter dans une vie de couple, un coach est un
                                véritable professionnel de la relation amoureuse. Il connaît tous les aspects incontournables
                                d'une vie amoureuse équilibrée et vous encourage à développer certains traits de votre caractère.
                                Faire en sorte que l'autre vous accorde sa confiance, qu'il devienne véritablement intime sont
                                les premiers paliers pour parvenir à <b>construire une relation</b> de qualité. Avec votre coach, vous
                                êtes entre les mains d'un expert du grand amour. Plus besoin de savoir draguer et d'être parfois
                                blessé dans votre amour-propre, votre coach dispose de toutes les ressources pour vous permettre
                                de trouver l'âme sœur rapidement.
                            </>}
                            textAlign={"justify"}/>
                        <img className={"img-fluid"} style={{marginBottom: "16px"}} src={ datingLoveCoaching } alt={ "coaching amoureux 1" }/>

                        <EliteSmallTitle
                            textAlign={"left"}
                            text={"Comment trouver l'amour grâce à un coaching amoureux ?"} />
                        <EliteParagraph
                            text={<>
                                Ne croyez pas que ce professionnel de la rencontre amoureuse soit un magicien ou encore un farfelu
                                qui va vous enseigner des techniques sans lendemain. Bien au contraire, il va <b>vous accompagner</b> dans
                                une démarche de développement personnel indispensable lorsque l'on veut vraiment s'engager avec un
                                partenaire de vie. Car c'est bien l'objectif que nous nous fixons, chez Élite Berbère.
                                Nous privilégions <b>des valeurs</b> de sérieux, de respect de l'autre et de droiture afin de rencontrer
                                l'amour de manière durable. Nous vous apportons ce service supplémentaire à travers
                                notre <a href={"https://eliteberbere.fr"}>site de
                                rencontre berbère</a> pour mieux gérer votre recherche de partenaire et améliorer votre affirmation de soi.
                                Ces critères sont des bases pour vous permettre d'évoluer dans vos sentiments, de savoir quels critères
                                vous recherchez chez l'autre et dans quelle mesure vous êtes prêt à <b>vous engager</b>.
                            </>}
                            textAlign={"justify"}/>

                        <EliteSmallTitle
                            textAlign={"left"}
                            text={"Lorsque le coup de foudre vient frapper à votre cœur !"} />
                        <EliteParagraph
                            text={<>
                                C'est évidemment la meilleure chose que nous souhaitons à toutes celles et ceux qui nous font confiance
                                pour trouver l'âme sœur. Nous sommes persuadés qu'il est possible de se rendre disponibles, de mieux se
                                connaître et que cela va vous aider dans les domaines personnel et professionnel. Vous êtes alors plus
                                sensible à l'autre et en capacité de repérer celle ou celui qui va vraiment correspondre à vos attentes.
                                Que demander de plus dans une relation de couple heureuse et aboutie ? Nous sommes des intermédiaires de
                                qualité et nous vous offrons tout <b>notre savoir-faire</b> et notre disponibilité pour ébaucher des rencontres
                                et les faire fructifier. Avec notre service de coaching amoureux, nous mettons tous les atouts entre vos
                                mains pour <b>vivre des rencontres</b> qui vont enfin avoir de beaux lendemains.
                            </>}
                            textAlign={"justify"}/>
                        <img className={"img-fluid"} style={{marginBottom: "16px"}} src={ datingLoveCoaching2 } alt={ "coaching amoureux 2" }/>
                    </div>
                </div>
            </div>
            <Footer />
        </>
        </HelmetProvider>
    );
}
