import React, { useState } from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import ResponsiveService from "../../Services/ResponsiveService";
import { Helmet, HelmetProvider } from "react-helmet-async";
import datingBelgium from "../../assets/images/dating-belgium-1.jpg";
import datingBelgium2 from "../../assets/images/dating-belgium-2.jpg";
import BigTitle from "../../Components/Uikit/Typography/BigTitle";
import EliteSmallTitle from "../../Components/Uikit/Typography/SmallTitle";
import EliteParagraph from "../../Components/Uikit/Typography/Paragraph";

export function BelgiquePage() {
    const [isMobile, setIsMobile] = useState(
        ResponsiveService.isMobile()
    );
    
    const handleResize = (e) => {
        e.preventDefault();
        if (isMobile !== ResponsiveService.isMobile()) {
          setIsMobile({ isMobile: ResponsiveService.isMobile() });
        }
    };
    window.addEventListener("resize", handleResize.bind(this));

    return (
        <HelmetProvider>
            <>
                <Helmet>
                    <title>Meilleur Site de Rencontre en Belgique – Elite Berbère</title>
                    <meta name="description" content="Faites connaissance avec des célibataires 
                    belges près de chez vous. Elite Berbère vous aide à rencontrer la personne qu'il vous faut."/>
                    <meta name="keywords" content="Nouvelles personnes, Site de rencontre belge, Rencontre belgique, 
                    Sincère, Nouvelles rencontres, Femme célibataire, Rencontre par affinité, Trouver un partenaire, 
                    Rencontres faciles, Seniors, Relations sérieuses, Nouveau site de rencontre, Faire de belles 
                    rencontres, Bonne personne, Rencontres sérieuses, Faire connaissance, Recherche de l amour, 
                    Affinités, Attractive, Célibataires, Célibataire, Trouver l amour, Faire des rencontres, 
                    Rencontres amicales, Gagner du temps"/>
                </Helmet>
                <Header />
                <div className="container">
                    <div className="row p-4">
                        <div style={{textAlign: "justify"}} className="col-12">
                            <BigTitle
                                marginBottom={"5vh"}
                                titleColor={"#4e4e4e"}
                                textAlign={"left"}
                                text={"Rencontrez de nouvelles personnes grâce à Élite Berbère, le site de rencontre en Belgique"}
                            />
                            <EliteParagraph
                                text={<>
                                    Vous êtes un célibataire installé en Belgique et vous désirez rencontrer un partenaire de vie ?
                                    Si vous désirez <b>construire une relation sérieuse</b> avec des hommes et femmes proches de chez vous,
                                    nous sommes les partenaires privilégiés pour vous aider dans ce projet. Le site de rencontre <b>Elite
                                    Berbère est accessible en Belgique</b>. Il vous accompagne pour trouver l'amour sincère, une personne
                                    partageant vos valeurs et votre culture berbère ou kabyle. Notre site de rencontre en Belgique vous
                                    aidera à <b>trouver la bonne personne</b> pour réaliser votre rêve de fonder un couple qui dure.
                                </>}
                                textAlign={"justify"}
                                fontSize={"1.3em"}/>

                            <EliteSmallTitle
                                textAlign={"left"}
                                text={"Notre entreprise familiale à vos côtés"} />
                            <EliteParagraph
                                text={<>
                                    C'est parce que nous sommes conscients des difficultés que rencontrent les célibataires dans leur
                                    recherche de l'amour que nous avons voulu leur offrir une <b>plateforme de rencontre en Belgique</b> dédiée
                                    à la culture kabyle, amazighe ou berbère. En effet, grâce au climat de confiance que nous savons établir,
                                    vous pouvez échanger aisément et filtrer une rencontre par affinité. Quelle satisfaction de pouvoir enfin
                                    libérer votre parole et échanger sur des sujets que vous partagez ! C'est véritablement l'occasion de
                                    <b>relations sérieuses</b> fondées sur un socle de transparence. Nous sommes des facilitateurs, alors n'hésitez
                                    pas à vous inscrire sur notre <b>site de qualité</b> pour faire des rencontres à votre mesure.
                                </>}
                                textAlign={"justify"}/>

                            <EliteSmallTitle
                                textAlign={"left"}
                                text={"Des valeurs qui comptent dans une rencontre"} />
                            <EliteParagraph
                                text={<>
                                    Nous savons que s'engager dans une relation avec une femme célibataire, un homme, entre seniors… suppose
                                    beaucoup d'attentions. C'est ce que nous mettons en avant dans notre plateforme afin de vous apporter le
                                    <b>respect</b>, <b>la bienveillance</b>, <b>mais aussi l'exigence</b> que vous recherchez. En choisissant de valoriser un cadre
                                    où affinités, attractivité et confiance vous permettent d'établir un dialogue pour des rencontres amicales,
                                    voire amoureuses, si vous rencontrez l'âme sœur, ce que nous appelons de tous nos vœux.
                                </>}
                                textAlign={"justify"}/>
                            <img className={"img-fluid"} style={{marginBottom: "16px"}} src={ datingBelgium } alt={ "dating Belgium 1" }/>

                            <EliteSmallTitle
                                textAlign={"left"}
                                text={"Le site de rencontre en Belgique et partout dans le monde"} />
                            <EliteParagraph
                                text={<>
                                    Si nous mettons à votre disposition ce nouveau site de rencontre en Belgique, c'est que nous nous sommes
                                    animés par la volonté de créer des ponts <b>entre les personnes de même origine</b>. Nous voulons simplifier
                                    votre recherche de l'âme sœur et pour cela aménager un cadre pour des rencontres faciles. Celles-ci
                                    débouchent le plus souvent sur des <b>rencontres sérieuses</b>. Les personnes que vous avez le plaisir de croiser
                                    partagent beaucoup avec vous. Ce sont déjà des confidents et peuvent devenir des compagnons de vie, car vous
                                    avez les mêmes goûts, les mêmes opinions et par-dessus tout l'envie de vous engager dans un projet de vie sur
                                    le long terme. C'est dans cette perspective qu'<b>Elite Berbère</b>, en ciblant les profils que vous pouvez contacter,
                                    vous révèle à vous-même.
                                </>}
                                textAlign={"justify"}/>

                            <EliteSmallTitle
                                textAlign={"left"}
                                text={"Faire connaissance en toute confiance"} />
                            <EliteParagraph
                                text={<>
                                    En bénéficiant d'un <b>site de rencontre en Belgique</b> à la hauteur de vos attentes, vous vous êtes assuré de
                                    concrétiser votre recherche. De nouvelles rencontres alimentent votre recherche. Vous approchez du profil
                                    qui vous correspond en Belgique et vous avez enfin l'assurance de pouvoir vous projeter durablement en compagnie
                                    d'une personne avec laquelle vous partagez tant de points communs. Vous avez enfin une réponse à votre quête de sens.
                                    Quelle que soit votre demande, vous avez sûrement une belle personne qui vous attend sur notre <b>site sérieux et
                                    exigeant</b>. Faites le premier pas, car vous êtes sûr qu'il va dans la bonne direction.
                                </>}
                                textAlign={"justify"}/>
                            <EliteParagraph
                                text={<>
                                    Elite Berbère, le <a href={"https://eliteberbere.fr"}>meilleur site de rencontre berbère</a> est à vos côtés pour vous satisfaire !
                                </>}
                                textAlign={"justify"}/>
                            <img className={"img-fluid"} style={{marginBottom: "16px"}} src={ datingBelgium2 } alt={ "dating Belgium 2" }/>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        </HelmetProvider>
    );
}
