import React, { Component } from "react";
import './TypographyStyles.css';
//title
class EliteSmallTitle extends Component {
  render() {
    return (
      <>
        <h2
            style={{
              textAlign: this.props.textAlign,
            }}
            className={"eliteSmallTitle mt-4"}>
            {this.props.text}
        </h2>
      </>
    );
  }
}
export default EliteSmallTitle;


