import React, { useState } from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import ResponsiveService from "../../Services/ResponsiveService";
import { Helmet, HelmetProvider } from "react-helmet-async";
import datingAfrica from "../../assets/images/dating-africa-1.jpg";
import datingAfrica2 from "../../assets/images/dating-africa-2.jpg";
import BigTitle from "../../Components/Uikit/Typography/BigTitle";
import EliteSmallTitle from "../../Components/Uikit/Typography/SmallTitle";
import EliteParagraph from "../../Components/Uikit/Typography/Paragraph";

export function AfriquePage() {
    const [isMobile, setIsMobile] = useState(
        ResponsiveService.isMobile()
    );
    
    const handleResize = (e) => {
        e.preventDefault();
        if (isMobile !== ResponsiveService.isMobile()) {
          setIsMobile({ isMobile: ResponsiveService.isMobile() });
        }
    };
    window.addEventListener("resize", handleResize.bind(this));

    return (
        <HelmetProvider>
            <>
                <Helmet>
                    <title>{"Site de rencontre en Afrique sérieux & gratuit – EliteBerbere.fr"}</title>
                    <meta name="description" content="Rencontrez des célibataires africains sur Elite Berbère, 
                    trouvez l'homme ou la femme de vos rêves parmi les nombreuses petites annonces."/>
                    <meta name="keywords" content="Compatibles, Nouvelles rencontres, Affinité, Votre 
                    personnalité, Relation durable, Faire une rencontre, Amoureuse, Premier rendez-vous, 
                    Rejoignez, Rencontrer une personne, Entrer en contact, Vie amoureuse, Faire des amis, 
                    Célibataires exigeants, Rencontres entre célibataires, Vie de couple, Femme noire, 
                    Femme africaine, Rencontre africaine, Rencontre en afrique, Faire des rencontres, 
                    Trouver l amour, Relation sérieuse, Rencontre amoureuse, Affinités"/>
                </Helmet>
                <Header />
                <div className="container">
                    <div className="row p-4">
                        <div style={{textAlign: "justify"}} className="col-12">
                            <BigTitle
                                marginBottom={"5vh"}
                                titleColor={"#4e4e4e"}
                                textAlign={"left"}
                                text={"Rencontrer une personne berbère grâce à un site de rencontre en Afrique"}
                            />
                            <EliteParagraph
                                text={<>
                                    Vous rêvez d'une <b>vie de couple</b> avec une personne qui partage les
                                    mêmes <b>affinités </b>
                                    culturelles que vous ? Que vous soyez Chleuh, Kabyle ou Souss, il n'est pas toujours
                                    simple de trouver la bonne personne pour une <b>relation durable</b>. Et cela est d'autant plus
                                    vrai si vous avez dû quitter votre terre natale pour partir vivre ailleurs en Afrique.
                                    Mais pour faire de <b>nouvelles rencontres</b> adaptées à votre personnalité,
                                    vous pouvez compter sur Élite Berbère.
                                </>}
                                textAlign={"justify"}
                                fontSize={"1.3em"}/>

                            <EliteSmallTitle
                                textAlign={"left"}
                                text={"Il n'est pas toujours simple de trouver la personne qui vous comprend"} />
                            <EliteParagraph
                                text={<>
                                    <b>Faire des rencontres</b> n'est pas toujours évident. On hésite, on tâtonne et finalement
                                    on peut passer à côté de la personne faite pour nous. Et pourtant <b>trouver l'amour</b> n'est
                                    pas si compliqué que cela. Même si vous avez des exigences spécifiques. Et cela est tout
                                    à fait normal. Pour faire de <b>nouvelles rencontres</b> qui marchent, mais aussi pour se faire des amis,
                                    il faut partager les mêmes valeurs, traditions, histoire et culture.
                                </>}
                                textAlign={"justify"}/>
                            <EliteParagraph
                                text={<>
                                    Pour avoir la garantie de trouver une Amazighe avec qui partager au mieux, il est alors indispensable
                                    de se tourner vers un <b>site de rencontres en Afrique</b> entre célibataires qui met vos exigences au premier
                                    rang. C'est en effet la meilleure façon d'entrer en contact avec cet homme ou cette <b>femme noire</b> qui
                                    respecte et partage votre culture berbère.
                                </>}
                                textAlign={"justify"}/>
                            <img className={"img-fluid"} style={{marginBottom: "16px"}} src={ datingAfrica } alt={ "dating Africa 1" }/>

                            <EliteSmallTitle
                                textAlign={"left"}
                                text={"Rencontrer une personne qui vous correspond"} />
                            <EliteParagraph
                                text={<>
                                    <b>Faire une rencontre</b> intéressante n'est pas toujours évident. Et c'est lors du <b>premier rendez-vous</b> que bien souvent,
                                    on réalise qu'il ou elle n'est pas fait(e) pour nous.
                                </>}
                                textAlign={"justify"}/>
                            <EliteParagraph
                                text={<>
                                    Si la culture berbère est au cœur de votre vie, il est naturel de vouloir que votre partenaire conçoive l'existence
                                    de la même manière que vous. Avoir de longues discussions sur vos traditions, votre culture, votre histoire autour
                                    d'un repas traditionnel est un moment unique. Il est donc important de pouvoir créer une <b>affinité</b> avec une personne
                                    qui vous comprend parfaitement.
                                </>}
                                textAlign={"justify"}/>
                            <EliteParagraph
                                text={<>
                                    Pour contacter facilement d'autres membres de la communauté berbère sur le territoire africain, vous pouvez alors
                                    vous adresser à un <b>site de rencontre en Afrique</b> sérieux.
                                </>}
                                textAlign={"justify"}/>

                            <EliteSmallTitle
                                textAlign={"left"}
                                text={"Rejoignez votre communauté sur notre site de rencontre en Afrique en quelques clics"} />
                            <EliteParagraph
                                text={<>
                                    Pour <b>entrer en contact</b> facilement avec d'autres personnes d'origine nord-africaine, Élite Berbère est le choix le plus
                                    intelligent à faire. Nous avons mis en place un site de rencontres réservé aux personnes ayant une compatibilité culturelle
                                    importante. Nous ne vous promettons pas une <b>vie amoureuse</b> sans souci (cela dépend de vous), mais nous vous permettons de
                                    rencontrer une personne qui vous ressemble sur le continent africain.
                                </>}
                                textAlign={"justify"}/>
                            <EliteParagraph
                                text={<>
                                    Nous avons réuni des <b>célibataires exigeants</b> pour entamer une relation sérieuse. Il ne vous reste plus qu'à sélectionner la
                                    personne (homme ou <b>femme africaine</b>) qui a fait battre votre cœur un peu plus vite et d'organiser une <b>rencontre amoureuse</b>
                                    digne de vos envies.
                                </>}
                                textAlign={"justify"}/>
                            <EliteParagraph
                                text={<>
                                    Convivial, notre <b>site de rencontre en Afrique</b> est une aide incomparable pour <b>faire une rencontre</b> de qualité.
                                </>}
                                textAlign={"justify"}/>
                            <EliteParagraph
                                text={<>
                                    Notre équipe met en avant le respect, la droiture et le sérieux. Site élitiste, nous vous proposons un service sur-mesure
                                    pour entamer une relation <b>amoureuse</b> sans aucun doute exceptionnelle.
                                </>}
                                textAlign={"justify"}/>
                            <EliteParagraph
                                text={<>
                                    Nous disposons d'antennes à travers le monde ce qui vous permet de trouver la personne parfaite là où vous vous trouvez.
                                    Vous pourrez ainsi faire une <b>rencontre africaine</b> en toute sécurité et pourquoi pas entamer une <b>relation sérieuse</b> avec la
                                    personne qui vous correspond ?
                                </>}
                                textAlign={"justify"}/>
                            <EliteParagraph
                                text={<>
                                    Nous voulons rapprocher tous les amazighophones et arabophones afin qu'ils puissent faire une <b>rencontre en Afrique</b> digne
                                    de leurs exigences sur notre <a href={"https://eliteberbere.fr"}>site de rencontre berbère</a> Élite Berbère.
                                </>}
                                textAlign={"justify"}/>
                            <img className={"img-fluid"} style={{marginBottom: "16px"}} src={ datingAfrica2 } alt={ "dating Africa 2" }/>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        </HelmetProvider>
    );
}
