import React from "react";
import {ReactSVG} from "react-svg";
import UniqueChoiceModal from "../../Uikit/UniqueChoiseModal/UniqueChoiceModal";
import closeButton from "../../../assets/images/btn-close.svg";
import EliteParagraph from "../../Uikit/Typography/Paragraph";
import backArrow from "../../../assets/images/icon-arrow-back.svg";

import ModalField from "../../Uikit/ModalField/ModalField";
import MultipleChoiseModal from "../../Uikit/MultipleChoiseModal/MultipleChoiseModal";

export function Activitie(props) {
  const choices = [
    {'value': 'Administration', 'label': 'Administration'},
    {'value': 'Aéroportuaire', 'label': 'Aéroportuaire'},
    {'value': 'Cadre supérieur', 'label': 'Cadre supérieur'},
    {'value': 'Dirigeant / À son compte', 'label': 'Dirigeant / À son compte'},
    {'value': 'Éducation', 'label': 'Éducation'},
    {'value': 'Finance', 'label': 'Finance'},
    {'value': 'Fonction publique', 'label': 'Fonction publique'},
    {'value': 'Ingénieur spécialisé', 'label': 'Ingénieur spécialisé'},
    {'value': 'Profession libérale', 'label': 'Profession libérale'},
    {'value': 'Restauration / Hôtellerie / Tourisme', 'label': 'Restauration / Hôtellerie / Tourisme'},
    {'value': 'Santé / Médical', 'label': 'Santé / Médical'},
    {'value': 'Secteur privé', 'label': 'Secteur privé'},
    {'value': 'Sportif', 'label': 'Sportif'},
    {'value': 'Vente / Marketing / Publicité', 'label': 'Vente / Marketing / Publicité'},
    {'value': 'Autre', 'label': 'Autre'},
  ];

  function sortAlphabetically(arrayToSort) {
    return arrayToSort.sort(function (a, b) {
      if (a.value.toLowerCase() < b.value.toLowerCase()) {
        return -1;
      }
      if (a.value.toLowerCase() > b.value.toLowerCase()) {
        return 1;
      }
      return 0;
    })
  }

  function handleClose() {
    props.closeModal();
  }

  function onChange(value) {
    props.onChange(value);
    handleClose()
  }

  function renderField(attrs) {
    if (props.multiple) {
      return (
        <MultipleChoiseModal
          choices={sortAlphabetically(choices)}
          {...attrs}
        />)
    }
    return (
      <UniqueChoiceModal
        isMobile={props.isMobile}
        choices={sortAlphabetically(choices)}
        {...attrs}
      />
    )
  }

  return (
    <>
      <div className={props.isMobile ? "d-none" : "d-block px-5 pt-4 pb-2"}>
        <EliteParagraph
          text={props.title}
          fontSize={"24px"}
          textColor={"#6c95e7"}
          textAlign={"left"}
          fontWeight={500}
        />
      </div>
      <div
        className={
          !props.isMobile ? "d-none" : "d-block col-lg-4 col-md-12 mb-4 headerModalMobile"
        }>
        <div className={"back"} style={{marginTop: "64px"}}>
          <div
            onClick={handleClose}>
            <ReactSVG src={backArrow}/> {props.title}
          </div>
        </div>
      </div>
      <div
        className={`d-flex flex-column w-100 contentModalMinHeight position-relative ${props.isMobile ? 'mobile' : 'px-5'}`}>
        <ModalField
          isMobile={props.isMobile}
          id="activitie"
          name="activitie"
          value={props.value}
          onChange={onChange}
          closeModal={handleClose}
          placeholder={props.title}
          renderField={renderField}
          classes={props.isMobile ? 'headerModalMobile' : ''}
        />
      </div>
      <div
        className={
          "closeButtonLevitate d-flex flex-column align-items-center"
        }>
        <ReactSVG
          style={{cursor: "pointer"}}
          src={closeButton}
          onClick={handleClose}/>
      </div>
    </>
  );
}

export default Activitie;
