import React, {useState} from 'react'
import { ReactSVG } from "react-svg";
import Footer from "../../Components/Footer/Footer";
import ConnectedHeader from "../../Components/Header/ConnectedHeader/ConnectedHeader";
import backArrow from "../../assets/images/icon-arrow-back.svg";
import iconCheck from "../../assets/images/icon-check.svg";
import { EliteRouters } from "../../Navigation/Routers";
import TxtAsButton from "../../Components/Uikit/TxtAsButton/TxtAsButton";
import ChooseProfilePhoto from "../../Components/ChooseProfilePhoto/ChooseProfilePhoto";
import EliteParagraph from "../../Components/Uikit/Typography/Paragraph";
import CustomTabs from "../../Components/Uikit/Tabs/Tabs";
import CustomTextArea from "../../Components/Uikit/TextArea/TextArea";
import ProfileDetailsEdition from '../../Components/ProfileDetailsEdition/ProfileDetailsEdition';
import { useHistory } from "react-router-dom";
import { useCurrentUser } from "../../Hooks/AuthenticatedUser";
import { saveProfile } from "./Actions/ProfileActions";
import { Form, Formik } from "formik";
import * as yup from "yup";
import CeQueRecherche from "../../Components/ProfileDetailsEdition/CeQueRecherche";
import Spinner from "react-bootstrap/Spinner";

export function ProfileEdition() {
  const history = useHistory();

  const goBack = () => history.push(EliteRouters.profile.path);
  const { user, setUser } = useCurrentUser();
  const [loading, setLoading] = useState(false);

  const updateProfile = (attr, value) => {
    setUser({ ...user, profile: { ...user.profile, [attr]: value } })
  }

  const updateSearching = (attr, value) => {
    setUser({ ...user, searching: { ...user.searching, [attr]: value } })
  }

  const setImages = (images) => {
    setUser({ ...user, images })
  }
  const onChange = (profile) => {
    setUser(profile)
  }

  const descriptionValidationSchema = yup.object().shape({
    description: yup.string().required('Champ obligatoire').min(50).max(500)
  })

  async function onSaveProfile() {
    const profile = await saveProfile(user)
    if (profile) {
      setUser(profile);
    }
  }

  const tabsItems = [
    {
      menuItem: (
        <EliteParagraph
          textAlign={ "left" }
          uppercase
          fontWeight={ 500 }
          margin={ "0" }
          text={ "à propos de moi" }
        />
      ),
      menuContent: (
        <>
          <EliteParagraph
            textColor={ "#6c95e7" }
            uppercase
            fontWeight={ 500 }
            textAlign={ "left" }
            margin={ '32px 0 16px 0' }
            text={ "Description" }
          />
          <Formik
            initialValues={ { description: user.profile.pending_description ? user.profile.pending_description : user.profile.description } }
            validationSchema={ descriptionValidationSchema }
            validateOnChange
            onSubmit={ ({ description }) => updateProfile('description', description) }
          >
            { ({ setFieldValue, values, submitForm, isValid, handleBlur }) => (
              <Form className={ "needs-validation" }>
                <div className={ `has-validation ${ isValid ? '' : 'isInvalid' }` }>
                  <CustomTextArea
                    name="description"
                    classes={ isValid ? '' : 'isInvalid' }
                    maxCharacters={ 500 }
                    minCharacters={ 50 }
                    value={ values.description === undefined ? (user.profile.pending_description ? user.profile.pending_description : user.profile.description) : values.description }
                    onChange={ (value) => setFieldValue('description', value, true) }
                    onBlur={ submitForm }
                    toModerate={ !!user.profile.pending_description }
                  />
                </div>
              </Form>
            ) }
          </Formik>
          <EliteParagraph
            textColor={ "#6c95e7" }
            uppercase
            fontWeight={ 500 }
            textAlign={ "left" }
            text={ "Détails" }
            margin={ '32px 0 16px 0' }
          />
          <ProfileDetailsEdition user={ user } onChange={ onChange }/>
        </>
      ),
    },
    {
      menuItem: (
        <EliteParagraph
          uppercase
          textAlign={ "left" }
          fontWeight={ 500 }
                margin={"0"}
                text={"Ce que je recherche"}
              />
            ),
            menuContent: (
              <>
                <EliteParagraph
                  textColor={ "#6c95e7" }
                  uppercase
                  fontWeight={ 500 }
                  textAlign={ "left" }
                  margin={ '32px 0 16px 0' }
                  text={ "Description" }
                />
                <Formik
                  initialValues={ { description: user.searching.pending_description ? user.searching.pending_description : user.searching.description } }
                  validationSchema={ descriptionValidationSchema }
                  validateOnChange
                  onSubmit={ ({ description }) => updateSearching('description', description) }
                >
                  { ({ setFieldValue, values, isValid, submitForm }) => (
                    <Form className={ "needs-validation" }>
                      <div className={ `has-validation ${ isValid ? '' : 'isInvalid' }` }>
                        <CustomTextArea
                          name="description"
                          classes={ isValid ? '' : 'isInvalid' }
                          maxCharacters={ 500 }
                          minCharacters={ 50 }
                          value={ values.description }
                          onChange={ (value) => setFieldValue('description', value, true) }
                          onBlur={ submitForm }
                          toModerate={ !!user.searching.pending_description }
                        />
                      </div>
                    </Form>
                  ) }
                </Formik>
                <EliteParagraph
                  textColor={ "#6c95e7" }
                  uppercase
                  fontWeight={ 500 }
                  textAlign={ "left" }
                  text={ "Détails" }
                  margin={ '32px 0 16px 0' }
                />
                <CeQueRecherche user={ user } onChange={ setUser }/>
              </>
            ),
          },
        ];

  return (
        <div>
          <ConnectedHeader/>
          <div className={ "container" }>
            <div className="row">
           <div className="col-2"></div>
           <div className="col-8">
             <div className="row">
             <div className="col-4">
                <div className={ "back py-5" }>
                  <div onClick={ goBack }>
                    <ReactSVG src={ backArrow }/> Modifier mon profil
                  </div>
                </div>
              </div>
              <div className="col-4"/>
              <div className="col-4 py-5 justify-content-center">
                <TxtAsButton
                  width={ "100%" }
                  icon={ iconCheck }
                  text={ "Valider les modifications" }
                  onClick={ () => {
                      setLoading(true)
                      onSaveProfile().then(() => setLoading(false)).catch(() => setLoading(false))
                  } }
                />
              </div>
              <div className="col-12">
                  {loading ?
                      <div className="row">
                          <div className="loading-searching">
                              <Spinner animation="border" />
                          </div>
                      </div> :
                      <div className="row">
                          <div className="col-5">
                              <ChooseProfilePhoto images={user.images} onChange={setImages}/>
                          </div>
                          <div className="col-7">
                              <CustomTabs tabs={tabsItems}/>
                          </div>
                      </div>
                  }
              </div>
             </div>
           </div>
           <div className="col-2"></div>
            </div>
          </div>
          <Footer isLogged/>
        </div>
      );
  }

export default ProfileEdition
