import React, { useEffect } from "react";

import ProfilePhoto from "../../../Components/ProfilePhoto/ProfilePhoto";
import Tags from "../../../Components/Uikit/Tags/Tags";
import TitleOverTitle from "../../../Components/Uikit/TitleOverTitle/TitleOverTitle";
import infosReligion from "../../../assets/images/infos-religion.svg";
import CustomTabs from "../../../Components/Uikit/Tabs/Tabs";
import EliteParagraph from "../../../Components/Uikit/Typography/Paragraph";
import AProposDeMoi from "../AProposDeMoi";
import JeRecherche from "../JeRecherche";
import Footer from "../../../Components/Footer/Footer";
import FooterMenu from "../../../Components/FooterMenu/FooterMenu";
import iconUserTitle from '../../../assets/images/icon-user-title.svg';
import { useCurrentUser } from "../../../Hooks/AuthenticatedUser";
import { toast } from "react-toastify";
import {origins} from "../../../Services/DataLists";

export function ProfileMobile() {
  const { user } = useCurrentUser()

  useEffect(() => {
    function handlePercentage() {
      if(user.percentageComplete < 100) {
        toast.warning("Profil non complété à 100%", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    handlePercentage()
  }, [user.percentageComplete])

  const tabsItems = [
    {
      menuItem: (
        <EliteParagraph
          textAlign={ "left" }
          uppercase
          fontWeight={ 500 }
          margin={ "0" }
          text={ "à propos de moi" }
          fontSize={ '16px' }
        />
        ),
        menuContent: (
          <>
            <EliteParagraph
              lineHeight={1.5}
              fontWeight={400}
              textAlign={"left"}
              fontSize={"16px"}
              text={ user.profile.description }
            />
            <AProposDeMoi user={ user }/>
          </>
        ),
      },
      {
        menuItem: (
          <EliteParagraph
            uppercase
            textAlign={"left"}
            fontWeight={500}
            margin={"0"}
            text={"Ce que je recherche"}
            fontSize={'12px'}
          />
        ),
        menuContent: (
          <>
            <EliteParagraph
              lineHeight={1.5}
              fontWeight={400}
              textAlign={"left"}
              fontSize={"14px"}
              text={ user.searching.description }
            />
            <JeRecherche user={ user } />
          </>
        ),
      },
    ];

    let getImageLabel = (value) => {
      let imageLabel = "";
      origins.forEach((e) => {
        if (value && (e.value === value || e.value === value[0])) {
          imageLabel = e.imageLabel;
        }
      })
      return imageLabel;
    }

    return (
      <>
        <div className={"container"}>
          <div className="row">
            <div className="col-12">
              <TitleOverTitle
                backTitleSize={"36px"}
                frontTitleSize={"24px"}
                title={"Mon profil"}
                overTitleMarginTop={"-25px"}
                icon={iconUserTitle}
              />
            </div>
            <div className="col-md-12 col-lg-6 d-flex flex-column">
              <ProfilePhoto
                user={ user }
                completitionPercentage={ user.percentageComplete }
              />
              <div className="d-flex justify-content-evenly mt-2 pl-3 pr-3">
                <Tags
                  icon={ `/images/flags/${getImageLabel(user.profile.origin)}.png` }
                  smallText={ "Origine" }
                  text={ user.profile.origin }/>
                <Tags
                  icon={infosReligion}
                  smallText={"je suis"}
                  text={ user.profile.religion }/>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 p-4 ">
              <CustomTabs tabs={tabsItems}/>
            </div>
          </div>
        </div>
        <Footer isLogged/>
        <FooterMenu/>
      </>
    );
  }
