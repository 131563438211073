import React from "react";
import {ReactSVG} from "react-svg";

import closeButton from "../../../assets/images/btn-close.svg";
import backArrow from "../../../assets/images/icon-arrow-back.svg";
import EliteParagraph from "../../Uikit/Typography/Paragraph";
import ModalField from "../../Uikit/ModalField/ModalField";

import Slider from "../../Uikit/Slider/Slider";

export function Age(props) {

  function handleClose() {
    props.closeModal();
  }

  function onChange([min, max]) {
    props.onChange({min, max});
    handleClose()
  }

  function renderField({value, ...attrs}) {
    return (
      <Slider
        starterPoint={value[0] || 18}
        endPoint={value[1] || 50}
        max={90}
        min={18}
        step={1}
        decimal={0}
        showValidationButton
        suffix={props.suffix}
        specificType={"sliderAge"}
        {...attrs}
      />
    )
  }

  return (
    <>
      <div className={props.isMobile ? "d-none" : "d-block px-5 pt-4 pb-2"}>
        <EliteParagraph
          text={props.title}
          fontSize={"24px"}
          textColor={"#6c95e7"}
          textAlign={"left"}
          fontWeight={500}
        />
      </div>
      <div
        className={
          !props.isMobile ? "d-none" : "d-block col-lg-4 col-md-12 mb-4 headerModalMobile"
        }>
        <div className={"back"} style={{marginTop: "64px"}}>
          <div
            onClick={handleClose}>
            <ReactSVG src={backArrow}/> {props.title}
          </div>
        </div>
      </div>
      <div
        className={`d-flex flex-column w-100 contentModalMinHeight position-relative ${props.isMobile ? 'mobile' : 'px-5'}`}>
        <ModalField
          isMobile={props.isMobile}
          id="age"
          name="age"
          value={[props.value.min, props.value.max]}
          onChange={onChange}
          closeModal={handleClose}
          placeholder={props.title}
          renderField={renderField}
          classes={props.isMobile ? 'headerModalMobile' : ''}
        />
      </div>
      <div
        className={
          "closeButtonLevitate d-flex flex-column align-items-center"
        }>
        <ReactSVG
          style={{cursor: "pointer"}}
          src={closeButton}
          onClick={handleClose}/>
      </div>
    </>
  );
}

export default Age;
