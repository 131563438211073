import React, { useState } from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import ResponsiveService from "../../Services/ResponsiveService";
import { Helmet, HelmetProvider } from "react-helmet-async";
import datingAmazigh from "../../assets/images/dating-amazigh-1.jpg";
import datingAmazigh2 from "../../assets/images/dating-amazigh-2.jpg";
import BigTitle from "../../Components/Uikit/Typography/BigTitle";
import EliteSmallTitle from "../../Components/Uikit/Typography/SmallTitle";
import EliteParagraph from "../../Components/Uikit/Typography/Paragraph";

export function AmazighPage() {
    const [isMobile, setIsMobile] = useState(
        ResponsiveService.isMobile()
    );
    
    const handleResize = (e) => {
        e.preventDefault();
        if (isMobile !== ResponsiveService.isMobile()) {
          setIsMobile({ isMobile: ResponsiveService.isMobile() });
        }
    };
    window.addEventListener("resize", handleResize.bind(this));

    return (
        <HelmetProvider>
            <>
                <Helmet>
                    <title>Site de rencontre Amazigh – EliteBerbere.fr</title>
                    <meta name="description" content="Vous souhaitez rencontrer des amazighs ? Nous avons la 
                    solution ! Inscrivez-vous en quelques clics sur notre site de rencontre Elite Berbère."/>
                    <meta name="keywords" content="Hommes et femmes, Rencontre par affinités, Faire de belles 
                    rencontres, Langue et la culture, Rencontre sérieuse, Sites de rencontre, Relation sérieuse, 
                    Mouvement culturel, Peuple kabyle, Culture berbère, Origine berbère, Langue amazigh, Langue berbère, 
                    Berbères, Touareg, Sahara, Haut-atlas, Langue kabyle, Atlas, Berbérophones, Maghreb, Afrique du Nord, 
                    Kabyle, Berbère, Amazigh"/>
                </Helmet>
                <Header />
                <div className="container">
                    <div className="row p-4">
                        <div style={{textAlign: "justify"}} className="col-12">
                            <BigTitle
                                marginBottom={"5vh"}
                                titleColor={"#4e4e4e"}
                                textAlign={"left"}
                                text={"Faites de belles rencontres dans un climat de confiance grâce à Elite berbère, le site de rencontre amazigh"}
                            />
                            <EliteParagraph
                                text={<>
                                    En tant que célibataire désireux d'<b>engager une relation sérieuse</b> avec des hommes et femmes qui correspondent
                                    à vos valeurs, vous êtes souvent déçu. Les sites de rencontres habituels sur lesquels vous recherchez des profils
                                    qui pourraient matcher sont décevants. Vous avez besoin de vous lancer dans une rencontre sérieuse qui est <b>en
                                    accord avec votre culture berbère</b>, sur un site de rencontre amazigh.
                                </>}
                                textAlign={"justify"}
                                fontSize={"1.3em"}/>

                            <EliteSmallTitle
                                textAlign={"left"}
                                text={"Une start-up familiale à votre service"} />
                            <EliteParagraph
                                text={<>
                                    Forts de notre origine berbère, nous avons voulu offrir aux célibataires de France et du monde entier une
                                    plateforme de rencontres qui rassemble les communautés Kabyle, Berbère et Amazigh. Pour ce faire, nous
                                    prônons les valeurs de sérieux, de droiture et de respect, indispensables pour un enjeu comme le nôtre.
                                    En vous inscrivant sur notre <b>site de rencontre amazigh</b>, vous savez que vous allez pouvoir faire une rencontre
                                    par affinités qui va vraiment compter.
                                </>}
                                textAlign={"justify"}/>

                            <EliteSmallTitle
                                textAlign={"left"}
                                text={"Des langues pour vraiment se comprendre"} />
                            <EliteParagraph
                                text={<>
                                    Les prémices d'une relation durable reposent le plus souvent sur la compréhension des attentes de l'autre.
                                    Nous privilégions les berbérophones et toutes celles et tous ceux qui pratiquent la langue amazighe, la langue
                                    kabyle et bien sûr la langue berbère. C'est cette communion de langues qui permet de poser les bases d'une
                                    relation de qualité.
                                </>}
                                textAlign={"justify"}/>
                            <img className={"img-fluid"} style={{marginBottom: "16px"}} src={ datingAmazigh } alt={ "dating Amazigh 1" }/>

                            <EliteSmallTitle
                                textAlign={"left"}
                                text={"Des peuples qui se rencontrent"} />
                            <EliteParagraph
                                text={<>
                                    L'Afrique du Nord et plus particulièrement le <b>Maghreb</b> est un véritable creuset de mouvements culturels divers et
                                    variés. C'est dans cette richesse, entre Touareg, au Sahara et Haut Atlas, le peuple kabyle avec des racines
                                    profondes et uniques que nous vous proposons de puiser. C'est une merveilleuse opportunité pour <b>rencontrer
                                    vraiment l'âme sœur</b>, celle ou celui qui partage les mêmes goûts, les mêmes ambitions et surtout la volonté tenace
                                    de s'inscrire dans une dynamique positive. Notre <b>site de rencontre amazigh</b> est un livre ouvert pour écrire
                                    votre futur.
                                </>}
                                textAlign={"justify"}/>

                            <EliteSmallTitle
                                textAlign={"left"}
                                text={"La langue et la culture au premier rang de nos priorités"} />
                            <EliteParagraph
                                text={<>
                                    L'Atlas est un symbole dont nous pouvons tous nous inspirer. C'est un sommet qu'il est ardu de grimper.
                                    Nous rencontrons des difficultés au quotidien, cependant cette cime nous appelle. Elle est le gage d'une
                                    harmonie à venir. Cet espoir est suffisamment puissant pour que nous mettions tout en œuvre pour vous
                                    permettre d'<b>accomplir votre destinée</b> en trouvant l'être que vous chérirez. Celle-ci ou celui-ci va <b>vous
                                    correspondre en tous points</b>. Vous allez échanger dans votre langue, participer aux mêmes rituels, vous
                                    engager dans une même voie de droiture et d'exigence.
                                </>}
                                textAlign={"justify"}/>

                            <EliteSmallTitle
                                textAlign={"left"}
                                text={"Élite Berbère, le site de rencontre amazigh à vos côtés pour vous complaire !"} />
                            <EliteParagraph
                                text={<>
                                    Ne soyez pas déçus par les rencontres que vous avez vécues. Nous sommes vos partenaires de confiance pour
                                    vous conduire sur le chemin ardu de la <b>réussite amoureuse</b>. En favorisant les rencontres qui peuvent déclencher
                                    un sentiment d'appartenance, de sens et de véritable et profond attachement, vous êtes assuré de vivre pleinement.
                                    Faites le choix d'un <b>engagement véritable</b> sur des bases qui vont vous garantir de vous élever vers la sagesse que
                                    vous recherchez. C'est notre motivation chez Élite Berbère, le <a href={"https://eliteberbere.fr"}>site de rencontre berbère</a> et amazigh, et vous allez
                                    la partager dans une atmosphère de <b>parfaite complicité</b> pour enfin faire des rencontres qui sont à votre mesure.
                                </>}
                                textAlign={"justify"}/>
                            <img className={"img-fluid"} style={{marginBottom: "16px"}} src={ datingAmazigh2 } alt={ "dating Amazigh 2" }/>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        </HelmetProvider>
    );
}
