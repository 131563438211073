import React, {useEffect, useRef, useState} from "react";
import "./HeaderStyle.css";
import CustomTextField from "../Uikit/TextFields/TextFields";
import CustomModal from "../Uikit/Modal/Modal";
import {ForgotPassword} from "../../Containers/ForgotPassword/ForgotPassword";
import TxtAsButton from "../Uikit/TxtAsButton/TxtAsButton";
import {Actions as LoginActions} from "../../Containers/Login/Actions";
import {EliteRouters} from "../../Navigation/Routers";
import {handleError} from "../../Services/ErrorHandler";
import {useHistory} from "react-router-dom";
import smallLogo from "../../assets/images/logo-short-colo.png"
import Spinner from "react-bootstrap/Spinner";

export default function Header() {
  const forgot = useRef();
  const history = useHistory();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (history.location.pathname
      && (
        history.location.pathname.match(/^\/reset-password/)
        || history.location.pathname === '/forgot-password')
    ) {
      showForgot()
    }
  }, [history.location.pathname])

  async function onLogin() {
    try {
      await LoginActions.logIn(email, password)
      window.location.href = "/home";
    } catch (err) {
      handleError(err)
    }
  }

  function showForgot() {
    forgot.current.handleOpenModal();
  }

  function goHome() {
    window.location.href = "/";
  }

  return (
    <>
      <div
        className={"container-fluid align-items-center header"}>
        {history.location.pathname === '/' || history.location.pathname === '/login' || history.location.pathname.match(/^\/reset-password/) || history.location.pathname === '/forgot-password' ?
          <div className="row align-items-center">
            <div className={"col-lg-6 col-2 align-items-center"}>
              <img title="Revenir à l'accueil" onClick={goHome} src={smallLogo} className={"img-fluid btn"}
                   alt="site de rencontre berbère"/>
            </div>
            <div className={"col-lg-6 col-10"}>
              <div className={"formContainer"}>
                <div className={"row"}>
                  <div className={'col-lg-2 col-1'}/>
                  <div className={"col-lg-4 col-4"}>
                    <CustomTextField
                      validateLogin={true}
                      email={email}
                      password={password}
                      value={email}
                      placeholder={"E-mail"}
                      onChange={setEmail}
                      isSubscription
                      height={'32px'}
                    />
                  </div>
                  <div className={"col-lg-4 col-4 loginEmailCont"}>
                    <CustomTextField
                      validateLogin={true}
                      email={email}
                      password={password}
                      type={"password"}
                      value={password}
                      onChange={setPassword}
                      placeholder={"Mot de passe"}
                      isSubscription
                      height={'32px'}
                      showPasswordIconClass="showPassword p-0 mt-1"
                    />
                    <small className={"forgotPassword"} onClick={showForgot}>Mot de passe oublié ?</small>
                    <CustomModal
                      modalContent={<ForgotPassword closeModal={forgot}/>}
                      modalClass={"forgotPasswordModal"}
                      ref={forgot}
                    />
                  </div>
                  <div className={"col-lg-2 col-3 d-flex justify-content-center"}>
                    {loading ?
                      <div className="loading-searching">
                        <Spinner animation="border"/>
                      </div> :
                      <TxtAsButton
                        routerProps={history}
                        targetUrl={EliteRouters.home.path}
                        onClick={() => {
                          setLoading(true)
                          onLogin().then(() => setLoading(false)).catch(() => setLoading(false))
                        }}
                        text={"ok"}
                        height={'32px'}
                        width={'51px'}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>

          </div>
          :
          <div className={"col-12 align-items-center"}>
            <img title="Revenir à l'accueil" onClick={goHome} src={smallLogo} className={"img-fluid btn"} alt="..."/>
          </div>
        }
      </div>
    </>
  );
}
