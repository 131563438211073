import React, { useEffect, useState } from "react";
// import { Form } from "react-final-form";
import "react-credit-cards/es/styles-compiled.css";
import { EliteRouters } from "../../Navigation/Routers";
import backArrow from "../../assets/images/icon-arrow-back.svg";

import UserService from "../../Services/ApiServices/UserService";
import { useLocation, useParams } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
// import Styles from "./CheckoutStyles.js";
import "./CheckoutStyles.css";
import { toast } from "react-toastify";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { getPaypalClientId } from "../../Services/ApiServices/api.client";
import Spinner from "react-bootstrap/esm/Spinner";
import ConnectedHeader from "../../Components/Header/ConnectedHeader/ConnectedHeader";
// import { generatePath } from "react-router-dom";
import { ReactSVG } from "react-svg";
import CardOffer from "../../Components/Offers/CardOffer";

import jcb from "../../assets/images/payments/jcb.png";
import visa from "../../assets/images/payments/visa.png";
import amex from "../../assets/images/payments/amex.png";
import mastercard from "../../assets/images/payments/mastercard.png";

// import { toast } from "react-toastify";
// import UserService from "../../Services/ApiServices/UserService";

export function Checkout(props) {
  // const queryString = props.location.search;
  // const history = useHistory();
  const location = useLocation();

  const { selectedSub } = useParams();

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  // const [details, setDetails] = useState(false);

  const [subscriptionId, setSubscriptionId] = useState();
  const [isBoostOffer, setIsBoostOffer] = useState();
  const [sub, setSub] = useState();
  const [clientId, setClientId] = useState("");
  const [plans, setPlans] = useState(null);

  //const subscription = new FormData();
  let subscription = {
    isBoostOffer: false,
    paymentSubscriptionId: "",
    subscriptionId: "",
    devise: "",
  };

  useEffect(() => {
    const fetchPlans = async () => {
      await UserService.getPlansFromPayPal().then((res) => {
        setPlans(res.data.plans);
      });
    };

    fetchPlans();
  }, []);

  useEffect(() => {
    getPaypalClientId().then((res) => {
      setClientId(res.clientId);
    });
  }, []);

  useEffect(() => {
    //if (!props.modifyCard) {
    // if (location.state) {
    //setPlans(location.state.plans);
    // }

    const query = new URLSearchParams(location.search);
    let boost = query && query.get("boost") === "true";
    setIsBoostOffer(boost);

    const setSubscription = (res) => {
      let list = res.data.list;
      let sub_ = list.find((i) => i.name.toLowerCase() === selectedSub);

      if (sub_ && sub_.id) {
        setSub(sub_);
        setSubscriptionId(sub_.id);
      }
    };

    if (boost) {
      UserService.getBoostSubscriptions().then((res) => setSubscription(res));
    } else {
      UserService.getSubscriptions().then((res) => setSubscription(res));
    }

    //}
  }, [location, selectedSub]);

  /*
  
  const Plan = () => {
    if (sub.name === "DIAMANT") {
      return <img src={DIAMANT} alt="diamant" />;
    } else if (sub.name === "RUBIS") {
      return <img src={RUBIS} alt="rubis" />;
    } else if (sub.name === "SAPHIR") {
      return <img src={SAPHIR} alt="saphir" />;
    } else if (sub.name === "STARTER") {
      return <img src={STARTER} alt="starter" />;
    } else {
      return <img src={BOOSTER} alt="booster" />;
    }
  };

  const onSubmit = async (values) => {
    if (props.modifyCard) {
      props.modifyCard(values);
    } else {
      try {
        await UserService.handleFormSubmit(
          values,
          subscriptionId,
          isBoostOffer
        );
        await UserService.getCurrentSubscription();
      } catch (e) {
        toast.error(e.response.data.error.message);
        return;
      }
      toast.success("Votre abonnement a bien été validé, Bienvenue à vous !");
      props.history.push("/home");
    }
  };
*/

  const getSubscFromPaypal = async (subscriptionid) => {
    await UserService.getSubscriptionFromPayPal(subscriptionid).then(
      (response) => {
        subscription.isBoostOffer = isBoostOffer;
        subscription.paymentSubscriptionId = subscriptionid;
        subscription.subscriptionId = subscriptionId;
        subscription.devise = sub.devise;
      }
    );
  };

  const subscribeStripe = async () => {
    const {
      data: { id },
    } = await UserService.subscribeStripe(
      { id: sub.id, currency: sub.devise, isBoostOffer },
      sub.id
    );
    return handleRedirectionStripe(id);
  };

  const handleRedirectionStripe = async (sessionId) => {
    const stripe = await stripePromise;
    await stripe.redirectToCheckout({ sessionId });
  };

  const SubCardOffer = () => {
    let s = sub;

    if (s) {
      return (
        <div className="checkOutCard">
          <CardOffer
            cardHeight="auto"
            hideAction={true}
            disableCurrencyChange={true}
            sliderData={s}
          />
        </div>
      );
    } else {
      return <></>;
    }
  };

  const StripePayments = () => {
    let cardPayments = [visa, mastercard, jcb, amex],
      OptionsList = cardPayments.map((i) => {
        return <img height="50px" className="mx-2" src={i} alt="" key={i} />;
      });

    return (
      <>
        <div className="d-flex justify-content-center">
          <fieldset
            style={{
              border: "1px solid #e0e0e0",
              borderRadius: "5px",
            }}
          >
            <div className="d-flex px-3 py-4" onClick={subscribeStripe}>
              {OptionsList}
            </div>
          </fieldset>
        </div>
      </>
    );
  };

  const PaypalOption = () => {
    if (clientId && plans) {
      return (
        <div>
          <PayPalScriptProvider
            options={{ "client-id": clientId, vault: true }}
          >
            <PayPalButtons
              fundingSource="paypal"
              style={{
                layout: "vertical",
                color: "black",
                label: "pay",
              }}
              createSubscription={(data, actions) => {
                const plan = plans.filter(
                  (pl) =>
                    pl.name.includes(sub.devise) &&
                    pl.name.includes(sub.name) &&
                    pl.status === "ACTIVE"
                );
                console.trace(actions);
                return actions.subscription.create({
                  plan_id: plan[0].id,
                });
              }}
              onApprove={async (data, actions) => {
                await getSubscFromPaypal(data.subscriptionID);
                await UserService.createUserSubscription({
                  ...subscription,
                  module_subscription_type: "paypal",
                  devise: sub.currency,
                });

                await UserService.getCurrentSubscription();
                toast.success("Souscription effectuée avec succès");
                props.history.push("/search");
              }}
            />
          </PayPalScriptProvider>
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" />
        </div>
      );
    }
  };
  return (
    <>
      <div>
        <ConnectedHeader isCheckout="true" />
        {
          <div className="container">
            <div className="d-flex my-5">
              <button
                onClick={() => {
                  props.history.push(EliteRouters.home.path);
                }}
                className="d-inline-flex btn"
                style={{
                  textDecoration: "none",
                  boxShadow: "none",
                }}
              >
                <ReactSVG src={backArrow}></ReactSVG>
                <span
                  className=""
                  style={{
                    fontSize: "20px",
                    display: "inline-block",
                    color: "#34D10E",
                    marginLeft: "32px",
                  }}
                >
                  Retour
                </span>
              </button>
            </div>
            <div className="row">
              <div className="col-12 col-md-4">
                <SubCardOffer />
                <div
                  className="mt-5 text-center"
                  style={{
                    fontSize: "12px",
                    lineHeight: "20px",
                    fontWeight: "300",
                  }}
                >
                  <p>
                    En activant votre abonnement, vous autorisez SAS ÉLITE
                    BERBÈRE à débiter votre carte pour ce paiement et les
                    paiements futurs conformément à ses conditions.
                  </p>

                  <p
                    className="mt-4"
                    style={{
                      color: "#6c95e7",
                      fontStyle: "italic",
                    }}
                  >
                    <a
                      style={{ color: "inherit", textDecoration: "none" }}
                      target="__blank"
                      href="/privacy-policy"
                    >
                      Politique de confidentialité
                    </a>
                    <span> - </span>
                    <a
                      style={{ color: "inherit", textDecoration: "none" }}
                      target="__blank"
                      href="/terms-conditions"
                    >
                      Conditions générales
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-7 offset-md-1">
                <p
                  style={{
                    color: "#6C95E7",
                    textTransform: "uppercase",
                    marginBottom: "20px",
                  }}
                >
                  Valider votre abonnement avec un moyen de paiement sécurisé
                  ci-dessous :
                </p>
                <div className="">
                  <div className="">
                    <button
                      className="btn d-inline-flex align-items-center justify-content-center border w-100 stripe-checkout"
                      onClick={subscribeStripe}
                    >
                      <span>Payer avec une&nbsp;</span>
                      <span
                        style={{
                          fontWeight: "600",
                        }}
                      >
                        CARTE BANCAIRE
                      </span>
                    </button>
                  </div>
                  <div className="mt-5">
                    <StripePayments />
                  </div>
                  <div className="py-5">
                    <strong className="d-block text-center mb-0">Ou</strong>
                  </div>
                  <div className="">
                    <PaypalOption />
                  </div>
                </div>

                <div className="my-5">
                  <p
                    className="text-center"
                    style={{
                      fontSize: "12px",
                      lineHeight: "20px",
                    }}
                  >
                    Une fois le moyen de paiement sélectionné, une nouvelle
                    fenêtre sera ouverte, veuillez suivre les indications afin
                    de valider votre abonnement.
                  </p>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </>
  );
}
