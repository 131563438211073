import UserService from "../../../Services/ApiServices/UserService";

export const Actions = {
  handleFiltersContainer: () => {
    this.setState({
      filtersIsOpen: !this.state.filtersIsOpen,
    });
  },

  handleFiltersOptionsChange: (key, value) => {
    let obj = {}
    obj[key] = value
    this.setState({"selectedOptions" : {...this.state.selectedOptions, ...obj}})
  },

  fetchProfiles: (selectedOptions) => {
    return UserService.fetchProfiles(selectedOptions)
  }
};
