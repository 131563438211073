import axios from "axios/index";

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${ localStorage.getItem('tokenApi') || '' }`
    },
    withCredentials: true,
});

export const apiClientPaypal = axios.create({
    baseURL: process.env.REACT_APP_PAYPAL_API_URL,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${ localStorage.getItem('paypalTokenApi') || '' }`
    }
});

export function setToken(token){
    apiClient.defaults.headers.Authorization = `Bearer ${ token }`
    localStorage.setItem('tokenApi', token)
}

export function setPaypalToken(token){
    apiClientPaypal.defaults.headers.Authorization = `Bearer ${ token }`
    localStorage.setItem('paypalTokenApi', token)
}

export function setCurrentSubscription(subscription){
    localStorage.removeItem('subscription')
    if (subscription && subscription?.name !== "") {
        localStorage.setItem('subscription', JSON.stringify(subscription))
    }
}

export function setMatchingSubscription(subscription){
    localStorage.removeItem('matching')
    if (subscription && subscription?.name !== "") {
        localStorage.setItem('matching', JSON.stringify(subscription))
    }
}

export function getCurrentSubscription(){
    return localStorage.getItem('subscription') !== null && localStorage.getItem('subscription') !== "" ?
        (JSON.parse(localStorage.getItem('subscription'))?.name !== "" ? JSON.parse(localStorage.getItem('subscription')) : null) : null
}

export function getMatchingSubscription(){
    return localStorage.getItem('matching') !== null && localStorage.getItem('matching') !== "" ?
        (JSON.parse(localStorage.getItem('matching'))?.name !== "" ? JSON.parse(localStorage.getItem('matching')) : null) : null
}

export function hasValidSubscription(){
    return getCurrentSubscription() !== null
}

export function hasMatchingSubscription(){
    return getMatchingSubscription() !== null
}

export function getPaypalClientId(){
    return apiClient.get('/api/clientId').then((response) => {
        return response.data;
      });
}

export function getPaypalAccessToken(){
   /* return apiClient.get('/api/paypaltoken').then((response) => {
        return response.data
    });*/
    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic " + 
    btoa(process.env.REACT_APP_PAYPAL_CLIENT_ID + ":" + process.env.REACT_APP_PAYPAL_CLIENT_SECRET));

    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    let urlencoded = new URLSearchParams();
    urlencoded.append("grant_type", "client_credentials");

    let requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
    redirect: 'follow'
    };

    return fetch(process.env.REACT_APP_PAYPAL_API_URL+"/v1/oauth2/token", requestOptions)
  .then(response => response.text())
  .then(result => { return JSON.parse(result)})
  .catch(error => console.log('error', error));
}

export default apiClient;
