import React, { Component } from "react";
import { ReactSVG } from "react-svg";
import nextArrow from "../../../../assets/images/right-arrow.svg";

class NextArrow extends Component {
  render() {
    return (
      <div className={this.props.className} onClick={this.props.onClick}>
        <ReactSVG src={nextArrow}></ReactSVG>
      </div>
    );
  }
}
export default NextArrow;
