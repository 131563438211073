import React, { Component } from "react";
import iconMenu from "../../../assets/images/icon-menu.svg";
import iconMenuColor from "../../../assets/images/icon-menu-ctx-color.svg";
import iconClose from "../../../assets/images/icon-ignore-ctx.svg";
import iconCloseColor from "../../../assets/images/icon-ignore-ctx-color.svg";
import EliteParagraph from "../Typography/Paragraph";
import "./ContextualMenuStyles.css";

export class ContextualMenu extends Component {
  constructor(props) {
    super(props);
    this.state = { menuIsOpen: false };
    this.handleMenu = this.handleMenu.bind(this);
    this.buildMenu = this.buildMenu.bind(this);
  }

  handleMenu() {
    this.setState({ menuIsOpen: !this.state.menuIsOpen });
  }
  buildMenu = () => {
    return this.props.menuItems.map((menuItem, key) => {
      return (
        <li className={ "ctxMenuItem" } key={ key }>
          <div
            className={ `${ this.props.isFirst ? "isFirstItem" : '' }` }
            onClick={ () => {
              menuItem.clickEvent();
              this.handleMenu()
            } }>
            <EliteParagraph
              fontWeight={ 400 }
              margin={ "0" }
              fontSize={ "12px" }
              textAlign={ "left" }
              text={ menuItem.label }/>
          </div>
        </li>
      );
    });
  };

  render() {
    const iconMenuInUse=this.props.isColored ? iconMenuColor : iconMenu;
    const iconIgnoreInUse=this.props.isColored ? iconCloseColor : iconClose;
    return (
      <div className={"contextualMenu"}>
        <div
          className={`contextualButton ${
            this.props.isActive ? "d-block" : "d-none"
          }`}>
          <img
            alt={'menu'}
            onClick={() => this.handleMenu()}
            src={this.state.menuIsOpen ? iconIgnoreInUse : iconMenuInUse}
          />
        </div>
        <div
          className={`contextualMenuContainer ${
            this.state.menuIsOpen ? "d-block" : "d-none"
          } ${this.props.isFirst && "isFirst"}`}>
          <ul className={"ctxMenu"}>{this.buildMenu()}</ul>
        </div>
      </div>
    );
  }
}

export default ContextualMenu;
