import React, { useState } from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import ResponsiveService from "../../Services/ResponsiveService";
import { Helmet, HelmetProvider } from "react-helmet-async";
import datingMuslim from "../../assets/images/dating-muslim-1.jpg";
import datingMuslim2 from "../../assets/images/dating-muslim-2.jpg";
import BigTitle from "../../Components/Uikit/Typography/BigTitle";
import EliteSmallTitle from "../../Components/Uikit/Typography/SmallTitle";
import EliteParagraph from "../../Components/Uikit/Typography/Paragraph";

export function MusulmanPage() {
    const [isMobile, setIsMobile] = useState(
        ResponsiveService.isMobile()
    );
    
    const handleResize = (e) => {
        e.preventDefault();
        if (isMobile !== ResponsiveService.isMobile()) {
          setIsMobile({ isMobile: ResponsiveService.isMobile() });
        }
    };
    window.addEventListener("resize", handleResize.bind(this));

    return (
        <HelmetProvider>
            <>
                <Helmet>
                    <title>Site de rencontre pour Musulman n°1 – Elite Berbère</title>
                    <meta name="description" content="Vous recherchez une personne musulmane ? 
                    Rencontrez des célibataires sérieux sur le site de rencontre Elite Berbère, inscrivez-vous gratuitement."/>
                    <meta name="keywords" content="Faire connaissance,  Hommes et femmes, Amicales, Célibat, Faire une rencontre, 
                    Âme-soeur, Affinités, Faire des rencontres, Trouver l amour, Célibataires, Site de rencontre, Hommes musulmans, 
                    Femmes musulmanes, Confession musulmane, Rencontre des musulmans, Musulmans français, Religion musulmane, 
                    Musulmans de france, Communauté musulmane, Site de rencontre musulman, Musulman, Musulmane, Rencontres musulmanes, 
                    Musulmanes, Mariage musulman"/>
                </Helmet>
                <Header />
                <div className="container">
                    <div className="row p-4">
                        <div style={{textAlign: "justify"}} className="col-12">
                            <BigTitle
                                marginBottom={"5vh"}
                                titleColor={"#4e4e4e"}
                                textAlign={"left"}
                                text={"Élite Berbère : le site de rencontre pour musulmans"}
                            />
                            <EliteParagraph
                                text={<>
                                    Élite Berbère vous accueille dans son antre de la <b>confession musulmane</b>, pour que vous puissiez <b>faire des rencontres </b>
                                    qui vous ressemblent. Notre <b>site de rencontre pour musulmans</b> est élitiste et vous permet de <b>trouver l'amour</b> autrement,
                                    en toute sécurité et avec authenticité. onnes qui correspondent à vos envies.
                                </>}
                                textAlign={"justify"}
                                fontSize={"1.3em"}/>

                            <EliteSmallTitle
                                textAlign={"left"}
                                text={"Faire une rencontre sur notre site de rencontre pour musulmans"} />
                            <EliteParagraph
                                text={<>
                                    Dans l'ère du numérique, il est désormais possible de rencontrer l'amour de sa vie sans pour autant sortir de chez soi.
                                    Si vous êtes ici, c'est que vous faites un premier pas vers votre quête du grand amour. Nous vous permettons de trouver
                                    chaussure à votre pied parmi un large choix de profils <b>musulmans français</b>, tous vérifiés de près par notre équipe.
                                    Notre priorité étant votre satisfaction et la réponse à vos exigences, nous mettons tout en œuvre pour vous fournir
                                    la meilleure expérience utilisateur qu'il soit. <b>Hommes et femmes</b> sont les bienvenus sur
                                    notre <a href={"https://eliteberbere.fr"}>site de rencontre
                                    Élite Berbère</a>, pour <b>faire connaissance</b> et nouer des <b>affinités</b>. Que vous fassiez des rencontres <b>amicales</b> ou que
                                    vous trouviez enfin votre âme sœur, vous pouvez vous laisser aller à explorer les divers profils des <b>musulmans
                                    de France</b>. Ne passez pas à côté de belles <b>rencontres musulmanes</b> qui pourraient vous faire oublier votre <b>célibat </b>
                                    actuel sur notre site de rencontre pour musulmans.
                                </>}
                                textAlign={"justify"}/>
                            <img className={"img-fluid"} style={{marginBottom: "16px"}} src={ datingMuslim } alt={ "dating Muslim 1" }/>

                            <EliteSmallTitle
                                textAlign={"left"}
                                text={"Rencontrer des musulmans respectueux et sérieux sur Élite Berbère"} />
                            <EliteParagraph
                                text={<>
                                    Vous vivez seul depuis longtemps et en avez définitivement marre de vous endormir dans un grand lit vide ?
                                    Vous rêvez d'un <b>mariage musulman</b> en bonne et due forme ? Chez Élite Berbère, nous donnons la priorité à vos
                                    aspirations profondes, grâce à la personnalisation de vos recherches en fonction de vos critères personnels.
                                    Pour que le rêve devienne réalité et que vous rencontriez l'amour de votre vie rapidement, nous vous mettons
                                    chaque jour en relation avec des <b>célibataires</b> cultivés, honnêtes et droits sur notre <b>site de rencontre pour
                                    musulmans</b>. Vous irez ainsi droit à l'essentiel, sans vous perdre dans des échanges infructueux et sans avoir
                                    à mettre le nez dehors. Il y a peut-être le <b>musulman</b> ou la <b>musulmane</b> qu'il vous faut sur Élite Berbère,
                                    le mieux étant de le découvrir en vous créant un profil en quelques minutes seulement.
                                </>}
                                textAlign={"justify"}/>
                            <img className={"img-fluid"} style={{marginBottom: "16px"}} src={ datingMuslim2 } alt={ "dating Muslim 2" }/>

                            <EliteSmallTitle
                                textAlign={"left"}
                                text={"La religion musulmane au centre de vos rencontres"} />
                            <EliteParagraph
                                text={<>
                                    Nous savons à quel point il peut être difficile de trouver l'amour en général. Il est vrai que lorsque l'on
                                    souhaite que son ou sa bien-aimé(e) pratique la même religion que nous, la tâche s'avère d'autant plus
                                    contraignante. Grâce à notre plateforme d'échange tournant autour de la <b>religion musulmane</b>, vous savez
                                    que vous discutez avec des <b>hommes musulmans</b> et des <b>femmes musulmanes</b>. Vous pouvez alors vous concentrer
                                    uniquement sur la recherche de vos points communs et divergents, pour créer un lien durable et unique.
                                    Vous êtes sûre de pouvoir effectuer ensemble les pratiques <b>musulmanes</b> si chères à votre cœur. Profitez de
                                    tendres discussions, d'échanges courtois et étoffez votre cercle d'amis ou de connaissances professionnelles
                                    par la même occasion.
                                </>}
                                textAlign={"justify"}/>
                            <EliteParagraph
                                text={<>
                                    Il est temps de prendre soin de vos relations en vous inscrivant dès maintenant sur <b>Élite Berbère</b>.
                                    Notre plateforme vous mènera tout droit vers le bonheur d'une vie à deux. C'est une aventure virtuelle
                                    unique qui vous attend, avec à la clé, de belles rencontres musulmanes.
                                </>}
                                textAlign={"justify"}/>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        </HelmetProvider>
    );
}
