import { each } from "lodash-es";
import UserService from "../../../Services/ApiServices/UserService";
import { handleError } from "../../../Services/ErrorHandler";
import {toast} from "react-toastify";


function dotify(result, obj, prefix) {
  each(obj, (val, attr) => {
    const key = ( prefix ? `${ prefix }[${ attr }]` : attr );
    if (val && typeof val === "object" && !( val instanceof File )) {
      dotify(result, val, key);
    } else {
      result[key] = val;
    }
  })
  return result;
}

export async function saveProfile(user) {
  const data = new FormData();
  let collection = dotify({}, user);
  each(collection, (val, key) => {
    data.append(key, val);
  })
  try {
    const { data: { data: profile } } = await UserService.updateProfile(data)
    // toast.success('Modification(s) enregistrée(s).')
    toast.success('Modification soumise à notre service Modération.')
    return profile
  } catch (e) {
    handleError(e);
  }
}
