import React, { useState } from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import ResponsiveService from "../../Services/ResponsiveService";
import { Helmet, HelmetProvider } from "react-helmet-async";
import datingLyon from "../../assets/images/dating-lyon-1.jpg";
import datingLyon2 from "../../assets/images/dating-lyon-2.jpg";
import BigTitle from "../../Components/Uikit/Typography/BigTitle";
import EliteSmallTitle from "../../Components/Uikit/Typography/SmallTitle";
import EliteParagraph from "../../Components/Uikit/Typography/Paragraph";


export function LyonPage() {
    const [isMobile, setIsMobile] = useState(
        ResponsiveService.isMobile()
    );
    
    const handleResize = (e) => {
        e.preventDefault();
        if (isMobile !== ResponsiveService.isMobile()) {
          setIsMobile({ isMobile: ResponsiveService.isMobile() });
        }
    };
    window.addEventListener("resize", handleResize.bind(this));

    return (
        <HelmetProvider>
        <>
            <Helmet>
                <title>Site de rencontre Lyon - Inscription Gratuite | EliteBerbere.fr</title>
                <meta name="description" content="Rencontrer des hommes et femmes célibataires à 
                Lyon sur Elite Berbère, le site de rencontre en ligne 100% gratuit. Inscrivez-vous dès maintenant."/>
                <meta name="keywords" content="Faire une rencontre, Pour rencontrer, Affinité, Grand amour, Nouer, 
                Compatibles, Durables, Exigeants, Clics, Rejoignez, Vie amoureuse, Célibataires exigeants, Faire de nouvelles, 
                Rencontre une personne, Entrer en contact, Rencontres entre célibataires, Perle rare, Points communs, 
                Trouver un partenaire, Votre personnalité, Faire connaissance, Sérieuses, Trouver l amour, 
                Faire des rencontres, Célibataires"/>
            </Helmet>
            <Header />
            <div className="container">
                <div className="row p-4">
                    <div style={{textAlign: "justify"}} className="col-12">
                        <BigTitle
                            marginBottom={"5vh"}
                            titleColor={"#4e4e4e"}
                            textAlign={"left"}
                            text={"Connectez-vous avec le grand amour grâce au site de rencontre à Lyon"}
                        />
                        <EliteParagraph
                            text={<>
                                Vous recherchez l'âme sœur ?
                                Faites confiance en notre <a href={"https://eliteberbere.fr"}>site de rencontre
                                EliteBerbere.fr</a>,
                                votre cœur sera comblé. Nous sommes spécialisés dans la rencontre berbère. Notre site est le
                                premier en France. Échangez avec des Maghrébins, des Maghrébines et des personnes musulmanes.
                                Nous offrons plusieurs services. En complément de la rencontre amoureuse, il est également
                                possible de constituer un réseau professionnel sur notre site de rencontre à Lyon.
                                Cela permet ainsi de combiner l'utile à l'agréable. Nous visons une forte croissance de notre
                                start-up grâce à des profils vérifiés, des textes de qualité et des recherches personnalisées.
                            </>}
                            textAlign={"justify"}
                            fontSize={"1.3em"}/>

                        <EliteSmallTitle
                            textAlign={"left"}
                            text={"Élite Berbère, un site de rencontre à Lyon inédit"} />
                        <EliteParagraph
                            text={<>
                                Notre site de rencontre à Lyon a vu le jour en 2019. Une famille Berbère a créé ce concept
                                inédit en France et dans le monde entier. Nous nous adressons principalement à la communauté
                                Berbère de 20 à 60 ans. Nous souhaitons vous permettre de <b>faire une rencontre</b> amoureuse qui
                                réponde parfaitement à vos exigences. Nos membres ont tous le même but : construire une
                                relation sérieuse qui perdure dans le temps.
                            </>}
                            textAlign={"justify"}/>
                        <EliteParagraph
                            text={<>
                                EliteBerbere.fr a pour objectif de vous faire <b>trouver l'amour</b>. Cette mission demande de l'exigence,
                                car il s'agit d'une véritable rencontre. Nous vous garantissons de faire la connaissance avec le
                                <b>grand amour</b>. Nous veillerons à vous assurer une rencontre de qualité à la hauteur de vos critères.
                            </>}
                            textAlign={"justify"}/>
                        <img className={"img-fluid"} style={{marginBottom: "16px"}} src={ datingLyon } alt={ "rencontre Lyon 1" }/>

                        <EliteSmallTitle
                            textAlign={"left"}
                            text={"Votre avenir amoureux entre nos mains"} />
                        <EliteParagraph
                            text={<>
                                Vous souhaitez <b>trouver un partenaire</b> pour vivre une belle histoire d'amour ? Partagez un moment
                                d'échange avec des hommes et des femmes berbères dans le respect des valeurs de l'Islam. Nous vous
                                permettons d'<b>entrer en contact</b> avec des <b>célibataires exigeants</b>. Notre rôle est d'être le garant
                                de votre vie amoureuse.
                            </>}
                            textAlign={"justify"}/>
                        <EliteParagraph
                            text={<>
                                Nous avons pour vocation de vous aider à <b>nouer</b> des liens avec des personnes <b>sérieuses</b>. C'est le site
                                de rencontre à Lyon parfait pour connaître de nouvelles personnes ayant les mêmes critères de sélection
                                et des valeurs identiques. La satisfaction de votre bonheur est notre priorité.
                            </>}
                            textAlign={"justify"}/>

                        <EliteSmallTitle
                            textAlign={"left"}
                            text={"Une compatibilité amoureuse"} />
                        <EliteParagraph
                            text={<>
                                Les amours les plus <b>durables</b> naîtront grâce à l'<b>affinité</b> qui sera créée lors de vos discussions.
                                Vos <b>points communs</b> vous permettront de faire la différence avec les autres profils. Cela déterminera
                                si vous êtes <b>compatibles</b> avec les <b>célibataires</b> avec qui vous discutez. Vous aurez alors la possibilité
                                de <b>rencontrer une personne</b> en adéquation avec vos critères. Trouvez ainsi votre <b>perle rare</b> sans plus
                                attendre sur <a href={"https://eliteberbere.fr"}>Eliteberbere.fr</a>.
                            </>}
                            textAlign={"justify"}/>
                        <img className={"img-fluid"} style={{marginBottom: "16px"}} src={ datingLyon2 } alt={ "rencontre Lyon 2" }/>

                        <EliteSmallTitle
                            textAlign={"left"}
                            text={"Connectez-vous sur EliteBerbere.fr"} />
                        <EliteParagraph
                            text={<>
                                <b>Rejoignez</b> vite EliteBerbere.fr pour <b>faire de nouvelles rencontres entre célibataires exigeants</b>.
                                Pour s'inscrire sur notre site de rencontre, rien de plus facile ! En quelques clics, vous pouvez
                                <b> faire connaissance</b> avec des personnes en quête de l'âme sœur. Créez votre profil en intégrant des
                                informations personnalisées sur <b>votre personnalité</b>. Connectez-vous vite sur notre site pour <b>faire des
                                rencontres</b> de qualité près de chez vous. Et laissez-vous guider par la magie de l'amour.
                            </>}
                            textAlign={"justify"}/>
                        <EliteParagraph
                            text={<>
                                Nous vous garantissons des prestations de qualité. Des modérateurs veillent sur le contenu de notre site.
                                Vos informations personnelles sont sécurisées. L'ensemble des photos et des annonces publiées sont acceptées
                                manuellement par notre équipe. Nous vous assurons ainsi des profils sérieux et fiables.
                            </>}
                            textAlign={"justify"}/>
                    </div>
                </div>
            </div>
            <Footer />
        </>
        </HelmetProvider>
    );
}
