import React from "react";
import CustomAvatar from "../Uikit/Avatar/Avatar";
import CircleProgressBar from "../Uikit/CircleProgressBar/CircleProgressBar";
import "./ProfilePhotoStyles.css";
import iconConfig from "../../assets/images/icon-config.svg";
import iconEdit from "../../assets/images/icon-edit.svg";
import EliteParagraph from "../Uikit/Typography/Paragraph";
import { useHistory } from "react-router-dom";
import { EliteRouters } from "../../Navigation/Routers";

export default function ProfilePhoto(props) {
  const history = useHistory();

  const defaultAvatar = `${process.env.REACT_APP_INITIALS_GENERATOR + props.user.username }`;

  return (
    <>
      <div className="d-flex w-100 justify-content-center align-items-center flex-column">
        <div className="d-flex flex-row justify-content-center align-items-center">
          <div className="d-flex px-3">
            <img onClick={ () => history.push(EliteRouters.parameters.path) } src={ iconConfig } alt={'parameters'} />
          </div>
          <div className="d-flex">
            <div className=" position-relative">
              <div className="position-absolute profilePhotoProgress">
                <CustomAvatar
                  noBorder
                  noMargin
                  size={ props.size ? props.size : "155"}
                  borderColor={ "#fff" }
                  photoUrl={ props.user.avatar || defaultAvatar }
                />
              </div>
              <CircleProgressBar
                percentage={ props.completitionPercentage }
              />
            </div>
          </div>
          <div className="d-flex px-3">
            <img src={ iconEdit } onClick={ () => history.push(EliteRouters.profileEdition.path) } alt={'edit'} />
          </div>
        </div>
        <div className="d-flex flex-column">
          <EliteParagraph
            text={ props.user.username }
            textColor={ "#4e4e4e" }
            fontSize={ "32px" }
            fontWeight={ "500" }
            textAlign={ "center" }
            margin={ "32px 0 0 0" }
          />
          <EliteParagraph
            text={ props.user?.profile?.age + ', ' + props.user?.profile?.city?.name }
            textColor={ "#6c95e7" }
            fontSize={ "14px" }
            fontWeight={ "500" }
            textAlign={ "center" }
            margin={ "8px 0 0 0" }
          />
        </div>
      </div>
    </>
  );
}
