import React, { useState } from "react";
import DatePicker from "react-date-picker";
import "./DateSelectorStyles.css";
import moment from "moment";
import EliteErrorText from "../Typography/ErrorText";
import {ReactSVG} from "react-svg";
import dateIcon from "../../../assets/images/icon-calendar.svg"

export function DateSelector(props) {
  let value = props.value ? moment(props.value).toDate() : new Date();
  const [date, setDate] = useState(value);

  function onChange(newDate) {
    setDate(newDate);
    if (props.onChange) {
      props.onChange(newDate);
    }
  }

  return (
    <div className={`d-flex flex-column w-100`}>
      <DatePicker
        maxDate={props.maxDate}
        clearIcon={null}
        calendarIcon={<ReactSVG src={dateIcon} color={"#FFFFFF"} className={"ml-2"} style={{position:"absolute", left:10, top: 3, bottom: 8,color: "#FFFFFF"}}/>}
        onChange={onChange}
        value={date}
        format={"d/M/yy"}
        showLeadingZeros={true}
        className={`${props.showContainer && "dateContainer"} ${
          props.isInvalid && "isInvalid"
        } ${props.isSubscriptions ? "isSubscription" : ""}`}
      />
      <EliteErrorText text={props.errorText} />
    </div>
  );
}

export default DateSelector;
