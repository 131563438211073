import React from "react";
import "./AvatarStyles.css";

export const STATE_ON_READ = "onRead";
export const STATE_ON_UNREAD = "onUnRead";
export const STATE_OFF_READ = "offRead";
export const STATE_OFF_UNREAD = "offUnRead";


function CustomAvatar(props) {
  return (
    <div className={"position-relative d-flex justify-content-center align-items-center"}
         style={{width: `${props.size}px`}}>
      {props.noLink ?
        [
          <div
            key={'div1'}
            style={{
              borderColor: `${props.borderColor}`,
              backgroundImage: `url(${
                (props.photoUrl || `${process.env.REACT_APP_INITIALS_GENERATOR + props.name}`)
              })`,
              margin: `${props.noMargin && -5}`,
              width: `${props.size}px`,
              height: `${props.size}px`,
              border: `${props.noBorder && "none"}`,
              marginBottom: `${props.mb && "none"}px`,
            }}
            className={`avatar ${
              props.customClass ? props.customClass : ''
            } ${props.state || 'd-block'}`} />,
          <div
            key={'div2'}
            className={
              props.state === STATE_ON_READ || props.state === STATE_ON_UNREAD
                ? "onlineSignal"
                : ""
            } />
        ]
        :
        <a href={props.link ?? "/profile"}
        title={props.title ?? ""}
        target={props.target ?? ""}>
          <div
            style={{
              borderColor: `${props.borderColor}`,
              backgroundImage: `url(${
                (props.photoUrl || `${process.env.REACT_APP_INITIALS_GENERATOR + props.name}`)
              })`,
              margin: `${props.noMargin && -5}`,
              width: `${props.size}px`,
              height: `${props.size}px`,
              border: `${props.noBorder && "none"}`,
              marginBottom: `${props.mb && "none"}px`,
            }}
            className={`avatar ${
              props.customClass ? props.customClass : ''
            } ${props.state || 'd-block'}`}/>
          <div
            className={
              props.state === STATE_ON_READ || props.state === STATE_ON_UNREAD
                ? "onlineSignal"
                : ""
            }/>
        </a>
      }
    </div>
  );
};

export default CustomAvatar;
