import React, { Component } from "react";
import Modal from "react-modal";
import "./ModalStyles.css";

class CustomModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal:
        window.location.pathname.indexOf("/reset-password") !== -1 &&
        props.modalClass === "forgotPasswordModal",
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleCustomModalState = this.handleCustomModalState.bind(this);
  }

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  handleCustomModalState() {}

  render() {
    return (
      <Modal
        style={{ content: { height: this.props.modalHeight && this.props.modalHeight } }}
        overlayClassName="Overlay"
        className={this.props.modalClass}
        ariaHideApp={false}
        isOpen={this.state.showModal}
        onRequestClose={this.props.notCloseOutside ? null : this.handleCloseModal}
        shouldCloseOnOverlayClick={true}>
        {/* <span className="float-end px-2 py-1 small text-black-50 pointer" onClick={ this.handleCloseModal }>
          Close
        </span> */}
        {this.props.modalContent}
      </Modal>
    );
  }
}
export default CustomModal;
