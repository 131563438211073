import React, { Component } from "react";
import BigTitle from "../Uikit/Typography/BigTitle";
import "./PartnershipBlocStyles.css";
//import logoPartner1 from "../../assets/images/logo-partenaire-berberetv.jpg";
import logoPartner2 from "../../assets/images/logo-partenaire-beurfm.png"
import logoPartner3 from "../../assets/images/logo_partner3.jpg"
import logoPartner4 from "../../assets/images/logo_partner4.jpg"
import logoPartner5 from "../../assets/images/logo_partner5.jpg"
import CustomAvatar from "../Uikit/Avatar/Avatar";

class PartnershipBloc extends Component {
    render() {
        return (
            <>
                <div className={"container-fluid w-100 p-0 m-2"} >
                    <div className={"row PartnershipBlocContainer d-flex w-100 justify-content-center"}>
                        <BigTitle
                            showUnderline
                            titleColor={"#4e4e4e"}
                            text={"Ils parlent d'Elite Berbère"}
                        />
                        <div className={"eliteSmallTitle2 pt-3 pb-3"}> Découvrez tous nos partenaires </div>
                        <div className={"d-flex w-100 imagePartnerContainer mt-3 row mx-0"}>
                            {/*<div className={"col-md-2 col-sm-12 text-center imagePartnerContent mb-3"}>
                                <a href="https://www.facebook.com/berberetelevision/"  target="_blank" rel="noreferrer" title="Berbère Télévision"><img className="img-fluid mx-auto d-block partner_img" src={logoPartner1} alt={"logo partner berberetv"}/></a>
                            </div>*/}
                            <div className={"col-md-2 col-sm-12 text-center imagePartnerContent mb-3 align-items-center"}>
                                <CustomAvatar
                                    link={'https://www.instagram.com/kbyla_abbas/?utm_medium=copy_link'}
                                    noMargin
                                    title={"ABBAS Cloë"}
                                    target={"_blank"}
                                    size={"100"}
                                    borderColor={'white'}
                                    photoUrl={logoPartner4}/>
                            </div>
                            <div className={"col-md-2 col-sm-12 text-center imagePartnerContent mb-3"}>
                                <a href="https://www.beurfm.net/news/actu-100/1"  target="_blank" rel="noreferrer" title="Beur FM"><img className="img-fluid mx-auto d-block partner_img" src={logoPartner2} alt={"logo partner beurfm"}/></a>
                            </div>
                            <div className={"col-md-2 col-sm-12 text-center imagePartnerContent mb-3 align-items-center"}>
                                <CustomAvatar
                                    link={'https://www.instagram.com/berbere_prod/?utm_medium=copy_link'}
                                    noMargin
                                    title={"Berbere_prod"}
                                    target={"_blank"}
                                    size={"100"}
                                    borderColor={'white'}
                                    photoUrl={logoPartner5}/>
                            </div>
                            <div className={"col-md-2 col-sm-12 text-center imagePartnerContent mb-3"}>
                                <a href="https://www.facebook.com/Association-Franco-Berb%C3%A8re-des-Portes-de-lEssonne-100488615133408"  target="_blank" rel="noreferrer" title="Association Franco-Berbère"><img className="img-fluid mx-auto d-block partner_img" src={logoPartner3} alt={"logo partner beurfm"}/></a>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    );
    }
}
export default PartnershipBloc;
