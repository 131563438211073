import React from "react";
import MatchMakingCardItem from "./MatchMakingCardItem";
import MatchMakingLimitCard from "./MatchMakingLimitCard";
import { hasMatchingSubscription, hasValidSubscription } from "../../Services/ApiServices/api.client";
import Alert from "react-bootstrap/Alert";

export default function MatchMakingOffer(props) {
  const isSubscribed = hasValidSubscription();
  const hasMatching = hasMatchingSubscription();

  return (
    <>
      { props.matchData.map((match, i) => (
        <div className="col-12 col-md-4 col-sm-12 mt-5" key={ i }>
          <MatchMakingCardItem
            key={ i }
            photoUrl={ match.url }
            id={ match.id }
            username={ match.username }
            gender={ match.gender }
            city={ match.city }
            age={match.age}
            otherPhotos={match.otherPhotos}
            isOnline={match.isOnline}
            callback={props.callback}
            isMobile={props.isMobile}
            profileId={match.profileId}
          />
        </div>
      ))}
      {props.matchData.length === 0 && isSubscribed &&
      <Alert className="col-lg-12 col-md-12" variant="primary">
        <p className={"m-auto"}>
          Toute sélection a une fin pour aujourd'hui, il en reste tant d'autre à voir demain...
        </p>
      </Alert>
      }
      { hasMatching ? <div className="py-5" /> :
        <div className="col-lg-4 col-md-6 col-sm-12 mt-5">
          <MatchMakingLimitCard
            overrideSize={16}
            title={
              isSubscribed
                ? "AUGMENTER VOS MATCHS AUGMENTER VOS CHANCES AVEC L'ELITE BOOSTER"
                : "AFIN D'AVOIR ACCÈS À LA SÉLECTION QUOTIDIENNE DES CÉLIBATAIRES\n" +
                "VEUILLEZ SOUSCRIRE À UNE OFFRE CI-DESSOUS"
            }
            buttonLabel={
              isSubscribed ? "Boostez votre offre" : "Choisir une offre"
            }
            isMobile={ props.isMobile }
          />
        </div>
      }
    </>
  );
}
