import React, { useState } from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import ResponsiveService from "../../Services/ResponsiveService";
import { Helmet, HelmetProvider } from "react-helmet-async";
import WeddingBerbere from "../../assets/images/wedding-berbere-1.jpg";
import WeddingBerbere2 from "../../assets/images/wedding-berbere-2.jpg";
import BigTitle from "../../Components/Uikit/Typography/BigTitle";
import EliteSmallTitle from "../../Components/Uikit/Typography/SmallTitle";
import EliteParagraph from "../../Components/Uikit/Typography/Paragraph";

export function BerberePage() {
    const [isMobile, setIsMobile] = useState(
        ResponsiveService.isMobile()
    );
    
    const handleResize = (e) => {
        e.preventDefault();
        if (isMobile !== ResponsiveService.isMobile()) {
          setIsMobile({ isMobile: ResponsiveService.isMobile() });
        }
    };
    window.addEventListener("resize", handleResize.bind(this));

    return (
        <HelmetProvider>
        <>
            <Helmet>
                <title>Site de rencontre Berbère pour trouver l'amour - Elite Berbère</title>
                <meta name="description" content="Vous êtes fier de votre culture berbère et 
                souhaitez partager votre vie avec une personne ayant la même passion que vous ? Inscrivez-vous 
                sur Eliteberbere.fr."/>
                <meta name="keywords" content="Touaregs, Grande kabylie, Langue et la culture, Musulman, 
                Musulmane, Pays du maghreb, Femmes berbères, Africain, Haut-atlas, Kabyles, Kabyle, Amazigh, 
                Tamazight, Atlas, Moyen-atlas, Mouvement culturel berbère, Identité berbère, Monde berbère, 
                Chleuhs, Origine berbère, Culture berbère, Langue berbère, Berbères, Berbère, Kabylie"/>
            </Helmet>
            <Header />
            <div className="container">
                <div className="row p-4">
                    <div style={{textAlign: "justify"}} className="col-12">
                        <BigTitle
                            marginBottom={"5vh"}
                            titleColor={"#4e4e4e"}
                            textAlign={"left"}
                            text={"Pourquoi s’inscrire sur un site de rencontres berbères ?"}
                        />
                        <EliteParagraph
                            text={<>
                                Vivre dans le respect de sa religion
                                et de sa culture n'est pas toujours simple, et lorsqu'il s'agit de trouver
                                un partenaire, les choses peuvent encore se compliquer. Il est naturel de
                                vouloir trouver la personne qui partage les mêmes valeurs pour vivre en
                                parfaite harmonie quelques mois ou toute une vie. Pour vous y aider,
                                pourquoi ne pas tenter l'aventure avec Élite Berbère ?
                            </>}
                            textAlign={"justify"}
                            fontSize={"1.3em"}/>

                        <EliteSmallTitle
                            textAlign={"left"}
                            text={"Pourquoi un site de rencontre berbère ?"} />
                        <EliteParagraph
                            text={<>
                                On ne vous apprend rien. Vous savez déjà que les sites de rencontres peuvent parfois
                                négliger ce qui est important pour vous, à savoir le respect de votre culture berbère,
                                ses spécificités, ses valeurs.
                            </>}
                            textAlign={"justify"}/>
                        <EliteParagraph
                            text={<>
                                Pour être sûr de ne pas se tromper et rencontrer uniquement des <b>Berbères</b> nés en
                                <b> Afrique du Nord</b> ou en France, il est alors indispensable de trouver
                                un site de rencontres spécialisé.
                            </>}
                            textAlign={"justify"}/>
                        <EliteParagraph
                            text={<>
                                Chez <a href={"https://eliteberbere.fr"}>Élite Berbère</a>, nous l'avons bien compris et
                                nous vous proposons un <b>site de rencontres berbères</b> de qualité.
                                C'est pourquoi nous mettons en avant les hommes et femmes d'<b>origine berbère</b>.
                            </>}
                            textAlign={"justify"}/>
                        <EliteParagraph
                            text={<>
                                Notre plateforme a été créée par des personnes fières de leur <b>identité berbère</b> pour leurs homologues.
                                Nous réunissons des hommes et femmes originaires du <b>Maghreb</b> (<b>Algérie</b>, <b>Maroc</b>, Tunisie ou <b>Kabylie</b>) à
                                la recherche d'une personne ayant
                                les mêmes histoire, tradition, <b>langue et culture</b>. Qu'ils soient <b>Algériens</b>, <b>Kabyles</b>, <b>Marocains</b>,
                                Tunisiens ou encore <b>Chleuhs</b>.
                            </>}
                            textAlign={"justify"}/>
                        <EliteParagraph
                            text={<>
                                Pour cela, nous réunissons des profils de qualité. Notre exigence nous a permis de présenter
                                des portraits de passionnés à la recherche de l'amour vrai.
                            </>}
                            textAlign={"justify"}/>
                        <EliteParagraph
                            text={<>
                                On peut naturellement être considéré comme élitiste. Mais n'est-ce pas un avantage dans notre métier ?
                                Nous prônons les mêmes valeurs que vous et nous adressons aux femmes et aux <b>hommes libres</b> qui désirent
                                un partenaire sérieux qui pense comme eux.
                            </>}
                            textAlign={"justify"}/>
                        <EliteParagraph
                            text={<>
                                Nous sommes le premier (et pour le moment, le seul) <b>site de rencontres berbères</b>.
                                Cela nous oblige à prendre en compte toutes les spécificités <b>Tamazights</b> que l'on retrouve dans les <b>pays du Maghreb</b>.
                            </>}
                            textAlign={"justify"}/>
                        <img className={"img-fluid"} style={{marginBottom: "16px"}} src={ WeddingBerbere } alt={ "wedding berbere 1" }/>

                        <EliteSmallTitle
                            textAlign={"left"}
                            text={"Comprendre les désirs et y répondre au mieux"} />
                        <EliteParagraph
                            text={<>
                                Vous n'aimez que le vrai couscous aux 5 légumes ? Vous savez faire une harira exceptionnelle et souhaiteriez
                                qu'un partenaire la savoure ? Vous adorez porter des bijoux de Tiznit ou décorez avec style votre logement de poteries de Chefchaouen ?
                                Vous aimez entendre la langue berbère et la pratiquer avec plaisir ?
                                </>}
                            textAlign={"justify"}/>
                        <EliteParagraph
                            text={<>
                                Bref, vous êtes un(e) vrai(e) <b>Amazigh</b>(e) ! Et vous avez des exigences et une identité qui vous sont chères.
                                Vous avez déjà tenté de créer un couple avec une personne de culture différente, mais cela n'a pas particulièrement bien fonctionné.
                                Et cela même si vous étiez <b>musulmans</b> et parliez tous les deux la <b>langue arabe</b>.
                            </>}
                            textAlign={"justify"}/>
                        <EliteParagraph
                            text={<>
                                Réussir sa vie à deux n'est pas une mince affaire et naturellement le simple fait d'avoir la même
                                origine n'est pas suffisant pour réussir. Mais cela peut grandement aider puisque vous avez les mêmes intérêts, la même culture.
                                Vous savez ainsi qu'il ou elle va se réjouir de célébrer Moussem ou Yennayer avec vous.
                            </>}
                            textAlign={"justify"}/>

                        <EliteSmallTitle
                            textAlign={"left"}
                            text={"Des particularités essentielles"} />
                        <EliteParagraph
                            text={<>
                                Vous êtes à la recherche d'une <b>Marocaine</b>, d'une <b>Algérienne</b>, d'un <b>Tunisien</b> ? Élite Berbère va vous aider.
                            </>}
                            textAlign={"justify"}/>
                        <EliteParagraph
                            text={<>
                                Nous réunissons des personnes en quête de l'amour, âgées de 20 à plus de 60 ans fiers de leurs <b>origines kabyles</b>.
                            </>}
                            textAlign={"justify"}/>
                        <EliteParagraph
                            text={<>
                                Notre plateforme s'adresse aux célibataires kabyles de France, mais aussi du monde entier. Nous avons en effet des antennes partout.
                                Vous avez ainsi plus de chances de rencontrer un ou une Berbère qui répond à tous vos critères. Et pourquoi pas, commencer
                                une nouvelle vie ailleurs ?
                            </>}
                            textAlign={"justify"}/>
                        <EliteParagraph
                            text={<>
                                En outre, notre <b>site de rencontres berbères</b> offre d'autres « petits plus » afin de vous faciliter la mise en relation avec l'autre.
                                Ainsi, nous avons, par exemple, imaginé un service de réseau professionnel qui vous permettra de trouver des collègues ou un travail
                                sans pour autant oublier la recherche de l'amour !
                            </>}
                            textAlign={"justify"}/>
                        <EliteParagraph
                            text={<>
                                Lorsque vous avez un crush, vous avez la liberté de partager avec elle en <b>langue kabyle</b> (ou berbère). Nos modérateurs la pratiquent !
                            </>}
                            textAlign={"justify"}/>
                        <EliteParagraph
                            text={<>
                                Nous mettons en avant la droiture, le sérieux et notre culture pour vous aider à trouver l'amour de votre vie.
                            </>}
                            textAlign={"justify"}/>
                        <img className={"img-fluid"} style={{marginBottom: "16px"}} src={ WeddingBerbere2 } alt={ "wedding berbere 2" }/>
                    </div>
                </div>
            </div>
            <Footer />
        </>
        </HelmetProvider>
    );
}
