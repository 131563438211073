import { ageMax, ageMin, heightMax, heightMin, distanceMax } from "../../Services/DataLists";
import { filterUpdate } from './types'

const initialState = {
  searchFields : {
    ageMin: ageMin,
    ageMax: ageMax,
    heightMin: heightMin,
    heightMax: heightMax,
    distanceMax: distanceMax,
    purpose: "",
    shape: "",
    cities: [],
    genders: [],
    limit: 50,
    offset: 0,
    withAvatar: false,
    withOption:false,
  }
};

const filterReducer = (state = initialState, action) => {
  switch(action.type) {
    case filterUpdate:
      return {
        ...state,
        searchFields: action.payload
      };
    default:
      return state;
  }
}

export default filterReducer;
