import React, { Component } from "react";
import "./FooterStyles.css";
import logoGrey from "../../assets/images/logo-short-grey.png";
import logoGreyDark from "../../assets/images/logo-short-grey-dark.png";
import { ReactSVG } from "react-svg";
import tw from "../../assets/images/icon-reseaux-tw.svg";
import fb from "../../assets/images/icon-reseaux-fb.svg";
import it from "../../assets/images/icon-reseaux-insta.svg";
import { EliteRouters } from "../../Navigation/Routers";
import {generatePath} from "react-router-dom";

class Footer extends Component {
  // constructor(props){
  //   super(props);
  // }

  pageRoutes = [
                    "berbereHomePage", "maghrebHomePage", "tunisieHomePage",
                    "algerieHomePage","belgiqueHomePage", "europeHomePage",
                    "amazighHomePage","kabyleHomePage", "africaHomePage",
                    "canadaHomePage", "muslimHomePage", "seriousHomePage",
                    "weddingHomePage", "demandingHomePage", "speeddatHomePage",
                    "lovCoachingHomePage","ileDeFranceHomePage","parisHomePage",
                    "lyonHomePage","marseilleHomePage"
                  ];

  render() {
    return (
      <>
        <div
          className={`container-fluid w-100 footerElite${
            this.props.isLogged ? " isLogged mt-5" : ""
          }`}>
          <div className={"d-flex flex-row justify-content-center"}>
            <img
              src={!this.props.isLogged ? logoGrey : logoGreyDark}
              alt={"small white logo"} />
          </div>
          <div className={"d-flex flex-row justify-content-center"}>
            <ul
              className={`horizontalFooterMenu ${
                this.props.isLogged && "d-none"
              }`}>
              <li>
                <a href={generatePath(EliteRouters.generalConditions.path)}>{EliteRouters.generalConditions.name}</a>
              </li>
              <li>
                <a href={generatePath(EliteRouters.confidentialPolitics.path)}>{EliteRouters.confidentialPolitics.name}</a>
              </li>
              <li>
                <a href={generatePath(EliteRouters.legalMentions.path)}>{EliteRouters.legalMentions.name}</a>
              </li>
            </ul>
            <ul
              className={`horizontalFooterMenu${
                !this.props.isLogged ? " d-none" : ""
              }`}>
              <li>
                <a href={"https://twitter.com/eliteberbere"} target="_blank" rel="noreferrer">
                  <ReactSVG src={tw ? tw : ''} />
                </a>
              </li>
              <li>   
                <a href={"https://www.facebook.com/eliteberbere.fr/"} target="_blank" rel="noreferrer">
                  <ReactSVG src={fb ? fb : ''} />
                </a>
              </li>
              <li>
                <a href={"https://www.instagram.com/eliteberbere/"} target="_blank" rel="noreferrer">
                  <ReactSVG src={it ? it : ''} />
                </a>
              </li>
            </ul>
          </div>
          <div className={"offset-2 col-8 justify-content-center"}>
            <ul
              className={`additionalPage ${
                this.props.isLogged && "d-none"
              }`}>
                {this.pageRoutes.map((pagePath, index) => {
                  return (<li key={index}>
                    <a href={generatePath(EliteRouters[pagePath].path)}>{EliteRouters[pagePath].name}</a>
                  </li>)
                })}
            </ul>
          </div>
          <div className={"d-flex flex-row justify-content-center"}>
            <span className={"copyrightElite"}>
              Copyright© - 2022 - Elite Berbère<br/>
              Tous droits déposés et réservés
            </span>
          </div>
        </div>
      </>
    );
  }
}
export default Footer;
