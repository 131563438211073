import { useEffect } from "react";
import UserService from "../Services/ApiServices/UserService";
import { handleError, handleErrorByStatus } from "../Services/ErrorHandler";
import { useStore } from "react-context-hook";

export function useCurrentUser() {
  const defaultUser = {
    profile: {},
    searching: {},
    city: {},
    loading: true,
  };
  const [user, setUser] = useStore('currentStore', { ...defaultUser });
  const [liked, setLiked] = useStore('liked', []);
  const [loading, setLoading] = useStore('loadingUser', false);

  async function onLogout() {
    window.location.href = '/';
    await UserService.logout()
    setUser({ ...defaultUser, loading: false })
    localStorage.removeItem('subscription');
    localStorage.removeItem('tokenApi');
  }

  useEffect(() => {
    async function getUserProfile() {
     await setLoading(true);
      try {
        const { data: { data } } = await UserService.loggedInProfile()
        await setUser({ ...data, loading: false});
        await setLiked(data.likedIds)
        await setLoading(false);
      } catch (e) {
        if (e.response && e.response.status === 401) {
          onLogout();
          handleErrorByStatus(e.response.status);
          return;
        }
        handleError(e);
      }
    }

    if (!user.username && !loading) getUserProfile();
  })

  return { user, liked, setLiked, setUser, onLogout }
}
