import React, {useMemo} from "react";
import EliteParagraph from "../Uikit/Typography/Paragraph";
import "./VisitedProfileStyles.css";
import {useNotifications} from "../../Hooks/Notifications";

export default function VisitedProfileSlider(props) {
  const { notifications } = useNotifications()

  const isOnline = useMemo(
    () => notifications.onlineUsers.includes(props.user.id),
    [props.user.id, notifications.onlineUsers],
  );

  return (
    <div className={"position-relative"}>
      <div
        className={`${props.photoGallery ? 'mainPhotoGallery' : 'visitedProfilePhoto'}  ${props.isMobile ? 'mobile' : ''}`}
        style={{ backgroundImage: `url(${props.sliderData.url || 'https://ui-avatars.com/api/?background=0D8ABC&size=340&color=fff&name='+props.user.username})` }}/>
      <div className="gradientFilter visitedProfileGradient">
        <div className={"carouselInfoContainer position-absolute"}>
          <div className={"userInfoContainerVisitedProfile"}>
            <EliteParagraph
              text={props.user.username}
              textColor={"#fff"}
              fontSize={"32px"}
              fontWeight={"bold"}
              margin={0}
              textAlign={"left"}
            />
            <EliteParagraph
              text={`${ props.user.profile.age }, ${ props.user.profile?.city?.name || ''}`}
              textColor={"#f9fa00"}
              fontSize={"16px"}
              fontWeight={400}
              margin={0}
              textAlign={"left"}
            />
          </div>

          {isOnline &&
            <div className={"isOnline"} />
          }
        </div>
      </div>
    </div>
  );
}
