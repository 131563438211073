import React, { useState } from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import ResponsiveService from "../../Services/ResponsiveService";
import { Helmet, HelmetProvider } from "react-helmet-async";
import datingParis from "../../assets/images/dating-paris-1.jpg";
import datingParis2 from "../../assets/images/dating-paris-2.jpg";
import BigTitle from "../../Components/Uikit/Typography/BigTitle";
import EliteSmallTitle from "../../Components/Uikit/Typography/SmallTitle";
import EliteParagraph from "../../Components/Uikit/Typography/Paragraph";


export function ParisPage() {
    const [isMobile, setIsMobile] = useState(
        ResponsiveService.isMobile()
    );
    
    const handleResize = (e) => {
        e.preventDefault();
        if (isMobile !== ResponsiveService.isMobile()) {
          setIsMobile({ isMobile: ResponsiveService.isMobile() });
        }
    };
    window.addEventListener("resize", handleResize.bind(this));

    return (
        <HelmetProvider>
        <>
            <Helmet>
                <title>Site de rencontre Paris n°1 – Elite Berbère</title>
                <meta name="description" content="Vous souhaitez rencontrer des célibataires parisiens ? 
                Découvrez Elite Berbère, le spécialiste des rencontres. L'inscription est gratuite et rapide !"/>
                <meta name="keywords" content="Compatibles, Rencontrer l amour, Faire connaissance, Votre personnalité, 
                Trouver une personne, Affinités, Rencontre amoureuse, Attractive, Âme-soeur, Relation durable, 
                Relation amoureuse, Construire une relation, Rejoignez, Sincère, Trouver l âme soeur, Femme célibataire, 
                Trouver un partenaire, Points communs, Faire une rencontre, Célibataire, Trouver l amour, Faire des rencontres, 
                Sérieuses, Célibataires, Site de rencontre"/>
            </Helmet>
            <Header />
            <div className="container">
                <div className="row p-4">
                    <div style={{textAlign: "justify"}} className="col-12">
                        <BigTitle
                            marginBottom={"5vh"}
                            titleColor={"#4e4e4e"}
                            textAlign={"left"}
                            text={"Elite berbère, numéro un des sites de rencontre à Paris"}
                        />
                        <EliteParagraph
                            text={<>
                                Paris est une ville fascinante, qui regorge d'atouts. C'est pourtant une capitale qui ne facilite
                                pas les rencontres de qualité, tant le mode de vie qu'elle impose à ses habitants est chronophage.
                                La Ville lumière attire et fascine beaucoup de monde, mais elle épuise vite nombre de Parisiennes et
                                de Parisiens, qui se retrouvent seul(e)s chez soi, une fois la journée de travail accomplie.
                                Il ne suffit pas de croiser et d'échanger avec plein de gens,
                                encore faut-il <b>réussir ses rencontres</b>.
                                C'est ici que le site de rencontre à Paris Élite Berbère intervient,
                                pour vous permettre de <b>rencontrer l'amour</b> à Paris.
                            </>}
                            textAlign={"justify"}
                            fontSize={"1.3em"}/>

                        <EliteSmallTitle
                            textAlign={"left"}
                            text={"Comment faire connaissance et trouver la bonne personne à Paris ?"} />
                        <EliteParagraph
                            text={<>
                                Pour trouver des personnes <b>compatibles</b> avec votre <b>personnalité</b>, il vous faut rencontrer des gens ayant
                                les mêmes <b>affinités</b> que vous. Élite Berbère est le spécialiste de la <b>rencontre amoureuse</b> berbère à Paris
                                et en région parisienne. Nous mettons en rapport des clients qui souhaitent s'investir dans une <b>relation
                                durable</b> permettant de <b>construire une relation amoureuse</b> de grande qualité. Notre site de rencontre à Paris
                                est particulièrement <b>attractif</b> pour celles et ceux qui sont à la recherche de <b>relations sincères</b>, basées
                                sur l'<b>échange</b> et le <b>respect</b>. Nous disposons d'un important fichier de <b>femmes célibataires</b> qui cherchent
                                l'<b>âme sœur</b> à Paris, au sein de la communauté berbère.
                            </>}
                            textAlign={"justify"}/>
                        <img className={"img-fluid"} style={{marginBottom: "16px"}} src={ datingParis } alt={ "rencontre Paris 1" }/>

                        <EliteSmallTitle
                            textAlign={"left"}
                            text={"Comment se déroule un premier rendez-vous Élite Berbère à Paris ?"} />
                        <EliteParagraph
                            text={<>
                                Rejoignez-nous et vous serez surpris de la richesse de notre fichier, qui regorge de clientes très motivées
                                pour trouver une <b>personne respectueuse</b> et <b>sincère</b> à Paris. Nous connaissons parfaitement la communauté berbère
                                et sommes à même d'organiser pour vous un premier contact, qui vous permettra de découvrir un profil vous
                                correspondant. Afin de maximiser vos chances de <b>trouver l'amour</b>, nous sélectionnons avec beaucoup de soins
                                celle ou celui qui aura le plus de <b>points communs</b> avec vous. Ainsi, vous éviterez une perte de temps considérable,
                                ainsi que des frais au final inutiles. Ce <b>premier rendez-vous</b> vous permettra d'affiner votre demande et de nous
                                en faire part immédiatement.
                            </>}
                            textAlign={"justify"}/>

                        <EliteSmallTitle
                            textAlign={"left"}
                            text={"Élite Berbère, le site de rencontre à Paris idéal pour les berbères"} />
                        <EliteParagraph
                            text={<>
                                La réputation de notre <a href={"https://eliteberbere.fr"}>site de rencontres berbères</a> nous permet
                                d'attirer les meilleurs profils d'hommes et de
                                <b> femmes célibataires</b> de Paris. Nous créons ainsi un très vaste fichier dans lequel nous pouvons sélectionner
                                les personnes susceptibles de vous correspondre au mieux. Plus de perte d'énergie inutile, de soirées sans
                                intérêt aucun, qui déçoivent immanquablement. Place à de <b>vraies rencontres</b> durant lesquelles vous découvrirez
                                que vous partagez nombre de <b>points communs</b> avec des femmes ou des hommes, qui étaient pourtant de parfaits
                                inconnus. C'est toute la magie du site de rencontre à Paris Élite Berbère, qui balise pour vous un chemin qui
                                va vous amener à trouver l'<b>âme sœur</b> à Paris.
                            </>}
                            textAlign={"justify"}/>
                        <EliteParagraph
                            text={<>
                                En faisant appel à Élite Berbère à Paris, vous allez réussir à sublimer votre <b>vie privée</b> au travers de <b>nouvelles
                                relations</b> qui se révéleront profitables à plusieurs niveaux. Il n'est pas rare que nos prospects conservent dans
                                leur réseau personnel et professionnel des membres d'Élite Berbère qu'ils ont rencontrés dans le cadre de leur
                                <b> recherche amoureuse</b>.
                            </>}
                            textAlign={"justify"}/>
                        <img className={"img-fluid"} style={{marginBottom: "16px"}} src={ datingParis2 } alt={ "rencontre Paris 2" }/>
                    </div>
                </div>
            </div>
            <Footer />
        </>
        </HelmetProvider>
    );
}
