import React from "react";
import { generatePath, NavLink } from "react-router-dom";
import { EliteRouters } from "../../../Navigation/Routers";
import Photo from "../../Uikit/Photo/Photo";
import { Loader } from "react-bootstrap-typeahead";
import { hasValidSubscription } from "../../../Services/ApiServices/api.client";

export function MatchMakingSliderContent(props) {
  return (
    <div className="ratio ratio-1x1 photo-slider">
      <Photo
        isMobile={props.isMobile}
        type={"miniature"}
        size={"big"}
        profile={props.sliderData}
      />
      </div>

  );
}

export function MatchMakingSlider(props) {
  if (!props.sliderData) {
    return <Loader />;
  }

  return hasValidSubscription() ? (
    <NavLink
      to={{
        pathname: generatePath(EliteRouters.visitedProfile.path, {
          id: props.sliderData.id,
        }),
        roomData: props.sliderData,
      }}>
      <MatchMakingSliderContent {...props} />
    </NavLink>
  ) : (
    <MatchMakingSliderContent {...props} />
  );
}

export default MatchMakingSlider;
