import { Component } from "react";
import './RadioButtonsStyles.css'

class CustomRadioButton extends Component {
    constructor(props) {
        super(props);
        this.state = {'isChecked': false};
        this.onRadioChange = this.onRadioChange.bind(this)
      }

    onRadioChange = e => {
        this.props.callBack(e);
    }
  render() {
    return (
      <label className={ "customRadioButton" }>{ this.props.checked ? <b>{ this.props.label }</b> : this.props.label }
        <input type={ "radio" }
               name={ this.props.name }
               checked={ this.props.checked }
               value={ this.props.rbId }
               onChange={ e => this.onRadioChange(e) }></input>
        <span className={ "customRadioButtonIndicator" }></span>
      </label>
    );
  }
}

export default CustomRadioButton;
