import React, { Component } from "react";
import Carousel from "../Uikit/Carousel/Carousel";
import BigTitle from "../Uikit/Typography/BigTitle";
import { meetsMock } from "../../mocks/OurMeetsMock";
import "./OurMeets.css";


class OurMeets extends Component {
  render() {
    return (
      <>
        <div className={"ourMeets container-fluid w-100 pb-4"}>
          <div className={"row"}>
            <div className={"col-12 p-4"}>
              <BigTitle
                showUnderlineWhite
                titleColor={"#4e4e4e"}
                text={"Nos belles rencontres"} />
            </div>
            <div className={"eliteSmallTitle2 pt-1 px-5"}>Nous sommes fiers de vous rendre heureux, enthousiaste d'être à l'origine de milliers d’unions sacrées et belles rencontres. </div>
            <a className={"eliteParagraph p-4"} href="mailto:aide.contact@eliteberbere.fr?subject=Témoignage">N’attendez plus pour partager votre histoire </a>
            <div className={"col-sm-3  d-none d-sm-block"}/>
            <div className={"col-sm-6 "}>
              <Carousel
                slidesToShow={1}
                slidesToScroll={1}
                carouselType={"ourMeets"}
                responsiveBreakpoints={[
                  {
                    breakpoint: 1400,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                    },
                  },
                ]}
                data={meetsMock} />
            </div>
            <div className={"col-sm-3 d-none d-sm-block"}/>
          </div>
        </div>
      </>
    );
  }
}
export default OurMeets;
