import React, {Component} from "react";
import TitleOverTitle from "../Uikit/TitleOverTitle/TitleOverTitle";
import iconConnexion from "../../assets/images/icon-connexion.svg";
import GridMosaic from "../Uikit/PhotoMosaic/PhotoMosaic_new";
import apiClient from "../../Services/ApiServices/api.client";

export default class DerniereConnexion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profiles: [],
      distance: 10
    }
  }

  componentDidMount() {
    this.fetchNearMe();
  }

  fetchNearMe() {
    apiClient.get('/api/near-me?distance='+this.state.distance).then((data) => {
      let profiles = data.data.data;
      if(profiles.length < 20 && this.state.distance < 1500) {
        this.setState({distance: this.state.distance + 50}, () => {
          this.fetchNearMe();
        })
      }else {
        this.setState({profiles: profiles})
      }
    })
  }

  render() {
    return (
      <>
        <div className={"col-12"}>
          <TitleOverTitle
            backTitleSize={this.props.isMobile && "29px"}
            frontTitleSize={this.props.isMobile && "22px"}
            icon={iconConnexion}
            title={"Dernières connexions"}
            overTitleMarginTop={this.props.isMobile && "-25px"}
          />
        </div>
        <div className={"col-12"}>
          {/* <PhotoMosaic
          isMobile={props.isMobile}
          photoType={"miniature"}
          photoSize={"small"}
          data={profiles}
        /> */}
          <div className="row g-0">
            <GridMosaic
                padding={this.props.isMobile ?'4px' : "8px"}
                rows={this.props.isMobile ? 2 : 4}
                isMobile={this.props.isMobile}
                photoType={"miniature"}
                photoSize={"small"}
                data={this.state.profiles}
            />
          </div>
        </div>
      </>
    );
  }
}
