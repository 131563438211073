import React, { useEffect, useRef, useState } from "react";
import "./BannerStyles.css";
import bigLogo from "../../assets/images/logo-web-elite-berbere.png";
import CustomButton from "../Uikit/Button/CustomButton";
import CustomModal from "../Uikit/Modal/Modal";
import CustomSignUp from "../SignUp/CustomSignUp";
import SubscriptionContainer from "./SubscriptionChooser/SubscriptionContainer";
import { toast } from "react-toastify";
import moment from "moment";

export default function CustomBanner() {
  const child = useRef();

  const [profile, setProfile] = useState({
    gender: '',
    looking_for: '',
    birth_date: moment().subtract(18, "years").toDate().toISOString().slice(0,10),
    email: '',
    username: '',
    password: '',
    password_confirmation: '',
    city: '',
    zip: '',
    photo: undefined,
    email_exists: false,
    accepted_terms: false,
    accepted_responsibility: false,
  });

  function updateProfile(data) {
    setProfile({ ...profile, ...data })
  }

  useEffect(() => {
    if (window.location.search === '?register=1') {
      toast.success("Demande d'inscription bien reçu. Vérifiez votre boîte mail pour confirmer votre adresse mail.");
    }
    // eslint-disable-next-line
  }, [window.location.search]);


  return (
    <>
      <div className={ "container-fluid w-100 p-0" }>
        <div className={ "bigHeroBanner" }>
          <div className={ "col-6" }>
            <div className={ "containerSubscription" }>
              <img className={ "bigLogoHome" } src={ bigLogo } alt={ "Logo Elite Berbère" }/>
              <p className="bannerTitle">Inscription 100% gratuite</p>
              <SubscriptionContainer profile={ profile }
                                     onBanner
                                     onChange={ updateProfile }/>
              <div className={ "buttonContainer mt-3" }>
                <CustomButton
                  onClick={ () => child.current.handleOpenModal() }
                  label={ "Inscrivez-vous gratuitement" }/>
                <CustomModal
                  notCloseOutside
                  modalClass={ "signUpModal" }
                  modalContent={
                    <CustomSignUp profile={ profile } closeModal={ child }/>
                  }
                  ref={ child }/>
              </div>
            </div>
          </div>
          <div className={ "col-6" }/>

          <div className={ "bigHeroBannerFooter" }/>
        </div>
      </div>
    </>
  );
}
