import React, { Component } from "react";
import Footer from "../../Components/Footer/Footer";
import ConnectedHeader from "../../Components/Header/ConnectedHeader/ConnectedHeader";
import SearchContainer from "../../Components/SearchContainer/SearchContainer";
import { toast } from 'react-toastify'
import UserService from "../../Services/ApiServices/UserService";


export class Search extends Component {
  componentDidMount() {
    const queryString = this.props.location.search;
    const query = new URLSearchParams(queryString);
    if (query && query.get("success") === "true") {
      UserService.createUserSubscription({
        subscriptionId: query.get("sub_id"),
        paymentSubscriptionId: query.get("session_id"),
        isBoostOffer: query.get("is_boost"),
        module_subscription_type: "stripe",
      }).then(async () => {
        if (query.get("is_boost") === "1") {
          toast.success("Merci d'avoir souscrit à un booster.");
        } else {
          toast.success(
            "Votre abonnement a bien été validé, Bienvenue à vous !"
          );
        }

        await UserService.getCurrentSubscription();
        this.props.history.replace('/search')

        // props.history.push("/home");
      });
    } else if (query && query.get("success") === "false") {
      // toast payment failure
    }
  }

  render() {
    return (
      <div style={{ height: "100%", width: "100%", position: "relative" }}>
        <ConnectedHeader></ConnectedHeader>
        <SearchContainer></SearchContainer>
        <Footer isLogged></Footer>
      </div>
    );
  }
}

export default Search;
