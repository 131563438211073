import React, { Component } from "react";
import "./PhotoButtonStyles.css";
import iconCameraAddPhoto from "../../../assets/images/icon-camera-2-addPhoto.svg";
import { ReactSVG } from "react-svg";
import ContextualMenu from "../ContextualMenu/ContextualMenu";
import {toast} from "react-toastify";

export class PhotoButton extends Component {

  state = {
    file: this.props.photoUrl,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.photoUrl && !state.file) {
      if (typeof props.photoUrl === 'string')
        return { file: props.photoUrl };
    }
    return null;
  }

  onChange(event) {
    const reader = new FileReader();

    reader.onload = (e) => {
      this.setState({ file: e.target.result });
      if (this.props.onChange) {
        this.props.onChange({
          url: e.target.result,
          file: event.target.files[0]
        })
      }
    };

    if (event.target.files.length > 0 && event.target.files[0].size <= 5 * 1024 * 1024) {
      reader.readAsDataURL(event.target.files[0])
    }else {
      toast.error(`${ event.target.files[0].name } est une image trop grosse. La taille max est de 5 mB`);
    }
  }

  render() {
    const contextualMenuAllItems = this.getContextMenu();
    return (
      <div
        key={this.props.index}
        className={ "hidddenInput" }
        style={ { margin: this.props.noMargin && "0" } }>
        <input
          type="file"
          accept="image/x-png,image/jpeg"
          onChange={ (e) => this.onChange(e) }
          ref={ (ref) => ( this.photoInput = ref ) }
        />

        <div
          className={ `buttonPhoto ${ this.state.file ? "notEmpty" : "" } ${
            this.state.file && (this.props.isPending ? "isPending" : (this.props.isMain ? "isMain" : (this.props.isRejected ? "isRejected" : "")))
          }` }
          style={ { backgroundImage: `url(${ this.state.file })` } }>
          <div className={ `${ this.state.file && "buttonPhotoOverlay" }` }>
            <div className="iconContainerPhotoButton">
              { <ReactSVG src={ this.state.file ? "" : iconCameraAddPhoto }/> }
            </div>
            <div className="contextualMenuContainer">
              <ContextualMenu
                isFirst={ this.props.isFirst }
                isMobile={ this.props.isMobile }
                menuItems={ contextualMenuAllItems }
                isActive={ this.state.file }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  getContextMenu() {
    const contextualMenuAllItems = [
      {
        label: "Modifier",
        clickEvent: () => this.photoInput.click(),
      },
    ];

    if (!this.props.isMain) {
      contextualMenuAllItems.unshift({
        label: "Choisir comme photo principal",
        clickEvent: () => {
          this.props.setMain && this.props.setMain()
        },
      })
    }

    contextualMenuAllItems.push({
      label: "Supprimer",
      clickEvent: () => {
        this.setState({ file: "" })
        this.props.onDelete && this.props.onDelete()
      },
    })
    return contextualMenuAllItems;
  }
}

export default PhotoButton;
