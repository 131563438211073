import React, { Component } from "react";
import { ReactSVG } from "react-svg";
import EliteParagraph from "../Typography/Paragraph";
import "./CounterIconStyles.css";
import { withRouter } from "react-router-dom";

export class CounterIcon extends Component {
  render() {
    return (
      <div
        onClick={() => this.props.history.push(this.props.goToUrl)}
        className={"counterIcon d-flex align-item-center"}
        style={{ paddingLeft:this.props.paddingLeft }}>
        <EliteParagraph
          text={this.props.qty}
          textColor={"#f9fa00"}
          fontWeight={"bold"}
          margin={"0 8px 0 0"}
        />
        <ReactSVG src={this.props.svg} />
      </div>
    );
  }
}

export default withRouter(CounterIcon);
