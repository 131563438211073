import React, { Component } from "react";
import CustomAvatar from "./Avatar/Avatar";
import CustomButton from "./Button/CustomButton";
import Carousel from "./Carousel/Carousel";
import CustomModal from "./Modal/Modal";
import CustomRadioButton from "./RadioButtons/RadioButtons";
import CustomTextField from "./TextFields/TextFields";
import TextAsButton from "./TxtAsButton/TxtAsButton";
import BigTitle from "./Typography/BigTitle";
import EliteParagraph from "./Typography/Paragraph";
import EliteSmallTitle from "./Typography/SmallTitle";
import { meetsMock } from "../../mocks/OurMeetsMock";
import CustomCheckbox from "./Checkbox/Checkbox";
import Chips from "./Chips/Chips";
import InputChips from "./InputChips/InputChips";
import Tags from "./Tags/Tags";
import marocFlag from "../../assets/images/flags/flag-maroc.png";
import CustomTabs from "./Tabs/Tabs";
import PhotoButton from "./PhotoButton/PhotoButton";

export class UiKit extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
  }

  tabsItems = [
    { menuItem: "menu1", menuContent: "menu1Content" },
    { menuItem: "menu2", menuContent: "menu2Content" },
  ];

  components = [
    {
      title: "Button",
      component: (
        <CustomButton
          onClick={() => console.log("CustomButton onClick")}
          label={"CustomButton"}
        />
      ),
    },
    {
      title: "Button secondary",
      component: (
        <CustomButton
          secondary
          onClick={() => console.log("CustomButton onClick")}
          label={"CustomButton"}
        />
      ),
    },
    {
      title: "Text as Button",
      component: <TextAsButton text={"TextAsButton component"}></TextAsButton>,
    },
    {
      title: "Avatar",
      component: (
        <CustomAvatar
          photoUrl={
            "https://uifaces.co/our-content/donated/gPZwCbdS.jpg"
          }></CustomAvatar>
      ),
    },
    {
      title: "Avatar borderColor",
      component: (
        <CustomAvatar
          borderColor={"#f9fa00"}
          photoUrl={
            "https://uifaces.co/our-content/donated/gPZwCbdS.jpg"
          }></CustomAvatar>
      ),
    },
    {
      title: "Big title",
      component: (
        <BigTitle text={"Big title"} titleColor={"#4e4e4e"}></BigTitle>
      ),
    },
    {
      title: "Big title",
      component: (
        <BigTitle
          fontFamily={"Roboto"}
          titleColor={"#4e4e4e"}
          text={"Big title"}></BigTitle>
      ),
    },
    {
      title: "Small Title",
      component: (
        <EliteSmallTitle
          text={
            "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit..."
          }></EliteSmallTitle>
      ),
    },
    {
      title: "Paragraph",
      component: (
        <EliteParagraph
          textAlign={"left"}
          text={
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam lobortis ante libero, bibendum viverra dui faucibus vel. Etiam rutrum rhoncus lectus sit amet auctor. Donec sodales, diam vitae scelerisque dignissim, dui ligula dignissim turpis, sed finibus urna leo bibendum mauris. Aenean ullamcorper ipsum id lorem cursus, quis blandit orci cursus. Morbi leo diam, pretium ac est tincidunt, vulputate faucibus tellus. Sed condimentum porta neque non tincidunt. Nunc aliquet tellus in neque scelerisque accumsan. Curabitur tempus arcu vel ligula semper porttitor."
          }></EliteParagraph>
      ),
    },
    {
      title: "Input",
      component: <CustomTextField></CustomTextField>,
    },
    {
      title: "Input disabled",
      component: <CustomTextField isDisabled></CustomTextField>,
    },
    {
      title: "Input invalid",
      component: <CustomTextField isInvalid></CustomTextField>,
    },
    {
      title: "Radio Buttons",
      component: (
        <>
          <CustomRadioButton
            callBack={() => {
              console.log("radio");
            }}
            name={"example"}></CustomRadioButton>
          <CustomRadioButton
            callBack={() => {
              console.log("radio");
            }}
            name={"example"}></CustomRadioButton>
        </>
      ),
    },
    {
      title: "Checkbox",
      component: (
        <>
          <CustomCheckbox
            label={"checkbox test"}
            checkboxId={"test"}></CustomCheckbox>
        </>
      ),
    },
    {
      title: "Carousel",
      component: <Carousel data={meetsMock}></Carousel>,
    },
    {
      title: "Chips",
      component: <Chips text={"teste"} showClose />,
    },
    {
      title: "InputChips",
      component: <InputChips></InputChips>,
    },
    {
      title: "Tags",
      component: (
        <Tags icon={marocFlag} smallText={"je suis"} text={"Maroquine"}></Tags>
      ),
    },
    {
      title: "Tabs",
      component: <CustomTabs tabs={this.tabsItems}></CustomTabs>,
    },
    {
      title: "PhotoButton",
      component: (
        <div className={"d-flex"}>
          <PhotoButton
            isInUse
            photoUrl={
              "https://steamcdn-a.akamaihd.net/steamcommunity/public/images/avatars/a0/a018936de41011e84b29dbea77bf853371592dd0_full.jpg"
            }></PhotoButton>
          <PhotoButton
            photoUrl={
              "https://uifaces.co/our-content/donated/gPZwCbdS.jpg"
            }></PhotoButton>
          <PhotoButton></PhotoButton>
          <PhotoButton></PhotoButton>
          <PhotoButton></PhotoButton>
        </div>
      ),
    },
  ];

  render() {
    return (
      <div className="container">
        <div className="row">
          {this.components.map((e, i) => (
            <div className={"col-12 pb-4"} key={i}>
              <h3>{e.title}</h3>
              <span>{e.component}</span>
            </div>
          ))}
        </div>

        {/** Modal is out of the object because he needs to re-render to reference as child **/}
        <h3>Modal</h3>
        <small onClick={() => this.child.current.handleOpenModal()}>
          Modal example
        </small>
        <CustomModal
          modalContent={"Modal example"}
          modalClass={"forgotPasswordModal"}
          ref={this.child}></CustomModal>
      </div>
    );
  }
}

export default UiKit
