import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import Carousel from "../../Uikit/Carousel/Carousel";
import "../Offers.css";
import closeButton from "../../../assets/images/btn-close.svg";
import TitleOverTitle from "../../Uikit/TitleOverTitle/TitleOverTitle";
import UserService from "../../../Services/ApiServices/UserService";
import EliteParagraph from "../../Uikit/Typography/Paragraph";
import PerfectScrollbar from "react-perfect-scrollbar";
import {handleError} from "../../../Services/ErrorHandler";
import {useCurrentUser} from "../../../Hooks/AuthenticatedUser";

export default function ChooseOfferMobile(props) {
  const [subscriptions, setSubscriptions] = useState([]);
    const [reloadSubscription, setReloadSubscription] = useState(0);
    const {user} = useCurrentUser();
    const [currentUserCurrency, setCurrentUserCurrency] = useState(user.devise);

  useEffect(() => {
    async function fetchDescriptions() {
      await UserService.getSubscriptions().then((res) => {
        setSubscriptions(res.data.list);
      });
    }
      if(typeof reloadSubscription !== 'undefined') {
          fetchDescriptions();
      }
  }, [reloadSubscription]);

    async function updateCurrency(newCurrency) {
        try {
            await UserService.updateDevise(newCurrency).then(()=> {
                setReloadSubscription(reloadSubscription + 1);
            })
            setCurrentUserCurrency(newCurrency)
        } catch (e) {
            handleError(e);
        }
    }

  return (
    <div className="Offers">
      <div
        className={"closeButtonLevitate d-flex flex-column align-items-center"}
        style={{ marginTop: "16px" }}>
        <ReactSVG
          style={{ cursor: "pointer" }}
          src={closeButton}
          onClick={() =>
            props.closeModal.current.handleCloseModal()
          }></ReactSVG>
      </div>
      <TitleOverTitle
        backTitleColor={"rgba(245, 245, 245,0.2)"}
        frontTitleColor={"#fff"}
        backTitleSize={"36px"}
        frontTitleSize={"24px"}
        title={"Nos offres"}
        overTitleMarginTop={"-30px"}
        centerTitle
      />
      <EliteParagraph
        // text={"Quel type de diamant êtes-vous ?"}
        uppercase
        margin={"0 0 0 0"}
        textAlign={"center"}
        textColor={"#f9fa00"}
        fontSize={"16px"}
        fontWeight={"500"}
      />
      <div className="mt-1 banner-mobile">
        <Carousel
         currentUserCurrency initialSlide={1}
          data={subscriptions}
          centerPadding={"10%"}
          centerMode
          infinite
          carouselType={"offers"}
          slidesToShow={1}
          slidesToScroll={1}
          hideDots
          hideArrows
          updateCurrency={ updateCurrency }
          devise={ currentUserCurrency }
          responsiveBreakpoints={[
            {
              breakpoint: 320,
              settings: {
                slidesToScroll: 1,
              },
            },
          ]}
        />
        <div className="container">
          <div className="row">
            <div className="col-12" style={{paddingLeft:'45px'}}>

                <div className="teaser mobile">
                <PerfectScrollbar>
                  <ul>
                    <li>Accéder aux profils</li>
                    <li>Communication illimitée</li>
                    <li>Matching sur mesure (5/jour)</li>
                    <li>
                      Accès aux évènements organisés chaque mois : diner,
                      soirée, conférence
                    </li>
                    <li>Consulter vos affinités (Visite/Like)</li>
                    <li>Accès aux photos et contenu des membres</li>
                  </ul>
                  </PerfectScrollbar>
                </div>

            </div>
          </div>
        </div>
      </div>
      )
    </div>
  );
}
