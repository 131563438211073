import React from "react";
import BoostOffer from "./BoostOffer";
import ChooseOffer from "./ChooseOffer";
import { hasValidSubscription } from "../../Services/ApiServices/api.client";
import ChooseOfferMobile from "./Mobile/ChooseOfferMobile";

export default function Offers(props) {
  const showOffer = () =>
    hasValidSubscription() ? (
      <BoostOffer isMobile={props.isMobile} closeModal={props.closeModal} />
    ) : props.isMobile ? (
      <ChooseOfferMobile closeModal={props.closeModal} />
    ) : (
      <ChooseOffer closeModal={props.closeModal} />
    );
  return showOffer();
}
