import React, {Component} from 'react';
import LoginMobile from "./Mobile/LoginMobile";
import './LoginStyle.css';

class Login extends Component {
  render() {
    return (
      <div>
        Login
      </div>
    );
  }
}

export {Login, LoginMobile};
