import React, { useState } from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import ResponsiveService from "../../Services/ResponsiveService";
import { Helmet, HelmetProvider } from "react-helmet-async";
import datingMarseille from "../../assets/images/dating-marseille-1.jpg";
import datingMarseille2 from "../../assets/images/dating-marseille-2.jpg";
import datingMarseille3 from "../../assets/images/dating-marseille-3.jpg";
import BigTitle from "../../Components/Uikit/Typography/BigTitle";
import EliteSmallTitle from "../../Components/Uikit/Typography/SmallTitle";
import EliteParagraph from "../../Components/Uikit/Typography/Paragraph";


export function MarseillePage() {
    const [isMobile, setIsMobile] = useState(
        ResponsiveService.isMobile()
    );
    
    const handleResize = (e) => {
        e.preventDefault();
        if (isMobile !== ResponsiveService.isMobile()) {
          setIsMobile({ isMobile: ResponsiveService.isMobile() });
        }
    };
    window.addEventListener("resize", handleResize.bind(this));

    return (
        <HelmetProvider>
        <>
            <Helmet>
                <title>{"Site de rencontre Marseille sérieux & gratuit – Elite Berbère"}</title>
                <meta name="description" content="Si vous souhaitez partager votre vie avec une personne qui a 
                les mêmes valeurs que vous, faites appel à Elite Berbère, le site de rencontre à Marseille."/>
                <meta name="keywords" content="Gagner du temps, Créer un profil, Vie de célibataire, Speed-dating, 
                Points communs, Sincère, Nouer, Recherche de l amour, Premier rendez-vous, Bonne personne, 
                Rencontrez, Pour rencontrer, Faire connaissance, Grand amour, Célibataires, Amoureuse, 
                Relation sérieuse, Trouver l amour, Sérieuse, Faire des rencontres, Affinités, Attractive, 
                Âme-soeur, Site de rencontre sérieux, Rencontre sérieuse"/>
            </Helmet>
            <Header />
            <div className="container">
                <div className="row p-4">
                    <div style={{textAlign: "justify"}} className="col-12">
                        <BigTitle
                            marginBottom={"5vh"}
                            titleColor={"#4e4e4e"}
                            textAlign={"left"}
                            text={"Trouvez l'amour avec Élite Berbère, le site de rencontre à Marseille"}
                        />
                        <EliteParagraph
                            text={<>
                                Il n'est pas toujours simple de <b>trouver l'amour</b>. Et cela est d'autant plus vrai si l'on est particulièrement
                                exigeant. Pour rencontrer des <b>célibataires</b> qui partagent les mêmes traditions que vous, vous pouvez laisser
                                place au hasard ou alors prendre les choses en main et décider de <b>gagner du temps</b> en faisant appel à un site
                                de rencontre à Marseille qui vous présentera uniquement des personnes qui correspondent à vos envies.
                            </>}
                            textAlign={"justify"}
                            fontSize={"1.3em"}/>

                        <EliteSmallTitle
                            textAlign={"left"}
                            text={"Où est votre âme sœur ?"} />
                        <EliteParagraph
                            text={<>
                                La <b>vie de célibataire</b> n'est pas toujours amusante. Si vous en avez assez de passer vos soirées seul(e)
                                devant la télé, il est temps de réagir et de chercher la personne qui vous fera sourire. Mais même si
                                Marseille renferme de nombreuses personnes partageant les mêmes origines que vous, beaucoup ont oublié
                                leur culture berbère. Après le <b>premier rendez-vous</b>, vous constatez alors que cela ne pourra
                                pas fonctionner. Pour éviter cette déception et rencontrer des personnes avec qui vous partagez
                                de nombreuses <b>affinités</b>, il est important de vous faire aider.
                            </>}
                            textAlign={"justify"}/>
                        <img className={"img-fluid"} style={{marginBottom: "16px"}} src={ datingMarseille } alt={ "rencontre Marseille 1" }/>

                        <EliteSmallTitle
                            textAlign={"left"}
                            text={"Élite Berbère, un site de rencontre à Marseille qui a tout compris à vos exigences"} />
                        <EliteParagraph
                            text={<>
                                Vous avez déjà entendu parler des sites de rencontre, mais n'avez pas encore voulu utiliser ce moyen pour <b>faire
                                des rencontres</b> ? Vous avez déjà fait appel à ce type de site, mais n'avez pas réussi à trouver la <b>bonne personne</b>,
                                car toutes vos exigences n'étaient pas prises en compte ?
                            </>}
                            textAlign={"justify"}/>
                        <EliteParagraph
                            text={<>
                                <a href={"https://eliteberbere.fr"}>Élite Berbère</a> va vous faire changer d'avis.
                            </>}
                            textAlign={"justify"}/>
                        <EliteParagraph
                            text={<>
                                Tout comme vous, nous mettons en avant notre culture et nos traditions et nous savons que pour <b>nouer</b> une <b>relation
                                sérieuse</b> qui durera dans le temps, il faut partager un maximum de choses avec l'autre. Nous avons donc créé un
                                site de rencontre à Marseille dédié aux personnes d'origine berbère (nées au Maghreb ou en France).
                            </>}
                            textAlign={"justify"}/>
                        <EliteParagraph
                            text={<>
                                Vous pourrez ainsi plus facilement trouver la personne qui partage un grand nombre de <b>points communs</b> avec vous
                                à travers le monde ou simplement à Marseille.
                            </>}
                            textAlign={"justify"}/>
                        <EliteParagraph
                            text={<>
                                Notre site a été imaginé par des Imazighen pour des Imazighen. Nous ne nous adressons pas à des personnes frivoles.
                                En effet, notre crédo est de vous aider à trouver le <b>Grand Amour</b>. Nous sommes un <b>site de rencontre sérieux</b> qui met
                                en avant la qualité, le respect et la droiture. Nous sommes le partenaire idéal pour que vous puissiez <b>faire
                                connaissance</b> avec un partenaire <b>sincère</b>. Vous trouverez également sur notre site de rencontre à Marseille
                                des services (conférences, ateliers, <b>speed-dating</b>...) qui vous permettront de partager plus facilement avec
                                celui ou celle qui deviendra peut-être l'élu(e) de votre cœur. Nous pouvons le dire, nous sommes une plateforme
                                élitiste, car nous pensons que pour faire une <b>rencontre sérieuse</b>, il faut que les partenaires
                                soient « triés sur le volet ».
                            </>}
                            textAlign={"justify"}/>
                        <img className={"img-fluid"} style={{marginBottom: "16px"}} src={ datingMarseille2 } alt={ "rencontre Marseille 2" }/>

                        <EliteSmallTitle
                            textAlign={"left"}
                            text={"Faites-nous confiance pour rencontrer la bonne personne"} />
                        <EliteParagraph
                            text={<>
                                La <b>recherche de l'amour</b> est simple avec nous. Il vous suffit de <b>créer un profil</b> puis de sélectionner la personne
                                <b>attractive</b> qui a fait battre un peu plus fort votre cœur. <b>Rencontrez</b>-la et commencez une relation <b>sérieuse</b>.
                                Avec nous, vous avez l'assurance de rencontrer de futurs partenaires qui partagent les mêmes valeurs que vous.
                                La <b>recherche de l'amour</b> est facilitée. À vous alors de débuter votre histoire <b>amoureuse</b>. Vous pourrez alors
                                ressentir ces petits frémissements si agréables et commencer une relation durable.
                            </>}
                            textAlign={"justify"}/>
                        <img className={"img-fluid"} style={{marginBottom: "16px"}} src={ datingMarseille3 } alt={ "rencontre Marseille 3" }/>
                    </div>
                </div>
            </div>
            <Footer />
        </>
        </HelmetProvider>
    );
}
