import React from "react";
import EliteParagraph from "../../Components/Uikit/Typography/Paragraph";
import { pick, values } from "lodash-es";

function buildLabels(labels) {
  return labels.map((label, i) => (
    <div
      key={ i }
      className={ `justify-content-between labelsContainerForm d-flex ${
        i === 7 || i === 13 || i >= labels.length - 1 ? "pt-4" : ""
      } ` }>
      <div className="titleContainerProfile">
        <EliteParagraph
          fontSize={ "12px" }
          fontWeight={ 500 }
          textColor={ "#6c95e7" }
          uppercase
          textAlign={ "left" }
          text={ label.title }
        />
      </div>
      <div className="titleContainerProfile">
        <EliteParagraph
          fontWeight={ 400 }
          fontSize={ "14px" }
          textAlign={ "left" }
          text={ label.content }
        />
      </div>
    </div>
  ));
}

export function JeRecherche({ user }) {

  const genders = {
    H: 'Homme',
    F: 'Femme',
  };

  const bool = {
    0: 'Non',
    1: 'Oui'
  }

  const labels = [
    { title: "âge :", content: (user.searching.age.min && user.searching.age.max) ? `Entre ${ user.searching.age.min || 18 } et ${ user.searching.age.max || 99 } ans` : '-' },
    { title: "sexe :", content: user.searching.gender ? values(pick(genders, user.searching.gender)).join(', ') : '-' },
    {
      title: "taille :",
      content: (user.searching.height.min && user.searching.height.max) ? `Entre ${ user.searching.height.min || 140 } cm et ${ user.searching.height.max || 220 } cm` : '-'
    },
    { title: "Silhouette :", content: user.searching.shape ? ( user.searching.shape || [] ).join(', ') : '-' },
    { title: "Couleur de cheveux :", content: user.searching.hairColor ? ( user.searching.hairColor || [] ).join(', ') : '-'},
    { title: "Couleur des yeux :", content: user.searching.eyeColor ? ( user.searching.eyeColor || [] ).join(', ') : '-' },

    { title: "Origine :", content: user.searching.origin ? ( user.searching.origin || [] ).join(', ') : '-' },
    { title: "Religieux :", content: user.searching.religion ? ( user.searching.religion || [] ).join(', ') : '-' },
    { title: "à des enfants :", content: user.searching.hasChildren ? values(pick(bool, user.searching.hasChildren)).join(', ') : '-' },
    { title: "Veux des enfants :",  content: user.searching.wantChildren ? values(pick(bool, user.searching.wantChildren)).join(', ') : '-' },

    { title: "Langue :", content:  user.searching.languages ? ( user.searching.languages || [] ).join(', ') : '-'},
    { title: "Habite à :", content: user.searching.city ? user.searching.city.map(e => e.name).join(', ') : '-' },
    { title: "Tabac :", content: user.searching.tabac ? ( user.searching.tabac || [] ).join(', ') : '-'},


    { title: "Niveau d’études :", content: user.searching.studyLevel ? ( user.searching.studyLevel || [] ).join(', ') : '-' },
    { title: "Secteur d’activité :", content: user.searching.activityArea ? ( user.searching.activityArea || [] ).join(', ') : '-' },

    //{ title: "Signe astrologique :", content: user.searching.astroSign ? ( user.searching.astroSign || [] ).join(', ') : '-' },
    //{ title: "Situation :", content: user.searching.maritalStatus ? ( user.searching.maritalStatus || [] ).join(', ') : '-' },
    { title: "Activité sportive :", content: user.searching.sport ? ( user.searching.sport || [] ).join(', ') : '-' },
    { title: "Caractère :", content: user.searching.traits ? ( user.searching.traits || [] ).join(', ') : '-' },

  ];

  return <div className={ "pt-4" }>{ buildLabels(labels) }</div>;
}

export default JeRecherche;
