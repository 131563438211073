import React from "react";
import {ReactSVG} from "react-svg";
import closeButton from "../../../assets/images/btn-close.svg";
import EliteParagraph from "../../Uikit/Typography/Paragraph";
import backArrow from "../../../assets/images/icon-arrow-back.svg";

import ModalField from "../../Uikit/ModalField/ModalField";
import MultipleChoiseModal from "../../Uikit/MultipleChoiseModal/MultipleChoiseModal";

export function Caractere(props) {
  const choices = [
    {label: "Aimable", value: 'Aimable'},
    {label: "Audacieux", value: 'Audacieux'},
    {label: "Calme", value: 'Calme'},
    {label: "Démonstratif", value: 'Démonstratif'},
    {label: "Discret", value: 'Discret'},
    {label: "Extravertie", value: 'Extravertie'},
    {label: "Facile à vivre", value: 'Facile à vivre'},
    {label: "Ferme", value: 'Ferme'},
    {label: "Fier", value: 'Fier'},
    {label: "Franc", value: 'Franc'},
    {label: "Généreux", value: 'Généreux'},
    {label: "Gentil", value: 'Gentil'},
    {label: "Introvertie", value: 'Introvertie'},
    {label: "Inquiet", value: 'Inquiet'},
    {label: "Jaloux", value: 'Jaloux'},
    {label: "Pointilleux", value: 'Pointilleux'},
    {label: "Pudique", value: 'Pudique'},
    {label: "Réserve", value: 'Réserve'},
    {label: "Respectueux", value: 'Respectueux'},
    {label: "Sensible", value: 'Sensible'},
    {label: "Sévère", value: 'Sévère'},
    {label: "Sociable", value: 'Sociable'},
    {label: "Solitaire", value: 'Solitaire'},
    {label: "Tendre", value: 'Tendre'},
    {label: "Tenace", value: 'Tenace'},
    {label: "Autres", value: 'Autres'},
  ];

  function sortAlphabetically(arrayToSort) {
    return arrayToSort.sort(function (a, b) {
      if (a.value.toLowerCase() < b.value.toLowerCase()) {
        return -1;
      }
      if (a.value.toLowerCase() > b.value.toLowerCase()) {
        return 1;
      }
      return 0;
    })
  }

  function handleClose() {
    props.closeModal();
  }

  function onChange(value) {
    props.onChange(value);
    handleClose()
  }

  function renderField(attrs) {
    return (
      <MultipleChoiseModal
        choices={sortAlphabetically(choices)}
        {...attrs}
      />)
  }


  return (
    <>
      <div className={props.isMobile ? "d-none" : "d-block px-5 pt-4 pb-2"}>
        <EliteParagraph
          text={props.title}
          fontSize={"24px"}
          textColor={"#6c95e7"}
          textAlign={"left"}
          fontWeight={500}
        />
      </div>
      <div
        className={
          !props.isMobile ? "d-none" : "d-block col-lg-4 col-md-12 mb-4 headerModalMobile"
        }>
        <div className={"back"} style={{marginTop: "64px"}}>
          <div
            onClick={handleClose}>
            <ReactSVG src={backArrow}/> {props.title}
          </div>
        </div>
      </div>
      <div
        className={`d-flex flex-column w-100 contentModalMinHeight position-relative ${props.isMobile ? 'mobile' : 'px-5'}`}>
        <ModalField
          isMobile={props.isMobile}
          id="caractere"
          name="caractere"
          value={props.value}
          onChange={onChange}
          closeModal={handleClose}
          placeholder={props.title}
          renderField={renderField}
          classes={props.isMobile ? 'headerModalMobile' : ''}
        />
      </div>
      <div
        className={
          "closeButtonLevitate d-flex flex-column align-items-center"
        }>
        <ReactSVG
          style={{cursor: "pointer"}}
          src={closeButton}
          onClick={handleClose}/>
      </div>
    </>
  );
}

export default Caractere;
