import React, { useEffect } from "react";
import HomeMobile from "./Mobile/HomeMobile";
import "./HomeStyle.css";
import Header from "../../Components/Header/Header";
import CustomBanner from "../../Components/Banner/Banner";
import Highlights from "../../Components/Highlights/Highlights";
import WhyChooseUs from "../../Components/WhyChooseUs/WhyChooseUs";
import OurMeets from "../../Components/OurMeets/OurMeets";
import Footer from "../../Components/Footer/Footer";
import { useHistory, useParams } from "react-router-dom";
import UserService from "../../Services/ApiServices/UserService";
import { toast } from "react-toastify";
import { handleError } from "../../Services/ErrorHandler";
import gaService from "../../Services/GaService";
import PartnershipBloc from "../../Components/PartnershipBloc/PartnershipBloc";

async function confirmEmail(token) {
  try {
    await UserService.confirmEmail(token)
    toast.success('Inscription validée. Vous pouvez vous connecter à votre compte.')
    setTimeout(() => window.location.href = '/login', 500);
  } catch (e) {
    handleError(e);
  }
}

function Home() {
  const history = useHistory();
  const { token } = useParams();

  useEffect(() => {
    gaService.track({type: "page", page: "login_desktop"})
    if (history.location.pathname.match(/^\/confirm-email/)) {
      confirmEmail(token);
    }else if(localStorage.getItem('tokenApi')) {
      window.location.href = '/home'
    }else if(history.location.pathname !== '/' && !history.location.pathname.match(/^\/confirm-email/) && !history.location.pathname.match(/^\/reset-password/)) {
      window.location.href = '/'
    }
  }, [history.location.pathname, token])

  return (
    <>
      <Header/>
      <CustomBanner/>
      <Highlights/>
      <WhyChooseUs/>
      <PartnershipBloc/>
      <OurMeets/>
      <Footer/>
    </>
  );
}

export { Home, HomeMobile };
