import React, { Component } from "react";
import EliteParagraph from "./Paragraph";

class EliteErrorText extends Component {
  render() {
    return (
      <EliteParagraph
        text={this.props.text}
        fontSize={'12px'}
        textColor={'#d1360e'}
        textAlign={'right'}
        margin={'0 0 4px 0'}
        fontWeight={'500'}
        fontStyle={'italic'}
        display={'inline'}
      />

    );
  }
}
export default EliteErrorText;
