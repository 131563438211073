import React, { useRef } from "react";
import "./ContactButtonStyles.css";
import iconRightColor from "../../../assets/images/icon-arrow-right-color.svg";
import { ReactSVG } from "react-svg";
import ContactForm from "./ContactForm";

export function ContactButton({ isMobile, icon, label, subject, dataList }) {
  const modal = useRef();
  function showForm() {
    return modal.current?.handleOpenForm();
  }

  return (
    <>
      <div
        className={ "contactButton d-flex w-100 justify-content-between mt-4" }
        onClick={ showForm }>
        <div className="d-flex">
          <ReactSVG src={icon} />
          <span className={"ms-2"}>{label}</span>
        </div>
        <span>
          <ReactSVG src={iconRightColor} />
        </span>
      </div>
      <ContactForm
        label={ label }
        subject={ subject }
        isMobile={ isMobile }
        dataList={ dataList || [] }
        ref={ modal }
      />
    </>
  );
}

export default ContactButton;
