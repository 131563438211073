import React from "react";
import { ReactSVG } from "react-svg";

import closeButton from "../../../assets/images/btn-close.svg";
import backArrow from "../../../assets/images/icon-arrow-back.svg";
import EliteParagraph from "../../Uikit/Typography/Paragraph";
import * as yup from "yup";
import CustomTextField from "../../Uikit/TextFields/TextFields";
import ModalFields from "../../Uikit/ModalField/ModalFields";

export default function Password(props) {
  function handleClose() {
    props.closeModal();
  }

  async function onChange(value = {}) {
    await props.onChange(value);
    handleClose();
  }

  const validationSchema = yup.object().shape({
    oldPassword: yup.string()
      .required('Champ obligatoire'),
    password: yup.string()
      .required()
      .matches(/(?=.{8,})(?=.*?[^\w\s])(?=.*?[0-9])(?=.*?[A-Z]).*?[a-z].*/g,
        'Votre mot de passe doit contenir 8 caractères incluant  lettre capitale, une minuscule, un caractère spécial ainsi qu’un nombre')
    ,
    passwordConfirmation: yup.string()
      .required('Champ obligatoire')
      .test({
        name: "confirmation",
        message: "Veuillez renseigner 2 mots de passe identiques",
        test: (value, { parent }) => value && value === parent.password,
      }),
  });

  const fields = {
    oldPassword: {
      label: "",
      render(attrs) {
        return <CustomTextField  placeholder={'Mot de passe actuel'} {...attrs} type="password" />;
      },
    },
    password: {
      label: "",
      render(attrs) {
        return <CustomTextField  placeholder={'Nouveau mot de passe'} {...attrs} type="password" />;
      },
    },
    passwordConfirmation: {
      label: "",
      render(attrs) {
        return <CustomTextField  placeholder={'Confirmation du nouveau mot de passe'} {...attrs} type="password" />;
      },
    },
  };

  return (
    <>
      <div className={props.isMobile ? "d-none" : "d-block px-5 pt-4 pb-2"}>
        <EliteParagraph
          text={props.title}
          fontSize={"24px"}
          textColor={"#6c95e7"}
          textAlign={"left"}
          fontWeight={500}
        />
      </div>
      <div
        className={
          !props.isMobile
            ? "d-none"
            : "d-block px-2 col-lg-4 col-md-12 mb-4 headerModalMobile"
        }>
        <div className={"back"} style={{ marginTop: "64px" }}>
          <div style={{height:"35px"}}/>
          <div onClick={handleClose}>
            <ReactSVG src={backArrow} /> {props.title}
          </div>
        </div>
      </div>
      <div
        className={`d-flex flex-column w-100 contentModalMinHeight position-relative ${
          props.isMobile ? "mobile" : "px-5"
        }`}>
        <ModalFields
          isMobile={props.isMobile}
          validationSchema={validationSchema}
          value={props.value}
          onChange={onChange}
          closeModal={handleClose}
          placeholder={props.title}
          fields={fields}
          classes={props.isMobile ? "headerModalMobile" : ""}
        />
      </div>
      <div
        className={"closeButtonLevitate d-flex flex-column align-items-center"}>
        <ReactSVG
          style={{ cursor: "pointer" }}
          src={closeButton}
          onClick={handleClose}
        />
      </div>
    </>
  );
}
