import React from "react";
import ConnectedHeader from "../../Components/Header/ConnectedHeader/ConnectedHeader";
import Footer from "../../Components/Footer/Footer";
import { useParams } from "react-router-dom";
import { useProfile } from "../../Hooks/Profile";

export function UserProfile(props) {
  const { id } = useParams()
  const { profile } = useProfile(id);

  return (
    <div>
      <ConnectedHeader/>
      <pre>{ JSON.stringify(profile, null, 2) }</pre>
      <Footer isLogged/>
    </div>
  );
}
