import React, { Component } from "react";
import Footer from "../../../Components/Footer/Footer";
import FooterMenu  from "../../../Components/FooterMenu/FooterMenu";
import SearchContainerMobile from "../../../Components/SearchContainer/Mobile/SearchContainerMobile";
import { toast } from "react-toastify";
import UserService from "../../../Services/ApiServices/UserService";


export class SearchMobile extends Component {
  componentDidMount() {
    const queryString = this.props.location.search;
    const query = new URLSearchParams(queryString);
    if (query && query.get("success") === "true") {
      UserService.createUserSubscription({
        subscriptionId: query.get("sub_id"),
        paymentSubscriptionId: query.get("session_id"),
        isBoostOffer: query.get("is_boost"),
        module_subscription_type: "stripe",
      }).then(async () => {
        if (query.get("is_boost") === "1") {
          toast.success("Merci d'avoir souscrit à un booster.");
        } else {
          toast.success(
            "Votre abonnement a bien été validé, Bienvenue à vous !"
          );
        }

        await UserService.getCurrentSubscription();
        this.props.history.replace("/search");

        // props.history.push("/home");
      });
    } else if (query && query.get("success") === "false") {
      // toast payment failure
    }
  }

  render() {
    return (
      <>
        {/* <ConnectedHeaderMobile></ConnectedHeaderMobile> */}
        <div className="container">
          <SearchContainerMobile></SearchContainerMobile>
        </div>
        <Footer isLogged></Footer>
        <FooterMenu></FooterMenu>
      </>
    );
  }
}

export default SearchMobile;
