import React, {useEffect, useRef} from "react";
import TitleOverTitle from "../../../Components/Uikit/TitleOverTitle/TitleOverTitle";
import iconChatTitle from "../../../assets/images/icon-chat-title.svg";
import FooterMenu from "../../../Components/FooterMenu/FooterMenu";
import EliteParagraph from "../../../Components/Uikit/Typography/Paragraph";
import MiniDropdown from "../../../Components/Uikit/Dropdown/MiniDropdown";
import ChatCardUser from "../../../Components/Chat/ChatCardUser";
import {generatePath, useHistory} from "react-router-dom";
import "./MessagesMobileStyles.css";
import { EliteRouters } from "../../../Navigation/Routers";
import { useMessages } from "../../../Hooks/MessagesHooks";
import { map } from "lodash-es";
import { subscribe } from "../../../Services/Mercure";
import { useCurrentUser } from "../../../Hooks/AuthenticatedUser";
import {hasValidSubscription} from "../../../Services/ApiServices/api.client";
import Offers from "../../../Components/Offers/Offers";
import CustomModal from "../../../Components/Uikit/Modal/Modal";
import {ReactSVG} from "react-svg";
import illusBallon from "../../../assets/images/illustration-bubble.svg";

export function MessagesMobile(props) {
  const history = useHistory();
  const {user} = useCurrentUser();
  const {sortedRooms, filterActions, updateRoom} = useMessages()
  const modalOffers = useRef();


  function goToProfile(id) {
    // history.push('/home/' + username);
    if (hasValidSubscription()) {
      history.push(generatePath(EliteRouters.visitedProfile.path, {id}))
    } else {
      modalOffers.current.handleOpenModal()
    }
  }

  const showChatRooms = <>{ map(sortedRooms, (room) => (
      <div
          className="mt-1 mb-1 chatUserCardContainer"
          key={room.id}
          onClick={() => {
            if (hasValidSubscription()) {
              history.push(generatePath(EliteRouters.messages.path, {roomId: room.id}))
            }  else {
              modalOffers.current.handleOpenModal();
            }
          }}>
      <div className={ "mt-1 mb-1 chatUserCardContainer" } key={ room.id }>
        <ChatCardUser
          room={room}
          isMobile={true}
          goToProfile={() => goToProfile(room.otherParticipant.id)}/>
      </div>
    </div>
  ))}</>;


  useEffect(() => {
    subscribe(`user-${user.id}-rooms`, updateRoom)
    // eslint-disable-next-line
  }, [user?.id]);

  return (
    <>
      <div className={"container"}>
        <div className="row">
          <div className="col-12">
            <TitleOverTitle
              backTitleSize={"36px"}
              frontTitleSize={"24px"}
              title={"Messages"}
              overTitleMarginTop={"-30px"}
              icon={iconChatTitle}
            />
          </div>
          <div className={"col-12 "}>
            <div className="row">
              <div className="col-12 d-flex justify-content-end align-items-center">
                <EliteParagraph customStyle={{
                  display: 'flex',
                  alignItems: 'center'
                }} fontSize={'12px'} margin={"0 8px 0 0"} text={"Trier par : "} />
                <MiniDropdown mobile noBorder label={"Date"} dropdownItems={filterActions}/>
              </div>
              <div className="col-12 d-flex flex-column userChatColumn messagesMobile">
                {sortedRooms.length === 0 ?
                  <div style={{padding: 10, textAlign: 'center'}}>
                    <ReactSVG src={illusBallon}/>
                    <div className="chooseConversationText">
                      <EliteParagraph
                        margin={"32px 0 0 0"}
                        text={
                          `Vous n'avez pas encore de conversation ? \n Les conversations se lancent automatiquement, lorsque 2 utilisateurs se sont respectivement likés.`
                        }
                        fontSize={"16px"}
                        fontWeight={"500"}
                        textColor={"#bcbcbc"}
                      />
                    </div>
                  </div>
                  : showChatRooms}
                {!hasValidSubscription() &&
                <div
                  onClick={() => modalOffers.current.handleOpenModal()}
                  className="d-flex mt-4 justify-content-center cursor-pointer">
                  <EliteParagraph
                    uppercase
                    textAlign={"center"}
                    textColor={"#6c95e7"}
                    fontSize={"18px"}
                    fontWeight={"bold"}
                    cursor={"pointer"}
                    text={"Découvrir nos offres"}
                    margin={"0"}
                  />
                </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        overlayClassName="Overlay"
        modalContent={
          <Offers
            isMobile={true}
            closeModal={modalOffers}
          />
        }
        modalClass={"offersModalMobile"}
        ref={modalOffers}
      />
      <FooterMenu/>
    </>
  );
}
