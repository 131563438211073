import React, { Component } from "react";
import "./CheckboxStyles.css";

class CustomCheckbox extends Component {
  render() {
    return (
        <div className={`d-flex justify-content-flex-start w-100 `} style={{width: this.props.isOnParameters ? '45%' : ''}}>
          <label
              style={{width:this.props.isOnParameters?'100%':'', justifyContent:this.props.isOnParameters?'space-between':''}}
              className={`form-check-label d-flex align-items-center ${
                  this.props.reverse && "reverseLabel"
              } ${this.props.isColor && "isColor"}`}
              htmlFor={this.props.checkboxId}>
            <input
                className={`form-check-input ${this.props.isColor && "isColor"}`}
                type={"checkbox"}
                checked={this.props.checked}
                id={this.props.checkboxId}
                onChange={(e) => this.props.onChange(e.target.checked)}
            />
            <span
                style={{
                  fontSize: `${this.props.fontSize}`,
                }}
                className="pt-2 ml-2 labelCheckbox">{this.props.label}</span>

          </label>
        </div>
    );
  }
}

export default CustomCheckbox;
