import React, {useRef} from "react";
import EliteParagraph from "../Uikit/Typography/Paragraph";
import iconIgnore from "../../assets/images/icon-ignore-red.svg";
import iconLoveWhite from "../../assets/images/icon-love-full-white.svg";
import {ReactSVG} from "react-svg";
import {hasValidSubscription} from "../../Services/ApiServices/api.client";
import {generatePath, useHistory} from "react-router-dom";
import {EliteRouters} from "../../Navigation/Routers";
import CustomModal from "../Uikit/Modal/Modal";
import Offers from "../Offers/Offers";

export function MatchMakingCardItemContent(props) {
    const history = useHistory()
    const modalOffers = useRef();

    function goToProfile() {
        if (hasValidSubscription()) {
            history.push(generatePath(EliteRouters.visitedProfile.path, {id: props.profile.id}))
        } else {
            modalOffers.current.handleOpenModal()
        }
    }

    return (
        <div className="gradientFilter visitedProfileGradient btn"
             onClick={ goToProfile }>
            <div className={"carouselInfoContainer position-absolute"}>
                <div
                    className={`userInfoContainerVisitedProfile ${
                        props.isMobile ? "mb-3" : "mb-5"
                    }`}>
                    <EliteParagraph
                        text={props.username}
                        textColor={"#fff"}
                        fontSize={"32px"}
                        fontWeight={"bold"}
                        margin={0}
                        textAlign={"left"}
                    />
                    <EliteParagraph
                        text={props.age + (!!props.city.length ? ", " + props.city : "")}
                        textColor={"#f9fa00"}
                        fontSize={"16px"}
                        fontWeight={400}
                        margin={0}
                        textAlign={"left"}
                    />
                </div>
                <div
                    className={`isOnline ${
                        props.isOnline ? "d-block" : "d-none"
                    }`} />
            </div>

            <CustomModal
                overlayClassName="Overlay"
                modalContent={
                    <Offers
                        isMobile={ props.isMobile }
                        closeModal={ modalOffers }
                    />
                }
                modalClass={ props.isMobile ? "offersModalMobile" : "offersModal" }
                ref={ modalOffers }
            />
        </div>
    )
}

export default function MatchMakingCardItem(props) {

    return (
        <div
            className={`w-100 d-flex flex-column`}
            style={{height: !hasValidSubscription() && "400px"}}>
            <div
                className={`card main ${
                    hasValidSubscription() ? "" : "noOffer"
                } ${props.isMobile ? "mobile" : ""}`}
                style={{backgroundImage: `url(${
                        process.env.REACT_APP_API_URL + props.photoUrl
                })`}}>
                {
                    hasValidSubscription() ? (
                        <div className="cursor" onClick={() => window.location = '/home/' + props.id}>
                            <MatchMakingCardItemContent {...props} />
                        </div>
                    ) : <MatchMakingCardItemContent {...props} />
                }
                <div
                    className={`d-flex justify-content-center position-absolute matchChooseBotContainer ${
                        !hasValidSubscription() ? "offer" : ""
                    }`}>
                    <div
                        className="circularButtons white"
                        onClick={() => {
                            if (hasValidSubscription()) {
                                props.callback("dislike", props.profileId)
                            }
                        }}>
                        <ReactSVG src={iconIgnore}/>
                    </div>
                    <div
                        className="circularButtons gradient"
                        onClick={() => {
                            if (hasValidSubscription()) {
                                props.callback("like", props.profileId)
                            }
                        }}>
                        <ReactSVG src={iconLoveWhite}/>
                    </div>
                </div>
            </div>
        </div>
    );
}
