import React, { Component } from "react";
import { ReactSVG } from "react-svg";
import BigTitle from "../Typography/BigTitle";
import "./TitleOverTitleStyles.css";

export class TitleOverTitle extends Component {
  render() {
    return (
      <div className={`TitlesContainer pb-2`}>
        <BigTitle
          textAlign={this.props.centerTitle ? "center" : "left"}
          titleColor={this.props.backTitleColor}
          fontSize={
            this.props.backTitleSize ? this.props.backTitleSize : "60px"
          }
          titleOpacity={0.8}
          text={this.props.title}></BigTitle>
        <div
          className={`OverTitle ${this.props.centerTitle && "center"}`}
          style={{
            marginTop: `${
              this.props.overTitleMarginTop && this.props.overTitleMarginTop
            }`,
          }}>
          <BigTitle
            textAlign={this.props.centerTitle ? "center" : "left"}
            titleColor={this.props.frontTitleColor}
            fontFamily={"Roboto"}
            fontSize={
              this.props.frontTitleSize ? this.props.frontTitleSize : "32px"
            }
            fontWeight={"bold"}
            padding={"0 16px 0 0"}
            text={this.props.title}></BigTitle>
          <ReactSVG src={this.props.icon ? this.props.icon : ""} />
        </div>
      </div>
    );
  }
}
TitleOverTitle.defaultProps = {
  backTitleColor: "#f5f5f5",
  frontTitleColor: "#6c95e7",
};
export default TitleOverTitle;
