import React, {useMemo, useRef, useState} from "react";
import CustomCheckbox from "../Uikit/Checkbox/Checkbox";
import ItemSelectorModal from "../Uikit/ItemSelectorModal/ItemSelectorModal";
import CustomModal from "../Uikit/Modal/Modal";
import ContactButton from "../Uikit/ContactButton/ContactButton";
import EliteParagraph from "../Uikit/Typography/Paragraph";
import EmailItem from "./ParametersEditionItems/Email";
import DeviseItem from "./ParametersEditionItems/Devise";
import PasswordItem from "./ParametersEditionItems/Password";
import {get} from "lodash-es";
import UserService from "../../Services/ApiServices/UserService";
import {handleError} from "../../Services/ErrorHandler";
import iconChat from "../../assets/images/icon-chat.svg";
import iconQuote from "../../assets/images/icon-quote.svg";
import iconImprove from "../../assets/images/icon-improve.svg";
import {useCurrentUser} from "../../Hooks/AuthenticatedUser";
import {generatePath, NavLink} from "react-router-dom";
import {EliteRouters} from "../../Navigation/Routers";
import Offers from "../Offers/Offers";
import {ReactSVG} from "react-svg";
import iconRightColor from "../../assets/images/icon-arrow-right-color.svg";
import backArrow from "../../assets/images/icon-arrow-back.svg";
import CustomTextArea from "../Uikit/TextArea/TextArea";
import CustomButton from "../Uikit/Button/CustomButton";
import {toast} from "react-toastify";
import {subjectsList} from "../../Services/DataLists";
import ErrorText from "../Uikit/Typography/ErrorText";

let changeCallback;

function generateForm() {
  return {
    email: {
      label: "EMAIL",
      key: "email",
      render(props) {
        return <EmailItem {...props} title={"EMAIL"} onChange={updateEmail}/>
      },
    },
    password: {
      label: "MOT DE PASSE",
      key: "password",
      render(props) {
        return (
          <PasswordItem {...props} title={"Mot de passe"} onChange={UserService.updatePassword}/>
        );
      },
    },
    devise: {
      label: "Devise",
      key: "devise",
      render(props) {
        return (
          <DeviseItem{...props} title={"Devise"} onChange={updateDevise}/>
        );
      },
    },
  };
}

async function updateEmail(email) {
  try {
    const {profile} = await UserService.updateEmail(email);
    changeCallback(profile);
  } catch (e) {
    handleError(e);
  }
}

async function updateDevise(email) {
  try {
    const {profile} = await UserService.updateDevise(email);
    changeCallback(profile);
  } catch (e) {
    handleError(e);
  }
}

export default function ParametersEdition({user, onChange, isMobile}) {
  const child = useRef();
  const {onLogout} = useCurrentUser();
  const [selected, setSelected] = useState();
  const deleteAccount = useRef();
  const [reasonWhy, setReasonWhy] = useState("");
  const [displayOffers, setDisplayOffers] = useState(true)
  changeCallback = onChange;

  function openModal(key) {
    setSelected(key);
    child.current.handleOpenModal();
  }

  function renderForm(user, openModal) {
    const controls = generateForm();
    return Object.keys(controls).map((key) => {
      const control = controls[key];
      const value = get(user, control.key, "");
      return (

        <div key={key}>
          <div
            className={control.className || ""}
            onClick={() => openModal(key)}>
            <ItemSelectorModal label={control.label + ":"} value={value}/>
          </div>
        </div>
      );
    });
  }

  const [editableControl, editableControlProps] = useMemo(() => {
    let control = generateForm()[selected];
    if (control) {
      const controlProps = {
        title: control.label,
        value: get(user, control.key),
        isMobile: isMobile,
        closeModal() {
          child.current.handleCloseModal();
          setSelected(null);
        },
        onChange(profile) {
          onChange(profile);
        },
      };
      return [control, controlProps];
    }
    return [];
  }, [selected, user, onChange, isMobile]);

  async function setPreference(attr, value) {
    const {
      data: {data},
    } = await UserService.updatePreference({[attr]: value});
    onChange({...data, [attr]: value});
  }

  return (
    <>
      <EliteParagraph
        textColor={"#6c95e7"}
        uppercase
        fontWeight={500}
        textAlign={"left"}
        text={"Connexion"}
        margin={"O 0 0 0"}
      />
      <div style={{height: "16px"}}/>
      {renderForm(user, openModal)}
      <EliteParagraph
        textColor={"#6c95e7"}
        uppercase
        fontSize={"16px"}
        fontWeight={500}
        textAlign={"left"}
        text={"Notifications par email"}
        margin={"32px 0 0 0"}
      />
      <div className="d-flex flex-sm-row flex-column mt-4 col-12 justify-content-between mt-4">
        <div className={" col-sm-5 col-12"}>
          <CustomCheckbox
            checked={user?.preference?.notifyLikes}
            isColor
            isOnParameters
            reverse
            label={"Nouveau like"}
            checkboxId={"like"}
            onChange={(value) => setPreference("notifyLikes", value)}
          />

        </div>
        <div className={"col-sm-5 col-12 mt-3 mt-sm-0"}>
          <CustomCheckbox
            checked={user?.preference?.notifyVisits}
            isColor
            isOnParameters
            reverse
            label={"Nouvelle visite"}
            checkboxId={"visite"}
            onChange={(value) => setPreference("notifyVisits", value)}
          />
        </div>
      </div>
      <div className="d-flex col-12 flex-sm-row flex-column col-12 mt-sm-4 justify-content-between ">
        <div className={"col-sm-5 col-12 mt-3 mt-sm-0"}>
          <CustomCheckbox
            checked={user?.preference?.notifyMessages}
            isColor
            isOnParameters
            reverse
            label={"Nouveau message"}
            checkboxId={"message"}
            onChange={(value) => setPreference("notifyMessages", value)}
          />
        </div>
        <div className={"col-sm-5 col-12 mt-3 mt-sm-0"}>
          <CustomCheckbox
            checked={user?.preference?.notifyMatches}
            isColor
            isOnParameters
            reverse
            label={"Nouveau match"}
            checkboxId={"match"}
            onChange={(value) => setPreference("notifyMatches", value)}
          />
        </div>
      </div>
      <ContactButton
        isMobile={isMobile}
        icon={iconChat}
        label="Nous contacter"
        subject={subjectsList.filter(subject => subject.value === "Contact")[0]?.subvalues[0]?.value || ''}
        dataList={subjectsList.filter(subject => subject.value === "Contact")[0]?.subvalues}
      />
      <ContactButton
        isMobile={isMobile}
        icon={iconQuote}
        label="Témoignages"
        subject={subjectsList.filter(subject => subject.value === "Témoignages")[0]?.subvalues[0]?.value || ''}
        dataList={subjectsList.filter(subject => subject.value === "Témoignages")[0]?.subvalues}/>
      <ContactButton
        isMobile={isMobile}
        icon={iconImprove}
        label="Envie d’améliorer Elite Berbère ?"
        subject={subjectsList.filter(subject => subject.value === "Amélioration")[0]?.subvalues[0]?.value || ''}
        dataList={subjectsList.filter(subject => subject.value === "Amélioration")[0]?.subvalues}
      />
      <div className={"d-flex flex-column mt-4 flex-wrap"}>
        <EliteParagraph
          textColor={"#4e4e4e"}
          margin={"16px 0 8px 0"}
          textAlign={"left"}>
          <a href={generatePath(EliteRouters.legalMentions.path)}>{EliteRouters.legalMentions.name}</a>
        </EliteParagraph>

        <EliteParagraph
          className={` ${isMobile ? '' : 'd-none'}`}
          textColor="#4e4e4e"
          margin="8px 0 8px 0"
          textAlign="left">
          <div className={` ${isMobile ? '' : 'd-none'}`} onClick={() => openModal(onLogout)}>
            <NavLink to={EliteRouters.parameters}> Déconnexion</NavLink>
          </div>
        </EliteParagraph>

        <EliteParagraph textColor="#4e4e4e" margin="8px 0" textAlign="left">
          <div onClick={() => {
            setDisplayOffers(true)
            deleteAccount.current.handleOpenModal()
          }}>
            <NavLink to={EliteRouters.parameters}>
              {" "}
              Supprimer mon compte
            </NavLink>
          </div>
        </EliteParagraph>
      </div>

      <CustomModal
        overlayClassName="Overlay"
        modalContent={
          displayOffers ? (
            <>
              <div
                className={"d-flex justify-content-end"}
                onClick={() => setDisplayOffers(false)}>
                <EliteParagraph
                  textAlign={"right"}
                  textColor={"#6c95e7"}
                  fontSize={"18px"}
                  fontWeight={"bold"}
                  margin={"0"}
                  cursor={"pointer"}
                  text={"Supprimer définitivement mon compte"}
                />
                <span>
                  <ReactSVG src={iconRightColor}/>
                </span>
              </div>
              <Offers
                isMobile={isMobile}
                closeModal={deleteAccount}
              />
            </>
          ) : (
            <>
              <div
                className={
                  "d-block col-md-12 mb-3 headerModalMobile"
                }>
                <div className={"back"} style={{marginTop: "64px"}}>
                  <div
                    onClick={() => setDisplayOffers(true)}>
                    <ReactSVG src={backArrow}/>
                    <EliteParagraph
                      text={"Revenir aux offres"}
                      fontSize={"22px"}
                      textColor={"#34d10e"}
                      textAlign={"left"}
                      fontWeight={500}
                    />
                  </div>
                </div>
              </div>

              <div
                className={`d-flex flex-column w-100 px-5 ${isMobile ? 'mobile' : ''}`}>
                <EliteParagraph text="Pourquoi nous quittez-vous ?" textAlign='left' fontWeight='bold'/>
                <CustomTextArea value={reasonWhy} onChange={setReasonWhy}/>
                {(!reasonWhy.length || reasonWhy.length < 10) &&
                <ErrorText text={"10 caractères minimum"}></ErrorText>
                }
              </div>

              <div
                className={`d-flex flex-column w-100 px-5 ${isMobile ? 'mobile' : ''}`}>
                <CustomButton isDisabled={!reasonWhy.length || reasonWhy.length < 10} type="button"
                              label="Supprimer mon compte"
                              onClick={async () => {
                               await UserService.deleteAccount(user.id, reasonWhy)
                                  .then(() => {
                                    toast.success("Votre compte a été supprimé. Revenez nous vite!");
                                    setTimeout(onLogout, 750);
                                  })
                                  .catch(() => toast.error("Echec de la suppression de votre profile, veuillez nous contacter via le formulaire accessible sur cette page!"))
                              }}
                />
              </div>
            </>
          )
        }
        modalClass={
          displayOffers ? (isMobile ? "offersModalMobile" : "offersModal") :
            (isMobile ? "defaultProfileModalMobile" : "defaultProfileModal")
        }
        ref={deleteAccount}
      />

      <CustomModal
        modalHeight={isMobile ? "100vh" : get(editableControl, "customHeight")}
        modalContent={
          editableControl && editableControl.render(editableControlProps)
        }
        modalClass={
          isMobile ? "defaultProfileModalMobile" : "defaultProfileModal"
        }
        ref={child}
      />
    </>
  );

}
