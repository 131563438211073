import React, {Component} from "react";
import PhotoButton from "../Uikit/PhotoButton/PhotoButton";
import EliteParagraph from "../Uikit/Typography/Paragraph";

class ChooseProfilePhoto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photos: [
        ...( props.images || [] ),
        { key: 'image-1' },
        { key: 'image-2' },
        { key: 'image-3' },
        { key: 'image-4' },
        { key: 'image-5' }
      ].slice(0, 5)
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if(nextProps.images) {
      if(nextProps.images.length === 5) {
        this.setState({photos: nextProps.images})
      }else {
        this.setState({
          photos: [
            ...( nextProps.images || [] ),
            { key: 'image-1' },
            { key: 'image-2' },
            { key: 'image-3' },
            { key: 'image-4' },
            { key: 'image-5' }
          ].slice(0, 5)
        })
      }
    }
  }

  setPhoto = (photo, key) => {
    const images = [...this.state.photos];
    images.splice(key, 1, { ...photo, isMain: images[key].isMain });
    this.setState({photos: images})
    this.props.onChange(images)
  }

  deletePhoto = (key) => {
    const images = [...this.state.photos];
    images.splice(key, 1, { key: `image-${ key }` });
    this.setState({photos: images})
    this.props.onChange(images)
  }

  setMain = (key) => {
    const images = this.state.photos.map((photo, i) => {
      if (photo.isMain) {
        photo.isMain = false;
      }
      if (i === key) {
        photo.isMain = true
      }
      return photo;
    });

    this.setState({photos: images})
    this.props.onChange(images)
  }

  renderPhoto = (photo, index) => {
    return <PhotoButton
      key={ photo.key || photo.id }
      isMobile={ this.props.isMobile }
      isMain={ photo.isMain }
      isPending={ photo.status === 0 }
      isInUse={ photo.status === 1 }
      isRejected={ photo.status === 2 }
      isFirst={ !index }
      index={photo.key || ('id' + photo.id)}
      noMargin
      photoUrl={ photo.url }
      onChange={ (photo) => this.setPhoto(photo, index) }
      setMain={ () => this.setMain(index) }
      onDelete={ () => this.deletePhoto(index) }
    />
  }

  render() {
    return (
      <div className={ "d-flex flex-wrap" } >
        { this.state.photos.map((photo, key) => {
          return this.renderPhoto(photo, key)
        }) }
        <EliteParagraph
            text={"Photo de préférence au format carré (type Instagram) et d'une taille inférieure ou égale à 1080 pixels."}
            fontSize={"0.7em"}
            width={"70%"}
        />
      </div>
    );
  }
}

export default ChooseProfilePhoto;
