import React from "react";
import DerniereConnexion from "../../Components/DerniereConnexions/DerniereConnexion";
import ConnectedHeader from "../../Components/Header/ConnectedHeader/ConnectedHeader";
import MatchMakingDuJour from "../../Components/MatchMakingDuJour/MatchMakingDuJour";
import "./ConnectedHomeStyle.css";
import Footer from "../../Components/Footer/Footer";

export function ConnectedHome() {
  return (
    <>
    <div style={{ height:"100vh", display: "flex", flexDirection: "column" }}>
      <ConnectedHeader />
      <div className="container py-2 homeContainer">
        <div className="row">
          <div className="col-2"></div>
          <div className="col-8">
            <MatchMakingDuJour />
            <DerniereConnexion />
          </div>
          <div className="col-2"></div>
        </div>
      </div>
      <Footer isLogged />
    </div>
    </>
  );
}
