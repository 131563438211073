import React from "react";
import { ReactSVG } from "react-svg";
import "./CustomButtonStyle.css";
import iconCheckMark from "../../../assets/images/icon-check-circle.svg";

function CustomButton({
  checkMark,
  dropdown,
  endIcon,
  fontWeight,
  height,
  hide,
  icon,
  isDisabled,
  isOpen,
  label,
  onClick,
  secondary,
  tertiary,
  textTransform,
  textColor,
  width,
  top0,
  relative,
  textGreen,
  ...props
}) {
  return (
    <button
      {...props}
      style={{ height: height, fontWeight: fontWeight, textTransform: textTransform, color: textColor, width:width }}
      disabled={isDisabled || props.disabled}
      className={`button${dropdown ? " dropdown" : ""}${tertiary ? " tertiary" : ""}${
        secondary ? " secondary" : ""
      }
        ${relative ? "position-relative" : ""}
        ${top0 ? "top-0" : ""}
       ${hide ? "d-none" : "d-block"}`}
      onClick={onClick}>
      <span
        className={`iconButton d-flex align-items-center${textGreen ? " textGreen" : ""}`}
        style={{ justifyContent: "center" }}>
        {icon ? (
          <ReactSVG className={`${icon ? "d-block" : "d-none"}`} src={icon} />
        ) : (
          ""
        )}
        {label}
        {endIcon ? (
          <ReactSVG
            className={!isOpen ? "pointDown" : "pointUp"}
            src={endIcon ? endIcon : ''}
          />
        ) : (
          ""
        )}
        <div className={`checkMark ${!checkMark ? "d-none" : "d-block"}`}>
          {<ReactSVG src={iconCheckMark ? iconCheckMark : ''} />}
        </div>
      </span>
    </button>
  );
}

export default CustomButton;
