import React, {useRef} from "react";
import { ReactSVG } from "react-svg";
import EliteParagraph from "../Uikit/Typography/Paragraph";
import "./MatchMakingCardStyles.css";
import boostIllust from "../../assets/images/illustration-boost.svg";
import CustomButton from "../Uikit/Button/CustomButton";
import CustomModal from "../Uikit/Modal/Modal";
import Offers from "../Offers/Offers";
import {hasValidSubscription} from "../../Services/ApiServices/api.client";

export default function MatchMakingLimitCard(props) {
  const child = useRef();
  return (
    <div className={`card limitMatches ${props.isMobile ? "mobile" : ""} ${hasValidSubscription() ? "" : "noOffer"}`}>
      <div className={"d-flex flex-column align-items-center"}>
        <div className="stars mt-4">
          <ReactSVG src={boostIllust} />
        </div>
        <div className={`d-flex flex-column ${props.isMobile ? "px-2" : "px-3"} pt-1`}>
          <EliteParagraph
            text={props.title}
            uppercase
            textColor={"#fff"}
            fontSize={props.overrideSize ? props.overrideSize : '18px'}
            fontWeight={"bold"}
            margin={'0 8px'}
          />
          <EliteParagraph
            text={props.subtitle}
            textColor={"#000"}
            fontSize={"14px"}
            margin={"10px 0 0 0"}
          />
            <div style={{position:"absolute", bottom: "30px",right:"16px",left:"16px"}} className={"align-items-center"}
                 onClick={ () => child.current.handleOpenModal()}>
                <CustomButton secondary label={props.buttonLabel} />
            </div>
        </div>
      </div>
      <CustomModal
          overlayClassName="Overlay"
          modalContent={
            <Offers
                isMobile={ props.isMobile }
                closeModal={ child }
            />
          }
          modalClass={ props.isMobile ? "offersModalMobile" : !hasValidSubscription() ? "offersModal" : "offersModalBoost" }
          ref={ child }
      />
    </div>
  );
}
