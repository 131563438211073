import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Photo from "../Photo/Photo";
import "./PhotoMosaicStyles.css";
import CustomButton from "../Button/CustomButton";
import Spinner from "react-bootstrap/Spinner";

export default function GridMosaic(props) {
  const [list, setList] = useState([]);
  useEffect(() => {
    setList(props.offset === 0 ? props.data : list.concat(props.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, props.offset]);
  const pagesCount = props.pagesCount;
  const hasNext = parseInt(props.offset) + 1 !== pagesCount;
  const hasPrev = parseInt(props.offset) - 1 >= 0;

  const contentDataInfitniteScroll = () => {
    return (
      <InfiniteScroll
        dataLength={list.length}
        next={() => {
          if (hasNext) {
            props.next();
          }
        }}
        hasMore={true}
        loader={props.loading ?
            <div className="loading-searching">
              <Spinner animation="border"/>
            </div> :
            <center>
              {hasPrev && <CustomButton
                  onClick={ () => props.prev() }
                  width={"40%"}
                  label={ "Retour" }
              ></CustomButton>}
              {hasNext && <CustomButton
                  onClick={ () => props.next() }
                  width={"40%"}
                  label={ "Afficher + de profils" }
              ></CustomButton>}
            </center>
        }>
        <div className="row">{contentData()}</div>
      </InfiniteScroll>
    );
  }

  const contentData = () =>
    props.data.map((profile, i) => (
      <div
        key={i}
        className={`col-${12 / props.rows} col-sm-${12 / props.rows} col-md-${
          12 / props.rows
        }`} style={{padding:props.padding}}>
        <div className="ratio ratio-1x1">
          <Photo
            isMobile={props.isMobile}
            type={props.photoType}
            size={props.photoSize}
            profile={profile}
          />
        </div>
      </div>
    ));

  return props.hasInfiniteScroll ? contentDataInfitniteScroll() : contentData();
}

GridMosaic.defaultProps = {
  rows: 4,
};
