import apiClient from "../../../Services/ApiServices/api.client";

export async function registerUser(profile) {
  const { looking_for, gender, birth_date, city, zip, username, photo, email, password } = profile;
  const data = new FormData();
  data.append('looking_for', looking_for);
  data.append('gender', gender);
  data.append('birth_date', birth_date);
  data.append('city', city);
  data.append('zip', zip);
  data.append('username', username);
  data.append('photo', photo);
  data.append('email', email);
  data.append('password', password);

  return apiClient.post('/api/register', data, { headers: { 'content-type': 'multipart/form-data' } });
}
