import apiClient from "../Services/ApiServices/api.client";
import { useEffect, useState } from "react";
import { handleError } from "../Services/ErrorHandler";

export function useProfile(id) {
  const [profile, setProfile] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    async function getProfile() {
      try {
        const { data: { data } } = await apiClient.get(`/api/profile/${ id }`)
        setProfile(data)
      } catch (e) {
        handleError(e);
        setError({
          message: e.response ? e.response.data.message : e.message,
          code: e.response ? e.response.status : 0
        })
      }
    }
    getProfile();

  }, [id]);

  return { profile , setProfile, error }
}
