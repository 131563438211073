export const ageMin = 18

export const ageMax = 90

export const heightMin = 0

export const heightMax = 220

export const distanceMax = 0

export const computedMaxDate = () => {
  let today = new Date();
  let y = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(today);
  let m = new Intl.DateTimeFormat('en', {month: '2-digit'}).format(today);
  let d = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(today);

  return `${y - ageMin}-${m}-${d}`
}

export const active_user = "Actif";

export const blocked_user = "Désactivé";

export const deleted_user = "Supprimé";

export const roles = [
  {'value': '1', 'label': 'Membre'},
  {'value': '2', 'label': 'Administrateur'}
]

export const genders = [
  {'value': 'F', 'label': 'Femme'},
  {'value': 'H', 'label': 'Homme'}
]

export const purposes = [
  {'value': 'Rencontre amoureuse', 'label': 'Rencontre amoureuse'},
  {'value': 'Rencontre amicale', 'label': 'Rencontre amicale'}
]

export const shapes = [
  {'value': 'Mince', 'label': 'Mince'},
  {'value': 'Sportive', 'label': 'Sportive'},
  {'value': 'Ronde', 'label': 'Ronde'},
  {'value': 'Normale', 'label': 'Normale'},
]

export const hairColors = [
  {'value': 'Noir', 'label': 'Noir'},
  {'value': 'Brun', 'label': 'Brun'},
  {'value': 'Chatain', 'label': 'Chatain'},
  {'value': 'Châtain foncé', 'label': 'Châtain foncé'},
  {'value': 'Châtain clair', 'label': 'Châtain clair'},
  {'value': 'Blond foncé', 'label': 'Blond foncé'},
  {'value': 'Blond moyen', 'label': 'Blond moyen'},
  {'value': 'Blond clair', 'label': 'Blond clair'},
  {'value': 'Blond très clair', 'label': 'Blond très clair'},
  {'value': 'Platine', 'label': 'Platine'},
  {'value': 'Autre', 'label': 'Autre'},
]

export const eyeColors = [
  {'value': 'Noirs', 'label': 'Noirs'},
  {'value': 'Bleus', 'label': 'Bleus'},
  {'value': 'Marrons', 'label': 'Marrons'},
  {'value': 'Verts', 'label': 'Verts'},
  {'value': 'Gris', 'label': 'Gris'},
  {'value': 'Noisette', 'label': 'Noisette'},
]

export const astroSigns = [
  {'value': 'Bélier', 'label': 'Bélier'},
  {'value': 'Taureau', 'label': 'Taureau'},
  {'value': 'Gémeaux', 'label': 'Gémeaux'},
  {'value': 'Cancer', 'label': 'Cancer'},
  {'value': 'Lion', 'label': 'Lion'},
  {'value': 'Vierge', 'label': 'Vierge'},
  {'value': 'Balance', 'label': 'Balance'},
  {'value': 'Scorpion', 'label': 'Scorpion'},
  {'value': 'Sagittaire', 'label': 'Sagittaire'},
  {'value': 'Capricorne', 'label': 'Capricorne'},
  {'value': 'Verseau', 'label': 'Verseau'},
  {'value': 'Poisson', 'label': 'Poisson'},
]

export const origins = [
  {'value': 'Australie', 'label': 'Australie', imageLabel: 'au'},
  {'value': 'Emirats arabes unis', 'label': 'Emirats arabes unis', imageLabel: 'ae'},
  {'value': 'Sénégal', 'label': 'Sénégal', imageLabel: 'sn'},
  {'value': 'Thaïlande', 'label': 'Thaïlande', imageLabel: 'th'},
  {'value': 'Chine', 'label': 'Chine', imageLabel: 'cn'},
  {'value': 'Russie', 'label': 'Russie', imageLabel: 'ru'},
  {'value': 'Maroc', 'label': 'Maroc', imageLabel: 'ma'},
  {'value': 'Algérie', 'label': 'Algérie', imageLabel: 'dz'},
  {'value': 'France', 'label': 'France', imageLabel: 'fr'},
  {'value': 'Pays-Bas', 'label': 'Pays-Bas', imageLabel: 'nl'},
  {'value': 'Belgique', 'label': 'Belgique', imageLabel: 'be'},
  {'value': 'Niger', 'label': 'Niger', imageLabel: 'ne'},
  {'value': 'Mali', 'label': 'Mali', imageLabel: 'ml'},
  {'value': 'Mauritanie', 'label': 'Mauritanie', imageLabel: 'mr'},
  {'value': 'Libye', 'label': 'Libye', imageLabel: 'ly'},
  {'value': 'Tunisie', 'label': 'Tunisie', imageLabel: 'tn'},
  {'value': 'Egypte', 'label': 'Egypte', imageLabel: 'eg'},
  {'value': 'Canada', 'label': 'Canada', imageLabel: 'ca'},
  {'value': 'Burkina Faso', 'label': 'Burkina Faso', imageLabel: 'bf'},
  {'value': 'Etats-Unis', 'label': 'Etats-Unis', imageLabel: 'us'},
  {'value': 'Autre', 'label': 'Autre', imageLabel: 'other'},
]

export const religions = [
  {'value': 'Pratiquant', 'label': 'Pratiquant'},
  {'value': 'Non pratiquant', 'label': 'Non pratiquant'},
  {'value': 'Modéré', 'label': 'Modéré'},
]

export const maritalStatuses = [
  {'value': 'Jamais marié', 'label': 'Jamais marié'},
  {'value': 'Divorcé', 'label': 'Divorcé'},
  {'value': 'Séparé', 'label': 'Séparé'},
  {'value': 'Célibataire', 'label': 'Célibataire'},
]

export const languages = [
  {'value': 'Anglais', 'label': 'Anglais'},
  {'value': 'Français', 'label': 'Français'},
  {'value': 'Espagnol', 'label': 'Espagnol'},
  {'value': 'Arabe', 'label': 'Arabe'},
  {'value': 'Italien', 'label': 'Italien'},
]

export const sports = [
  {'value': 'régulièrement', 'label': 'Pratique régulièrement'},
  {'value': 'parfois', 'label': 'Occasionnelelement'},
  {'value': 'non', 'label': 'Non'},
]

export const tabacs = [
  {'value': 'parfois', 'label': 'Oui, occasionnellement'},
  {'value': 'régulièrement', 'label': 'Oui, régulièrement'},
  {'value': 'tolérent', 'label': 'Non (et tolère la fumée)'},
  {'value': 'non', 'label': 'Non (et ne tolère pas la fumée)'},
]

export const studyLevels = [
  {'value': 'bac', 'label': 'Bac'},
  {'value': 'bac2', 'label': 'Bac + 2'},
  {'value': 'bac3', 'label': 'Bac + 3'},
  {'value': 'bac4', 'label': 'Bac + 4'},
  {'value': 'master', 'label': 'Bac + 5'},
]

export const activityArea = [
  {'value': 'Vente/Marketing/Publicité', 'label': 'Vente/Marketing/Publicité'},
  {'value': 'Sportif', 'label': 'Sportif'},
  {'value': 'Administration', 'label': 'Administration'},
  {'value': 'Santé/Médical', 'label': 'Santé/Médical'},
  {'value': 'Éducation', 'label': 'Éducation'},
  {'value': 'Restauration/Hôtellerie/Tourisme', 'label': 'Restauration/Hôtellerie/Tourisme'},
  {'value': 'Profession libérale', 'label': 'Profession libérale'},
  {'value': 'Fonction publique', 'label': 'Fonction publique'},
  {'value': 'Aéroportuaire', 'label': 'Aéroportuaire'},
  {'value': 'Secteur privé', 'label': 'Secteur privé'},
  {'value': 'Ingénieur spécialisé', 'label': 'Ingénieur spécialisé'},
  {'value': 'Finance', 'label': 'Finance'},
  {'value': 'Cadre supérieur', 'label': 'Cadre supérieur'},
  {'value': 'Dirigeant/À son compte', 'label': 'Dirigeant/À son compte'},
  {'value': 'Autre', 'label': 'Autre'},
]

export const traits = [
  {'value': 'Calme', 'label': 'Calme'},
  {'value': 'Tenace', 'label': 'Tenace'},
  {'value': 'Solitaire', 'label': 'Solitaire'},
  {'value': 'Inquiet', 'label': 'Inquiet'},
  {'value': 'Réserve', 'label': 'Réserve'},
  {'value': 'Sociable', 'label': 'Sociable'},
  {'value': 'Introvertie', 'label': 'Introvertie'},
  {'value': 'Extravertie', 'label': 'Extravertie'},
  {'value': 'Audacieux', 'label': 'Audacieux'},
  {'value': 'Démonstratif', 'label': 'Démonstratif'},
  {'value': 'Discret', 'label': 'Discret'},
  {'value': 'Facile à vivre', 'label': 'Facile à vivre'},
  {'value': 'Généreux', 'label': 'Généreux'},
  {'value': 'Sévère', 'label': 'Sévère'},
  {'value': 'Pointilleux', 'label': 'Pointilleux'},
  {'value': 'Gentil', 'label': 'Gentil'},
  {'value': 'Franc', 'label': 'Franc'},
  {'value': 'Fier', 'label': 'Fier'},
  {'value': 'Ferme', 'label': 'Ferme'},
  {'value': 'Jaloux', 'label': 'Jaloux'},
  {'value': 'Pudique', 'label': 'Pudique'},
  {'value': 'Respectueux', 'label': 'Respectueux'},
  {'value': 'Aimable', 'label': 'Aimable'},
  {'value': 'Tendre', 'label': 'Tendre'},
  {'value': 'Autres', 'label': 'Autres'},
]

export const defaultFormFields = {
  "id": "",
  "email": "",
  "username": "",
  "role": roles[0].value,
  "password": "",
  "profile": {
    "description": "",
    "hereFor": purposes[0].value,
    "gender": genders[0].value,
    "shape": shapes[0].value,
    "hairColor": hairColors[0].value,
    "eyeColor": eyeColors[0].value,
    "astroSign": astroSigns[0].value,
    "hasChildren": false,
    "wantChildren": false,
    "maritalStatus": maritalStatuses[0].value,
    "languages": languages[0].value,
    "tabac": false,
    "sport": sports[0].value,
    "studyLevel": studyLevels[0].value,
    "activityArea": activityArea[0].value,
    "birthdate": computedMaxDate(),
    "height": heightMin,
    "address": origins[0].value,
    "origin": origins[0].value,
    "religion": religions[0].value,
    "traits": traits[0],
  },
  "searching": {
    "description": "",
    "hereFor": purposes[0].value,
    "gender": "F",
    "shape": shapes[0].value,
    "hairColor": hairColors[0].value,
    "eyeColor": eyeColors[0].value,
    "astroSign": astroSigns[0].value,
    "hasChildren": false,
    "wantChildren": false,
    "maritalStatus": maritalStatuses[0].value,
    "languages": languages[0].value,
    "tabac": false,
    "sport": sports[0].value,
    "studyLevel": studyLevels[0].value,
    "activityArea": activityArea[0].value,
    "ageMin": ageMin,
    "ageMax": ageMax,
    "heightMin": heightMin,
    "heightMax": heightMax,
    "address": origins[0].value,
    "origin": origins[0].value,
    "religion": religions[0].value,
    "traits": traits[0],
  },
  "blocksCount": 0,
  "warnsCount": 0,
  "status": active_user,
}

export const withOrWithoutChildren = [
  {'value': 'Sans enfant', 'label': 'Sans enfant'},
  {'value': 'A un/des enfant(s)', 'label': 'A un/des enfant(s)'},
]

export const yesNoOptions = [
  {'value': 'Oui', 'label': 'Oui'},
  {'value': 'Non', 'label': 'Non'},
]

export const subjectsList = [
  {
    value: "Contact",
    label: "Contact",
    subvalues: [
      {value: "Partenariat/Sponsor/Ambassadeur", label: "Partenariat/Sponsor/Ambassadeur"},
      {value: "Connexion / Identification", label: "Connexion / Identification"},
      {value: "Gestion des achats d'Abonnements/ Paiements", label: "Gestion des achats d'Abonnements/ Paiements"},
      {value: "Gestion du profil/Compte", label: "Gestion du profil/Compte"},
      {value: "Evènements/Soirées", label: "Evènements/Soirées"},
      {value: "Modération/Sécurité", label: "Modération/Sécurité"},
      {value: "Échanges et interactions", label: "Échanges et interactions"},
      {value: "Inscription", label: "Inscription"},
      {value: "Recherche", label: "Recherche"},
      {value: "Presse/Radio/TV", label: "Presse/Radio/TV"},
      {value: "Autre", label: "Autre"},
    ]
  },
  {
    value: "Témoignages",
    label: "Témoignages",
    subvalues: [
      {value: "Rencontres", label: "Rencontres"},
      {value: "Retour de votre expérience", label: "Retour de votre expérience"},
    ]
  },
  {
    value: "Amélioration",
    label: "Amélioration",
    subvalues: [
      {value: "Difficultés techniques/Utilisation du site", label: "Difficultés techniques/Utilisation du site"},
      {value: "Idées d'amélioration/Retours abonnés", label: "Idées d'amélioration/Retours abonnés"},
    ]
  },
  {value: "Autre", label: "Autre"},
  {value: "Signalement", label: "Signalement"},
]
