import React from "react";
import ChatBody from "./ChatBody";
import ChatHeader from "./ChatHeader";

// import "./ChatStyles.css";

export function Chat({ isMobile, room, getRooms, messages, onSendMessage }) {
  return (
    <div>
      <div className={isMobile ? "headerMobileChat" : ''}>
        <ChatHeader
          isMobile={ isMobile }
          room={ room }
          getRooms={ getRooms }
        />
      </div>
      <div>
        <ChatBody
          isMobile={ isMobile }
          room={ room }
          messages={ messages }
          onSendMessage={ onSendMessage }
        />
      </div>
    </div>
  );
}

export default Chat;
