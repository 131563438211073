import React from "react";
import { ReactSVG } from "react-svg";

import closeButton from "../../../assets/images/btn-close.svg";
import backArrow from "../../../assets/images/icon-arrow-back.svg";
import EliteParagraph from "../../Uikit/Typography/Paragraph";
import ModalField from "../../Uikit/ModalField/ModalField";
import * as yup from "yup";
import CustomTextField from "../../Uikit/TextFields/TextFields";
import UserService from "../../../Services/ApiServices/UserService";
import { handleError } from "../../../Services/ErrorHandler";

export function Email(props) {

  function handleClose() {
    props.closeModal();
  }

  async function onChange(value = {}) {
    await props.onChange(value);
    handleClose()
  }

  const validationSchema = yup.object().shape({
    email: yup.string().required('Champ obligatoire').email().test({
      name: 'unique-email',
      message: 'Cet email exist dejà',
      async test(email) {
        try {
          const exists = await UserService.checkIfEmailExists(email)
          return !exists;
        } catch (e) {
          handleError(e);
          return e.response.data.message;
        }
      }
    })
  })

  function renderField(attrs) {
    return (
      <CustomTextField
        { ...attrs }
        id={ props.id }
        name={ props.name }
        type={ props.type || 'text' }
      />
    )
  }

  return (
    <>
      <div className={ props.isMobile ? "d-none" : "d-block px-5 pt-4 pb-2" }>
        <EliteParagraph
          text={ props.title }
          fontSize={ "24px" }
          textColor={ "#6c95e7" }
          textAlign={ "left" }
          fontWeight={ 500 }
        />
      </div>
      <div
        className={
          !props.isMobile ? "d-none" : "d-block col-lg-4 col-md-12 mb-4 headerModalMobile"
        }>
        <div className={ "back" } style={ { marginTop: "64px" } }>
          <div style={{height:"35px"}}/>
          <div
            onClick={ handleClose }>
            <ReactSVG src={ backArrow }/> { props.title }
          </div>
        </div>
      </div>
      <div className={`d-flex flex-column w-100 contentModalMinHeight position-relative ${props.isMobile ? 'mobile' : 'px-5' }`}>
        <ModalField
isMobile={props.isMobile}
          id="email"
          name="email"
          validationSchema={ validationSchema }
          value={ props.value }
          onChange={ onChange }
          closeModal={ handleClose }
          placeholder={ props.title }
          renderField={ renderField }
          classes={props.isMobile ? 'headerModalMobile' : ''}
        />
      </div>
      <div
        className={
          "closeButtonLevitate d-flex flex-column align-items-center"
        }>
        <ReactSVG
          style={ { cursor: "pointer" } }
          src={ closeButton }
          onClick={ handleClose }/>
      </div>
    </>
  );
}

export default Email;
