import React from "react";
import './App.css';
import CustomRouter from "./Navigation/CustomRouter";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {withStore} from 'react-context-hook'
import NotificationsTracker from "./Components/NotificationsTracker/NotificationsTracker";

function App() {
  return (
    <div className={"app"}>
        <CustomRouter/>
        <NotificationsTracker/>
        <ToastContainer/>
    </div>
  );
}

export default withStore(App)
