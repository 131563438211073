import React, { useState } from "react";
import iconCheck from "../../../assets/images/icon-check-color.svg";
import { ReactSVG } from "react-svg";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";

export function UniqueChoiceModal(props) {
  const [selected, setSelected] = useState(props.value === true ? 1 : props.value);

  function onSelect(choice) {
    setSelected(choice.value);
    if (props.onChange) {
      props.onChange(choice.value);
    }
  }

  function renderList() {
    return props.choices.map((choice) => (
      <div
        className={`d-flex justify-content-between itemChoice ${
          choice.value === selected ? "isSelected" : ""
        }`}
        key={choice.value}
        onClick={() => onSelect(choice)}>
        {choice.label}{" "}
        {<ReactSVG src={choice.value === selected ? iconCheck : ""} />}
      </div>
    ));
  }

  return (
    <PerfectScrollbar>
      <div className={`choicesContainer ${props.isMobile ? "mobile" : ""}`}>
        {renderList()}
      </div>
    </PerfectScrollbar>
  );
}

export default UniqueChoiceModal;
