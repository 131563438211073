import React, { useState } from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import ResponsiveService from "../../Services/ResponsiveService";
import { Helmet, HelmetProvider } from "react-helmet-async";
import datingTunisie from "../../assets/images/dating-tunisie-1.jpg";
import datingTunisie2 from "../../assets/images/dating-tunisie-2.jpg";
import BigTitle from "../../Components/Uikit/Typography/BigTitle";
import EliteSmallTitle from "../../Components/Uikit/Typography/SmallTitle";
import EliteParagraph from "../../Components/Uikit/Typography/Paragraph";

export function TunisiePage() {
    const [isMobile, setIsMobile] = useState(
        ResponsiveService.isMobile()
    );
    
    const handleResize = (e) => {
        e.preventDefault();
        if (isMobile !== ResponsiveService.isMobile()) {
          setIsMobile({ isMobile: ResponsiveService.isMobile() });
        }
    };
    window.addEventListener("resize", handleResize.bind(this));

    return (
        <HelmetProvider>
            <>
                <Helmet>
                    <title>Site de rencontre Tunisie 100% gratuit – Eliteberbere.fr</title>
                    <meta name="description" content="Vous êtes tunisien ou tunisienne et vous recherchez l'amour ? 
                    N'attendez plus et trouvez votre moitié sur notre site de rencontre Elite Berbère."/>
                    <meta name="keywords" content="Rejoignez, Faire de nouvelles rencontres, Concernant 
                    la rencontre, Vie amoureuse, Rencontre réelle, Faire des amis, Rencontre musulman, 
                    Faire des rencontres intéressantes, Rencontrer facilement, Rencontre pour mariage, 
                    Construire une relation, Vie de célibataire, Rencontres amicales, Faire connaissance, 
                    Hommes et femmes, Pour rencontrer, Edarling, Rencontre par affinités, Faire de belles rencontres, 
                    Âme-soeur, Rencontre amoureuse, Célibataires, Site de rencontre, Relation sérieuse, Dating"/>
                </Helmet>
                <Header />
                <div className="container">
                    <div className="row p-4">
                        <div style={{textAlign: "justify"}} className="col-12">
                            <BigTitle
                                marginBottom={"5vh"}
                                titleColor={"#4e4e4e"}
                                textAlign={"left"}
                                text={"Faites de belles rencontres grâce au site de rencontres en Tunisie Elite Berbère"}
                            />
                            <EliteParagraph
                                text={<>
                                    Vous habitez en Tunisie et cherchez l'amour ? Pour <b>faire de nouvelles rencontres</b> près
                                    de chez vous, vous pouvez trouver de l'aide avec un <b>site de rencontre en Tunisie</b> de
                                    qualité qui met votre satisfaction au premier plan. Vous cherchez quelqu'un qui partage
                                    vos valeurs berbères ? Nous sommes là pour vous.
                                </>}
                                textAlign={"justify"}
                                fontSize={"1.3em"}/>

                            <EliteSmallTitle
                                textAlign={"left"}
                                text={"Célibataire depuis trop longtemps ?"} />
                            <EliteParagraph
                                text={<>
                                    Vous rêvez de trouver l'<b>âme sœur</b> ? Mais cela n'est pas aussi simple que cela. Il faut, en effet,
                                    que l'autre soit en adéquation avec vous, qu'il partage les mêmes envies et valeurs. <b>Faire de
                                    nouvelles rencontres</b> est facile, mais pour que cela se transforme en <b>relation sérieuse</b>, ce n'est
                                    plus aussi évident. Impossible de baser sa vie amoureuse sur des futilités. Parce qu'un couple
                                    solide c'est un partage permanent et une union de tous les instants.
                                </>}
                                textAlign={"justify"}/>
                            <EliteParagraph
                                text={<>
                                    Si vous mettez vos valeurs berbères au premier plan, il est naturellement très important
                                    que l'autre pense comme vous.
                                </>}
                                textAlign={"justify"}/>
                            <EliteParagraph
                                text={<>
                                    Pour être sûr de trouver cette personne, notre <a href={"https://eliteberbere.fr"}>site de
                                    rencontres berbères</a> nommé <b>Élite Berbère</b> a été
                                    créé en 2019 par des Berbères. Nous comprenons vos exigences et tentons d'y répondre au mieux afin de
                                    vous aider à <b>faire des rencontres intéressantes</b> (que vous habitiez en Tunisie ou dans le reste du monde).
                                    Nous valorisons la <b>rencontre par affinités</b>. C'est pour vous l'assurance de faire de <b>belles rencontres</b>.
                                </>}
                                textAlign={"justify"}/>
                            <EliteParagraph
                                text={<>
                                    Vous pouvez également vous <b>faire des amis</b> partageant les mêmes intérêts que vous.
                                </>}
                                textAlign={"justify"}/>

                            <EliteSmallTitle
                                textAlign={"left"}
                                text={"Prêt(e) à faire une rencontre amoureuse sérieuse ?"} />
                            <EliteParagraph
                                text={<>
                                    Peut-être pensez-vous que les <b>sites de rencontre en Tunisie</b> ne sont là que pour gagner de l'argent.
                                    Avec Élite Berbère, vous avez affaire à un site familial, créé en 2019 pour aider les Imazighen à
                                    rencontrer d'autres Imazighen. Que cela soit pour terminer votre <b>vie de célibataire</b> ou pour faire des
                                    <b>rencontres amicales</b>. Nous nous adressons <b>aux hommes et femmes</b> qui ont les mêmes valeurs que vous
                                    (et que nous). Nous nous adressons aux Berbères du monde entier et vous proposons des personnes de
                                    qualité près de chez vous. <b>Rencontre de musulmans</b> de Tunisie ou d'ailleurs, vous avez le choix.
                                </>}
                                textAlign={"justify"}/>
                            <EliteParagraph
                                text={<>
                                    À vous ensuite de créer avec elle une relation solide ou non.
                                </>}
                                textAlign={"justify"}/>
                            <img className={"img-fluid"} style={{marginBottom: "16px"}} src={ datingTunisie } alt={ "dating tunisie 1" }/>

                            <EliteSmallTitle
                                textAlign={"left"}
                                text={"Trouver l'amour grâce à un site de rencontre en Tunisie"} />
                            <EliteParagraph
                                text={<>
                                    Pour <b>construire une relation</b> via Élite Berbère il vous faudra avant tout vous inscrire. C'est simple et rapide.
                                    Nous vous demandons vos coordonnées et c'est tout pour créer un compte. Ensuite, à vous de remplir vos choix.
                                    Une fois cela fait, vous <b>rejoignez</b> notre communauté.
                                </>}
                                textAlign={"justify"}/>
                            <EliteParagraph
                                text={<>
                                    Vous pouvez alors <b>faire connaissance</b> avec les personnes qui vous attirent et les <b>rencontrer facilement</b>.
                                </>}
                                textAlign={"justify"}/>
                            <EliteParagraph
                                text={<>
                                    <b>Concernant la rencontre</b>, vous êtes libre d'organiser les choses comme vous le souhaitez. Dans un restaurant ou chez vous
                                    après avoir préparé un bon repas traditionnel, vous ferez une <b>rencontre réelle</b> et partagerez un moment unique.
                                </>}
                                textAlign={"justify"}/>
                            <EliteParagraph
                                text={<>
                                    Pour sympathiser avec des personnes qui partagent les mêmes valeurs que vous ou pour une <b>rencontre pour mariage</b>,
                                    vous avez les cartes en main.
                                </>}
                                textAlign={"justify"}/>
                            <EliteParagraph
                                text={<>
                                    Mais nous ne vous laissons pas seul(e). Nous organisons en effet régulièrement de nombreux événements pour vous
                                    aider à <b>faire de belles rencontres</b> (dîner, <b>dating</b>, conférences...). Nous pouvons aussi vous proposer des coachings
                                    pour vous sentir mieux dans votre vie. <b>Pour rencontrer</b> la personne qui partagera longtemps votre vie,
                                    faites confiance à Élite Berbère, le <b>site de rencontre en Tunisie</b> idéal pour vous.
                                </>}
                                textAlign={"justify"}/>
                            <img className={"img-fluid"} style={{marginBottom: "16px"}} src={ datingTunisie2 } alt={ "dating tunisie 2" }/>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        </HelmetProvider>
    );
}
