import React, { useMemo } from "react";
import "./CustomForgotPasswordMobileStyles.css";
import CustomTextField from "../Uikit/TextFields/TextFields";
import iconLock from "../../assets/images/icon-lock.svg";
import CustomButton from "../Uikit/Button/CustomButton";
import EliteParagraph from "../Uikit/Typography/Paragraph";
import UserService from "../../Services/ApiServices/UserService";
import { toast } from "react-toastify";
import './CustomForgotPasswordMobileStyles.css';
import iconEmail from "../../assets/images/icon-mail.svg";
import { useHistory, useParams } from "react-router-dom";
import { ErrorMessage, Form, Formik } from "formik";
import * as yup from "yup";


function renderFields(resetMode, setFieldValue) {
  if (resetMode) {
    return [
      <CustomTextField
          isSubscription
        placeholder={ "Nouveau mot de passe" }
        type="password"
        isMobile
        onChange={ (val) => setFieldValue('password', val) }
        addOn={ iconLock ? iconLock : null }/>,
      <ErrorMessage
        component='div'
        className={ 'invalid-feedback d-block' }
        name="password"/>,
      <CustomTextField
          isSubscription
        placeholder={ "Confirmez mot de passe" }
        type="password"
        isMobile
        onChange={ (val) => setFieldValue('passwordConfirmation', val) }
        addOn={ iconLock ? iconLock : null }/>,
      <ErrorMessage
        component='div'
        className={ 'invalid-feedback d-block' }
        name="passwordConfirmation"/>
    ];
  }
  return [
    <CustomTextField
        isSubscription
      type="email"
      isMobile
      onChange={ (val) => setFieldValue('email', val) }
      placeholder={ "E-mail" }
      addOn={ iconEmail ? iconEmail : null  }/>,
    <ErrorMessage
      component='div'
      className={ 'invalid-feedback d-block' }
      name="email"/>
  ];
}

function CustomForgotPasswordMobile( ) {
  const history = useHistory();
  const resetMode = useMemo(() => history.location.pathname.match(/^\/reset-password/), [history.location.pathname])
  const { token } = useParams()

  async function onSubmit({ email, password }) {
    if (resetMode) {
      try {
        await UserService.resetPassword(password, token)
        toast.success("Mot de passe changé")
        setTimeout(() => window.location.href = '/login', 500);
      } catch (e) {
        toast.error("Une erreur est survenue")
      }
    } else {
      try {
        await UserService.forgotPassword(email)
      } finally {
        toast.success("Email envoyé")
      }
    }
  }


  const validationSchema = yup.object().shape(
    !resetMode
      ? {
        email: yup.string()
          .required()
          .email(),
      }
      : {

        password: yup.string()
          .required()
          .matches(/(?=.{8,})(?=.*?[^\w\s])(?=.*?[0-9])(?=.*?[A-Z]).*?[a-z].*/g, 'Votre mot de passe doit contenir 8 caractères incluant lettre capitale, une minuscule, un caractère spécial ainsi qu’un nombre'),
        passwordConfirmation: yup.string()
          .required()
          .test({
            name: 'confirmation',
            message: 'Veuillez renseigner 2 mots de passe identiques',
            test: (value, { parent }) => value && value === parent.password
          }),
      })


  return (
    <>
      <div className={ "signUpFormContainer w-100vh" }>
          <div style={{height:"1vh"}} />
          <EliteParagraph
          textColor={ "#fff" }
          fontSize={ "14px" }
          fontWeight={ 400 }
          margin={ "0 0 22px 0" }
          text={
            resetMode
              ? "Entrez votre nouveau mot de passe"
              : "Un email vous sera envoyé pour réinitialisé votre mot de passe."
          }/>
      </div>
        <div style={{height:"1vh"}} />
        <Formik
        initialValues={ { email: '', password: '', passwordConfirmation: '' } }
        validationSchema={ validationSchema }
        onSubmit={ onSubmit }
      >
        { ({ isSubmitting, setFieldValue, values }) => (
          <Form className={ "needs-validation" }>
            <div className={ `has-validation` }>
              { renderFields(resetMode, setFieldValue, values) }
            </div>
              <div style={{height:"8vh"}} />
            <CustomButton type="submit" isDisabled={ isSubmitting } label={ "Envoyer" }/>
              <div style={{height:"4vh"}} />

          </Form>
        ) }
      </Formik>
      <EliteParagraph textColor={ "#fff" } fontSize={ "14px" } text={ "Vous avez déjà un compte ?  " }>
        <span style={{fontWeight: 'bold', textDecoration: 'underline'}} onClick={ () => history.push("/login") }>Connectez-vous</span>
      </EliteParagraph>
    </>
  );
}

export default CustomForgotPasswordMobile;
