import React from "react";
import '../WizardStyles.css';

function WizardNav(props) {
  const dots = [];
  for (let i = 1; i <= props.totalSteps; i += 1) {
    const isActive = props.currentStep === i;
    dots.push(
      <span
        key={ `step-${ i }` }
        className={ `dot ${ isActive ? 'active' : "" }` }
        /*onClick={ () => props.currentStep > i ? props.goToStep(i) : '' }*/
      >
      </span>
    );
  }
  return (
    <>
      <div className={ "wizardNav" }>{ dots }</div>
    </>
  );
}

export default WizardNav;
