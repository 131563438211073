import bigLogo from "../../../assets/images/logo-full-white-ws.png";
import CustomAvatar from "../../Uikit/Avatar/Avatar";
import CounterIcon from "../../Uikit/CounterIcon/CounterIcon";
import EliteParagraph from "../../Uikit/Typography/Paragraph";
import "./ConnectedHeaderStyles.css";
import iconVue from "../../../assets/images/icon-vue.svg";
import iconHeart from "../../../assets/images/icon-love-empty.svg";
import MenuConnected from "./MenuConnected";
import { EliteRouters } from "../../../Navigation/Routers";
import { useCurrentUser } from "../../../Hooks/AuthenticatedUser";

export default function ConnectedHeader(props) {
  const { user } = useCurrentUser();

  return (
    <>
      <div style={{justifyContent: props.isCheckout ? "center" : "", margin: 0 }} className={"container-fluid d-flex flex-row w-100 headerConnected"}>
        <div className="col-2 text-center">
          <a href="/home"><img src={bigLogo} alt="" /></a>
        </div>
        {!props.isCheckout &&

          <div className="col-3 d-flex">
            <CustomAvatar
                size={"88"}
              customClass={"connectedHeader"}
              borderColor={"#fff"}
              photoUrl={user ? user.avatar : ""}
              name={user ? user.username : ""}
            />
            <div className="greetings d-flex flex-column justify-content-center px-4">
              <EliteParagraph
                  fontFamily={"Roboto medium"}
                  fontWeight={"500"}
                textAlign={"left"}
                textColor={"#fff"}
                margin={"2px 0 0 0"}
                fontSize={"20px"}
                text={user ? user.username : ""}
              />
              <div className="likeSeen d-flex">
                <CounterIcon
                  goToUrl={EliteRouters.mesvisites.path}
                  qty={user ? user.visitsCount : 0}
                  svg={iconVue}
                />
                <CounterIcon
                  goToUrl={EliteRouters.meslikes.path}
                  qty={user ? user.likersCount : 0}
                  svg={iconHeart}
                  paddingLeft={"24px"}
                />
              </div>
            </div>
          </div>
        }
        {!props.isCheckout && 
          <div className="col-7 d-flex menuConnectedContainer">
            <MenuConnected />
          </div>
        }
      </div>
    </>
  );
}
