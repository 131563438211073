import React, { Component } from "react";
//import Faces from "../../Services/getFaces";
import CustomAvatar from "../Uikit/Avatar/Avatar";
import EliteParagraph from "../Uikit/Typography/Paragraph";
import EliteSmallTitle from "../Uikit/Typography/SmallTitle";
import "./HighlightsStyles.css";
import iconCadena from "../../assets/images/cadenas-carre-verrouille-rempli.png";
import iconChrono from "../../assets/images/chronometres.png";
import iconUserGroup from "../../assets/images/user-group.png";
import ResponsiveService from "../../Services/ResponsiveService";
class Highlights extends Component {
  constructor(props) {
    super(props);
    this.state = {
      avatarPhoto: "",
    };
  }

  componentDidMount() {
    /*Faces.getFaces().then((result) => {
      this.setState({ avatarPhoto: result[0].avatars[0].url });
    });*/
  }

  render() {
    return (
      <>
        <div className={"container-fluid d-flex flex-sm-row flex-column mt-5"}>
          <div className={"col-sm-4 col-12 p-4 d-flex flex-column align-items-center"}>
            <CustomAvatar photoUrl={iconCadena} borderColor={"white"}/>
            <div style={{height: "10px"}}/>
            <EliteSmallTitle text={"DES RENCONTRES SÛRES ET SÉCURISÉES"} />
            <div style={{height: "6px"}} />
            <div className={ResponsiveService.isMobile() ? "" : "highlightParagraphs"}>
              <EliteParagraph
                text={
                  "Notre site de rencontre berbère : EliteBerbère.fr SE PORTE GARANT ! Imposant une exigence d'entrée à tous les profils célibataires. Une vérification quotidiennement faite par une équipe dédiée à supprimer tous les faux profils."
                }
                textAlign={"justify"}/>
            </div>
          </div>
          <div className={"col-sm-4 col-12 p-4 d-flex flex-column align-items-center"}>
            <CustomAvatar photoUrl={iconChrono} borderColor={"white"} />
            <div style={{height: "10px"}}/>
            <EliteSmallTitle text={"INSCRIPTION RAPIDE ET GRATUITE"} />
            <div style={{height: "6px"}} />
            <div className={ResponsiveService.isMobile() ? "" : "highlightParagraph"}>
              <EliteParagraph
                text={
                  "PAS DE PERTE DE TEMPS ! L'accès au site de rencontre berbère se fait à l'aide d'une inscription gratuite en 3 étapes, simple et efficace."
                }
                textAlign={"justify"}
              />
            </div>
          </div>
          <div className={"col-sm-4 col-12 p-4 d-flex flex-column align-items-center"}>
            <CustomAvatar photoUrl={iconUserGroup} borderColor={"white"} />
            <div style={{height: "10px"}}/>
            <EliteSmallTitle text={"DES ÉVENEMENTS EXCLUSIFS"} />
            <div style={{height: "6px"}} />
            <div className={ResponsiveService.isMobile() ? "" : "highlightParagraph"}>
              <EliteParagraph
                text={
                  "Eliteberbère.fr VOUS AIDE ! Avec le site de rencontre berbère, vous pouvez maintenant profiter près de chez vous d'organisations d'évènements, diners, conférences (accompagnement personnalisé de professions, coaching, personnalités...)"
                }
                textAlign={"justify"}/>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Highlights;
