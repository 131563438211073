import { apiClientPaypal } from "./api.client";
import apiClient, {
  setCurrentSubscription,
  setMatchingSubscription,
  setToken,
  setPaypalToken,
  getPaypalAccessToken
} from './api.client';

class UserService {
  constructor() {
    this.getCurrentSubscription();
  }

  async checkIfEmailExists(email) {
    const data = new FormData();
    data.append('email', email);
    const {data: {exists}} = await apiClient.post('/api/check-email', data, {
      headers: {'content-type': 'multipart/form-data'}
    })
    return exists;
  }

  handleFormSubmit = (values, subscriptionId, isBoostOffer) => {
    subscriptionId = parseInt(subscriptionId);
    isBoostOffer = typeof isBoostOffer === 'undefined' ? 0 : (isBoostOffer ? 1 : 0)

    return apiClient.post('/api/createSubscriptionSession/' + isBoostOffer + '/' + subscriptionId, {
      'cardholderName': values.name,
      'cardNumber': values.number,
      'expiryDate': values.expiry,
      'cvc': values.cvc
    })
  }

  async logIn(email, password) {
    let {data: {data: {token}}} = await apiClient.post('/api/login', {
      username: email,
      password: password,
      fromBackOffice: false
    });
    if (token) {
      setToken(token);
      await this.getCurrentSubscription();
    }
  }

  async getCurrentSubscription() {

    const data = await apiClient.get('/api/getUserSubscription')
    setCurrentSubscription(data.data.currentSubscription)
    setMatchingSubscription(data.data.matchingSubscription)
    return data;
  }

  getUserCards() {
    return apiClient.get('/api/userCards')
      .then((response) => {
        return response.data.cardList;
      })
  }

  updateSubscription(securionSubscriptionId, data) {
    return apiClient.post('/api/updateSubscription/' + securionSubscriptionId, data)
      .then((response) => {
        return response.data;
      })
  }

  forgotPassword(email) {
    return apiClient.post('/api/forgot-password', {email: email});
  }

  resetPassword(password, token) {
    return apiClient.post('/api/reset-password/' + token, {password: password});
  }

  loggedInProfile() {
    return apiClient.get('/api/loggedUser');
  }

  async logout() {
    // todo: invalidate token
    setToken('');
    setCurrentSubscription(null);
    setMatchingSubscription(null);
  }

  async updateProfile(data) {
    return apiClient.post('/api/profile', data, {headers: {'content-type': 'multipart/form-data'}});
  }

  async updateEmail(email) {
    const {data} = await apiClient.post('/api/update-email', {email});
    setToken(data.token)
    return data;
  }

  async updatePassword(data) {
    return apiClient.post('/api/update-password', data);
  }

  async updateDevise(devise) {
    return apiClient.post('/api/update-devise', {devise});
  }

  fetchCities(q) {
    return apiClient.get('/api/cities?q=' + q);
  }

  getSubscriptionToken() {
    return apiClient.get('/api/subscriptionToken');
  }

  fetchProfiles(params) {
    return apiClient.get('/api/profiles', {params})
      .then((response) => {
        return response.data;
      })
  }

  async updatePreference(data) {
    return apiClient.post('/api/preference', data);
  }

  fetchSuggestions() {
    return apiClient.get('/api/suggestions');
  }

  updateSuggestionFlag(profileId, flag) {
    return apiClient.post('/api/updateFlag/' + profileId + '/' + flag);
  }

  getSubscriptions() {
    return apiClient.get('/api/subscriptions');
  }

  getBoostSubscriptions() {
    return apiClient.get('/api/boostSubscriptions');
  }

  subscribe(subscriptionId, isBoostOffer) {
    isBoostOffer = typeof isBoostOffer === 'undefined' ? 0 : (isBoostOffer ? 1 : 0)
    return apiClient.post('/api/create/' + isBoostOffer + '/' + subscriptionId);
  }

  async subscribeStripe(params, subscriptionId) {
    // ${ isBoostOffer}/${subscriptionId}
    return apiClient.post(`/api/createSubscriptionSession/${subscriptionId}`, params);
  }

  async createUserSubscription(params) {
    return apiClient.post('/api/createUserSubscription', params)
      .then((response) => {
        return response.data;
      })
  }

  accessCustomerPortal(boosted) {
    return apiClient.post('/api/accessCustomerPortal/' + boosted);
  }

  async confirmEmail(token) {
    return apiClient.post('/api/confirm-email', {token});
  }

  async blockUser({id}) {
    return apiClient.delete(`/api/block-user/${id}`);
  }

  deleteAccount(id, reasonWhy) {
    return apiClient.put('/api/user/' + id + '/delete', {
      'why': reasonWhy,
      //'stripeSubscriptionId': hasValidSubscription() ? getCurrentSubscription().stripeSubscriptionId : null,
    })
  }

  updateCustomer(data) {
    return apiClient.post('/api/updateCustomer', data);
  }

  deleteCard(card) {
    return apiClient.post('/api/deleteUserCard', {cardId: card.id})
  }

  createSubscription(params) {
    return apiClient.post('/api/createSubscription', params);
  }

  async getPaypalToken(){
    await getPaypalAccessToken().then((response) => {
      const token = response.access_token;
      setPaypalToken(token);
    });
  }

  async getSubscriptionFromPayPal(id) {
    await this.getPaypalToken();
    return apiClientPaypal.get(`/v1/billing/subscriptions/${id}`);
  }

  async getPlansFromPayPal() {
    await this.getPaypalToken();
    return apiClientPaypal.get('/v1/billing/plans?page_size=20&page=1');
  }

  async getStripePlans(sub){
    return apiClient.post('/api/getStripePlans',{sub})
  }

  updateSubscriptionStatus(params){
    return apiClient.post('/api/updateSubscriptionStatus', params);
  }

  async cancelSubscription(id,type){
    if(type==='paypal'){
      await this.getPaypalToken();
      return apiClientPaypal.post(`/v1/billing/subscriptions/${id}/cancel`, {reason: "-"});
    } else if(type ==='stripe'){
      const response = await apiClient.post(`/api/cancelStripeSubscription`,{
        id,
      })
      return response;
    }
  }
  
  async reviseSubscription(id, type, params){
    if(type==='paypal'){
      await this.getPaypalToken();
      return apiClientPaypal.post(`/v1/billing/subscriptions/${id}/revise`, {
        subscription_id: id,
        ...params
      });
    }  else if(type==='stripe'){
      const response = await apiClient.post(`/api/modifyStripeSubscription`,{
        id,
        ...params
      })
      return response;
    }
  }

   async updateUserSubscription(subscription_id,current_sub_id){
      await apiClient.post("api/updateUserSubscription/", {
        subscription_id,
        current_sub_id,
      });

     this.getCurrentSubscription()


   }
}

export default new UserService();
