import React, { Component } from "react";
import CustomBannerMobile from "../../../Components/BannerMobile/BannerMobile";
import Highlights from "../../../Components/Highlights/Highlights";
import WhyChooseUs from "../../../Components/WhyChooseUs/WhyChooseUs";
import OurMeets from "../../../Components/OurMeets/OurMeets";
import Footer from "../../../Components/Footer/Footer";
import "./HomeMobileStyle.css";
import PartnershipBloc from "../../../Components/PartnershipBloc/PartnershipBloc";

class HomeMobile extends Component {
  componentDidMount() {
    if(localStorage.getItem('tokenApi')) {
      window.location.href = '/home'
    }
  }

  render() {
    return (
      <>
        <CustomBannerMobile></CustomBannerMobile>
        <Highlights></Highlights>
        <WhyChooseUs></WhyChooseUs>
        <PartnershipBloc></PartnershipBloc>
        <OurMeets></OurMeets>
        <Footer></Footer>
      </>
    );
  }
}

export default HomeMobile;
