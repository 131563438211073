import React, {Component} from "react";
import { ReactSVG } from "react-svg";
import EliteParagraph from "../Uikit/Typography/Paragraph";
import illusBallon from "../../assets/images/illustration-bubble.svg";
import {hasValidSubscription} from "../../Services/ApiServices/api.client";
import CustomModal from "../Uikit/Modal/Modal";
import Offers from "../Offers/Offers";

export class ChooseConversation extends Component {
    constructor(props) {
        super(props);
        this.child = React.createRef();
    }

    render() {
        return (
            <div
                className={
                    "d-flex flex-column justify-content-center align-items-center"
                }>
                <div className="illus">
                    <ReactSVG src={illusBallon}/>
                </div>
                <div className="chooseConversationText">
                    <EliteParagraph
                        margin={"32px 0 0 0"}
                        text={
                            this.props.noRooms
                                ? `Vous n'avez pas encore de conversation ? \n Les conversations se lancent automatiquement, lorsque 2 utilisateurs se sont respectivement likés.`
                                : (hasValidSubscription() ?
                                "Veuillez sélectionner une conversation pour voir les messages" :
                                "Qu'attendez-vous pour faire le premier pas ?")
                        }
                        fontSize={"16px"}
                        fontWeight={"500"}
                        textColor={"#bcbcbc"}
                    />
                    {!hasValidSubscription() &&
                    <div
                        onClick={() => this.child.current.handleOpenModal()}
                        className="d-flex justify-content-center cursor-pointer">
                        <EliteParagraph
                            margin={"10px 0 0 0"}
                            text={"Découvrir nos offres"}
                            fontSize={"16px"}
                            uppercase
                            textAlign={"center"}
                            textColor={"#6c95e7"}
                            fontWeight={"500"}
                            cursor={"pointer"}
                        />
                    </div>
                    }
                </div>

                <CustomModal
                    overlayClassName="Overlay"
                    modalContent={
                        <Offers
                            isMobile={this.props.isMobile}
                            closeModal={this.child}
                        />
                    }
                    modalClass={this.props.isMobile ? "offersModalMobile" : "offersModal"}
                    ref={this.child}
                />
            </div>
        );
    }
}

export default ChooseConversation;
