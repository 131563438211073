import React, {Component} from "react";
import './CookiesBannerStyleMobile.css';
import CookiesBanner from "../CookiesBanner";

class CookiesBannerMobile extends Component {
  render() {
    return (
      <CookiesBanner trigger={this.props.trigger} mobile />
    )
  }
}

export default CookiesBannerMobile;
