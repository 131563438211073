import React, { useState } from "react";
import EliteErrorText from "../Typography/ErrorText";
import "./TextFieldsStyle.css";
import { Actions as LoginActions } from "../../../Containers/Login/Actions";
import { handleError } from "../../../Services/ErrorHandler";
import { ReactSVG } from "react-svg";
import iconVue from "../../../assets/images/icon-vue.svg";

function CustomLogTextField(props) {
  const [isFocused, setIsFocused] = useState(false);
  const [inputText, setInputText] = useState(
    props.value === null ? "" : props.value
  );

  const type = props.type ?? "text";

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [inputType, setInputType] = useState(type);

  function onChange(e) {
    let value = e.target.value
    if (props.noEmoji) {
      value = value.replace(/\p{Emoji}/gu, '');
    }
    setInputText(value);
    if (props.onChange) {
      props.onChange(value);
    }
  }

  function TogglePasswordButton() {
    if (props.type === "password") {
      return (
        <button
          type="button"
          style={{
            boxShadow: "none",
            position: "absolute",
            right: "10px",
            marginTop: "3px",
            outline: "none",
          }}
          className={`btn ${props.showPasswordIconClass ?? "p-1 "}`}
          onClick={TogglePasswordVisible}
        >
          <ReactSVG
            style={{
              opacity: passwordVisible ? ".3" : "1",
            }}
            src={iconVue}
          />
        </button>
      );
    } else {
      return null;
    }
  }

  function TogglePasswordVisible() {
    let v = !passwordVisible;
    setPasswordVisible(v);
    let newType = v ? "text" : props.type ?? "text";
    setInputType(newType);
  }

  async function onLogin(email, password) {
    try {
      await LoginActions.logIn(email, password);
      window.location.href = "/home";
    } catch (err) {
      handleError(err);
    }
  }

  return (
    <>
      <div className={` ${props.classes || ""}`}>
        <div className="d-flex position-relative">
          {props.addOn && (
            <ReactSVG
              src={props.addOn}
              style={{ position: "absolute", left: 8, top: 8, bottom: 8 }}
            />
          )}
          <input
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                if (props.validateLogin === true) {
                  onLogin(props.email, props.password);
                }
              }
            }}
            maxLength={props.inputLength ? props.inputLength : ""}
            autoComplete={props.autoComplete}
            value={inputText}
            onChange={onChange}
            onBlur={() => setIsFocused(false)}
            onFocus={() => setIsFocused(true)}
            disabled={props.isDisabled}
            type={inputType}
            placeholder={props.placeholder}
            className={`customTextField form-control${
              props.isInvalid ? " isInvalid" : ""
            }${props.isMobile ? " inputBorderMobile" : ""}${
              props.addOnSuffix ? " customTextFieldSuffix" : ""
            }${isFocused ? " isFocus" : ""}${
              props.isInvalid ? " isInvalid" : ""
            }`}
            style={{
              backgroundColor: `${props.isMobile && "transparent"}`,
              color: `${props.isMobile && "#fff"}`,
              paddingLeft: `${props.addOn ? "40px" : ""}`,
              borderColor: `${props.borderColor}`,
              borderTopRightRadius: props.isSubscription ? "24px" : "0",
              borderBottomRightRadius: props.isSubscription ? "24px" : "0",
              borderBottomLeftRadius: props.isSubscription ? "24px" : "0",
              borderTopLeftRadius: props.isSubscription ? "24px" : "0",
              height: props.height,
              fontFamily: "roboto",
              fontSize: `${props.fontSize}`,
            }}
          ></input>
          {typeof props.suffix !== "undefined" && !!props.suffix.length && (
            <div className="input-group-append">
              <span className="input-group-text">{props.suffix}</span>
            </div>
          )}
          <TogglePasswordButton />
        </div>
        <div>
          <div className={"invalid-feedback"}>
            <EliteErrorText text={props.errorText} />
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomLogTextField;
