import React from "react";
import "./ContactButtonStyles.css";
import {ReactSVG} from "react-svg";
import CustomModal from "../Modal/Modal";
import EliteParagraph from "../Typography/Paragraph";
import CustomTextArea from "../TextArea/TextArea";
import CustomButton from "../Button/CustomButton";
import backArrow from "../../../assets/images/icon-arrow-back.svg";
import closeButton from "../../../assets/images/btn-close.svg";
import {ErrorMessage, Form, Formik} from 'formik';
import * as yup from "yup";
import apiClient from "../../../Services/ApiServices/api.client";
import * as PropTypes from "prop-types";
import {toast} from "react-toastify";
import Select, {components} from "react-select";
import iconDrop from "../../../assets/images/icon-dropup.svg";

export class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {}
  }

  handleOpenForm(subjects) {
    if (subjects) {
      this.setState({subjects})
    }
    this.child.current?.handleOpenModal()
  }

  async onSubmit(message, formik) {
    try {
      let report = window.location.pathname !== '/profile/parameters';
      await apiClient.post('api/contact', {...message, report})
      if (!report) {
        toast.success('Message envoyé !')
      }
      if (this.props.onSend) {
        await this.props.onSend(message);
      }
      this.child.current?.handleCloseModal()
    } finally {
      formik.setSubmitting(false)
    }
  }

  renderForm() {
    const {isMobile, label, subject, dataList} = this.props;
    const validationSchema = yup.object().shape({
      subject: yup.object().required('Champ obligatoire'),
      body: yup.string().required('Champ obligatoire'),
    });

    const DropdownIndicator = (props) => {
      return (
        <components.DropdownIndicator {...props}>
          <ReactSVG className={"pointDown"} src={iconDrop}/>
        </components.DropdownIndicator>
      );
    };

    let subjects = this.state.subjects;
    if (!dataList && subjects) {
      subjects = this.state.subjects.map((e) => {
        return {...e, label: e.value}
      })
    }

    return (
      <>
        <div className={isMobile ? "d-none" : "d-block p-4"}>
          <EliteParagraph
            text={label}
            fontSize={"24px"}
            textColor={"#6c95e7"}
            textAlign={"left"}
            fontWeight={500}
          />
        </div>
        <div
          className={
            !isMobile ? "d-none" : "d-block col-lg-4 col-md-12 mb-4 headerModalMobile"
          }>
          <div className={"back"} style={{marginTop: "64px"}}>
            <div style={{height: "2vh"}}/>
            <div
              onClick={() => {
                this.child.current?.handleCloseModal()
              }}>
              <ReactSVG src={backArrow}/> {label}
            </div>
          </div>
        </div>
        <div
          className={`d-flex flex-column w-100 contentModalMinHeight force px-5 position-relative ${isMobile ? 'mobile' : ''}`}>
          <Formik
            initialValues={{subject, body: '', dataList: []}}
            validationSchema={validationSchema}
            onSubmit={(values, formik) => this.onSubmit(values, formik)} >
            {({isSubmitting, isValid, setFieldValue, values}) => {
              return (
                <Form className={"needs-validation"}>

                  <EliteParagraph text="Sujet" textAlign='left'/>
                  <Select
                    components={{DropdownIndicator, IndicatorSeparator: () => null}}
                    options={dataList || subjects}
                    placeholder={"Sélectionner..."}
                    menuPlacement={'auto'}
                    value={values.subject}
                    onChange={(value) => setFieldValue('subject', value)}
                  />
                  <ErrorMessage component='div' className={'invalid-feedback d-block'} name="subject"/>

                  <div style={{height: "30px"}}/>

                  <EliteParagraph text="Message" textAlign='left' margin={'16px 0 16px 0'}/>
                  <CustomTextArea
                    value={values.body}
                    onChange={(value) => setFieldValue('body', value)}/>
                  <ErrorMessage component='div' className={'invalid-feedback d-block'} name="body"/>
                  <div className={""} style={{position: "absolute", bottom: "40px", right: "3rem", left: "3rem"}}>
                    <CustomButton
                      isDisabled={isSubmitting || !values.subject.value || !values.body.length}
                      type="submit"
                      label="valider"/>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        <div
          className={
            "closeButtonLevitate d-flex flex-column align-items-center"
          }>
          <ReactSVG
            style={{cursor: "pointer"}}
            src={closeButton}
            onClick={() => this.child.current?.handleCloseModal()}/>
        </div>

      </>
    );
  }

  render() {

    return (
      <>
        <CustomModal
          modalHeight={this.props.isMobile ? '100vh' : ''}
          modalContent={this.renderForm()}
          modalClass={this.props.isMobile ? "defaultProfileModalMobile" : "defaultProfileModal"}
          ref={this.child}
        />
      </>
    );
  }
}

ContactForm.propTypes = {
  isMobile: PropTypes.any,
  label: PropTypes.any,
  subject: PropTypes.any
}

export default ContactForm;
