import React from "react";
import CustomAvatar from "../../../Uikit/Avatar/Avatar";
import CounterIcon from "../../../Uikit/CounterIcon/CounterIcon";
import iconVue from "../../../../assets/images/icon-vue.svg";
import iconHeart from "../../../../assets/images/icon-love-empty.svg";
import "./ConnectedHeaderMobileStyles.css";
import EliteParagraph from "../../../Uikit/Typography/Paragraph";
import { EliteRouters } from "../../../../Navigation/Routers";
import { useCurrentUser } from "../../../../Hooks/AuthenticatedUser";
import logoGrey from "../../../../assets/images/logo-short-grey.png";

export function ConnectedHeaderMobile(props) {
  const { user } = useCurrentUser();

  return (
    <div style={{justifyContent: props.isCheckout ? "center" : ""}}
      className={
        "container-fluid d-flex flex-row w-100 headerConnected mobile fixed-top"
      }>
        {props.isCheckout &&
          <div className="col-1 text-center">
            <a href="/home"><img className="img-fluid"
              src={logoGrey}
              alt={"small white logo"} /></a>
          </div> }
        {!props.isCheckout &&

          <div className="col-6 d-flex">
          <CustomAvatar
            customClass={"connectedHeader mobile"}
            borderColor={"#fff"}
            photoUrl={user ? user.avatar : ""}
            name={user ? user.username : ""}
          />
            <div className="greetings mobile d-flex flex-column justify-content-center w-100">
              <EliteParagraph
                textAlign={"left"}
                textColor={"#fff"}
                margin={"2px 0 0 0"}
                fontWeight={"bold"}
                fontSize={"20px"}
                text={user ? user.username : ""}
              />
            </div>
          </div>
        }

        {!props.isCheckout &&
          <div className="col-6">
            <div className="likeSeen d-flex pt-2 justify-content-end">
              <CounterIcon
                goToUrl={EliteRouters.mesvisites.path}
                qty={user ? user.visitsCount : 0}
                svg={iconVue}
              />
              <CounterIcon
                goToUrl={EliteRouters.meslikes.path}
                qty={user ? user.likersCount : 0}
                svg={iconHeart}
                paddingLeft={"24px"}
              />
            </div>
          </div>
        }
    </div>
  );
}

export default ConnectedHeaderMobile;
