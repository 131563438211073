import React, { Component } from "react";
import {AsyncTypeahead, Menu, Typeahead, withItem} from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "../TextFields/TextFieldsStyle.css";
import "./AutoComplete.css";
import axios from "axios";
import { map } from "lodash-es";
import EliteErrorText from "../Typography/ErrorText";
import {Dropdown} from "react-bootstrap";

const CancelToken = axios.CancelToken;
let cancel;

const Item = withItem(Dropdown.Item);

class AutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isFocused: false,
      options: props.options || [],
      selected: props.value || [],
    };
  }

  onChange = (selected) => {
    this.setState({ selected: selected });
    if (this.props.onChange && selected.length) {
      let values = selected;
      if (this.props.valueKey) {
        values = map(selected, this.props.valueKey);
      }
      this.props.onChange(this.props.multiple ? values : values[0]);
    }
  };

  onSearch = async (val) => {
    this.setState({ isLoading: true });
    try {
      if (cancel !== undefined) {
        cancel();
      }
      try {
        const { data } = await axios.get(`${this.props.url}${val}`, {
          cancelToken: new CancelToken(function executor(c) {
            cancel = c;
          }),
        });
        this.setState({ options: data });
      } catch (e) {
        //cancel
      }
    } finally {
      this.setState({ isLoading: false });
    }
  };

  renderField() {
    if (this.props.url) {
      return (
        <>
          <AsyncTypeahead
            inputProps={{
              autoComplete: "chrome-off",
              className: this.props.isInvalid && "isInvalid",
            }}
            searchText={"Recherche..."}
            emptyLabel={"Aucun résultats trouvé"}
            promptText={"Type de recherche"}
            id={this.props.id}
            labelKey={(option) => option.name || ""}
            isLoading={this.state.isLoading}
            onSearch={this.onSearch}
            onChange={this.onChange}
            options={this.state.options}
            multiple={this.props.multiple}
            onBlur={() => this.setState({ isFocused: false })}
            onFocus={() => this.setState({ isFocused: true })}
            disabled={this.props.isDisabled}
            selected={this.state.selected}
            placeholder={this.props.placeholder}
            renderMenu={(results, menuProps) => {
              return <Menu {...menuProps} maxHeight={200}>
                {results.map((option, position) => (
                  <Item option={option} position={position}>
                    {option.name}
                  </Item>
                ))}
              </Menu>
            }}
            className={`customTextField form-control p-0 ${
              this.props.isInvalid ? "isInvalid" : ""
            } ${this.props.isMobile ? "inputBorderMobile" : ""}`}
            style={{
              borderLeft: `${this.props.addOn && "none"}`,
              backgroundColor: `${"transparent"}`,
              color: `${this.props.isMobile ? "#fff" : this.props.color}`,
              borderRadius:this.props.isSubscription && '30px',
              height:this.props.height,
              fontSize :  `${this.props.fontSize}`,

            }}
          />
          <div className={"invalid-feedback"}>
            <EliteErrorText text={this.props.errorText} />
          </div>
        </>
      );
    }

    return (
      <>
        <Typeahead
          inputProps={{
            autoComplete: "chrome-off",
            className: this.props.isInvalid && "isInvalid",
            style:{borderRadius:this.props.isSubscription && '30px'}
          }}
          id={this.props.id}
          searchText={"Recherche..."}
          emptyLabel={"Aucun résultats trouvé"}
          promptText={"Type de recherche"}
          labelKey={({ name }) => name}
          onChange={this.onChange}
          options={this.state.options}
          onBlur={() => this.setState({ isFocused: false })}
          onFocus={() => this.setState({ isFocused: true })}
          disabled={this.props.isDisabled}
          multiple={this.props.multiple}
          selected={this.state.selected}
          placeholder={this.props.placeholder}
          className={`customTextField form-control p-0 ${
            this.props.isInvalid && "isInvalid"
          } ${this.props.isMobile && "inputBorderMobile"}`}
          style={{
            borderLeft: `${this.props.addOn && "none"}`,
            backgroundColor: `${"transparent"}`,
            color: `${this.props.isMobile ? "#fff" : this.props.color}`,
            borderRadius:this.props.isSubscription && '30px',
            height:this.props.height,
            fontSize :  `${this.props.fontSize}`,
          }}
        />
        <div className={"invalid-feedback"}>
          <EliteErrorText text={this.props.errorText} />
        </div>
      </>
    );
  }

  render() {
    return (
      <>
        <div
          id={this.props.id}
          className={`input-group ${this.props.classes ?? ""}`}>
          {this.props.addOn && (
            <span
              style={{
                backgroundColor: `${this.props.isMobile && "transparent"}`,
              }}
              className={`input-group-addon addon-inline ${
                this.props.isMobile && "inputBorderMobile"
              } ${this.state.isFocused && "isFocus"} ${
                this.state.isInvalid && "isInvalid"
              }`}>
              {this.props.addOn}
            </span>
          )}
          {this.renderField()}
        </div>
      </>
    );
  }
}

export default AutoComplete;
