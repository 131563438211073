import { Component } from "react";
import CustomRadioButton from "../../Uikit/RadioButtons/RadioButtons";
import "./SubscriptionChooserStyles.css";

class ContainerSubsciptionChooser extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedOption: props.selected };
  }

    isChecked = checked => {
      this.setState({selectedOption: checked.target.value})
      if(this.props.onChange) {
        this.props.onChange(checked.target.value)
      }
    }

  render() {
    return (
      <>
        <div className={"containerChooser"}>
          <div className={`d-flex align-items-center p-0 ${this.props.title && 'containerTitleChooser'}`}>
            <div className={`mr-3 mb-1 ${this.props.title ? "col-4" : ""}`}>
              <span>{this.props.title}</span>
            </div>
            <div className={`mr-3 ${this.props.title ? "col-4" : "col-6"}`}>
              <CustomRadioButton
                rbId={"H"}
                label={"Un homme"}
                name={this.props.name}
                checked={this.state.selectedOption === "H"}
                callBack={this.isChecked.bind(this)}/>
            </div>
            <div className={`${this.props.title ? "col-4" : "col-6"}`}>
              <CustomRadioButton
                label={"Une femme"}
                rbId={"F"}
                name={this.props.name}
                checked={this.state.selectedOption === "F"}
                callBack={this.isChecked.bind(this)}/>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default ContainerSubsciptionChooser;
