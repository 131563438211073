import React, { useMemo, useRef } from "react";
import { ReactSVG } from "react-svg";
import { EliteRouters } from "../../Navigation/Routers";
import CustomAvatar from "../Uikit/Avatar/Avatar";
import ContextualMenu from "../Uikit/ContextualMenu/ContextualMenu";
import EliteParagraph from "../Uikit/Typography/Paragraph";
import backArrow from "../../assets/images/icon-arrow-back.svg";
import {generatePath, useHistory} from "react-router-dom";
import { getState } from "./Helpers";
import { useNotifications } from "../../Hooks/Notifications";
import apiClient, {hasValidSubscription} from "../../Services/ApiServices/api.client";
import ContactForm from "../Uikit/ContactButton/ContactForm";
import UserService from "../../Services/ApiServices/UserService";
import { find } from "lodash-es";
import ConfirmBlockModal from "./ConfirmBlockModal";
import { toast } from "react-toastify";
import { handleError } from "../../Services/ErrorHandler";

let reportTypes = []

async function getReportTypes() {
  const { data: { list } } = await apiClient.get('/api/reportTypes');
  reportTypes = list.map(item => ( { id: item.id, value: `${ item.label }` } ));
}

export function ChatHeader({ isMobile, room, getRooms }) {
  const contact = useRef();
  const confirmBlock = useRef();
  const history = useHistory();
  const { notifications } = useNotifications()
  const participant = room.otherParticipant;
  const isOnline = useMemo(() => notifications.onlineUsers.includes(participant.id), [participant.id, notifications.onlineUsers]);

  const contextualMenuAllItems = [
    {
      label: "Signaler",
      clickEvent: async () => {
        if (!reportTypes.length) {
          await getReportTypes();
        }
        contact.current.handleOpenForm(reportTypes)
      },
    },
    {
      label: "Bloquer",
      clickEvent() {
        if (!confirmBlock.current) return;
        confirmBlock.current.open(async () => {
          try {
            await UserService.blockUser(participant)
            toast.success("L'utilisateur a été bloqué et n’apparaîtra plus.")
            history.push(EliteRouters.rooms.path)
            getRooms()
          } catch (e) {
            handleError(e);
          }
        })
      }
    },
  ];

  async function sendReport(report) {
    try {
      const type = find(reportTypes, { value: report.subject.value }).id
      await apiClient.post(`/api/report/${ participant.id }`, { ...report, type })
      toast.success("Votre signalement a bien été envoyé.")
    } catch (e) {
      handleError(e);
    }
  }

  return (
    <div className={ `d-flex justify-content-between headerContainer align-items-center ${ isMobile ? "pt-2" : "" }` }>
      <div className={ `d-flex chatHeaderInfo align-items-center` }
        onClick={() => {
          history.push(generatePath(EliteRouters.visitedProfile.path, {id: participant.id}))
        }}>
        <div
          className={ `pe-2 ${ isMobile ? "d-block" : "d-none" }` }
          onClick={ () => history.push(EliteRouters.rooms.path) }>
          <ReactSVG src={ backArrow }/>
        </div>

        <CustomAvatar
          size={ "48" }
          state={ getState(isOnline, parseInt(room.unreadCount)) }
          photoUrl={ participant.avatar }
          link={"/home/"+ participant.id}
        />
        <div style={{marginLeft: "8px"}}/>
        <div className={ "chatHeaderName" }>
          <div className="d-flex flex-column">
            <EliteParagraph
              text={ participant.username }
              fontSize={ "20px" }
              fontWeight={ 400 }
              margin={ "0 0 4px 0" }
            />
            <EliteParagraph
              text={ isOnline ? "En ligne" : "Hors ligne" }
              fontSize={ "10px" }
              textColor={ "#34d10e" }
              fontWeight={ 400 }
              margin={ 0 }
              textAlign={ 'left' }
            />
          </div>
        </div>
      </div>
      {hasValidSubscription() &&
        <ContextualMenu isColored menuItems={contextualMenuAllItems} isActive/>
      }
      <ConfirmBlockModal ref={ confirmBlock } isMobile={isMobile}/>
      <ContactForm
        label="Signalement"
        subject="Signalement"
        isMobile={ isMobile }
        ref={ contact }
        onSend={ sendReport }/>
    </div>
    );
  }

export default ChatHeader;
