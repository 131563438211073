import {ConnectedHome} from "../Containers/ConnectedHome/ConnectedHome";
import {ConnectedHomeMobile} from "../Containers/ConnectedHome/Mobile/ConnectedHomeMobile";
import {ForgotPasswordMobile} from "../Containers/ForgotPassword/ForgotPassword";
import {Home, HomeMobile} from "../Containers/Home/Home";
import {LoginMobile} from "../Containers/Login/Login";
import {MesLikes} from "../Containers/MesLikes/MesLikes";
import {MesLikesMobile} from "../Containers/MesLikes/Mobile/MesLikesMobile";
import {MesVisites} from "../Containers/MesVisites/MesVisites";
import {MesVisitesMobile} from "../Containers/MesVisites/Mobile/MesVisitesMobile";
import {SearchMobile} from "../Containers/Search/Mobile/SearchMobile";
import {Search} from "../Containers/Search/Search";
import {SignUpMobile} from "../Containers/SignUp/SignUp";
import {UiKit} from "../Components/Uikit/UiKit";
import {UserProfile} from "../Containers/UserProfile/Profile";
import {UserProfileMobile} from "../Containers/UserProfile/ProfileMobile/ProfileMobile";

import {ProfileMobile} from "../Containers/Profile/ProfileMobile/ProfileMobile";
import {Profile} from "../Containers/Profile/Profile";
import {Checkout} from "../Containers/Checkout/Checkout";
import {CheckoutMobile} from "../Containers/Checkout/CheckoutMobile/CheckoutMobile";
import {Management} from "../Containers/Management/Management";
import {ManagementMobile} from "../Containers/Management/ManagementMobile/ManagementMobile";
import {ParametersMobile} from "../Containers/Parameters/ParametersMobile/ParametersMobile";
import {Parameters} from "../Containers/Parameters/Parameters";
import {ProfileEditionMobile} from "../Containers/ProfileEdition/ProfileEditionMobile/ProfileEditionMobile";
import {ProfileEdition} from "../Containers/ProfileEdition/ProfileEdition";
import VisitedProfile from "../Containers/VisitedProfile/VisitedProfile";
import VisitedProfileMobile from "../Containers/VisitedProfile/VisitedProfileMobile/VisitedProfileMobile";
import Matchmaking from "../Containers/Matchmaking/Matchmaking";
import MatchMakingMobile from "../Containers/Matchmaking/MatchMakingMobile/MatchMakingMobile";
import {MessagesMobile} from "../Containers/Messages/MessagesMobile/MessagesMobile";
import {Messages} from "../Containers/Messages/Messages";
import ChatRoomMobile from "../Containers/Messages/MessagesMobile/ChatRoomMobile";
import {ConditionGenerales} from "../Containers/ConditionGenerales/ConditionGenerales";
import {MentionsLegales} from "../Containers/MentionsLegales/MentionsLegales";
import {PolitiqueConfidentialites} from "../Containers/PolitiqueConfidentialites/PolitiqueConfidentialites";
import { BerberePage } from "../Containers/Berbere/Berbere";
import { MaghrebPage } from "../Containers/MaghrebPage/MaghrebPage";
import { TunisiePage } from "../Containers/TunisiePage/TunisiePage";
import { AlgeriePage } from "../Containers/AlgeriePage/AlgeriePage";
import { KabylePage } from "../Containers/KabylePage/KabylePage";
import { AmazighPage } from "../Containers/AmazighPage/AmazighPage";
import { BelgiquePage } from "../Containers/BelgiquePage/BelgiquePage";
import { EuropePage } from "../Containers/EuropePage/EuropePage";
import { AfriquePage } from "../Containers/AfriquePage/AfriquePage";
import { CanadaPage } from "../Containers/CanadaPage/CanadaPage";
import { MusulmanPage } from "../Containers/MusulmanPage/MusulmanPage";
import { MariagePage } from "../Containers/MariagePage/MariagePage";
import { SerieusePage } from "../Containers/SerieusePage/SerieusePage";
import { ExigeantPage } from "../Containers/ExigeantPage/ExigeantPage";
import { SpeedDatPage } from "../Containers/SpeedDatPage/SpeedDatPage";
import { CoachingAmoureuxPage } from "../Containers/CoachingAmoureuxPage/CoachingAmoureuxPage";
import { IleDeFrancePage } from "../Containers/IleDeFrancePage/IleDeFrancePage";
import { LyonPage } from "../Containers/LyonPage/LyonPage";
import { MarseillePage } from "../Containers/MarseillePage/MarseillePage";
import { ParisPage } from "../Containers/ParisPage/ParisPage";

export const EliteRouters = {
  uikit: {
    name: "uiKit",
    path: "/uikit",
    components: {
      desktop: UiKit,
    },
  },
  login: {
    name: "login",
    path: "/login",
    components: {mobile: LoginMobile, desktop: Home},
  },
  confirmEmail: {
    name: "confirmEmail",
    path: "/confirm-email/:token",
    components: {mobile: LoginMobile, desktop: Home},
  },
  sigup: {
    name: "signup",
    path: "/sign-up",
    components: {mobile: SignUpMobile, desktop: Home},
  },
  forgotPassword: {
    name: "forgotPassword",
    path: "/forgot-password",
    components: {mobile: ForgotPasswordMobile, desktop: Home},
  },
  resetPassword: {
    name: "resetPassword",
    path: "/reset-password/:token",
    components: {mobile: ForgotPasswordMobile, desktop: Home},
  },
  home: {
    name: "Accueil",
    path: "/home",
    components: {mobile: ConnectedHomeMobile, desktop: ConnectedHome},
  },
  mesvisites: {
    name: "mesvisites",
    path: "/home/mes-visites",
    components: {mobile: MesVisitesMobile, desktop: MesVisites},
  },
  meslikes: {
    name: "meslikes",
    path: "/home/mes-likes",
    components: {mobile: MesLikesMobile, desktop: MesLikes},
  },
  root: {
    name: "root",
    path: "/",
    components: {mobile: HomeMobile, desktop: Home},
  },
  search: {
    name: "Recherche",
    path: "/search",
    components: {mobile: SearchMobile, desktop: Search},
  },
  rooms: {
    name: "Messages",
    path: "/messages",
    components: {mobile: MessagesMobile, desktop: Messages},
  },
  messages: {
    name: "Messages",
    path: "/messages/:roomId",
    components: {mobile: ChatRoomMobile, desktop: Messages},
  },
  chatroom: {
    name: "Chat Room",
    path: "/messages/:roomId/chatroom",
    components: {mobile: ChatRoomMobile, desktop: Messages},
  },
  management: {
    name: "Management",
    path: "/profile/management",
    components: {mobile: ManagementMobile, desktop: Management},
  },
  userProfile: {
    name: "UserProfil",
    path: "/profile/:username",
    components: {mobile: UserProfileMobile, desktop: UserProfile},
  },
  profile: {
    name: "Profil",
    path: "/profile",
    components: {mobile: ProfileMobile, desktop: Profile},
  },
  profileEdition: {
    name: "Profil Edition",
    path: "/profile/edition",
    components: {mobile: ProfileEditionMobile, desktop: ProfileEdition},
  },
  parameters: {
    name: "parameters",
    path: "/profile/parameters",
    components: {mobile: ParametersMobile, desktop: Parameters},
  },
  nomatch: {
    name: "nomatch",
    path: "*",
    components: {mobile: HomeMobile, desktop: Home},
  },
  checkout: {
    name: "Checkout",
    path: "/home/checkout/:selectedSub",
    components: {mobile: CheckoutMobile, desktop: Checkout},
  },
  visitedProfile: {
    name: "Profil",
    path: "/home/:id",
    components: {mobile: VisitedProfileMobile, desktop: VisitedProfile},
  },
  matchmaking: {
    name: "Matching",
    path: "/matchmaking",
    components: {mobile: MatchMakingMobile, desktop: Matchmaking},
  },
  generalConditions: {
    name: "Conditions générales",
    path: "/terms-conditions",
    components: {mobile: ConditionGenerales, desktop: ConditionGenerales},
  },
  legalMentions: {
    name: "Mentions légales",
    path: "/legal-notice",
    components: {mobile: MentionsLegales, desktop: MentionsLegales},
  },
  confidentialPolitics: {
    name: "Politique de confidentialité",
    path: "/privacy-policy",
    components: {mobile: PolitiqueConfidentialites, desktop: PolitiqueConfidentialites},
  },

  berbereHomePage: {
    name: "Site de rencontre berbere",
    path: "/site-de-rencontre-berbere",
    components: {mobile: BerberePage, desktop: BerberePage},
  },
  maghrebHomePage: {
    name: "Site de rencontre Maghreb",
    path: "/site-de-rencontre-maghreb",
    components: {mobile: MaghrebPage, desktop: MaghrebPage},
  },
  tunisieHomePage: {
    name: "Site de rencontre Tunisie",
    path: "/site-de-rencontre-tunisie",
    components: {mobile: TunisiePage, desktop: TunisiePage},
  },
  algerieHomePage: {
    name: "Site de rencontre Algérie",
    path: "/site-de-rencontre-algerie",
    components: {mobile: AlgeriePage, desktop: AlgeriePage},
  },
  kabyleHomePage: {
    name: "Site de rencontre Kabyle",
    path: "/site-de-rencontre-kabyle",
    components: {mobile: KabylePage, desktop: KabylePage},
  },
  amazighHomePage: {
    name: "Site de rencontre Amazigh",
    path: "/site-de-rencontre-amazigh",
    components: {mobile: AmazighPage, desktop: AmazighPage},
  },
  belgiqueHomePage: {
    name: "Site de rencontre Belgique",
    path: "/site-de-rencontre-belgique",
    components: {mobile: BelgiquePage, desktop: BelgiquePage},
  },
  europeHomePage: {
    name: "Site de rencontre Europe",
    path: "/site-de-rencontre-europe",
    components: {mobile: EuropePage, desktop: EuropePage},
  },
  africaHomePage: {
    name: "Site de rencontre Afrique",
    path: "/site-de-rencontre-afrique",
    components: {mobile: AfriquePage, desktop: AfriquePage},
  },
  canadaHomePage: {
    name: "Site de rencontre Canada",
    path: "/site-de-rencontre-canada",
    components: {mobile: CanadaPage, desktop: CanadaPage},
  },
  muslimHomePage: {
    name: "Site de rencontre pour musulman",
    path: "/site-de-rencontre-musulman",
    components: {mobile: MusulmanPage, desktop: MusulmanPage},
  },
  seriousHomePage: {
    name: "Site de rencontre sérieux et Gratuit",
    path: "/site-de-rencontre-serieux-gratuit",
    components: {mobile: SerieusePage, desktop: SerieusePage},
  },
  weddingHomePage: {
    name: "Site de rencontre pour mariage",
    path: "/site-de-rencontre-mariage",
    components: {mobile: MariagePage, desktop: MariagePage},
  },
  demandingHomePage: {
    name: "Site de rencontre exigeant",
    path: "/site-de-rencontre-exigeant",
    components: {mobile: ExigeantPage, desktop: ExigeantPage},
  },
  speeddatHomePage: {
    name: "Rencontre speed dating",
    path: "/site-de-rencontre-speed-dating",
    components: {mobile: SpeedDatPage, desktop: SpeedDatPage},
  },
  lovCoachingHomePage: {
    name: "Coaching amoureux",
    path: "/site-de-rencontre-coaching-amoureux",
    components: {mobile: CoachingAmoureuxPage, desktop: CoachingAmoureuxPage},
  },
  ileDeFranceHomePage: {
    name: "Site de rencontre ile de France",
    path: "/site-de-rencontre-ile-de-france",
    components: {mobile: IleDeFrancePage, desktop: IleDeFrancePage},
  },
  parisHomePage: {
    name: "Site de rencontre Paris",
    path: "/site-de-rencontre-paris",
    components: {mobile: ParisPage, desktop: ParisPage},
  },
  lyonHomePage: {
    name: "Site de rencontre Lyon",
    path: "/site-de-rencontre-lyon",
    components: {mobile: LyonPage, desktop: LyonPage},
  },
  marseilleHomePage: {
    name: "Site de rencontre Marseille",
    path: "/site-de-rencontre-marseille",
    components: {mobile: MarseillePage, desktop: MarseillePage},
  },
}
