import React, {Component} from "react";
import CustomButton from "../../Button/CustomButton";
import { withRouter } from "react-router";
import EliteParagraph from "../../Typography/Paragraph";
import moment from "moment";
import { toast } from "react-toastify";
import { registerUser } from "../../../SignUp/Actions";


class NavigationFrontBack extends Component {
  constructor(props) {
    super(props);
    this.state = { saving: false };
  }

  onNext = async () => {
    const {
      looking_for,
      gender,
      birth_date,
      city,
      username,
      photo,
    } = this.props.profile;

    if (this.props.wizardProps.currentStep === 1
      && ( !looking_for || !gender )) {
      this.props.onShowErrors(true)
      return;
    }

    if (this.props.wizardProps.currentStep === 2
      && ( !birth_date || !city || moment().diff(moment(birth_date), 'year') < 18 )) {
      this.props.onShowErrors(true)
      return;
    }

    if (this.props.wizardProps.currentStep === 3
      && ( !photo || !username || photo.size > 5 * 1024 * 1024
        || !['image/jpg', 'image/png', 'image/jpeg'].includes(photo.type) )) {
      this.props.onShowErrors(true)
      return;
    }

    this.props.onShowErrors(false)
    this.props.wizardProps.nextStep()
  }

  onComplete = async () => {
    const {
      email,
      password,
      password_confirmation,
      accepted_terms,
      accepted_responsibility,
      email_exists,
    } = this.props.profile;

    this.props.onShowErrors(true)

    if (email_exists || !email || !password || !password.match(/(?=.{8,})(?=.*?[^\w\s])(?=.*?[0-9])(?=.*?[A-Z]).*?[a-z].*/g) ||
        password !== password_confirmation || !accepted_terms || !accepted_responsibility) {
      this.props.wizardProps.goToStep(4);
      return;
    }

    this.setState({ saving: true })

    try {
      await registerUser(this.props.profile)
          .then(() => {
            toast.success("Demande d'inscription bien reçu. Vérifiez votre boîte mail pour confirmer votre adresse mail.")
            setTimeout(() => this.props.history.push("/login"), 750);
          });
    } catch (e) {
      let message = e.message
      if(e.response){
        message = e.response.data.message
        if(e.response.status === 422){
          message = e.response.data.errors.detail
        }
      }
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

    } finally {
      this.setState({ saving: false })
    }
  }

  get hideBack() {
    return this.props.wizardProps.currentStep === 1;
  }

  get hideNext() {
    return this.props.wizardProps.totalSteps === this.props.wizardProps.currentStep || this.props.wizardProps.totalSteps === 1
  }

  get hideComplete() {
    return this.props.wizardProps.totalSteps !== this.props.wizardProps.currentStep;
  }

  closeModal(){
    this.props.closeModal.current.handleCloseModal();
  }

  previousFunction(){
    if(this.props.isStepOne === true){
      if(this.props.isMobile){
        return this.props.history.push('/');
      }
    }else{
      return this.props.wizardProps.previousStep();
    }
  }

  render() {
    return (
      <>
        <div className={ "container-fluid pt-4" }>
          <div className={ "row" }>
            <div className={ "col-6 ps-0" }>
              <CustomButton
                secondary
                onClick={ ()=>{ this.previousFunction()} }
                label={ "Retour" }/>
            </div>
            <div className={ "col-6 pe-0" }>
              <CustomButton
                onClick={ this.onNext }
                hide={ this.hideNext }
                label={ "suivant" }/>
              <CustomButton
                isDisabled={ this.state.saving }
                onClick={ this.onComplete }
                hide={ this.hideComplete }
                label={ 'valider' }/>
            </div>
            <div
              className={`col-12 signUpFormContainer mt-3 ${
                !this.props.isMobile && "d-none"
              }`}>
              <EliteParagraph
                textColor={"#fff"}
                fontSize={"14px"}
                text={"Déjà inscrit ? "}>
                <span onClick={() => this.props.history.push("/login")}>
                  Connectez-vous
                </span>
              </EliteParagraph>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(NavigationFrontBack);
