import React, { useMemo, useRef, useState } from "react";
import ItemSelectorModal from "../Uikit/ItemSelectorModal/ItemSelectorModal";
import CustomModal from "../Uikit/Modal/Modal";
import { get, set } from 'lodash-es';
import Sexe from "./EditionComponents/Sexe";
import Jhabite from "./EditionComponents/Jhabite";
import Silhouette from "./EditionComponents/Silhouette";
import Cheveux from "./EditionComponents/Cheveux";
import Yeux from "./EditionComponents/Yeux";
import Origine from "./EditionComponents/Origine";
import Religieux from "./EditionComponents/Religieux";
import AEnfants from "./EditionComponents/aEnfants";
import VeuxEnfants from "./EditionComponents/VeuxEnfants";
import Langues from "./EditionComponents/Langues";
import Tabac from "./EditionComponents/Tabac";
import Sportive from "./EditionComponents/Sportive";
import Etudes from "./EditionComponents/Etudes";
import Activitie from "./EditionComponents/Activitie";
import Caractere from "./EditionComponents/Caractere";
import moment from "moment";
import Age from "./EditionComponents/Age";
import {TailleRadius} from "./EditionComponents/TailleRadius";

function generateForm() {
  return {
    age: {
      key: 'searching.age',
      type: 'range',
      label: "Âge",
      suffix: " (ans)",
      render(props = {}) {
        return ( <Age suffix={' ans'} { ...props } /> )
      },
    },
    taille: {
      key: 'searching.height',
      type: 'range',
      label: "Taille",
      suffix: " (cm)",
      render(props = {}) {
        return ( <TailleRadius suffix={' cm'} { ...props } /> )
      },
    },
    gender: {
      key: 'searching.gender',
      type: 'list',
      single: 'map',
      map: {
        F: 'Femme',
        H: 'Homme'
      },
      label: "Sexe",
      render(props = {}) {
        return ( <Sexe multiple { ...props } /> )
      },
    },
    shape: {
      type: 'list',
      label: "Silhouette",
      key: 'searching.shape',
      render(props = {}) {
        return <Silhouette multiple { ...props }/>
      },
    },
    hairColor: {
      type: 'list',
      label: "Couleur de cheveux",
      key: 'searching.hairColor',
      render(props = {}) {
        return <Cheveux multiple { ...props }/>
      },
    },
    eyeColor: {
      type: 'list',
      label: "Couleur des yeux",
      key: 'searching.eyeColor',
      render(props = {}) {
        return <Yeux multiple { ...props }/>
      },
    },
    origin: {
      type: 'list',
      label: "Origine",
      key: 'searching.origin',
      render(props = {}) {
        return <Origine multiple { ...props }/>
      },
    },
    religion: {
      type: 'list',
      label: "Religieux",
      key: 'searching.religion',
      render(props = {}) {
        return <Religieux multiple { ...props }/>
      },
    },
    hasChildren: {
      type: 'list',
      single: 'bool',
      label: "à des enfants",
      key: 'searching.hasChildren',
      render(props = {}) {
        return <AEnfants multiple { ...props }/>
      },
    },
    wantChildren: {
      type: 'list',
      single: 'bool',
      label: "Veux des enfants",
      key: 'searching.wantChildren',
      render(props = {}) {
        return <VeuxEnfants multiple { ...props }/>
      },
    },
    languages: {
      type: 'list',
      label: "Langue",
      key: 'searching.languages',
      render(props = {}) {
        return <Langues { ...props }/>
      },
    },
    address: {
      type: 'list',
      single: 'object',
      label: "Habite à",
      key: 'searching.city',
      map: {
        label: 'name',
        value: 'id'
      },
      render(props = {}) {
        return <Jhabite multiple { ...props }/>
      },
    },
    tabac: {
      type: 'list',
      single: 'map',
      label: "Tabac",
      key: 'searching.tabac',
      map: {
        occasional: "Oui, occasionnellement",
        regular: "Oui, régulièrement",
        tolerant: "Non (et tolère la fumée)",
        intolerant: "Non (et ne tolère pas la fumée)",
      },
      render(props = {}) {
        return <Tabac multiple { ...props }/>
      },
    },
    sport: {
      type: 'list',
      single: 'map',
      label: "Activité sportive",
      map: {
        regular: "Pratique régulièrement",
        occasional: "Occasionnellement",
        non: "Non",
      },
      key: 'searching.sport',
      render(props = {}) {
        return <Sportive multiple { ...props }/>
      },
    },
    studyLevel: {
      type: 'list',
      label: "Niveau d’études",
      key: 'searching.studyLevel',
      render(props = {}) {
        return <Etudes multiple { ...props }/>
      },
    },
    activityArea: {
      type: 'list',
      label: "Secteur d’activité",
      key: 'searching.activityArea',
      render(props = {}) {
        return <Activitie multiple { ...props }/>
      },
    },
    traits: {
      type: 'list',
      label: "Caractère",
      key: 'searching.traits',
      render(props = {}) {
        return <Caractere multiple { ...props }/>
      },
    },
  }
}

function formatValue(value, control) {
  if (value !== null) {
    if (control.type === 'bool') {
      return value ? 'Oui' : 'Non'
    }
    if (control.type === 'date') {
      return value ? moment(value).format('DD-MM-YYYY') : value
    }
    if (control.type === 'list') {
      return value.join ? value.map(v => formatValue(v, { type: control.single, map: control.map })).join(', ') : value
    }
    if (control.type === 'map') {
      return get(control.map, value, value)
    }
    if (control.type === 'object') {
      return get(value, control.map.label)
    }
    if (control.type === 'range') {
      return (value.min && value.max) ? `${value.min} - ${value.max}` : null
    }
  }
  return value;
}

function renderForm(user, openModal) {
  const controls = generateForm();
  return Object.keys(controls).map(key => {
    const control = controls[key];
    const value = get(user, control.key, '');
    const formattedValue = formatValue(value, control) + (formatValue(value, control) ? (!!control?.suffix?.length ? control.suffix : '') : '');

    return (
      <div key={ key }>
        <div
          className={ control.className || '' }
          onClick={ () => openModal(key) }>
          <ItemSelectorModal
            label={ control.label + ":" }
            value={ formattedValue }
          />
        </div>
      </div>
    )
  })
}

export function CeQueRecherche({ user, onChange, isMobile }) {
  const child = useRef()
  const [selected, setSelected] = useState()

  function openModal(key) {
    setSelected(key)
    child.current.handleOpenModal()
  }

  const [editableControl, editableControlProps] = useMemo(() => {
    let control = generateForm()[selected];
    if (control) {
      const controlProps = {
        isMobile:isMobile,
        title: control.label,
        value: get(user, control.key),
        closeModal() {
          child.current.handleCloseModal()
          setSelected(null)
        },
        onChange(value) {
          const profile = { ...user }
          set(profile, control.key, value)
          onChange(profile)
        }
      };
      return [control, controlProps]
    }
    return []
  }, [selected, user, onChange,isMobile])

  return (
    <>{ renderForm(user, openModal) }
      <CustomModal
        modalHeight={ isMobile ? '100%' : get(editableControl, 'customHeight') }
        modalContent={ editableControl && editableControl.render(editableControlProps) }
        modalClass={ isMobile ? "defaultProfileModalMobile" : "defaultProfileModal" }
        ref={ child }
      />
    </>
  );
}

export default CeQueRecherche;
