import React from "react";
import { useParams } from "react-router-dom";
import { useProfile } from "../../../Hooks/Profile";

export function UserProfileMobile() {
  const { id } = useParams()
  const { profile } = useProfile(id);
  return <>
    <pre>{ JSON.stringify(profile, null, 2) }</pre>
  </>
}
