import { useEffect, useMemo, useState } from "react";
import apiClient from "../Services/ApiServices/api.client";
import { each, findIndex, get, groupBy, keyBy, orderBy } from "lodash-es";
import moment from "moment";
import { subscribe } from "../Services/Mercure";
import { useStore } from "react-context-hook";
import { useNotifications } from "./Notifications";

function sortMessages(messages) {
  return orderBy(messages, 'createdAt', 'DESC');
}

function setMessagesAsRead(roomId) {
  return apiClient.post(`/api/rooms/${ roomId }/read`)
}

async function loadMessages({ id }, messages, page = 1) {
  const { data } = await apiClient.get(`/api/rooms/${ id }/messages?page=${ page }`)
  messages[id] = sortMessages([...( page === 1 ? [] : messages[id] ), ...data]);
  return messages;
}

async function getRoom({ id }) {
  const { data } = await apiClient.get(`/api/rooms/${ id }`)
  return data;
}

function categorizeMessages(messages, room) {
  const categories = { olderMessages: [], newerMessages: [], all: [] }
  if (room && messages) {
    categories.all = messages[room.id]
    return groupBy(categories.all, (message) => {
      let date = moment(message.createdAt);
      if (date.isAfter(moment().startOf('day'))) {
        return 'Aujourd\'hui';
      }
      if (date.isAfter(moment().subtract(1, 'day').startOf('day'))) {
        return 'Hier'
      }
      return date.format('DD/MM/YYYY');
    })
  }
  return categories;
}

async function sendMessage(room, content, files) {
  const message = new FormData();
  message.append('content', content);
  each(files, (file, i) => {
    message.append(`files[${ i }]`, file);
  })
  return apiClient.post(`/api/rooms/${ room.id }/messages`, message);
}

export function useMessages() {
  const [messages, setMessages] = useStore('messages', {});
  const [rooms, setRooms] = useStore('rooms', {});
  const [roomId, setRoomId] = useStore('selectedRoom', null);
  const { notifications } = useNotifications()
  const [sortBy, setSortBy] = useState('date');

  const filterActions = [
    { label: "date", action: () => setSortBy('date') },
    { label: "messages non lu", action: (e) => setSortBy('unread') },
    { label: "messages lu", action: (e) => setSortBy('read') },
  ];

  async function getRooms() {
    const { data } = await apiClient.get('/api/rooms')
    setRooms(keyBy(data, 'id'))
  }

  const sortedRooms = useMemo(() => {
    if (sortBy === 'date') {
      return orderBy(rooms, 'lastMessageAt', 'desc');
    }else if (sortBy === 'unread') {
      return orderBy(rooms, 'unreadCount', 'desc');
    }else if(sortBy === 'read') {
      return orderBy(rooms, 'unreadCount', 'asc');
    }
    return rooms;
  }, [rooms, sortBy])

  const selectedRoom = useMemo(() => {
    if (roomId) {
      return rooms[roomId]
    }
  }, [roomId, rooms])

  useEffect(() => {
    if (roomId) {
      getRoomMessages();
      return subscribe(`room-${ roomId }`, addMessage);
    }
    // eslint-disable-next-line
  }, [roomId])


  useEffect(() => {
    getRooms()
    // eslint-disable-next-line
  }, [notifications.rooms?.length])

  async function addMessage(message) {
    const current = get(messages, roomId, []);

    if (!current.length) {
      return getRoomMessages()
    }

    const index = findIndex(current, ({ id }) => id === message.id);
    if (index > -1) {
      current.splice(index, 1)
    }
    current.unshift(message);
    setMessages({
      ...messages,
      [roomId]: sortMessages(current)
    })
  }

  function updateRoom(room) {
    if (!Object.keys(rooms).length) {
      return getRooms();
    }
    setRooms({ ...rooms, [room.id]: room })
  }

  async function getRoomMessages() {
    if (roomId) {
      try {
        const data = await loadMessages({ id: roomId }, { ...messages })
        await setMessages(data)
        const updatedRoom = await getRoom({ id: roomId })
        updateRoom(updatedRoom)
      } catch(e) {
        // Todo handle
      }
    }
  }

  async function onSendMessage(content, files) {
    const { data: { message } } = await sendMessage(selectedRoom, content, files);
    setMessages({
      ...messages,
      [selectedRoom.id]: [...( messages[selectedRoom.id] || [] ), message]
    })
  }

  function setRoomLastReadAt(roomId) {
    setMessagesAsRead(roomId)
  }

  return {
    messages: categorizeMessages(messages, selectedRoom),
    rooms,
    sortedRooms,
    filterActions,
    selectedRoom,
    setRoomId,
    getRooms ,
    updateRoom,
    onSendMessage,
    setRoomLastReadAt
  }
}
