import React, { useMemo, useRef, useState } from "react";
import IciPour from "./EditionComponents/IciPour";
import ItemSelectorModal from "../Uikit/ItemSelectorModal/ItemSelectorModal";
import CustomModal from "../Uikit/Modal/Modal";
import { get, set } from "lodash-es";
import Sexe from "./EditionComponents/Sexe";
import Pseudo from "./EditionComponents/Pseudo";
import DateNaissance from "./EditionComponents/DateNaissance";
import Jhabite from "./EditionComponents/Jhabite";
import Taille from "./EditionComponents/Taille";
import Silhouette from "./EditionComponents/Silhouette";
import Cheveux from "./EditionComponents/Cheveux";
import Yeux from "./EditionComponents/Yeux";
import Astrologique from "./EditionComponents/Astrologique";
import Origine from "./EditionComponents/Origine";
import Religieux from "./EditionComponents/Religieux";
import AEnfants from "./EditionComponents/aEnfants";
import VeuxEnfants from "./EditionComponents/VeuxEnfants";
import SituationMaritale from "./EditionComponents/SituationMaritale";
import Langues from "./EditionComponents/Langues";
import Tabac from "./EditionComponents/Tabac";
import Sportive from "./EditionComponents/Sportive";
import Etudes from "./EditionComponents/Etudes";
import Activitie from "./EditionComponents/Activitie";
import Caractere from "./EditionComponents/Caractere";
import moment from "moment";

function generateForm() {
  return {
    hereFor: {
      key: "profile.hereFor",
      type: "list",
      label: "Ici pour",
      render(props = {}) {
        return <IciPour {...props} />;
      },
    },
    username: {
      key: "username",
      type: "text",
      label: "Pseudo",
      render(props = {}) {
        return <Pseudo {...props} />;
      },
    },
    gender: {
      key: "profile.gender",
      label: "Sexe",
      type: "map",
      map: {
        F: "Femme",
        H: "Homme",
      },
      render(props = {}) {
        return <Sexe {...props} />;
      },
    },
    birthDate: {
      type: "date",
      label: "Date de naissance",
      key: "profile.birthDate",
      render(props = {}) {
        return <DateNaissance {...props} />;
      },
    },
    address: {
      type: "object",
      label: "J’habite à",
      key: "profile.city",
      map: {
        label: "name",
        value: "id",
      },
      render(props = {}) {
        return <Jhabite {...props} />;
      },
    },
    height: {
      type: "text",
      label: "Taille (cm)",
      key: "profile.height",
      suffix: " (cm)",
      render(props = {}) {
        return <Taille {...props} />;
      },
    },
    shape: {
      type: "text",
      label: "Silhouette",
      key: "profile.shape",
      render(props = {}) {
        return <Silhouette {...props} />;
      },
    },
    hairColor: {
      type: "text",
      label: "Couleur de cheveux",
      key: "profile.hairColor",
      render(props = {}) {
        return <Cheveux {...props} />;
      },
    },
    eyeColor: {
      type: "text",
      label: "Couleur des yeux",
      key: "profile.eyeColor",
      render(props = {}) {
        return <Yeux {...props} />;
      },
    },
    astroSign: {
      type: "text",
      label: "Signe astrologique",
      key: "profile.astroSign",
      render(props = {}) {
        return <Astrologique {...props} />;
      },
    },
    origin: {
      type: "text",
      label: "Origine",
      key: "profile.origin",
      render(props = {}) {
        return <Origine {...props} />;
      },
    },
    religion: {
      type: "text",
      label: "Religieux",
      key: "profile.religion",
      render(props = {}) {
        return <Religieux {...props} />;
      },
    },
    hasChildren: {
      type: "bool",
      label: "A des enfants",
      key: "profile.hasChildren",
      render(props = {}) {
        return <AEnfants {...props} />;
      },
    },
    wantChildren: {
      type: "bool",
      label: "Veux des enfants",
      key: "profile.wantChildren",
      render(props = {}) {
        return <VeuxEnfants {...props} />;
      },
    },
    maritalStatus: {
      type: "text",
      label: "Situation maritale",
      key: "profile.maritalStatus",
      render(props = {}) {
        return <SituationMaritale {...props} />;
      },
    },
    languages: {
      type: "list",
      label: "Langue",
      key: "profile.languages",
      render(props = {}) {
        return <Langues {...props} />;
      },
    },
    tabac: {
      type: "map",
      label: "Tabac",
      key: "profile.tabac",
      render(props = {}) {
        return <Tabac {...props} />;
      },
    },
    sport: {
      type: "map",
      label: "Activité sportive",
      map: {
        regular: "Pratique régulièrement",
        occasional: "Occasionnellement",
        non: "Non",
      },
      key: "profile.sport",
      render(props = {}) {
        return <Sportive {...props} />;
      },
    },
    studyLevel: {
      type: "text",
      label: "Niveau d’études",
      key: "profile.studyLevel",
      render(props = {}) {
        return <Etudes {...props} />;
      },
    },
    activityArea: {
      type: "text",
      label: "Secteur d’activité",
      key: "profile.activityArea",
      render(props = {}) {
        return <Activitie {...props} />;
      },
    },
    traits: {
      type: "list",
      label: "Caractère",
      key: "profile.traits",
      render(props = {}) {
        return <Caractere {...props} />;
      },
    },
  };
}

function formatValue(value, control) {
  if (value !== null) {
    if (control.type === "bool") {
      return value ? "Oui" : "Non";
    }
    if (control.type === "date") {
      return value ? moment(value).format("DD-MM-YYYY") : value;
    }
    if (control.type === "list") {
      return value.join
        ? value
            .map((v) =>
              formatValue(v, { type: control.single, map: control.map }),
            )
            .join(", ")
        : value;
    }
    if (control.type === "map") {
      return get(control.map, value, value);
    }
    if (control.type === "object") {
      return get(value, control.map.label);
    }
    if (control.type === "range") {
      return `${value.min} - ${value.max}`;
    }
  }
  return value;
}

function renderForm(user, openModal) {
  const controls = generateForm();
  return Object.keys(controls).map((key) => {
    const control = controls[key];
    const value = get(user, control.key, "");
    const formattedValue =
      value !== null
        ? formatValue(value, control) +
          (!!control?.suffix?.length ? control.suffix : "")
        : value;

    return (
      <div key={key}>
        <div className={control.className || ""} onClick={() => openModal(key)}>
          <ItemSelectorModal
            label={control.label}
            value={formattedValue}
          />
        </div>
      </div>
    );
  });
}

export function ProfileDetailsEdition({ user, onChange, isMobile }) {
  const child = useRef();
  const [selected, setSelected] = useState();

  function openModal(key) {
    setSelected(key);
    child.current.handleOpenModal();
  }

  const [editableControl, editableControlProps] = useMemo(() => {
    let control = generateForm()[selected];
    if (control) {
      const controlProps = {
        isMobile: isMobile,
        title: control.label,
        value: get(user, control.key),
        closeModal() {
          child.current.handleCloseModal();
          setSelected(null);
        },
        onChange(value) {
          const profile = { ...user };
          set(profile, control.key, value);
          onChange(profile);
        },
      };
      return [control, controlProps];
    }
    return [];
  }, [selected, user, onChange, isMobile]);

  return (
    <>
      {renderForm(user, openModal)}
      <CustomModal
        modalHeight={isMobile ? "100%" : get(editableControl, "customHeight")}
        modalContent={
          editableControl && editableControl.render(editableControlProps)
        }
        modalClass={
          isMobile ? "defaultProfileModalMobile" : "defaultProfileModal"
        }
        ref={child}
      />
    </>
  );
}

export default ProfileDetailsEdition;
