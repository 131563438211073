import React, { useState } from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import ResponsiveService from "../../Services/ResponsiveService";
import { Helmet, HelmetProvider } from "react-helmet-async";
import datingWedding from "../../assets/images/dating-wedding-1.jpg";
import datingWedding2 from "../../assets/images/dating-wedding-2.jpg";
import BigTitle from "../../Components/Uikit/Typography/BigTitle";
import EliteSmallTitle from "../../Components/Uikit/Typography/SmallTitle";
import EliteParagraph from "../../Components/Uikit/Typography/Paragraph";

export function MariagePage() {
    const [isMobile, setIsMobile] = useState(
        ResponsiveService.isMobile()
    );
    
    const handleResize = (e) => {
        e.preventDefault();
        if (isMobile !== ResponsiveService.isMobile()) {
          setIsMobile({ isMobile: ResponsiveService.isMobile() });
        }
    };
    window.addEventListener("resize", handleResize.bind(this));

    return (
        <HelmetProvider>
        <>
            <Helmet>
                <title>N°1 du site de Rencontre pour Mariage – EliteBerbere.fr</title>
                <meta name="description" content="Elite Berbère est le site de rencontre 
                de référence pour une histoire sérieuse aboutissant à un mariage. Inscrivez-vous en quelques clics."/>
                <meta name="keywords" content="Votre personnalité, Relation durable, Âme-soeur, Célibataires, 
                Rencontrez, Rencontrer l amour, Premier rendez-vous, Faire connaissance, Grand amour, Relation amoureuse, 
                Sincère, Vie de couple, Femme célibataire, Rencontres réelles, Rejoignez, Permet de rencontrer, Coup de foudre, 
                Pour un mariage, Rencontre pour mariage, Amoureuse, Relation sérieuse, Affinités, Trouver l amour, 
                Faire des rencontres, Sérieuse"/>
            </Helmet>
            <Header />
            <div className="container">
                <div className="row p-4">
                    <div style={{textAlign: "justify"}} className="col-12">
                        <BigTitle
                            marginBottom={"5vh"}
                            titleColor={"#4e4e4e"}
                            textAlign={"left"}
                            text={"Un site de rencontre fiable pour un mariage heureux !"}
                        />
                        <EliteParagraph
                            text={<>
                                Vous arrangez votre coiffure une dernière fois devant le miroir avant de partir au restaurant.
                                Suite à votre inscription sur le <a href={"https://eliteberbere.fr"}>site de rencontre Élite Berbère</a>, le jour de votre premier
                                rendez-vous est arrivé. Votre nervosité est palpable. Rencontrer une personne contactée sur Internet
                                n'est pas anodin ! Pas de panique : il ne s'agit pour le moment que de <b>faire connaissance</b>.
                                Et si cette personne était la bonne ? Ce genre de choses n'arrive pas que dans les comédies romantiques !
                                Vous pourriez très bien avoir un <b>coup de foudre</b>. Vous retrouvez calme et confiance. Vous avez choisi un
                                <b>site de rencontre sérieux</b> qui connaît parfaitement vos attentes. Vous allez quoi qu'il arrive passer un
                                bon moment en compagnie d'une personne de qualité avec laquelle vous avez de nombreux points communs !
                                Et tout cela, grâce à un site de rencontre pour mariage.
                            </>}
                            textAlign={"justify"}
                            fontSize={"1.3em"}/>

                        <EliteSmallTitle
                            textAlign={"left"}
                            text={"Des rencontres personnalisées"} />
                        <EliteParagraph
                            text={<>
                                Pour vous permettre de rencontrer l'<b>âme sœur</b>, nous vous proposons une méthode qui repose sur les rencontres
                                par <b>affinités</b>. Nous établissons des profils de <b>célibataires</b> à partir d'études de personnalités que nous mettons
                                en place pour tous nouveaux inscrits sur notre site de rencontre pour mariage. Cela vous <b>permet de rencontrer </b>
                                des êtres qui vous ressemblent et qui, comme vous, sont prêts <b>pour le mariage</b> ! Nous ajoutons à cela vos propres
                                critères de recherche, par exemple le fait que vous ne souhaitiez échanger qu'avec des personnes appartenant à la
                                communauté berbère et prêtes elles aussi à s'engager. Vous avez ainsi l'assurance d'entrer en contact avec des gens
                                qui correspondent à vos attentes. Vous augmentez considérablement vos chances de <b>trouver l'amour</b> et de faire
                                un beau <b>mariage</b> !
                            </>}
                            textAlign={"justify"}/>
                        <img className={"img-fluid"} style={{marginBottom: "16px"}} src={ datingWedding } alt={ "wedding dating 1" }/>

                        <EliteSmallTitle
                            textAlign={"left"}
                            text={"Un site de rencontre pour mariage fiable et sécurisé"} />
                        <EliteParagraph
                            text={<>
                                Élite Berbère est un site spécialisé <b>sérieux</b> sur lequel vous pourrez <b>faire des rencontres</b> en toute confiance.
                                Tous les profils inscrits sont vérifiés afin d'éviter les fausses inscriptions. Une attention particulière est
                                accordée à la <b>personnalité</b> de chacun afin de mieux répondre à vos attentes. Nous attachons une importance
                                particulière à la protection de vos données personnelles qui restent confidentielles et que nous ne communiquons
                                jamais. Les hommes et les <b>femmes célibataires</b> apprécient notre discrétion. Cela leur permet d'augmenter leur
                                chance de débuter une relation amoureuse sans être dérangés par des personnes qu'ils ne souhaitent pas rencontrer.
                                Faites comme eux, passez à l'action et <b>rencontrez</b> des gens !
                            </>}
                            textAlign={"justify"}/>

                        <EliteSmallTitle
                            textAlign={"left"}
                            text={"Une inscription gratuite et rapide"} />
                        <EliteParagraph
                            text={<>
                                Nous avons pour ambition de vous permettre de faire une rencontre <b>pour un mariage</b>. Afin de ne pas vous faire
                                perdre de temps, le processus est simple. En trois étapes, vous serez inscrit sur notre site de rencontre pour
                                mariage, prêt à contacter d'autres célibataires et à débuter une <b>relation sérieuse</b> ! Nous privilégions les
                                <b>rencontres réelles</b> propices à la naissance du <b>grand amour</b>. Élite Berbère organise des événements uniques :
                                dîners, expositions, conférences, etc. Autant d'occasions qui favorisent les échanges <b>sincères</b> entre personnes
                                faisant partie de la même communauté. Avant de vous lancer dans une <b>vie de couple</b>, il est primordial de
                                prendre votre temps, de découvrir l'autre. Ces interactions sont parfaites pour y parvenir et oser vous lancer !
                                Vous n'avez plus qu'à vous laisser porter par la magie de l'instant présent. <b>Rejoignez</b> l'exigence et le sérieux
                                de <b>rencontres amoureuses</b> personnalisées en vous inscrivant sur le site Élite Berbère !
                            </>}
                            textAlign={"justify"}/>
                        <img className={"img-fluid"} style={{marginBottom: "16px"}} src={ datingWedding2 } alt={ "wedding dating 2" }/>
                    </div>
                </div>
            </div>
            <Footer />
        </>
        </HelmetProvider>
    );
}
