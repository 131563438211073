import React, { Component } from "react";
import CustomTextField from "../TextFields/TextFields";
import iconAdd from "../../../assets/images/icon-add-blue.svg";
import { ReactSVG } from "react-svg";
import Chips from "../Chips/Chips";

export class InputChips extends Component {
  constructor(props) {
    super(props);
    this.state = { chips: [] };
    this.inputOnChange = this.inputOnChange.bind(this);
    this.addChips = this.addChips.bind(this);
    this.removeChips = this.removeChips.bind(this);
  }

  inputOnChange(e) {
    this.addChips(e);
  }

  addChips(chipText) {
    this.setState({ chips: [...this.state.chips, chipText] });
  }
  removeChips(i) {
    const bk = this.state.chips;
    bk.splice(i, 1);
    this.setState({ chips: bk });
  }

  render() {
    return (
      <div>
        <CustomTextField
          callBack={this.inputOnChange}
          addOnSuffix={<ReactSVG src={iconAdd} />}
        />
        <div className="d-flex flex-wrap">
          {this.state.chips.map((text, i) => (
            <div className="p-1">
              <Chips showClose text={text} close={() => this.removeChips(i)} />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default InputChips;
