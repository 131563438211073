export const meetsMock = [
  {
    womanPhotoUrl: process.env.REACT_APP_API_URL + "/uploads/our-meets-1.jpg",
    womanName: "Sonia",
    manName: "Idir",
    womanAge: 36,
    manAge: 39,
    introText: "Grâce à Elite Berbère, j’ai pu rencontrer l’amour de ma vie. Au début j’étais septique des sites de rencontre... Mais aujourd’hui, je veux vous remercier grâce à votre site je suis de nouveau heureux.",
  },
  {
    womanPhotoUrl: process.env.REACT_APP_API_URL + "/uploads/our-meets-2.jpg",
    womanName: "Amina",
    manName: "Massinissa",
    womanAge: 29,
    manAge: 33,
    introText: "Je dois avouer, je ne cherchais pas spécialement un coup de foudre sur Elite Berbère, c’était juste par curiosité au départ. Puis, j’ai pu échanger avec un homme qui me plaisait vraiment beaucoup et maintenant il est mien. Merci à vous !",
  },
  {
    womanPhotoUrl: process.env.REACT_APP_API_URL + "/uploads/our-meets-3.jpg",
    womanName: "Lina",
    manName: "Farid",
    womanAge: 25,
    manAge: 27,
    introText: "Elite Berbère a vraiment changé ma vie ! Tout a été très vite, c’est la première fois que je faisais la connaissance d’une femme aussi géniale que Lina… Merci Merci Merci ! À vous.",
  },
  {
    womanPhotoUrl: process.env.REACT_APP_API_URL + "/uploads/our-meets-4.jpg",
    womanName: "Oujda",
    manName: "Samir",
    womanAge: 38,
    manAge: 40,
    introText: "J'ai rencontré mon homme sur Elite Berbère. J'ai pas eu beaucoup de chance c’est clair mais le mektoub fait bien les choses, les personnes du site étaient toutes sympas mais Samir !",
  },
  {
    womanPhotoUrl: process.env.REACT_APP_API_URL + "/uploads/our-meets-5.jpg",
    womanName: "Djida",
    manName: "Farid",
    womanAge: 50,
    manAge: 45,
    introText: "Je vous remercie d’avoir mis sur mon chemin l’homme que je ne pensais pas rencontrer un homme qui me rends heureuse et qui m’a redonné le sourire je vous dis aujourd'hui que je l’aime et vous remercie encore une fois pour tout.",
  },
  {
    womanPhotoUrl: process.env.REACT_APP_API_URL + "/uploads/our-meets-6.jpg",
    womanName: "Luna",
    manName: "Yusuf",
    womanAge: 33,
    manAge: 33,
    introText: "Je cherchais à me mettre en couple avec une personne qui ait les mêmes origines et religions que moi et je suis tombé sur le visage de cette femme qui me disait quelque chose. Après quelques échanges, on s’est reconnus. Merci à Élite Berbère.",
  },
  {
    womanPhotoUrl: process.env.REACT_APP_API_URL + "/uploads/our-meets-7.jpg",
    womanName: "Kenza",
    manName: "Amine",
    womanAge: 32,
    manAge: 36,
    introText: "Certaines copines m’avaient parlé d’un site de rencontre pour les rencontres exigeantes. J’ai rencontré grâce à ce site un homme. On s’est tout d’abord échangé des messages pendant une semaine et puis on a décidé de se voir.",
  },
];
