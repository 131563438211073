import React, {Component} from 'react';
import './ForgotPasswordMobileStyle.css';
import fullLogoColor from "../../../assets/images/logo-full-colo.png";
import BigTitle from "../../../Components/Uikit/Typography/BigTitle";
import CustomForgotPasswordMobile from '../../../Components/ForgoPasswordMobile/CustomForgotPasswordMobile';
import { withRouter } from "react-router";

class ForgotPasswordMobile extends Component {
  render() {
    return (
      <div
        className={
          "LoginContainer container-fluid d-flex flex-column align-items-center"
        }>
        <div className={"LoginImageContainer"} onClick={()=>this.props.history.push('/')}>
          <img src={fullLogoColor} alt={"logo full color"}></img>
        </div>
        <div className={"LoginTitlesContainer w-100 p-2"}>
          <BigTitle fontSize={'32px'} titleOpacity={0.1} text={"Mot de passe oublié ?"}></BigTitle>
          <div className={"LoginOverTitle"}>
            <BigTitle
              fontFamily={"Roboto"}
              fontSize={"26px"}
              fontWeight={"bold"}
              text={"Mot de passe oublié ?"}></BigTitle>
          </div>
          <CustomForgotPasswordMobile></CustomForgotPasswordMobile>
        </div>
      </div>
    );
  }
}

export default withRouter(ForgotPasswordMobile);
