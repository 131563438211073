import React, { Component } from "react";
import CustomSignUp from "../../../Components/SignUp/CustomSignUp";
import moment from "moment";

class SignUpMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: {
        gender: '',
        looking_for: '',
        birth_date: moment().subtract(18, "years").toDate().toISOString().slice(0,10),
        email: '',
        username: '',
        password: '',
        password_confirmation: '',
        city: '',
        zip: '',
        photo: undefined,
        email_exists: false,
        accepted_terms: false,
        accepted_responsibility: false,
      }
    };
  }

  render() {
    return(
      <>
      <div className={"h-100vh w-100vh position-relative"} >
          <CustomSignUp profile={ this.state.profile } on/>
      </div>
    </>
    )
  }
}

export default SignUpMobile;
