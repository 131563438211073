import React, { useState } from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import ResponsiveService from "../../Services/ResponsiveService";
import { Helmet, HelmetProvider } from "react-helmet-async";
import WeddingMaghreb from "../../assets/images/wedding-maghreb-1.png";
import WeddingMaghreb2 from "../../assets/images/wedding-maghreb-2.png";
import BigTitle from "../../Components/Uikit/Typography/BigTitle";
import EliteSmallTitle from "../../Components/Uikit/Typography/SmallTitle";
import EliteParagraph from "../../Components/Uikit/Typography/Paragraph";

export function MaghrebPage() {
    const [isMobile, setIsMobile] = useState(
        ResponsiveService.isMobile()
    );
    //const history = useHistory();
    
    const handleResize = (e) => {
        e.preventDefault();
        if (isMobile !== ResponsiveService.isMobile()) {
          setIsMobile({ isMobile: ResponsiveService.isMobile() });
        }
    };
    window.addEventListener("resize", handleResize.bind(this));

    return (
        <HelmetProvider>
            <>
                <Helmet>
                    <title>Site de Rencontre au Maghreb n°1 – Eliteberbere.fr</title>
                    <meta name="description" content="Inscrivez-vous sur Eliteberbere.com 
                    et rencontrez des maghrébins et maghrébines près de chez vous. Faites de nouvelles rencontres enrichissantes !"/>
                    <meta name="keywords" content="Nouer, Exigeants, Points communs, Compatibles, Rencontre un véritable, 
                    Site de rencontre fiable, Relation durable, Faire une rencontre, Rencontre par affinités, 
                    Attractive, Rencontres sérieuses, Célibataires, Trouver l amour, Faire des rencontres, 
                    Sérieuse, Site de rencontre, Âme-soeur, Faire connaissance, Votre personnalité, Affinité, 
                    Relation amoureuse, Rencontre musulmane, Amour maghreb, Rencontres simples, Rencontrer une femme"/>
                </Helmet>
                <Header />
                <div className="container">
                    <div className="row p-4">
                        <div className="col-12">
                            <BigTitle
                                marginBottom={"5vh"}
                                titleColor={"#4e4e4e"}
                                textAlign={"left"}
                                text={"Élite berbère, le premier site de rencontre au Maghreb"}
                            />
                            <EliteParagraph
                                text={<>
                                    Le Maghreb est une terre fascinante qui possède une population à la fois jeune et très dynamique.
                                    Empreints de spiritualité et de <b>culture musulmane</b>, les hommes et les femmes qui y vivent aspirent
                                    à réussir leur <b>vie amoureuse</b>. Pour y parvenir, rien de tel qu'une <b>agence de rencontre</b> qui comprenne
                                    parfaitement leurs attentes dans le <b>domaine amoureux</b>. Élite Berbère, le <a href={"https://eliteberbere.fr"}>site de rencontre berbère</a> par excellence,
                                    est le <b>partenaire idéal</b> pour celles et ceux qui veulent <b>rencontrer leur âme sœur</b> au <b>Maghreb</b>,
                                    dans le respect des traditions, mais avec les outils de la modernité.
                                </>}
                                textAlign={"justify"}
                                fontSize={"1.3em"}/>

                            <EliteSmallTitle
                                textAlign={"left"}
                                text={"Que propose Élite Berbère pour réussir ses rencontres au Maghreb ?"} />
                            <EliteParagraph
                                text={<>
                                    Nous proposons à notre clientèle des moyens efficaces pour
                                    optimiser leurs <b>rencontres amoureuses </b>
                                    et <b>trouver l'amour</b> au Maghreb. Nos clients sont exigeants et veulent le meilleur pour échanger
                                    entre homme et femme dans de bonnes conditions au Maghreb. Au cours de leurs recherches,
                                    ils font appel à nos services pour organiser des <b>rendez-vous</b> de qualité, basés sur un respect
                                    réciproque permettant de faire des <b>rencontres</b> de haut niveau, à la fois <b>simples</b> et <b>sérieuses</b>.
                                    Notre <b>site de rencontre au Maghreb</b> leur permet de <b>faire connaissance</b> sous les meilleurs auspices,
                                    chaque échange ayant été préparé en amont par nos conseillers. C'est grâce à cette approche professionnelle
                                    qu'Élite Berbère obtient un taux de réussite de ses <b>rencontres entre célibataires</b> particulièrement élevé au Maghreb.
                                </>}
                                textAlign={"justify"}/>
                            <img className={"img-fluid"} style={{marginBottom: "16px"}} src={ WeddingMaghreb } alt={ "wedding maghreb 1" }/>

                            <EliteSmallTitle
                                textAlign={"left"}
                                text={"Élite Berbère, le site de rencontre au Maghreb pour nouer des relations amoureuses exceptionnelles"} />
                            <EliteParagraph
                                text={<>
                                    Nous nous mettons au diapason des exigences de notre clientèle, qui souhaite rencontrer
                                    des gens triés sur le volet. Voilà ce qui fait notre différence, et même notre excellence.
                                    Nous sommes très attentifs aux demandes formulées par celles et ceux qui nous font confiance
                                    pour donner un nouvel élan à leur <b>vie amoureuse</b>. Nous sommes un <b>site de rencontre au Maghreb
                                    fiable</b> qui permet de <b>construire des relations durables</b> au travers de <b>rencontres musulmanes</b> sérieuses.
                                    Nous sommes à l'écoute de tous les <b>points communs</b> que nos clientes et clients souhaitent retrouver
                                    chez leur futur <b>partenaire</b> de vie. Grâce à cette approche, il nous est possible de satisfaire toutes
                                    les exigences permettant de <b>nouer</b> de vraies <b>relations amoureuses au Maghreb</b>.
                                </>}
                                textAlign={"justify"}/>

                            <EliteSmallTitle
                                textAlign={"left"}
                                text={"Élite Berbère, la solution idéale pour trouver l'amour au Maghreb"} />
                            <EliteParagraph
                                text={<>
                                    Le monde change, les mentalités évoluent, les moyens de se rencontrer aussi.
                                    Mais les désillusions peuvent également être nombreuses, si l'on ne confie
                                    pas sa destinée amoureuse aux bonnes personnes. C'est pourquoi nous vous mettons en garde,
                                    car il est assez commun de croiser des amateurs dans nos métiers. Rien de cela avec Élite Berbère,
                                    qui est une agence labellisée qui est présente depuis plusieurs années aussi bien en Europe qu'au Maghreb,
                                    et jusqu'en Amérique du Nord. C'est pour vous une garantie de sérieux qui va vous permettre d'aborder vos
                                    nouvelles <b>relations amoureuses</b> en toute sérénité. C'est un point important, car il vous faut être vous-même,
                                    vous sentir à l'aise, pour réussir votre parcours amoureux avec Élite Berbère, le <b>site de rencontre au Maghreb</b>.
                                </>}
                                textAlign={"justify"}/>
                            <EliteParagraph
                                text={<>
                                    Il est maintenant temps d'obtenir la <b>relation amoureuse</b> que vous méritez.
                                    Avec Élite Berbère Maghreb, vous allez vraiment <b>trouver l'amour</b>. C'est une certitude.
                                </>}
                                textAlign={"justify"}/>
                            <img className={"img-fluid"} style={{marginBottom: "16px"}} src={ WeddingMaghreb2 } alt={ "wedding maghreb 2" }/>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        </HelmetProvider>
    );
}
