import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import "./Offers.css";
import closeButton from "../../assets/images/btn-close.svg";
import TitleOverTitle from "../Uikit/TitleOverTitle/TitleOverTitle";
import EliteParagraph from "../Uikit/Typography/Paragraph";
import UserService from "../../Services/ApiServices/UserService";
import CardOffer from "./CardOffer";
import {handleError} from "../../Services/ErrorHandler";
import {useCurrentUser} from "../../Hooks/AuthenticatedUser";

export default function ChooseOffer(props) {
  const [subscriptions, setSubscriptions] = useState([]);
  const [reloadSubscription, setReloadSubscription] = useState(0);
  const {user} = useCurrentUser();
  const [currentUserCurrency, setCurrentUserCurrency] = useState(user.devise);


    useEffect(() => {
    async function fetchDescriptions() {
      await UserService.getSubscriptions().then((res) => {
        setSubscriptions(res.data.list);
      });
    }
    if(typeof reloadSubscription !== 'undefined') {
          fetchDescriptions();
    }
  }, [reloadSubscription]);

    async function updateCurrency(newCurrency) {
        try {
                await UserService.updateDevise(newCurrency).then(()=> {
                    setReloadSubscription(reloadSubscription + 1);
                })
                setCurrentUserCurrency(newCurrency)
        } catch (e) {
            handleError(e);
        }
    }

  return (
    <div className="Offers">
      <TitleOverTitle
        backTitleColor={"rgba(245, 245, 245,0.2)"}
        frontTitleColor={"#fff"}
        backTitleSize={"60px"}
        frontTitleSize={"32px"}
        title={"Nos offres"}
        overTitleMarginTop={"-55px"}
        centerTitle
      />

      <EliteParagraph
        // text={"Quel type de diamant êtes-vous ?"}
        uppercase
        textAlign={"center"}
        textColor={"#f9fa00"}
        fontSize={"16px"}
        fontWeight={"500"}
        margin={'0 0 10px 0'}
      />

      <div className={"d-flex flex-row mt-8 justify-content-center flex-wrap"}>
        {subscriptions.map((details, idx) => (
          <div key={idx} className="flex-column mx-2 customOfferCardWrapper">
            <CardOffer customOffer idx={idx} sliderData={details} updateCurrency={ updateCurrency } devise={currentUserCurrency}/>
          </div>
        ))}
      </div>

      <div className="container">
        <div className="row">
          <div className="col-4">
            <div className="teaser">
              <ul>
                <li>Accéder aux profils</li>
                <li>Communication illimitée</li>
              </ul>
            </div>
          </div>
          <div className="col-4">
          <div className="teaser">
              <ul>
                <li>Matching sur mesure (5/jour)</li>
                <li>Accès aux évènements organisés chaque mois : diner, soirée, conférence</li>
              </ul>
            </div>
          </div>
          <div className="col-4">
          <div className="teaser">
              <ul>
                <li>Consulter vos affinités (Visite/Like)</li>
                <li>Accès aux photos et contenu des membres</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        className={"closeButtonLevitate d-flex flex-column align-items-center"}
        style={{ marginTop: props.isMobile ? "16px" : "0px" }}>
        <ReactSVG
          style={{ cursor: "pointer" }}
          src={closeButton}
          onClick={() =>
            props.closeModal.current.handleCloseModal()
          }></ReactSVG>
      </div>
    </div>
  );
}
