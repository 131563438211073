import React, { useEffect, useState } from "react";
import "react-credit-cards/es/styles-compiled.css";
import { EliteRouters } from "../../../Navigation/Routers";
import backArrow from "../../../assets/images/icon-arrow-back.svg";
import UserService from "../../../Services/ApiServices/UserService";
import { useLocation, useParams } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import "../CheckoutStyles.css";
import { toast } from "react-toastify";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { getPaypalClientId } from "../../../Services/ApiServices/api.client";
import Spinner from "react-bootstrap/esm/Spinner";
import ConnectedHeader from "../../../Components/Header/ConnectedHeader/ConnectedHeader";
import { ReactSVG } from "react-svg";
import CardOffer from "../../../Components/Offers/CardOffer";

import jcb from "../../../assets/images/payments/jcb.png";
import visa from "../../../assets/images/payments/visa.png";
import amex from "../../../assets/images/payments/amex.png";
import mastercard from "../../../assets/images/payments/mastercard.png";

export function CheckoutMobile(props) {
  const location = useLocation();
  const queryString = props.location.search;

  const {selectedSub } = useParams()

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

  const [subscriptionId, setSubscriptionId] = useState();
  const [isBoostOffer, setIsBoostOffer] = useState();
  const [sub, setSub] = useState();
  const [clientId, setClientId] = useState("");
  const [plans, setPlans] = useState(null);

  let subscription = {
    isBoostOffer: false,
    paymentSubscriptionId: "",
    subscriptionId: "",
    devise: "",
  };

  useEffect(() => {
    const query = new URLSearchParams(queryString);
    if (query && query.get("success") === "true") {
      // set up
      UserService.createUserSubscription({
        subscriptionId: query.get("sub_id"),
        paymentSubscriptionId: query.get("session_id"),
        isBoostOffer: query.get("is_boost"),
        module_subscription_type: "stripe",
      }).then(async () => {
        if (query.get("is_boost") === "1") {
          toast.success("Merci d'avoir souscrit à un booster.");
        } else {
          toast.success(
            "Votre abonnement a bien été validé, Bienvenue à vous !"
          );
        }

        await UserService.getCurrentSubscription();
        props.history.push("/home");
      });
    } else if (query && query.get("success") === "false") {
      // toast payment failure
    }
  }, [queryString, props.history]);

  useEffect(() => {
    const fetchPlans = async () => {
      await UserService.getPlansFromPayPal().then((res) => {
        setPlans(res.data.plans);
      });
    };

    fetchPlans();
  }, []);

  useEffect(() => {
    getPaypalClientId().then((res) => {
      setClientId(res.clientId);
    });
  }, []);

   useEffect(() => {
     const query = new URLSearchParams(location.search);
     let boost = query && query.get("boost") === "true";
     setIsBoostOffer(boost);

     const setSubscription = (res) => {
       let list = res.data.list;
       let sub_ = list.find((i) => i.name.toLowerCase() === selectedSub);

       if (sub_ && sub_.id) {
         setSub(sub_);
         setSubscriptionId(sub_.id);
       }
     };

     if (boost) {
       UserService.getBoostSubscriptions().then((res) => setSubscription(res));
     } else {
       UserService.getSubscriptions().then((res) => setSubscription(res));
     }

   }, [location, selectedSub]);

  const getSubscFromPaypal = async (subscriptionid) => {
    await UserService.getSubscriptionFromPayPal(subscriptionid).then(
      (response) => {
        subscription.isBoostOffer = isBoostOffer;
        subscription.paymentSubscriptionId = subscriptionid;
        subscription.subscriptionId = subscriptionId;
        subscription.devise = sub.devise;
      }
    );
  };

  const subscribeStripe = async () => {
    const {
      data: { id },
    } = await UserService.subscribeStripe(
      { id: sub.id, currency: sub.devise, isBoostOffer },
      sub.id
    );
    return handleRedirectionStripe(id);
  };

  const handleRedirectionStripe = async (sessionId) => {
    const stripe = await stripePromise;
    await stripe.redirectToCheckout({ sessionId });
  };

  const SubCardOffer = () => {
    let s = sub;

    if (s) {
      return (
        <div className="checkOutCard">
          <CardOffer
            cardHeight="auto"
            hideAction={true}
            disableCurrencyChange={true}
            sliderData={s}
          />
        </div>
      );
    } else {
      return <></>;
    }
  };

  const StripePayments = () => {
    let cardPayments = [visa, mastercard, jcb, amex],
      OptionsList = cardPayments.map((i) => {
        return <img height="50px" className="mx-2" src={i} alt="" key={i} />;
      });

    return (
      <>
        <div className="">
          <fieldset
            style={{
              border: "1px solid #e0e0e0",
              borderRadius: "5px",
            }}
          >
            <div className="d-flex justify-content-center px-2 py-4" onClick={subscribeStripe}>
              {OptionsList}
            </div>
          </fieldset>
        </div>
      </>
    );
  };

  const PaypalOption = () => {
    if (clientId && plans) {
      return (
        <div>
          <PayPalScriptProvider
            options={{ "client-id": clientId, vault: true }}
          >
            <PayPalButtons
              fundingSource="paypal"
              style={{
                layout: "vertical",
                color: "black",
                label: "pay",
              }}
              createSubscription={(data, actions) => {
                const plan = plans.filter(
                  (pl) =>
                    pl.name.includes(sub.devise) &&
                    pl.name.includes(sub.name) &&
                    pl.status === "ACTIVE"
                );
                console.trace(actions);
                return actions.subscription.create({
                  plan_id: plan[0].id,
                });
              }}
              onApprove={async (data, actions) => {
                await getSubscFromPaypal(data.subscriptionID);
                await UserService.createUserSubscription({
                  ...subscription,
                  module_subscription_type: "paypal",
                  devise: sub.currency,
                });

                console.log(sub);
                await UserService.getCurrentSubscription();
                toast.success("Souscription effectuée avec succès");
                props.history.push("/home");
              }}
            />
          </PayPalScriptProvider>
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" />
        </div>
      );
    }
  };
  return (
    <>
      <div>
        <ConnectedHeader isCheckout="true" />
        {
          <div className="container">
            <div className="d-flex my-4">
              <button
                onClick={() => {
                  props.history.push(EliteRouters.home.path);
                }}
                className="d-inline-flex btn"
                style={{
                  textDecoration: "none",
                  boxShadow: "none",
                }}
              >
                <ReactSVG src={backArrow}></ReactSVG>
                <span
                  className=""
                  style={{
                    fontSize: "20px",
                    display: "inline-block",
                    color: "#34D10E",
                    marginLeft: "32px",
                  }}
                >
                  Retour
                </span>
              </button>
            </div>

            <div className="">
              <div className="mb-5">
                <SubCardOffer />
              </div>
              <div className="">
                <p
                  style={{
                    color: "#6C95E7",
                    textTransform: "uppercase",
                    marginBottom: "20px",
                  }}
                >
                  Valider votre abonnement avec un moyen de paiement sécurisé ci-dessous :
                </p>
                <div className="">
                  <div className="">
                    <button
                      className="btn d-inline-flex align-items-center justify-content-center border w-100 stripe-checkout"
                      onClick={subscribeStripe}
                    >
                      <span>
                        Payer avec une&nbsp;
                      </span>
                      <span
                          style={{
                            fontWeight: "600",
                          }}
                      >
                        CARTE BANCAIRE
                      </span>
                    </button>
                  </div>
                  <div className="mt-5">
                    <StripePayments />
                  </div>
                  <div className="py-5">
                    <strong className="d-block text-center mb-0">Ou</strong>
                  </div>
                  <div className="">
                    <PaypalOption />
                  </div>
                </div>

                <div className="my-5">
                  <p
                    className="text-center"
                    style={{
                      fontSize: "12px",
                      lineHeight: "20px",
                    }}
                  >
                    Une fois le moyen de paiement sélectionné, une nouvelle
                    fenêtre sera ouverte, veuillez suivre les indications afin
                    de valider votre abonnement.
                  </p>
                </div>

                <div className="mt-5 pb-5">
                  <div
                    className="text-center"
                    style={{
                      fontSize: "12px",
                      lineHeight: "20px",
                      fontWeight: "300",
                    }}
                  >
                    <p>
                      En activant votre abonnement, vous autorisez SAS ÉLITE
                      BERBÈRE à débiter votre carte pour ce paiement et les
                      paiements futurs conformément à ses conditions.
                    </p>

                    <p
                      className="mt-4"
                      style={{
                        color: "#6c95e7",
                        fontStyle: "italic",
                      }}
                    >
                      <a
                        style={{ color: "inherit", textDecoration: "none" }}
                        target="__blank"
                        href="/terms-conditions"
                      >
                        Politique de confidentialité
                      </a>
                      <span> - </span>
                      <a
                        style={{ color: "inherit", textDecoration: "none" }}
                        target="__blank"
                        href="/legal-notice"
                      >
                        Conditions générales
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </>
  );
}
