import React, { useEffect } from "react";
import { ReactSVG } from "react-svg";
import ConnectedHeader from "../../Components/Header/ConnectedHeader/ConnectedHeader";
import backArrow from "../../assets/images/icon-arrow-back.svg";
import Footer from "../../Components/Footer/Footer";
import EliteParagraph from "../../Components/Uikit/Typography/Paragraph";
import AProposDeMoi from "../Profile/AProposDeMoi";
import JeRecherche from "../Profile/JeRecherche";
import CustomTabs from "../../Components/Uikit/Tabs/Tabs";
import infosReligion from "../../assets/images/infos-religion.svg";
import iconDiscuter from "../../assets/images/icon-chat-bot.svg";
import iconDiscuterDisabled from "../../assets/images/icon-chat-grey.svg";
import iconLoveEmpty from "../../assets/images/icon-love-empty-bot.svg";
import iconLoveFull from "../../assets/images/icon-love-full-bot.svg";
import Tags from "../../Components/Uikit/Tags/Tags";
import CustomButton from "../../Components/Uikit/Button/CustomButton";
import { useLiked } from "../../Hooks/Likes";
import { useHistory, useParams } from "react-router-dom";
import { useProfile } from "../../Hooks/Profile";
import "./VisitedProfileStyles.css";
import PhotoGallery from "../../Components/Uikit/PhotoGallery/PhotoGallery";
import { hasValidSubscription } from "../../Services/ApiServices/api.client";
import { handleError } from "../../Services/ErrorHandler";
import { origins } from "../../Services/DataLists";

export default function VisitedProfile(props) {
  const history = useHistory();
  const { toggleLike, isLiked } = useLiked();
  const { id } = useParams();
  const { error, profile } = useProfile(id);

  useEffect(() => {
    if (!hasValidSubscription()) {
      history.push("/home");
    }
  }, [history]);

  if (!profile && !error) {
    return <div></div>;
  }

  if (error) {
    return <h1>{error.message}</h1>;
  }

  async function onToggleLike(e) {
    e.stopPropagation();
    try {
      await toggleLike(profile);
    } catch (e) {
      handleError(e);
    }
  }

  function openChat() {
    history.push(`/messages/${profile.room?.id || ""}`);
  }

  function getImageLabel(value) {
    let imageLabel = "";
    origins.forEach((e) => {
      if (value && e.value === value) {
        imageLabel = e.imageLabel;
      }
    });
    return imageLabel;
  }

  const tabsItems = [
    {
      menuItem: (
        <EliteParagraph
          textAlign={"left"}
          uppercase
          fontWeight={500}
          margin={"0"}
          text={"à propos de moi"}
        />
      ),
      menuContent: (
        <>
          <EliteParagraph
            lineHeight={1.5}
            fontWeight={400}
            textAlign={"left"}
            fontSize={"14px"}
            text={profile.profile.description}
          />
          <AProposDeMoi user={profile} />
        </>
      ),
    },
    {
      menuItem: (
        <EliteParagraph
          uppercase
          textAlign={"left"}
          fontWeight={500}
          margin={"0"}
          text={"Ce que je recherche"}
        />
      ),
      menuContent: (
        <>
          <EliteParagraph
            lineHeight={1.5}
            fontWeight={400}
            textAlign={"left"}
            fontSize={"14px"}
            text={profile.searching.description}
          />
          <JeRecherche user={profile} />
        </>
      ),
    },
  ];

  if (!profile) {
    return (
      <>
        <h1>Profile not found</h1>
      </>
    );
  }

  let origin  = '-';

  if(profile.profile.origin) {
    origin = profile.profile.origin;
  }

  return (
    <>
      <ConnectedHeader />
      <div className={"container"}>
        <div className="row">
          <div className="col-2"></div>
          <div className="col-8">
            <div className={"row"}>
              <div className={"col-2"}>
                <div className={"back py-5"}>
                  <div onClick={() => props.history.goBack()}>
                    <ReactSVG src={backArrow} /> Profil
                  </div>
                </div>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col-lg-6 col-md-12"}>
                <div
                  className={
                    "d-flex flex-column position-relative container-pic"
                  }>
                  <PhotoGallery
                    data={profile.images || []}
                    additionalData={profile}
                  />
                  <div className="d-flex justify-content-between mt-2">
                    <Tags
                      icon={`/images/flags/${getImageLabel(
                        origin,
                      )}.png`}
                      smallText={"origine"}
                      text={
                        origin
                      }
                    />
                    <Tags
                      icon={infosReligion}
                      smallText={"je suis"}
                      text={profile.profile.religion}
                    />
                  </div>
                </div>
              </div>
              <div className={"col-lg-6 col-md-12 d-flex flex-column"}>
                <div className={"row"}>
                    <div className="col-lg-6 col-md-12">
                      <CustomButton
                        icon={typeof profile.room?.id === "undefined" ? iconDiscuterDisabled : iconDiscuter}
                        label={"Discuter"}
                        onClick={openChat}
                        isDisabled={typeof profile.room?.id === "undefined"}
                      />
                    </div>

                  <div className="col-6">
                    <CustomButton
                      icon={isLiked(profile) ? iconLoveFull : iconLoveEmpty}
                      secondary
                      textGreen
                      label={isLiked(profile) ? "UNLIKER" : "LIKER"}
                      onClick={onToggleLike}
                    />
                  </div>
                  {
                    typeof profile.room?.id === "undefined" &&
                    <div className="col-12" style={{fontSize: 12, paddingTop: 10, color: '#bcbcbc', fontWeight: 'bold'}}>
                      Les conversations se lancent automatiquement, lorsque 2 utilisateurs se sont respectivement likés.
                    </div>
                  }
                  <div className="clearfix" />
                </div>
                <div className="mt-4">
                  <CustomTabs tabs={tabsItems} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-2"></div>
        </div>
      </div>
      <Footer isLogged />
    </>
  );
}
