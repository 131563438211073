import React, { useMemo } from "react";
import CustomTextField from "../Uikit/TextFields/TextFields";
import BigTitle from "../Uikit/Typography/BigTitle";
import EliteParagraph from "../Uikit/Typography/Paragraph";
import { ReactSVG } from "react-svg";
import closeButton from "../../assets/images/btn-close.svg";
import "./ForgotPasswordModalStyles.css";
import CustomButton from "../Uikit/Button/CustomButton";
import iconEmailDark from "../../assets/images/icon-mail-dark.svg";
import UserService from "../../Services/ApiServices/UserService";
import { toast } from "react-toastify";
import iconLock from "../../assets/images/icon-lock.svg";
import { useHistory, useParams } from "react-router-dom";
import { ErrorMessage, Form, Formik } from "formik";
import * as yup from "yup";

function renderFields(resetMode, setFieldValue) {
  if (resetMode) {
    return [
      <CustomTextField
        placeholder={"Nouveau mot de passe"}
        type="password"
        onChange={(val) => setFieldValue("password", val)}
        addOn={iconLock}
      />,
      <ErrorMessage
        component="div"
        className={"invalid-feedback d-block"}
        name="password"
      />,
      <CustomTextField
        placeholder={"Confirmez mot de passe"}
        type="password"
        onChange={(val) => setFieldValue("passwordConfirmation", val)}
        addOn={iconLock}
      />,
      <ErrorMessage
        component="div"
        className={"invalid-feedback d-block"}
        name="passwordConfirmation"
      />,
    ];
  }
  return [
    <CustomTextField
      type="email"
      onChange={(val) => setFieldValue("email", val)}
      placeholder={"E-mail"}
      addOn={iconEmailDark}
    />,
    <ErrorMessage
      component="div"
      className={"invalid-feedback d-block"}
      name="email"
    />,
  ];
};

function ForgotPasswordModal({ closeModalContent }) {
  const history = useHistory();
  const resetMode = useMemo(
    () => history.location.pathname.match(/^\/reset-password/),
    [history.location.pathname],
  );
  const { token } = useParams();

  function onClose() {
    closeModalContent.current.handleCloseModal();
  }

  async function onSubmit({ email, password }) {
    if (resetMode) {
      try {
        await UserService.resetPassword(password, token);
        toast.success("Mot de passe changé");
        onClose();
        setTimeout(() => (window.location.href = "/login"), 500);
      } catch (e) {
        toast.error("Une erreur est survenue");
      }
    } else {
      try {
        await UserService.forgotPassword(email);
      } finally {
        toast.success("Email envoyé");
        onClose();
      }
    }
  }

  const validationSchema = yup.object().shape(
    !resetMode
      ? {
          email: yup.string().required("Champ obligatoire").email(),
        }
      : {
          password: yup
            .string()
            .required("Champ obligatoire")
            .matches(
              /(?=.{8,})(?=.*?[^\w\s])(?=.*?[0-9])(?=.*?[A-Z]).*?[a-z].*/g,
              "Votre mot de passe doit contenir 8 caractères incluant lettre capitale, une minuscule, un caractère spécial ainsi qu’un nombre",
            ),
          passwordConfirmation: yup
            .string()
            .required("Champ obligatoire")
            .test({
              name: "confirmation",
              message: "Veuillez renseigner 2 mots de passe identiques",
              test: (value, { parent }) => value && value === parent.password,
            }),
        },
  );

  return (
    <>
      <div className={"container-fluid d-flex flex-column p-4"}>
        <BigTitle
          titleColor={"#6c95e7"}
          fontSize={24}
          fontFamily={"Roboto"}
          fontWeight={"bold"}
          text={"Mot de passe oublié ?"}
        />
        <div className={"pt-2"}>
          <EliteParagraph
          margin={'0 0 24px 0'}
            text={
              resetMode
                ? "Entrez votre nouveau mot de passe"
                : "Un email vous sera envoyé pour réinitialiser votre mot de passe."
            }
          />
        </div>
        <Formik
          initialValues={{ email: "", password: "", passwordConfirmation: "" }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}>
          {({ isSubmitting, setFieldValue, values }) => (
            <Form className={"needs-validation"}>
              <div className={`has-validation mb-2`}>
                {renderFields(resetMode, setFieldValue, values)}
              </div>
              <div>
                <CustomButton
                  type="submit"
                  isDisabled={isSubmitting}
                  label={"Envoyer"}
                />
              </div>
            </Form>
          )}
        </Formik>
        <div className={"closeModalForgotPassword"}>
          <div
            className={
              "closeButtonLevitate d-flex flex-column align-items-center"
            }>
            <ReactSVG
              style={{ cursor: "pointer" }}
              src={closeButton}
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPasswordModal;
